/* eslint-disable react/prop-types */
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Col,
    Row,
    Empty
} from 'antd';

import { isEmpty } from 'underscore';
import Layout from '../../components/layout/Layout';
import Avatar from '../../components/elements/Avatar';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';
import AssetUpdateForm from './forms/AssetUpdateForm';
import history from '../../core/utils/history';
import CustomEmpty from '../../components/CustomEmpty';

export default function () {
    const { asset, isFetching, user } = this.props;

    const isLoading = isFetching && isFetching.length > 0;

    return (
        <Layout
            className="assets"
            title={<div>Assets</div>}
            headerTitle={<div>Assets Page</div>}
            showFooter={false}
            loading={isLoading}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <div 
                    className="content"
                    style={{ opacity: isLoading ? 0 : 1 }}
                >
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <br />
                                <div
                                    className="section__action-bar justify-center"
                                    style={{ marginBottom: '16px' }}
                                >
                                    <span className="asset-name-label">Edit {asset.name}</span>
                                </div>
                                <Row
                                    gutter={40}
                                    type="flex"
                                    justify="space-between"
                                    className="data-row">
                                    <Col xl={6}>
                                        <AssetUpdateForm
                                            initialValues={asset}
                                            updateForm
                                        />
                                    </Col>
                                    <Col xl={11}>
                                        <div className="actions">
                                            <Row
                                                type="flex"
                                                gutter={40}
                                                justify="space-between">
                                                <Col>
                                                    <br />
                                                    <Avatar placeholder="car" pictureUrl={asset.pictureThumb || null} onChange={this.uploadImage} />
                                                </Col>
                                                <Col>
                                                    <div className="actions__buttons">
                                                        <Button
                                                            form="asset-form"
                                                            htmlType="submit"
                                                            type="primary"
                                                        >
                                                            Save Changes
                                                        </Button>
                                                        <br />
                                                        <Button
                                                            onClick={() => history.push(`/assets/view/${asset.id}`)}
                                                            type="primary"
                                                        >
                                                            View Asset
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
