/* eslint-disable react/no-unused-state */
/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import DOM from './viewAsset';
import { isoStandardDateTimeFormat, getTodayInServerFormat, getYesterdayInServerFormat } from '../../core/utils/dateUtils';
import * as driverActions from '../../core/driver/driverActions';
import * as assetActions from '../../core/asset/assetActions';

const { DB_DATE_FORMAT } = require('../../core/constants').default;

class ViewAsset extends React.PureComponent {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.googleMapAccess = React.createRef();

        const { assetJourneyFilters } = this.props;

        this.state = {
            currentTabKey: '1',
            journeyHistoryTabLoaded: false,
            selectedAssetLocation: '',
            journeyHistoryTabFilter: {
                asset_info: '',
                division: '',
                registration_year: '',
                make: '',
                fuel_type: '',
                current_location: '',
                page: 1,
                date_from: getYesterdayInServerFormat(),
                date_to: getTodayInServerFormat(),
            },
            eventHistoryTabLoaded: false,
            eventsTabFilter: {
                asset_info: '',
                division: '',
                registration_year: '',
                make: '',
                fuel_type: '',
                current_location: '',
                page: 1,
                date_from: getYesterdayInServerFormat(),
                date_to: getTodayInServerFormat(),
            },
            videoHistoryTabLoaded: false,
            videoHistoryTabFilter: {
                asset_info: '',
                division: '',
                registration_year: '',
                make: '',
                fuel_type: '',
                current_location: '',
                page: 1,
                date_from: getYesterdayInServerFormat(),
                date_to: getTodayInServerFormat(),
            },
            serviceAlertsTabLoaded: false,
            serviceAlertsTabFilter: {
                asset_info: '',
                division: '',
                registration_year: '',
                make: '',
                fuel_type: '',
                current_location: '',
                page: 1,
            },
            deleteDataConfirmButtonVisible: false,
            isDeleteDataModalVisible: false,
            deleteDataModalInput: null,
            displayFilter: true,
        };
    }

    componentDidMount() {
        const { match, singleAsset, driverList } = this.props;
        const { asset_id } = match.params;
        const { actions } = this.props;
        actions.getAssetInfoRequest(asset_id);
        if (driverList.length <= 0) {
            actions.getDriverListRequest();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { singleAsset } = this.props;
        if (this.googleMapAccess && this.googleMapAccess.current) {
            const gooleInnerMap = this.googleMapAccess.current?.state.map;
            if (gooleInnerMap !== null) {
                gooleInnerMap.panTo({ lat: parseFloat(singleAsset.lat), lng: parseFloat(singleAsset.lon) });
                gooleInnerMap.setZoom(13);
            }
        }
    }

    showModal = () => {
        this.setState({ isDeleteDataModalVisible: true });
    };

    saveDeleteText = (text) => {
        this.setState({ deleteDataModalInput: text });
        if (text.toLowerCase() === 'delete') {
            this.setState({ deleteDataConfirmButtonVisible: true });
        } else {
            this.setState({ deleteDataConfirmButtonVisible: false });
        }
    };

    handleOk = () => {
        const { deleteDataModalInput } = this.state;
        const { actions, singleAsset } = this.props;
        if (deleteDataModalInput && deleteDataModalInput.toLowerCase() === 'delete') {
            // make api request to delete data
            actions.deleteAssetDataRequest({ assetId: singleAsset.id });
        }
        this.setState({ isDeleteDataModalVisible: false });
    };

    handleCancel = () => {
        this.setState({ isDeleteDataModalVisible: false, deleteDataModalInput: null });
    };

    uploadImage = (data) => {
        const { actions, match } = this.props;
        const { asset_id } = match.params;
        actions.uploadAssetImageRequest({
            assetId: asset_id,
            image: data,
        });
    };

    assetJourneyTabLoaded = () => {
        this.setState({ journeyHistoryTabLoaded: true });
    };

    eventHistoryTabLoaded = () => {
        this.setState({ eventHistoryTabLoaded: true });
    };

    videoHistoryTabLoaded = () => {
        this.setState({ videoHistoryTabLoaded: true });
    };

    serviceAlertsTabLoaded = () => {
        this.setState({ serviceAlertsTabLoaded: true });
    };

    onEventsHistoryTabPageChange = (page, pageSize) => {
        const { match, actions } = this.props;
        const { asset_id } = match.params;
        const { eventsTabFilter } = this.state;
        eventsTabFilter.page = page;
        this.setState({ eventsTabFilter });
        const params = { assetId: asset_id, ...eventsTabFilter };
        actions.getAssetEventHistoryRequest(params);
    };

    onJourneyHistoryTabPageChange = (page) => {
        const { match, actions } = this.props;
        const { asset_id } = match.params;
        const { journeyHistoryTabFilter } = this.state;
        const {
            date_range, journey_event, driver, orderBy,
        } = journeyHistoryTabFilter;
        journeyHistoryTabFilter.page = page;

        this.setState({ journeyHistoryTabFilter });

        const date_from = date_range && date_range[0] && date_range[0].format(isoStandardDateTimeFormat);
        const date_to = date_range && date_range[1] && date_range[1].format(isoStandardDateTimeFormat);
        const params = {
            assetId: asset_id, date_from, date_to, journey_event, driver, orderBy, page,
        };
        actions.getAssetJourneyRequest(params);
    };

    onJourneyHistoryTabFilterChange = (values) => {
        const { match, actions } = this.props;
        const { asset_id } = match.params;
        const filters = values;
        const {
            date_from, date_to, journey_event, driver, is_authorised,
        } = values;
        filters.page = 1;
        this.setState({ journeyHistoryTabFilter: filters });
        const params = {
            assetId: asset_id, 
            page: 1, 
            date_from: date_from && `${date_from.format(DB_DATE_FORMAT)} 00:00:00`,
            date_to: date_to && `${date_to.format(DB_DATE_FORMAT)} 23:59:59`,
            journey_event, 
            driver, 
            is_authorised, 
            orderBy: '-start_time',
        };
        actions.getAssetJourneyRequest(params);
    };

    onJourneyHistoryTabSortChange = (pagination, filters, sorter) => {
        const { match, actions } = this.props;
        const { asset_id } = match.params;
        const { journeyHistoryTabFilter } = this.state;
        const orderBy = `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`;
        journeyHistoryTabFilter.orderBy = orderBy;
        this.setState({ journeyHistoryTabFilter });

        const {
            date_range, page, journey_event, driver,
        } = journeyHistoryTabFilter;
        const date_from = date_range && date_range[0] && date_range[0].format(isoStandardDateTimeFormat);
        const date_to = date_range && date_range[1] && date_range[1].format(isoStandardDateTimeFormat);
        const params = {
            assetId: asset_id, page, date_from, date_to, journey_event, driver, orderBy,
        };
        actions.getAssetJourneyRequest(params);
    };

    onJourneyEventsTabFilterChange = (values) => {
        const filters = values;
        const { date_to, date_from } = filters;
        filters.date_from = date_from && date_from.format('YYYY-MM-DD 00:00:00');
        filters.date_to = date_to && date_to.format('YYYY-MM-DD 23:59:59');
        filters.date_range = null;
        filters.page = 1;
        this.setState({ eventsTabFilter: filters });
        const { actions, match } = this.props;
        const { asset_id } = match.params;
        const params = { assetId: asset_id, ...filters };
        actions.getAssetEventHistoryRequest(params);
    };

    onVideoHistoryTabFilterChange = (values) => {
        const filters = values;
        const { date_to, date_from } = filters;
        filters.date_from = date_from && date_from.format('YYYY-MM-DD 00:00:00');
        filters.date_to = date_from && date_from.format('YYYY-MM-DD 23:59:59');
        delete filters.date_range;
        filters.page = 1;
        this.setState({ videoHistoryTabFilter: filters });
        const { actions, match } = this.props;
        const { asset_id } = match.params;
        const params = { asset_id, ...filters };
        actions.getAssetHistoricVideoRequest(params);
    };

    onVideoHistoryTabPageChange = (page) => {
        const { videoHistoryTabFilter } = this.state;
        videoHistoryTabFilter.page = page;
        const { actions, match } = this.props;
        const { asset_id } = match.params;
        this.setState({ videoHistoryTabFilter });
        const params = { asset_id, ...videoHistoryTabFilter };
        actions.getAssetHistoricVideoRequest(params);
    };

    onVideoHistoryTabSortChange = (pagination, filters, sorter) => {
        const { match, actions } = this.props;
        const { asset_id } = match.params;
        const { videoHistoryTabFilter } = this.state;
        const orderBy = `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`;
        videoHistoryTabFilter.orderBy = orderBy;
        this.setState({ videoHistoryTabFilter });

        const {
            date_from, date_to, page,
        } = videoHistoryTabFilter;
        const params = {
            assetId: asset_id, page, date_from, date_to, orderBy,
        };
        actions.getAssetHistoricVideoRequest(params);
    };

    onJourneyEventsTabSortChange = (pagination, filters, sorter) => {
        // const { match, actions } = this.props;
        // const { asset_id } = match.params;
        // const { journeyHistoryTabFilter } = this.state;
        // const params = { orderBy: `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`, assetId: asset_id, ...journeyHistoryTabFilter };
        // actions.getAssetEventHistoryRequest(params);
    };

    onServiceAlertsTabPageChange = (page) => {
        const { serviceAlertsTabFilter } = this.state;
        serviceAlertsTabFilter.page = page;
        const { actions, match } = this.props;
        const { asset_id } = match.params;
        this.setState({ serviceAlertsTabFilter });
        const params = { assetId: asset_id, ...serviceAlertsTabFilter };
        actions.getAssetServiceAlertsRequest(params);
    };

    onServiceAlertsTabFilterChange = (values) => {
        const filters = values;
        filters.page = 1;
        if (values.next_alert) {
            filters.next_alert = moment(values.next_alert, 'DD/MM/YYYY').format(DB_DATE_FORMAT);
        }
        this.setState({ serviceAlertsTabFilter: filters });
        const { actions, match } = this.props;
        const { asset_id } = match.params;
        const params = { assetId: asset_id, ...filters };
        actions.getAssetServiceAlertsRequest(params);
    };

    onServiceAlertsTabSortChange = (pagination, filters, sorter) => {
        const { serviceAlertsTabFilter } = this.state;
        const { actions, match } = this.props;
        const { asset_id } = match.params;
        const params = { assetId: asset_id, orderBy: `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`, ...serviceAlertsTabFilter };
        actions.getAssetServiceAlertsRequest(params);
    };

    render() {
        return this.view();
    }
}

ViewAsset.propTypes = {
    actions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    assetJourneyFilters: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        singleAsset: state.asset.singleAsset,
        isFetching: state.asset.isFetching,
        user: state.user,
        driverList: state.driver.driverList,
        assetJourneyFilters: state.asset.assetJourneyFilters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...assetActions,
                ...driverActions,
            },
            dispatch,
        ),
    };
}
export const ViewAssetTest = ViewAsset;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ViewAsset);
