/* global: localStorage */
import {
    call,
    put,
    takeEvery,
    all,
} from 'redux-saga/effects';
import { message, notification } from 'antd';
import history from '../utils/history';
import { fetchApiAuth } from '../utils/api';

import {
    getDivisionsGridSuccess,
    getDivisionsGridFailure,

    getDivisionAssetsGridSuccess,
    getDivisionAssetsGridFailure,

    getDivisionDetailsSuccess,
    getDivisionDetailsFailure,

    assignDivisionAssetSuccess,
    assignDivisionAssetFailure,

    unassignDivisionAssetSuccess,
    unassignDivisionAssetFailure,

    updateDivisionSuccess,
    updateDivisionFailure,

    getOtherAssetsSuccess,
    getOtherAssetsFailure,

    createDivisionSuccess,
    createDivisionFailure,

    getDivisionOptionsSuccess,
    getDivisionOptionsFailure,

    getDivisionOptionsStrictSuccess,
    getDivisionOptionsStrictFailure,

    getUserDivisionsSuccess,
    getUserDivisionsFailure,
} from './divisionActions';

const {
    GET_DIVISIONS_GRID_REQUEST,
    GET_DIVISION_ASSETS_GRID_REQUEST,
    GET_DIVISION_DETAILS_REQUEST,
    ASSIGN_DIVISION_ASSET_REQUEST,
    UNASSIGN_DIVISION_ASSET_REQUEST,
    UPDATE_DIVISION_REQUEST,
    GET_OTHER_ASSETS_REQUEST,
    CREATE_DIVISION_REQUEST,
    GET_DIVISION_OPTIONS_REQUEST,
    GET_DIVISION_OPTIONS_STRICT_REQUEST,
    GET_USER_DIVISIONS_REQUEST,
} = require('./divisionActions').constants;

function* getDivisionsGrid(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/division/get-divisions',
            params: {
                page: ((actions.payload && actions.payload.page) || 1),
            },
            body: actions.payload,
        });

        yield put(getDivisionsGridSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDivisionsGridFailure(e));
    }
}

function* getDivisionAssetsGrid(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/division/get-assets',
            params: {
                page: ((actions.payload && actions.payload.page) || 1),
            },
            body: actions.payload,
        });

        yield put(getDivisionAssetsGridSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDivisionAssetsGridFailure(e));
    }
}

function* getDivisionDetails(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/division/get-info',
            body: actions.payload,
        });

        yield put(getDivisionDetailsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDivisionDetailsFailure(e));
    }
}

function* assignDivisionAsset(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/division/assign-asset',
            body: actions.payload,
        });

        if (response.data) {
            yield put(assignDivisionAssetSuccess(actions.payload));
        } else {
            const errorMsg = 'Unable to assign asset to division.';
            message.error(errorMsg);
            yield put(assignDivisionAssetFailure(errorMsg));
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(assignDivisionAssetFailure(e));
    }
}

function* unassignDivisionAsset(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/division/unassign-asset',
            body: actions.payload,
        });

        if (response.data) {
            yield put(unassignDivisionAssetSuccess(actions.payload));
        } else {
            const errorMsg = 'Unable to unassign asset from division.';
            message.error(errorMsg);
            yield put(unassignDivisionAssetFailure(errorMsg));
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(unassignDivisionAssetFailure(e));
    }
}

function* updateDivision(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/division/update-division',
            body: actions.payload,
        });

        notification.success({ message: 'Success', description: 'Succesfully updated division' });
        yield put(updateDivisionSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(updateDivisionFailure(e));
    }
}

function* getOtherAssets(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/division/get-other-assets',
            body: actions.payload,
        });

        yield put(getOtherAssetsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getOtherAssetsFailure(e));
    }
}

function* createDivision(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/division/create-division',
            body: actions.payload,
        });

        history.push(`/division/view/${response.data.id}`);
        yield put(createDivisionSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(createDivisionFailure(e));
    }
}

function* getDivisionOptions(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/division/get-list',
            body: actions.payload,
        });

        yield put(getDivisionOptionsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDivisionOptionsFailure(e));
    }
}

function* getDivisionOptionsStrict({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/division/get-list-strict',
            body: payload,
        });

        yield put(getDivisionOptionsStrictSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDivisionOptionsStrictFailure(e));
    }
}

function* getUserDivisions({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/division/get-user-divisions',
            body: payload,
        });

        yield put(getUserDivisionsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getUserDivisionsFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* assetSaga() {
    yield all([
        takeEvery(GET_DIVISIONS_GRID_REQUEST, getDivisionsGrid),
        takeEvery(GET_DIVISION_ASSETS_GRID_REQUEST, getDivisionAssetsGrid),
        takeEvery(GET_DIVISION_DETAILS_REQUEST, getDivisionDetails),
        takeEvery(ASSIGN_DIVISION_ASSET_REQUEST, assignDivisionAsset),
        takeEvery(UNASSIGN_DIVISION_ASSET_REQUEST, unassignDivisionAsset),
        takeEvery(UPDATE_DIVISION_REQUEST, updateDivision),
        takeEvery(GET_OTHER_ASSETS_REQUEST, getOtherAssets),
        takeEvery(CREATE_DIVISION_REQUEST, createDivision),
        takeEvery(GET_DIVISION_OPTIONS_REQUEST, getDivisionOptions),
        takeEvery(GET_DIVISION_OPTIONS_STRICT_REQUEST, getDivisionOptionsStrict),
        takeEvery(GET_DIVISION_OPTIONS_STRICT_REQUEST, getUserDivisions),
    ]);
}
