import React from 'react';
import PasswordInput from '../../components/PasswordInput';
import { Form } from '@ant-design/compatible';
import { Input, Button, Modal, Spin } from 'antd';
import srcFleetclearLogo from '../../assets/images/fleetclear-logo.svg';
import { Email } from '@carbon/icons-react';
import Spinner from '../Spinner';

import './loginForm.css';

const FormItem = Form.Item;

export default function () {
    const { form, isFetching } = this.props;
    const { getFieldDecorator } = form;

    return (
        <Spinner loading={isFetching}>
            <div className="loginform-wrap">
                <div className="loginform-container">
                    <div className="d-flex dir-column align-center text-center">
                        <img
                            src={srcFleetclearLogo}
                            className="login__logo-fleet"
                            alt="Fleetclear"
                            style={{ width: '80%' }}
                        />
                        <h1 className="login__heading" />
                    </div>
                    <Form onSubmit={this.handleSubmit} layout="vertical">
                        <FormItem>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid Email',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ],
                            })(<Input placeholder="Enter Email" className='login-email' />)}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ],
                            })(
                                <PasswordInput 
                                    placeholder="Enter Password" 
                                    className="login-password" 
                                />
                            )}
                        </FormItem>
                        <div style={{ marginTop: '-10px' }}>
                            <a
                                href='#'
                                onClick={() => this.setModalForgetVisible(true)}
                            >
                                Forgotten password?
                            </a>
                        </div>
                        <Button
                            style={{ width: '70%' }}
                            htmlType="submit"
                            type='primary'
                        >
                            Login
                        </Button>
                    </Form>
                    <div className="d-flex dir-column align-center text-center">
                        <h3 className="login__heading">New to Fleetclear?</h3>
                        <a href="https://fleetclear.com/contact-us/">
                            <span className="login__contact-us">
                                <span className="login__contact-us-icon">
                                    <Email size={36} />
                                </span>
                                <a>Contact us</a>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <Modal
                centered
                width={540}
                footer={null}
                open={this.state.modalForgetVisible}
                onOk={() => this.setModalForgetVisible(false)}
                onCancel={() => this.setModalForgetVisible(false)}>
                <h4 className="h-3 text-center">Reset Password</h4>
                <div className="form-wrap">
                    <Form onSubmit={this.handleForgotsubmit} layout="vertical">
                        <FormItem label="Email">
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid Email',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ],
                            })(<Input placeholder="Your Registered Email" />)}
                        </FormItem>
                        <Button type="primary" htmlType="submit" className="wide center-align">
                            Send Email
                        </Button>
                    </Form>
                </div>
            </Modal>
        </Spinner>
    );
}
