/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty, size } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import CustomEmpty from '../../../../../components/CustomEmpty';

class ReportTab extends PureComponent {
    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    render() {
        const {
            drivingTimeReport,
            isFetching,
            toggleFilterSidebar,
        } = this.props;

        const listReports = drivingTimeReport.map((report) => ({
            ...report,
            ...(Array.isArray(report.name) && report.name[0] && {
                name: (
                    <Link to={`/assets/view/${report.name[0]}`} className="btn btn-empty">
                        {report.name[1]}
                    </Link>
                ),
            }),
        }));
        const exportableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];

        if (isFetching) {
            return <></>;
        } else {
            if (isEmpty(listReports)) {
                return (
                    <CustomEmpty type='report' />
                );
            } else {
                return (
                    <>
                        <span className='report-title'>Driving Time/Vehicle Usage Time Report</span>
                        <br />
                        <GridView
                            showExportButton
                            exportableColumns={exportableColumns}
                            data={listReports}
                            onChange={this.onTableChange}
                            disablePagination
                        />
                    </>
                );
            }
        }
    }
}

ReportTab.defaultProps = {
    drivingTimeReport: [],
    onPageChange: () => null,
    onTableChange: () => null,
};

ReportTab.propTypes = {
    drivingTimeReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        drivingTimeReport: state.report.drivingTimeReport,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
