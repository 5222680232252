import React from 'react';
import { Form } from '@ant-design/compatible';
import PasswordInput from '../../../../components/PasswordInput';
import { Button } from 'antd';
import Spinner from '../../../../components/Spinner';

const FormItem = Form.Item;

export default function () {
    const { user, form } = this.props;
    const { getFieldDecorator } = form;

    return (
        <Spinner loading={user.isFetching}>
            <Form onSubmit={this.handleSubmit} layout="vertical" className="form mt-4">
                <FormItem label="Your old password:">
                    {getFieldDecorator('old_password', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter old password!',
                            },
                        ],
                    })(<PasswordInput />)}
                </FormItem>
                <FormItem label="Your new password:">
                    {getFieldDecorator('new_password', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter new password!',
                            },
                            {
                                min: 8,
                                message: 'A new password must be at least 8 characters length!\n\r',
                            },
                            {
                                pattern: /\d+/,
                                message: 'Must contain at least 1 digit\n\r',
                            },
                            {
                                pattern: /[A-Z]+/,
                                message: 'Must contain at least 1 uppercase character\n\r',
                            },
                            {
                                pattern: /[a-z]+/,
                                message: 'Must contain at least 1 lowercase character\n\r',
                            },
                            {
                                pattern: /[!#$@%\-_=+<>\'"`£^&¬\.*\(\)\[\]\?:;\/,{}]/,
                                message: 'Must contain at least 1 special character',
                            },
                        ],
                    })(<PasswordInput />)}
                </FormItem>
                <FormItem label="Your new password (again):">
                    {getFieldDecorator('new_password_confirm', {
                        rules: [
                            {
                                required: true,
                                message: 'Please confirm new password!',
                            },
                            {
                                min: 8,
                                message: 'A new password must be at least 8 characters length!\n\r',
                            },
                            {
                                pattern: /\d+/,
                                message: 'Must contain at least 1 digit\n\r',
                            },
                            {
                                pattern: /[A-Z]+/,
                                message: 'Must contain at least 1 uppercase character\n\r',
                            },
                            {
                                pattern: /[a-z]+/,
                                message: 'Must contain at least 1 lowercase character\n\r',
                            },
                            {
                                pattern: /[!#$@%\-_=+<>\'"`£^&¬\.*\(\)\[\]\?:;\/,{}]/,
                                message: 'Must contain at least 1 special character',
                            },
                        ],
                    })(<PasswordInput />)}
                </FormItem>
                <div className="text-center mt-5">
                    <Button type="primary" htmlType="submit">
                        Change Password
                    </Button>
                </div>
            </Form>
        </Spinner>
    );
}
