/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import {
    Button,
    Row,
    Col,
    Modal,
    Input,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import LeftSide from './partials/LeftSide';
import ChangePasswordForm from './forms/ChangePasswordForm';
import Layout from '../../components/layout/Layout';
import Avatar from '../../components/elements/Avatar';

export default function () {
    const { modalOpen } = this.state;
    const { user } = this.props;

    const tabTitle = user.profile.full_name;

    return (
        (<Layout
            data-test="pages-profile"
            className="drivers-view"
            title={<div>UserView</div>}
            headerTitle={<div>UserView Page</div>}
            showFooter={false}
            loading={user.isFetching}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <div 
                    className="content"
                    style={{ opacity: user.isFetching ? 0 : 1 }}
                >
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <div className="top-section">
                                    <div className="section__action-bar justify-center">
                                        <span 
                                            className="user-profile-title"
                                            style={{ margin: '16px 0' }}
                                        >Edit User Profile</span>
                                    </div>
                                    <Button
                                        type="primary"
                                        onClick={() => this.submitUpdateProfileForm()}
                                        className="page-header-button"
                                        style={{ top: '16px', right: 0 }}
                                    >
                                        Save
                                    </Button>
                                </div>
                                    <Row
                                        gutter={40}
                                        type="flex"
                                        justify="space-between"
                                        className="data-row"
                                    >
                                        <Col xl={6}>
                                            <LeftSide 
                                                openPasswordChangeModal={() => { this.setState({ modalOpen: true }); }} 
                                                registerSubmitHandler={(f) => { this.submitUpdateProfileForm = f; }} 
                                            />
                                        </Col>
                                        <Col xl={11}>
                                            <div className="actions">
                                                <Row
                                                    type="flex"
                                                    gutter={40}
                                                    justify="space-between"
                                                >
                                                    <Col>
                                                        <br />
                                                        <Avatar 
                                                            pictureUrl={user.profile.profilePictureFull} 
                                                            onChange={(data) => this.updateProfileImage(data)} 
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Modal
                open={modalOpen}
                title="Change Your Password"
                onCancel={() => this.setState({ modalOpen: false })}
                footer={null}
            >
                <ChangePasswordForm />
            </Modal>
        </Layout>)
    );
}
