// APP CONSTATNS
const app = {
    DEBUG: false,
    MOCK: false,
    APP_NAME: process.env.REACT_APP_APP_NAME,
    API_URL: process.env.REACT_APP_API_URL,
    OSM_URL: process.env.REACT_APP_OSM_URL,
    AUTH_KEY: 'iss-key',
    API_LOGIN_KEY: 'iss-api-key',
    SHARD_KEY: 'shard-id',
    LOCALSTORAGE_EXPIRES_KEY: 'expire-auth',
    GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    DEFAULT_MAP_CENTER: { // Center of UK
        lat: 52.48759,
        lng: -1.91199,
    },
    DEFAULT_MAP_ZOOM: 6,
    LOCALSTORAGE_EXPIRES: '30', // minutes
    BUGSNAG_KEY: '719ed98fd8d631b89b17e4b188a1f4d8',
    MAP_SLIDER_INTERVAL: parseInt(process.env.REACT_APP_MAP_SLIDER_INTERVAL, 10),
    MAP_LIVE_ASSET_DELAY: parseInt(process.env.REACT_APP_MAP_LIVE_ASSET_DELAY, 10),
    GEOFENCE_REPORT_FEATURE: 'geofence-report',

    /* TIME AND DATE */
    SECONDS_IN_A_DAY: 86400,
    ZERO_INDEXED_SECONDS_IN_A_DAY: 86399,
    DB_DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    DB_DATE_FORMAT: 'YYYY-MM-DD',
    DB_TIME_FORMAT: 'HH:mm:ss',
    
    /* PADDING & SIZING */
    PADDING_1: 8, // matches ml-1, mr-1, mt-1, mb-1
    PADDING_2: 16, // matches ml-2, mr-2, mt-2, mb-2
    PADDING_3: 24, // matches ml-3, mr-3, mt-3, mb-3
    PADDING_4: 32, // matches ml-4, mr-4, mt-4, mb-4
};

export default app;
