/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import {
    Avatar as AntAvatar,
    DatePicker,
    Input,
    Select,
    Button,
    Divider,
    Row,
    Col,
    Layout,
    Checkbox,
    Radio,
    Modal,
    List,
    notification,
} from 'antd';
import Sticky from 'react-sticky-el';
import '@rc-component/color-picker/assets/index.css';
import { isEmpty, map, isUndefined } from 'underscore';
import { connect } from 'react-redux';
import { Erase } from '@carbon/icons-react';
import { bindActionCreators } from 'redux';
import {
    WarningFilled, UserOutlined, MenuOutlined, CloseCircleOutlined, ClockCircleOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import DynamicFields from './DynamicFields';

import * as dashboardActions from '../../../core/dashboard/dashboardActions';
import * as smartWidgetActions from '../../../core/smartWidget/smartWidgetActions';
import * as divisionActions from '../../../core/division/divisionActions';
import * as driverActions from '../../../core/driver/driverActions';
import * as assetActions from '../../../core/asset/assetActions';

import Icon from '../../elements/Icon';
import Avatar from '../../elements/Avatar';
import ChartMultiDropDown from '../ChartComponents/ChartMultiDropDown';
import BarChart from '../ChartComponents/BarChart';

import BarChartForm from './BarChartForm';
import LineChartForm from './LineChartForm';
import StackedAreaChartForm from './StackedAreaChartForm';
import MultiIconForm from './MultiIconForm';
import PieChartForm from './PieChartForm';
import CustomActiveShapePieChartForm from './CustomActiveShapePieChartForm';
import IconWithValueForm from './IconWithValueForm';
import IconWithValuesForm from './IconWithValuesForm';
import SimpleScatterChartForm from './SimpleScatterChartForm';
import ThreeDPieChartWithCustomLabelForm from './ThreeDPieChartWithCustomLabelForm';
import ColorRule from './ColorRule';
import dayjs from 'dayjs';
import { Calendar } from "@carbon/icons-react";

const { DB_DATE_FORMAT } = require('../../../core/constants').default;

const { Option } = Select;
const FormItem = Form.Item;

const {
    Footer,
} = Layout;

class CreateWidgetForm extends Component {
    constructor(props) {
        super(props);

        const timeframe = props.presetWidget?.timeframe || 'hourly';

        this.state = {
            // make: null,
            checked: false,
            colorOption: 'fixed', // fixed, smart-rule
            modalVisible: false,
            modalParent: null,
            datatype_a: 'All',
            datatype_b: 'All',
            datatype_c: 'All',
            datatype_d: 'All',
            timeframe,
            iconSelected: null,
        };
    }

    componentDidUpdate(prevProps) {
        const {
            actions,
            resetPresetCheckBox,
        } = this.props;

        if (resetPresetCheckBox) {
            this.setState({ checked: false });
            actions.resetPresetCheckBoxRequest();
        }

        if (this.props.presetWidget !== prevProps.presetWidget) {
            if (this.props.presetWidget.timeframe) {
                this.setState({ timeframe: this.props.presetWidget.timeframe });
            }
        }
    }

    componentDidMount() {
        const {
            actions,
            division,
            driver,
            asset,
            resetPresetCheckBox,
        } = this.props;
        actions.getSmartWidgetInputDataRequest();

        if (isEmpty(driver.driverList)) {
            actions.getDriverListRequest();
        }

        if (isEmpty(division.divisionOptions)) {
            actions.getDivisionOptionsRequest();
        }

        if (isEmpty(asset.options)) {
            actions.getAssetOptionsRequest();
        }
    }

    onCheckboxChange = (e) => {
        this.setState({ checked: e.target.checked });
    };

    onColorRadioChange = (e) => {
        this.setState({ colorOption: e.target.value });
    };

    setVisible = (value, modalParent) => {
        this.setState({ modalVisible: value, modalParent, iconSelected: null });
    };

    handleSubmit = (e) => {
        const {
            form, actions, newImageId, logo, editWidgetData,
        } = this.props;
        const {
            datatype_a,
            datatype_b,
            datatype_c,
            datatype_d,
            checked,
            timeframe,
        } = this.state;

        e.preventDefault();

        const inputData = form.getFieldValue('input_data');
        if (!inputData || !inputData.length || !inputData[0].event_type) {
            notification.error({
                message: 'Error',
                description: 'Please select input data',
            });
        }

        form.validateFields((err, values) => {
            const allowSubmit = isEmpty(err);
            let date = form.getFieldValue('date');
            if (date instanceof dayjs) {
                date = date.format(DB_DATE_FORMAT);
            }
            const primaryIcon = form.getFieldValue('primary_icon');
            // const logo = form.getFieldValue('logo');
            const mainLabelText = form.getFieldValue('main_label_text');
            const secondaryLabelText = form.getFieldValue('secondary_label_text');
            const iconPosition = form.getFieldValue('icon_position');
            const DEFAULT_DATATYPE = 'All';
            if (allowSubmit === true) {
                values.datatype_a = datatype_a || DEFAULT_DATATYPE;
                values.datatype_b = datatype_b || DEFAULT_DATATYPE;
                values.datatype_c = datatype_c || DEFAULT_DATATYPE;
                values.datatype_d = datatype_d || DEFAULT_DATATYPE;
                values.newImageId = newImageId;
                values.input_data[0].logo = logo;
                values.date = date;
                values.primary_icon = primaryIcon;
                values.logo = logo;
                values.main_label_text = mainLabelText;
                values.secondary_label_text = secondaryLabelText;
                values.icon_position = iconPosition;
                values.save_as_preset = checked;
                values.timeframe = timeframe;
                actions.createSmartWidgetRequest(values);
            }
        });
    };

    handleUpdate = (id) => {
        const {
            form, actions, newImageId, logo, editWidgetData,
        } = this.props;
        const {
            datatype_a,
            datatype_b,
            datatype_c,
            datatype_d,
            checked,
            timeframe,
        } = this.state;

        form.validateFields((err, values) => {
            let allowSubmit = isEmpty(err);
            let date = form.getFieldValue('date');
            if (date instanceof dayjs) {
                date = date.format(DB_DATE_FORMAT);
            }
            const primaryIcon = form.getFieldValue('primary_icon');
            // const logo = form.getFieldValue('logo');
            const mainLabelText = form.getFieldValue('main_label_text');
            const secondaryLabelText = form.getFieldValue('secondary_label_text');
            const iconPosition = form.getFieldValue('icon_position');

            if (values && values.chart_type) {
                if ([11, 14].includes(values.chart_type)) {
                    allowSubmit = false;
                    values.id = id;

                    if (!isEmpty(editWidgetData)) {
                        values.editWidgetData = editWidgetData;
                    }

                    actions.updateSmartWidgetRequest(values);
                }
            }

            if (allowSubmit === true) {
                values.id = id;
                values.datatype_a = datatype_a;
                values.datatype_b = datatype_b;
                values.datatype_c = datatype_c;
                values.datatype_d = datatype_d;
                values.newImageId = newImageId;
                values.input_data[0].logo = logo;
                values.date = date;
                values.primary_icon = primaryIcon;
                values.logo = logo;
                values.main_label_text = mainLabelText;
                values.secondary_label_text = secondaryLabelText;
                values.icon_position = iconPosition;
                values.save_as_preset = checked;
                values.timeframe = timeframe;
                if (!isEmpty(editWidgetData)) {
                    values.editWidgetData = editWidgetData;
                }

                actions.updateSmartWidgetRequest(values);
            }
        });
    };

    getChartDataFromInput(inputDataMin) {
        const { form } = this.props;
        setTimeout(() => this.getChartData(inputDataMin), 200);
    }

    getChartData(inputDataMin) {
        const { actions } = this.props;
        const chartData = this.findChartData();

        // remove empty item
        chartData.inputData = chartData.inputData.filter((el) => el != null && el.event_type != undefined);

        let minInputDataEntered = true;
        if (!isUndefined(inputDataMin)) {
            for (let i = 0; i < inputDataMin; i++) {
                minInputDataEntered = minInputDataEntered && chartData.inputData[i] && chartData.inputData[i].event_type;
            }
        }

        let allowGetData = true;
        if (chartData.inputData) {
            map(chartData.inputData, (inputElement) => {
                if (isUndefined(inputElement) || isUndefined(inputElement.event_type) || !inputElement.event_type) {
                    allowGetData = false;
                }
            });

            if (isEmpty(chartData.inputData)) {
                allowGetData = false;
            }
        }

        if (allowGetData && chartData.inputData && chartData.chartType && minInputDataEntered) {
            actions.getChartDataRequest(chartData);
        }
    }

    findChartData() {
        const { form, logo, presetWidget } = this.props;
        const {
            datatype_a, datatype_b, datatype_c, datatype_d, timeframe,
        } = this.state;
        let date = form.getFieldValue('date');
        let inputData = form.getFieldValue('input_data');
        const chartType = form.getFieldValue('chart_type');
        const primaryIcon = form.getFieldValue('primary_icon');
        const mainLabelText = form.getFieldValue('main_label_text');
        const secondaryLabelText = form.getFieldValue('secondary_label_text');
        const iconPosition = form.getFieldValue('icon_position');
        const selectedTimeframe = timeframe;
        if (!inputData) {
            inputData = [];
        }

        if (!isUndefined(inputData[0])) {
            inputData[0].logo = logo;
        }
        if (date instanceof dayjs) {
            date = date.format(DB_DATE_FORMAT);
        }

        const chartData = {
            date,
            inputData,
            chartType,
            timeframe: selectedTimeframe,
            primaryIcon,
            mainLabelText,
            secondaryLabelText,
            iconPosition,
            optionsData: {
                datatype_a,
                datatype_b,
                datatype_c,
                datatype_d,
            },
        };

        return chartData;
    }

    uploadImage = (data) => {
        const { actions } = this.props;
        actions.uploadWidgetImageRequest({
            image: data,
        });
    }

    saveDatatypeValues = (data) => {
        this.setState({
            datatype_a: data.firstOptionValue,
            datatype_b: data.secondOptionValue,
            datatype_c: data.thirdOptionValue,
            datatype_d: data.fourOptionValue,
        });
    }

    setTimeframe = (inputDataMin, data) => {
        this.setState({
            timeframe: data,
        });
        this.getChartDataFromInput(inputDataMin);
    }

    setIcon = (item) => {
        const { form } = this.props;
        const { modalParent } = this.state;

        this.setState({ iconSelected: item.id });
        form.setFieldsValue({ [modalParent]: item.id });
    }

    removeIcon = (item) => {
        const { form } = this.props;

        form.setFieldsValue({ [item]: null });
    }

    mapIcons = (icons) => icons.reduce((a, { id, title }) => {
        a[id] = title;
        return a;
    }, Object.create(null))

    render() {
        const {
            form, inputData, smartWidget, chartTypes, division, driver, asset, user, presetWidget, fetchPreview, actions, clearCreatedForm, editWidgetData,
        } = this.props;

        const { getFieldDecorator, setFieldsValue } = form;
        const {
            checked,
            modalVisible,
            timeframe,
            datatype_a,
            datatype_b,
            datatype_c,
            datatype_d,
            numberOfInput,
            iconSelected,
            telematicsRetentionDays,
        } = this.state;

        const icons = [
            {
                id: 1,
                title: <UserOutlined />,
            },
            {
                id: 2,
                title: <WarningFilled />,
            },
            {
                id: 3,
                title: <MenuOutlined />,
            },
            {
                id: 4,
                title: <CloseCircleOutlined />,
            },
            {
                id: 5,
                title: <ClockCircleOutlined />,
            },
        ];
        const mappedIcons = this.mapIcons(icons);
        const formula = [
            {
                name: 'Highest value found',
                key: 1,
            },
            {
                name: 'Average (mean) * default',
                key: 2,
            },
            {
                name: 'Average (median)',
                key: 3,
            },
            {
                name: 'Max value in data',
                key: 4,
            },
            {
                name: 'Min value in data',
                key: 5,
            },
            {
                name: 'Sum of all values',
                key: 6,
            },

        ];

        const mappedInputData = !inputData ? [] : inputData.map((e) => (
            <Option key={e.id} value={e.id}>{e.title}</Option>
        ));

        let chart_type = this.props?.form?.getFieldValue('chart_type');

        // set preset widget data
        let inputDataList = [];
        let updatePreset = false;
        let size = '1';

        if (!isEmpty(presetWidget)) {
            inputDataList = presetWidget.input_data.map((data) => data.event_type);
            if (isUndefined(chart_type) && !isUndefined(presetWidget.chart_type)) {
                chart_type = presetWidget.chart_type;
            }
            if (!isUndefined(presetWidget.size)) {
                size = parseInt(presetWidget.size, 10);
            }

            updatePreset = true;

            if (fetchPreview) {
                this.getChartDataFromInput();
                actions.resetFetchPreviewRequest();
            }
        }

        if (clearCreatedForm) {
            form.resetFields();
            actions.resetClearCreatedFormRequest();
        }

        let inputDataMin = 1;
        let inputDataMax = 99;

        if ([5].includes(chart_type)) {
            inputDataMin = 1;
            inputDataMax = 15;
        }

        if ([7].includes(chart_type)) {
            inputDataMin = 2;
            inputDataMax = 2;
        }

        if ([10].includes(chart_type)) {
            inputDataMin = 1;
            inputDataMax = 1;
        }

        if ([4].includes(chart_type)) {
            inputDataMin = 2;
            inputDataMax = 2;
        }

        return (
            <Form id="create-widget-form" onSubmit={this.handleSubmit} layout="vertical">
                <Row className="presets advanced-tab" gutter={[32, 32]}>

                    {![11, 14].includes(chart_type) ? (
                        <>
                            <Col className="gutter-row first-col" xs={24} sm={24} md={12} lg={12} xl={12}>
                                <p className="field-label">Input Data</p>
                                <DynamicFields
                                    {...form}
                                    name="input_data"
                                    data={inputDataList}
                                    min={inputDataMin}
                                    max={inputDataMax}
                                    onRemove={() => this.getChartDataFromInput(inputDataMin)}
                                    fields={[
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please select Input Data',
                                                },
                                            ],
                                            name: 'event_type',
                                            field: () => (
                                                <Select
                                                    onChange={() => this.getChartDataFromInput(inputDataMin)}
                                                    showSearch
                                                    allowClear={<Erase size={12} />}
                                                >
                                                    {mappedInputData}
                                                </Select>
                                            ),
                                        },
                                    ]} />
                            </Col>
                            <Col className="smart-widget-preview-row gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                                <FormItem label="Chart type">
                                    {getFieldDecorator('chart_type', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please select chart type',
                                            },
                                        ],
                                        initialValue: presetWidget.chart_type,
                                    })(
                                        <Select
                                            onChange={() => this.getChartDataFromInput(inputDataMin)}
                                            style={{ width: '100%' }}
                                            placeholder="Select Chart Type"
                                        >
                                            {
                                                chartTypes.map((v) => (
                                                /* eslint-disable-next-line react/no-array-index-key */
                                                    (<Option key={v.id} value={v.id}>{v.title}</Option>)
                                                ))
                                            }
                                        </Select>,
                                    )}
                                </FormItem>
                                <p className="chart-type-message">
                                    <WarningFilled />
                                    Some chart types are not available for the current input data type selection
                                </p>
                            </Col>
                            <Divider />
                        </>
                    )
                        : (
                            <div>

                                {[11].includes(chart_type) ? <h2> Update VOR Chart </h2> : null }
                                {[14].includes(chart_type) ? <h2> Update Driver Score Chart </h2> : null }

                                <div style={{ display: 'none' }}>
                                    <Col className="smart-widget-preview-row gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <FormItem label="Chart type">
                                            {getFieldDecorator('chart_type', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select chart type',
                                                    },
                                                ],
                                                initialValue: presetWidget.chart_type,
                                            })(
                                                <Select
                                                    onChange={() => this.getChartDataFromInput(inputDataMin)}
                                                    style={{ width: '100%' }}
                                                    placeholder="Select Chart Type"
                                                >
                                                    {
                                                        chartTypes.map((v) => (
                                                        /* eslint-disable-next-line react/no-array-index-key */
                                                            (<Option key={v.id} value={v.id}>{v.title}</Option>)
                                                        ))
                                                    }
                                                </Select>,
                                            )}
                                        </FormItem>
                                    </Col>
                                </div>
                            </div>
                        )}

                    {[3].includes(chart_type) ? (
                        <>
                            <Col className="gutter-row first-col" xs={24} sm={24} md={12} lg={12} xl={12}>
                                <div className="input-data-col">

                                    {/* Icon Position */}
                                    <FormItem className="icon-position-checkbox" label="Icon Position">
                                        {getFieldDecorator('icon_position', {
                                            rules: [
                                            ],
                                        })(
                                            <Radio.Group>
                                                <Radio value="top">Top</Radio>
                                                <Radio value="left">Left</Radio>
                                            </Radio.Group>,
                                        )}
                                    </FormItem>
                                    <br />

                                    {/* Main Label */}
                                    <FormItem label="Main label text">
                                        {getFieldDecorator('main_label_text', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please enter main label text',
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Enter Main Label" />,
                                        )}
                                    </FormItem>
                                    <br />

                                    {/* Secondary Label */}
                                    <FormItem label="Secondary label text">
                                        {getFieldDecorator('secondary_label_text', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please enter label text',
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Enter Secondary Label" />,
                                        )}
                                    </FormItem>
                                    <br />

                                    {/* Icon */}
                                    <FormItem className="icon-field" label="Icon">
                                        {getFieldDecorator('icon', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please choose icon',
                                                },
                                            ],
                                        })(
                                            <>
                                                <Input hidden />
                                                <AntAvatar className="icon-avatar" shape="square" size={64} icon={<LegacyIcon type={mappedIcons[form.getFieldValue('icon')]} />} />
                                                <Button className="icon-button change-button" onClick={() => this.setVisible(true, 'icon')}>Change</Button>
                                                <Button className="icon-button danger-button" onClick={() => this.removeIcon('icon')} danger>Remove</Button>
                                            </>,
                                        )}
                                    </FormItem>
                                    <br />

                                    {/* Colour */}
                                    <ColorRule form={form} name="color" />

                                    {/* Formula */}
                                    <FormItem label="Formula">
                                        {getFieldDecorator('formula', {
                                            rules: [
                                            ],
                                        })(
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Select Formula"
                                            >
                                                {
                                                    formula.map((v, i) => (
                                                        /* eslint-disable-next-line react/no-array-index-key */
                                                        (<Option key={i} value={v.key}>{v.name}</Option>)
                                                    ))
                                                }

                                            </Select>,
                                        )}

                                    </FormItem>

                                    {/* Icon Modal */}
                                    <Modal
                                        title="Select Icon"
                                        centered
                                        transitionName=""
                                        maskTransitionName=""
                                        open={modalVisible}
                                        onOk={() => this.setVisible(false)}
                                        onCancel={() => this.setVisible(false)}
                                        width={1000}>
                                        <List
                                            className="icon-list"
                                            grid={{
                                                gutter: 16,
                                                xs: 1,
                                                sm: 2,
                                                md: 4,
                                                lg: 4,
                                                xl: 4,
                                                xxl: 4,
                                            }}
                                            dataSource={icons}
                                            renderItem={(item) => (
                                                <List.Item>
                                                    <AntAvatar
                                                        style={{ cursor: 'pointer', border: '2px solid #ccc' }}
                                                        shape="square"
                                                        size={128}
                                                        icon={<LegacyIcon type={item.title} />}
                                                        className={(iconSelected === item.id) ? 'icon_active' : null}
                                                        onClick={() => this.setIcon(item)} />
                                                </List.Item>
                                            )} />
                                    </Modal>
                                    <br />

                                    <p className="field-label">Default data view</p>

                                    <ChartMultiDropDown
                                        onDataChange={(optionsData) => this.saveDatatypeValues(optionsData)}
                                        divisions={[
                                            { id: 1, name: 'division best' },
                                            { id: 2, name: 'division other' },
                                            { id: 3, name: 'division third' },
                                        ]}
                                        drivers={[
                                            { id: 1, name: 'driver best' },
                                            { id: 2, name: 'driver other' },
                                            { id: 3, name: 'driver third' },
                                        ]}
                                        assets={[
                                            { id: 1, name: 'assets best' },
                                            { id: 2, name: 'assets other' },
                                            { id: 3, name: 'assets third' },
                                        ]} />
                                </div>
                            </Col>
                            <Col className="smart-widget-preview-row gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Sticky>
                                    <div className="smart-widget-preview">
                                        <h2>Widget Preview</h2>
                                        <FormItem className="widget-preview-date">
                                            {getFieldDecorator('lastService', {
                                                rules: [
                                                ],
                                            })(
                                                <DatePicker
                                                    format="DD/MM/YYYY" 
                                                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                                                    allowClear={false}
                                                    suffixIcon={<Calendar />}
                                                />,
                                            )}

                                        </FormItem>
                                        <br />
                                        {chart_type === 1 ? (
                                            <BarChart
                                                heightRatio={1}
                                                timeframe={smartWidget?.presetWidget?.timeframe || timeframe}
                                                rightMenuItems={['edit', 'delete', 'copy']}
                                                tooltipText={presetWidget?.name || 'This is tooltip text'}
                                                data={[
                                                    { x: '00:00', 'Runtime since engine start': 1, 'Diagnostics Warning': 2 },
                                                    { x: '01:00', 'Runtime since engine start': 0, 'Diagnostics Warning': 4 },
                                                    { x: '03:00', 'Runtime since engine start': 2, 'Diagnostics Warning': 6 },
                                                    { x: '04:00', 'Runtime since engine start': 3, 'Diagnostics Warning': 10 },
                                                    { x: '05:00', 'Runtime since engine start': 3, 'Diagnostics Warning': 1 },
                                                    { x: '06:00', 'Runtime since engine start': 6, 'Diagnostics Warning': 6 },
                                                    { x: '07:00', 'Runtime since engine start': 8, 'Diagnostics Warning': 0 },
                                                    { x: '08:00', 'Runtime since engine start': 10, 'Diagnostics Warning': 2 },
                                                    { x: '09:00', 'Runtime since engine start': 1, 'Diagnostics Warning': 1 },
                                                    { x: '10:00', 'Runtime since engine start': 5, 'Diagnostics Warning': 6 },
                                                    { x: '11:00', 'Runtime since engine start': 2, 'Diagnostics Warning': 3 },
                                                    { x: '12:00', 'Runtime since engine start': 2, 'Diagnostics Warning': 6 },
                                                    { x: '13:00', 'Runtime since engine start': 3, 'Diagnostics Warning': 6 },
                                                    { x: '14:00', 'Runtime since engine start': 2, 'Diagnostics Warning': 4 },
                                                    { x: '15:00', 'Runtime since engine start': 2, 'Diagnostics Warning': 6 },
                                                    { x: '16:00', 'Runtime since engine start': 0, 'Diagnostics Warning': 9 },
                                                    { x: '17:00', 'Runtime since engine start': 2, 'Diagnostics Warning': 6 },
                                                    { x: '18:00', 'Runtime since engine start': 1, 'Diagnostics Warning': 9 },
                                                    { x: '19:00', 'Runtime since engine start': 2, 'Diagnostics Warning': 6 },
                                                    { x: '20:00', 'Runtime since engine start': 2, 'Diagnostics Warning': 2 },
                                                    { x: '21:00', 'Runtime since engine start': 2, 'Diagnostics Warning': 2 },
                                                    { x: '22:00', 'Runtime since engine start': 7, 'Diagnostics Warning': 6 },
                                                    { x: '23:00', 'Runtime since engine start': 1, 'Diagnostics Warning': 1 },
                                                ]}
                                                defaultFirstOptionValue="Divisions"
                                                defaultSecondOptionValue={1}
                                                defaultThirdOptionValue="Drivers"
                                                divisions={[
                                                    { id: 1, name: 'division best' },
                                                    { id: 2, name: 'division other' },
                                                    { id: 3, name: 'division third' },
                                                ]}
                                                drivers={[
                                                    { id: 1, name: 'driver best' },
                                                    { id: 2, name: 'driver other' },
                                                    { id: 3, name: 'driver third' },
                                                ]}
                                                assets={[
                                                    { id: 1, name: 'assets best' },
                                                    { id: 2, name: 'assets other' },
                                                    { id: 3, name: 'assets third' },
                                                ]} />
                                        )
                                            : null}
                                    </div>
                                </Sticky>
                            </Col>
                        </>
                    ) : null}

                    {[4].includes(chart_type) ? (
                        <ThreeDPieChartWithCustomLabelForm
                            data={presetWidget.input_data}
                            getChartDataFromInput={(data) => this.getChartDataFromInput(data)}
                            saveDatatypeValues={(data) => this.saveDatatypeValues(data)}
                            setTimeframe={(data) => this.setTimeframe(inputDataMin, data)}
                            form={form} />
                    ) : null }

                    {[1].includes(chart_type) ? (
                        <BarChartForm
                            data={presetWidget.input_data}
                            timeframe={timeframe}
                            getChartDataFromInput={(data) => this.getChartDataFromInput(data)}
                            saveDatatypeValues={(data) => this.saveDatatypeValues(data)}
                            setTimeframe={(data) => this.setTimeframe(inputDataMin, data)}
                            form={form} />
                    ) : null }

                    {[2].includes(chart_type) ? (
                        <LineChartForm
                            data={presetWidget.input_data}
                            getChartDataFromInput={(data) => this.getChartDataFromInput(data)}
                            saveDatatypeValues={(data) => this.saveDatatypeValues(data)}
                            setTimeframe={(data) => this.setTimeframe(inputDataMin, data)}
                            form={form} />
                    ) : null }

                    {[6].includes(chart_type) ? (
                        <StackedAreaChartForm
                            data={presetWidget.input_data}
                            getChartDataFromInput={(data) => this.getChartDataFromInput(data)}
                            saveDatatypeValues={(data) => this.saveDatatypeValues(data)}
                            setTimeframe={(data) => this.setTimeframe(inputDataMin, data)}
                            form={form} />
                    ) : null }

                    {[12].includes(chart_type) ? (
                        <SimpleScatterChartForm
                            data={presetWidget.input_data}
                            getChartDataFromInput={(data) => this.getChartDataFromInput(data)}
                            saveDatatypeValues={(data) => this.saveDatatypeValues(data)}
                            setTimeframe={(data) => this.setTimeframe(inputDataMin, data)}
                            form={form} />
                    ) : null }

                    {[5].includes(chart_type) ? (
                        <MultiIconForm
                            data={presetWidget.input_data}
                            getChartDataFromInput={(data) => this.getChartDataFromInput(data)}
                            saveDatatypeValues={(data) => this.saveDatatypeValues(data)}
                            setTimeframe={(data) => this.setTimeframe(inputDataMin, data)}
                            form={form} />
                    ) : null }

                    {[7].includes(chart_type) ? (
                        <IconWithValuesForm
                            data={presetWidget.input_data}
                            getChartDataFromInput={(data) => this.getChartDataFromInput(data)}
                            saveDatatypeValues={(data) => this.saveDatatypeValues(data)}
                            setTimeframe={(data) => this.setTimeframe(inputDataMin, data)}
                            form={form} />
                    ) : null }

                    {[8].includes(chart_type) ? (
                        <PieChartForm
                            data={presetWidget.input_data}
                            getChartDataFromInput={(data) => this.getChartDataFromInput(data)}
                            saveDatatypeValues={(data) => this.saveDatatypeValues(data)}
                            setTimeframe={(data) => this.setTimeframe(inputDataMin, data)}
                            form={form} />
                    ) : null }

                    {[13].includes(chart_type) ? (
                        <CustomActiveShapePieChartForm
                            data={presetWidget.input_data}
                            getChartDataFromInput={(data) => this.getChartDataFromInput(data)}
                            saveDatatypeValues={(data) => this.saveDatatypeValues(data)}
                            setTimeframe={(data) => this.setTimeframe(inputDataMin, data)}
                            form={form} />
                    ) : null }

                    {[10].includes(chart_type) ? (
                        <IconWithValueForm
                            data={presetWidget.input_data}
                            getChartDataFromInput={(data) => this.getChartDataFromInput(data)}
                            saveDatatypeValues={(data) => this.saveDatatypeValues(data)}
                            setTimeframe={(data) => this.setTimeframe(inputDataMin, data)}
                            form={form} />
                    ) : null }
                </Row>
                <br />
                <Footer className="smart-widget-footer">
                    <Row gutter={[32, 32]}>

                        {user.role === 'super-admin' && (checked || updatePreset) && isEmpty(editWidgetData)
                            ? (
                                <Col className="gutter-row" xs={24} sm={24} md={2}>
                                    <Avatar
                                        className="create-widget-avatar"
                                        pictureUrl={presetWidget?.newImageId}
                                        onChange={(data) => this.uploadImage(data)} />
                                </Col>
                            )
                            : <Col xs={0} md={2} />}
                        <Col className="gutter-row" xs={24} md={8}>
                            <FormItem label="Name">
                                {getFieldDecorator('name', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter a name for the widget',
                                        },
                                    ],
                                    initialValue: presetWidget?.name,
                                })(
                                    <Input placeholder="Enter Widget Name" />
                                )}
                            </FormItem>
                        </Col>
                        <Col className="gutter-row" xs={24} md={5}>
                            <FormItem label="Size">
                                {getFieldDecorator('size', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select size',
                                        },
                                    ],
                                    initialValue: size.toString(),
                                })(
                                    <Radio.Group size="large" buttonStyle="solid" className="widget-sizes-radio-group">
                                        <Radio.Button value="1">1 x 1</Radio.Button>
                                        <Radio.Button value="2">1 x 2</Radio.Button>
                                        <Radio.Button value="3">2 x 1</Radio.Button>
                                        <Radio.Button value="4">2 x 2</Radio.Button>
                                    </Radio.Group>,
                                )}

                            </FormItem>
                            {/* sizeSelector */}
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={3} style={{ paddingTop: '45px' }}>
                            { updatePreset ? (
                                <Button htmlType="button" onClick={() => this.handleUpdate(presetWidget.id)} type="primary">
                                    Update Widget
                                </Button>
                            ) : (
                                <Button 
                                    htmlType="submit" 
                                    type="primary" 
                                    style={{ marginTop: '12px' }}
                                >
                                    Add Widget
                                </Button>
                            )}
                        </Col>

                        {user.role === 'super-admin' && isEmpty(editWidgetData)
                            ? (
                                <Col className="gutter-row" xs={24} sm={12} md={3} style={{ paddingTop: '45px' }}>
                                    <FormItem className="save-as-preset">
                                        <Checkbox checked={checked || updatePreset} onChange={this.onCheckboxChange}>
                                            Save as preset
                                        </Checkbox>
                                    </FormItem>
                                </Col>
                            )
                            : null}
                    </Row>
                </Footer>
            </Form>
        );
    }
}

const WrappedCreateWidgetForm = Form.create({
    name: 'create-widget',
})(CreateWidgetForm);

WrappedCreateWidgetForm.defaultProps = {
    updateForm: false,
    inputData: [],
    chartTypes: [],
};

WrappedCreateWidgetForm.propTypes = {
    visible: PropTypes.bool,
    loading: PropTypes.bool,
    inputData: PropTypes.array,
    chartTypes: PropTypes.array,
    editWidgetData: PropTypes.object,
    actions: PropTypes.object.isRequired,
    smartWidget: PropTypes.object.isRequired,
    division: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    newImageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        inputData: state.smartWidget.inputData,
        chartTypes: state.smartWidget.chartTypes,
        newImageId: state.smartWidget.newImageId,
        logo: state.smartWidget.logo,
        smartWidget: state.smartWidget,
        division: state.division,
        driver: state.driver,
        asset: state.asset,
        user: state.user,
        presetWidget: state.smartWidget.presetWidget,
        fetchPreview: state.smartWidget.fetchPreview,
        clearCreatedForm: state.smartWidget.clearCreatedForm,
        resetPresetCheckBox: state.smartWidget.resetPresetCheckBox,
        telematicsRetentionDays: state.user.userCompany.telematics_retention_days,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...dashboardActions,
            ...smartWidgetActions,
            ...divisionActions,
            ...driverActions,
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WrappedCreateWidgetForm);
