/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ExportToCsv } from 'export-to-csv';
import { isEmpty } from 'underscore';
import { notification } from 'antd';

import DOM from './idleReport';

import * as eventsActions from '../../../../core/events/eventsActions';
import * as reportActions from '../../../../core/report/reportActions';
import * as globalActions from '../../../../core/global/globalActions';

const { DB_DATE_FORMAT } = require('../../../../core/constants').default;

class IdleReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            filterSidebar: true,
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, dataEventToReportParams } = this.props;
        actions.resetReportsRequest();

        if (dataEventToReportParams !== null) {
            const params = {
                asset_or_driver: 'assets',
                ...dataEventToReportParams,
                page: 1,
            };

            if (dataEventToReportParams.asset_id) {
                params.asset_info = [dataEventToReportParams.asset_id];
            }

            if (dataEventToReportParams.driver_id) {
                params.driver = [dataEventToReportParams.driver_id];
            }

            actions.getIdleReportRequest(params);
            actions.setDataEventToReportParams(null);

            if (dataEventToReportParams.driver_id) {
                params.driver = [parseInt(dataEventToReportParams.driver_id)];
            }
            this.setState({ filters: params, filterSidebar: false });
        } else {
            this.onEventsTabFilterChange({
                asset_or_driver: 'assets',
                frequency: '1',
                page: 1,
            });
        }
    }

    onEventsTabFilterChange = (values) => {
        const filters = values;
        if (filters.date_from) {
            filters.date_from = values.date_from.format(DB_DATE_FORMAT);
        }
        if (filters.date_to) {
            filters.date_to = values.date_to.format(DB_DATE_FORMAT);
        }
        if (filters.asset_id) {
            filters.asset_info = values.asset_id;
        }
        filters.page = 1;
        this.setState({ filters });
        const { actions } = this.props;
        const params = { ...filters };
        actions.getIdleReportRequest(params);
    };

    exportCsv() {
        const { idleReport } = this.props;

        if (isEmpty(idleReport)) {
            notification.warning({ message: 'Warning', description: 'There is no data to export!' });
            return false;
        }

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Idle Time Report CSV',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: 'Idle Time Report',
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(idleReport);

        return true;
    }

    render() {
        return this.view(
            {
                update: (newVals) => (
                    this.props.actions.UpdateEventNotifications(newVals)
                ),
            },
        );
    }
}

IdleReport.propTypes = {
    actions: PropTypes.object.isRequired,
    idleReport: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        idleReport: state.report.idleReport,
        events: state.events.companyEvents,
        user: state.user,
        filterSidebar: state.filterSidebar,
        dataEventToReportParams: state.global.dataEventToReportParams,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IdleReport);
