import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Menu, Dropdown, Button, Modal } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { isEmpty } from 'underscore';
import Icon from '../../elements/Icon';
import * as assetActions from '../../../core/asset/assetActions';
import * as smartWidgetActions from '../../../core/smartWidget/smartWidgetActions';
import pieChart from '../images/piechart.png';
import CustomEmpty from '../../CustomEmpty';

const { confirm } = Modal;

class PresetsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        const { actions } = this.props;

        actions.getSmartWidgetPresetsRequest();
    }

    publishPreset(item) {
        const { actions } = this.props;
        confirm({
            content: `Are you sure want to publish "${item.title}" preset?`,
            onOk() {
                actions.updatePresetRequest({ id: item.id, status: 1 });
            },
            onCancel() {
            },
        });
    }

    unpublishPreset(item) {
        const { actions } = this.props;
        confirm({
            content: `Are you sure want to unpublish "${item.title}" preset?`,
            onOk() {
                actions.updatePresetRequest({ id: item.id, status: 0 });
            },
            onCancel() {
            },
        });
    }

    editPreset(item) {
        const { actions, onTabSwitch } = this.props;
        // reset widget data
        actions.resetPresetWidgetRequest();

        // get widget data
        actions.getPresetWidgetRequest({ id: item.id });

        // switch to advance tab
        onTabSwitch('2');
    }

    deletePreset(item) {
        const { actions } = this.props;
        confirm({
            content: `Are you sure want to delete "${item.title}" preset?`,
            onOk() {
                actions.deletePresetRequest({ id: item.id });
            },
            onCancel() {
            },
        });
    }

    createWidgetFromPreset(item) {
        const { actions } = this.props;
        confirm({
            content: `Are you sure want to create a new widget from "${item.title}" preset?`,
            onOk() {
                actions.createWidgetFromPresetRequest({ id: item.id });
            },
            onCancel() {
            },
        });
    }

    _renderWidget(item) {
        const style = { padding: '0px 0' };
        const { user } = this.props;

        let menu = (
            <Menu>
                {item.isCustomChart == 0
                    ? (
                        <Menu.Item key="preset-menu-6" onClick={() => this.editPreset(item)}>
                            Edit preset
                        </Menu.Item>
                    )
                    : null}
                {item.status == 1 ? (
                    <Menu.Item key="preset-menu-3" onClick={() => this.unpublishPreset(item)}>
                        Unpublish  preset
                    </Menu.Item>
                )
                    : (
                        <Menu.Item key="preset-menu-2" onClick={() => this.publishPreset(item)}>
                            Publish preset
                        </Menu.Item>
                    )}

                {item.status == 1 ? (
                    <Menu.Item key="preset-menu-5" onClick={() => this.createWidgetFromPreset(item)}>
                        Create widget from preset
                    </Menu.Item>
                ) : null}
                {item.isCustomChart == 0
                    ? (
                        <Menu.Item key="preset-menu-4" onClick={() => this.deletePreset(item)}>
                            Delete preset
                        </Menu.Item>
                    )
                    : null}
            </Menu>
        );

        if (user.role !== 'super-admin') {
            menu = (
                <Menu>
                    <Menu.Item key="preset-menu-5" onClick={() => this.createWidgetFromPreset(item)}>
                        Create widget from preset
                    </Menu.Item>
                </Menu>
            );
        }

        const pictureUrl = !isEmpty(item.pictureUrl) ? item.pictureUrl : pieChart;

        return (
            <Col key={`preset-widget-${item.id}`} className="gutter-row" xs={24} sm={12} md={8} lg={8} xl={6}>
                <div className='smart-widget-wrapper'>
                    <div style={style}>
                        <br />
                        <div style={{ height: 192, marginLeft: '16px', marginRight: '16px', backgroundColor: 'white', borderRadius: '16px' }}>
                            <div
                                className="preset-image-container"
                                style={{
                                    height: 192,
                                    background: `url(${pictureUrl})`,
                                    backgroundRepeat: 'no-repeat',
                                    // backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                }} />
                            </div>
                        <br />
                        <div className="smart-widget-title">
                            <span className="preset-title">{item.title}</span>
                            &ensp;&ensp;
                            <Dropdown className="smart-widget-ellipsis" overlay={menu} trigger={['click']}>
                                <Button className="btn btn-empty">
                                    <Icon name="more" />
                                </Button>
                            </Dropdown>

                        </div>
                    </div>
                    {item.status == 1 ? (
                        <div className="smart-widget-published">
                            <Loading3QuartersOutlined style={{
                                fontSize: '12px', borderRadius: '12px', color: 'green', backgroundColor: 'green',
                            }} /> Published
                        </div>
                    ) : (
                        <span className="smart-widget-unpublished">
                            <Loading3QuartersOutlined style={{
                                fontSize: '12px', borderRadius: '12px', color: 'grey', backgroundColor: 'grey',
                            }} /> Unpublished
                        </span>
                    )}
                    <br />
                </div>
            </Col>
        );
    }

    render() {
        const { smartWidgetPresets, isFetchingPresetWidgets, isFetchingUserWidgets } = this.props;

        return (
            <Row 
                className="presets" 
                gutter={[32, 32]} 
                type="flex"
            >
                {!isFetchingPresetWidgets && !isFetchingUserWidgets 
                    ? isEmpty(smartWidgetPresets) 
                        ? (
                            <div style={{ width: '100%' }}>
                                <div style={{ 
                                    width: '300px',
                                    margin: 'auto',
                                }}>
                                    <CustomEmpty />
                                </div>
                            </div>
                        ) : (
                            smartWidgetPresets.map(item => this._renderWidget(item))
                        )
                    : null
                }
            </Row>
        );
    }
}

PresetsTab.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        assetJourney: state.asset.assetJourney,
        smartWidgetPresets: state.smartWidget.smartWidgetPresets,
        isFetchingUserWidgets: state.smartWidget.isFetching,
        isFetchingPresetWidgets: state.smartWidget.isFetchingPresetWidgets,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
            ...smartWidgetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PresetsTab);
