/* eslint-disable no-case-declarations */

import { Record } from 'immutable';
import { findIndex } from 'underscore';

const {
    GET_DIVISIONS_GRID_REQUEST,
    GET_DIVISIONS_GRID_SUCCESS,
    GET_DIVISIONS_GRID_FAILURE,

    GET_DIVISION_ASSETS_GRID_REQUEST,
    GET_DIVISION_ASSETS_GRID_SUCCESS,
    GET_DIVISION_ASSETS_GRID_FAILURE,

    GET_DIVISION_DETAILS_REQUEST,
    GET_DIVISION_DETAILS_SUCCESS,
    GET_DIVISION_DETAILS_FAILURE,

    ASSIGN_DIVISION_ASSET_REQUEST,
    ASSIGN_DIVISION_ASSET_SUCCESS,
    ASSIGN_DIVISION_ASSET_FAILURE,

    UNASSIGN_DIVISION_ASSET_REQUEST,
    UNASSIGN_DIVISION_ASSET_SUCCESS,
    UNASSIGN_DIVISION_ASSET_FAILURE,

    UPDATE_DIVISION_REQUEST,
    UPDATE_DIVISION_SUCCESS,
    UPDATE_DIVISION_FAILURE,

    GET_OTHER_ASSETS_REQUEST,
    GET_OTHER_ASSETS_SUCCESS,
    GET_OTHER_ASSETS_FAILURE,

    CREATE_DIVISION_REQUEST,
    CREATE_DIVISION_SUCCESS,
    CREATE_DIVISION_FAILURE,

    GET_DIVISION_OPTIONS_REQUEST,
    GET_DIVISION_OPTIONS_SUCCESS,
    GET_DIVISION_OPTIONS_FAILURE,

    GET_DIVISION_OPTIONS_STRICT_REQUEST,
    GET_DIVISION_OPTIONS_STRICT_SUCCESS,
    GET_DIVISION_OPTIONS_STRICT_FAILURE,

    GET_USER_DIVISIONS_REQUEST,
    GET_USER_DIVISIONS_SUCCESS,
    GET_USER_DIVISIONS_FAILURE,
} = require('./divisionActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    allAssets: [],
    divisions: [],
    divisionsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    divisionAssets: [],
    divisionAssetsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    divisionDetail: {},
    divisionOptions: [],
    divisionOptionsStrict: [],
    userDivisions: [],
});

const divisionInitialState = new InitialState();

/**
 * ## divisionReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function divisionReducer(state = divisionInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = divisionInitialState.mergeDeep(state);
    switch (type) {
    case GET_DIVISIONS_GRID_REQUEST:
    case GET_DIVISION_ASSETS_GRID_REQUEST:
    case GET_DIVISION_DETAILS_REQUEST:
    case ASSIGN_DIVISION_ASSET_REQUEST:
    case UNASSIGN_DIVISION_ASSET_REQUEST:
    case UPDATE_DIVISION_REQUEST:
    case GET_OTHER_ASSETS_REQUEST:
    case CREATE_DIVISION_REQUEST:
    case GET_DIVISION_OPTIONS_REQUEST:
    case GET_DIVISION_OPTIONS_STRICT_REQUEST:
    case GET_USER_DIVISIONS_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_DIVISIONS_GRID_SUCCESS: {
        const divisionsPagination = {};
        divisionsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        divisionsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        divisionsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        divisionsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
        return state.set('isFetching', false)
            .set('divisions', payload.data)
            .set('divisionsPagination', divisionsPagination);
    }
    case GET_DIVISION_ASSETS_GRID_SUCCESS: {
        const divisionAssetsPagination = {};
        divisionAssetsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        divisionAssetsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        divisionAssetsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        divisionAssetsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
        return state.set('isFetching', false)
            .set('divisionAssets', payload.data)
            .set('divisionAssetsPagination', divisionAssetsPagination);
    }
    case GET_DIVISION_DETAILS_SUCCESS:
        return state.set('isFetching', false)
            .set('divisionDetail', payload.data);

    case ASSIGN_DIVISION_ASSET_SUCCESS: {
        const { allAssets, divisionAssets } = state;
        const userIndex = findIndex(allAssets, (val) => val.id === payload.asset_id);
        if (userIndex !== -1) {
            divisionAssets.push(allAssets[userIndex]);
            allAssets.splice(userIndex, 1);
        }

        return state.set('divisionAssets', divisionAssets)
            .set('allAssets', allAssets)
            .set('isFetching', false);
    }

    case UNASSIGN_DIVISION_ASSET_SUCCESS: {
        const { divisionAssets, allAssets } = state;
        let newUser = {};

        const userIndex = findIndex(divisionAssets, (val) => val.id === payload.asset_id);
        if (userIndex !== -1) {
            // adding removed user to all users list
            newUser = {
                id: divisionAssets[userIndex].id,
                name: divisionAssets[userIndex].name,
                asset_reg: divisionAssets[userIndex].asset_reg,
            };
            allAssets.push(newUser);
            divisionAssets.splice(userIndex, 1);
        }

        return state.set('divisionAssets', divisionAssets)
            .set('allAssets', allAssets)
            .set('isFetching', false);
    }

    case UPDATE_DIVISION_SUCCESS: {
        return state.set('isFetching', false)
            .set('departmentDetail', payload.data.department);
    }

    case GET_OTHER_ASSETS_SUCCESS: {
        return state.set('allAssets', payload.data)
            .set('isFetching', false);
    }

    case CREATE_DIVISION_SUCCESS:

        return state.set('isFetching', false);

    case GET_DIVISION_OPTIONS_SUCCESS: {
        return state.set('divisionOptions', payload.data)
            .set('isFetching', false);
    }

    case GET_DIVISION_OPTIONS_STRICT_SUCCESS: {
        return state.set('divisionOptionsStrict', payload.data)
            .set('isFetching', false);
    }

    case GET_USER_DIVISIONS_SUCCESS: {
        return state.set('userDivisions', payload.data)
            .set('isFetching', false);
    }

    case GET_DIVISIONS_GRID_FAILURE:
    case GET_DIVISION_ASSETS_GRID_FAILURE:
    case GET_DIVISION_DETAILS_FAILURE:
    case ASSIGN_DIVISION_ASSET_FAILURE:
    case UNASSIGN_DIVISION_ASSET_FAILURE:
    case UPDATE_DIVISION_FAILURE:
    case GET_OTHER_ASSETS_FAILURE:
    case CREATE_DIVISION_FAILURE:
    case GET_DIVISION_OPTIONS_FAILURE:
    case GET_DIVISION_OPTIONS_STRICT_FAILURE:
    case GET_USER_DIVISIONS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}

export {
    divisionReducer,
    divisionInitialState,
};
