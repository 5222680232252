import React from 'react';
import Layout from '../../components/layout/Layout';
import LoginForm from '../../components/LoginForm';

import './login.scss';

export default function() {
    return (
        <Layout data-test="pages-login" className="login" title="login" showHeader={false} showFooter={false}>
            <LoginForm />
        </Layout>
    );
}
