/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import { Popconfirm, Button, Tooltip } from 'antd';
import history from '../../../core/utils/history';
import { Edit, TrashCan, View } from '@carbon/icons-react';
import GridView from '../../../components/elements/GridView/index';

export default function () {
    const {
        actions,
        users,
        usersPagination,
        usersIsFetching,
    } = this.props;
    const { searchParams, orderParams } = this.state;

    let data = users.map((d) => {
        let displayRole = d.role;

        if (displayRole === 'user') {
            displayRole = 'User';
        }

        if (displayRole === 'admin') {
            displayRole = 'Admin';
        }

        if (displayRole === 'super-admin') {
            displayRole = 'Super Admin';
        }

        if (displayRole === 'manager') {
            displayRole = 'Manager';
        }

        return ({
            id: d.user_id,
            email: d.email,
            role: displayRole,
            name: d.name,
            status: parseInt(d.status, 10) === 1 ? 'Active' : 'Inactive',
            actions: (
                <>
                    <Tooltip title="View User">
                        <Link 
                            to={`/users/view/${d.user_id}`} 
                            className="actions-btn"
                        >
                            <View />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Edit User">
                        <Link 
                            to={`/users/view/${d.user_id}`} 
                            className="actions-btn"
                        >
                            <Edit />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Delete User">
                        <Popconfirm
                            title={`Are you sure you want to delete "${d.name}"?`}
                            onConfirm={(e) => {
                                e.stopPropagation();
                                this.removeUser(d.user_id);
                            }}
                            onCancel={(e) => {
                                e.stopPropagation();
                            }}
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <Button 
                                className='btn btn-empty actions-btn'
                                onClick={e => { e.stopPropagation(); }}
                            >
                                <TrashCan />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </>
                
            ),
        });
    });

    const paginationChange = (page) => {
        if (page !== usersPagination.currentPage) {
            actions.getUsersPageRequest({ page, searchParams, ...orderParams });
        }
    };

    const gridChange = (pagination, filters, sorter) => {
        if (pagination && pagination.current === parseInt(usersPagination.currentPage, 10)) {
            const params = { orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}` };
            this.setState({ orderParams: params });
            actions.saveUserOrderParamsRequest(params);
            actions.getUsersPageRequest({ page: usersPagination.currentPage, searchParams, ...params });
        }
    };

    return (
        <>
            <div className="section__action-bar">
                <Button
                    type='primary'
                    onClick={() => history.push('/users/create')}
                    className='page-header-button'
                    style={{ top: '16px', right: '16px' }}
                >
                    Add user
                </Button>
            </div>
            <div style={{ width: '100%', height: '16px'}} />
            <GridView
                data={data}
                onChange={gridChange}
                sortableColumns={['user_id', 'email', 'role', 'name', 'status']}
                pagination={{
                    total: parseInt(usersPagination.totalRecords, 10),
                    showSizeChanger: false,
                    pageSize: usersPagination.perPage,
                    current: parseInt(usersPagination.currentPage, 10),
                    onChange: paginationChange,
                }} />
        </>
    );
}
