import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { scaleTime } from 'd3-scale';
import {
    Slider, Rail, Handles, Tracks, Ticks,
} from 'react-compound-slider';
import {
    format, addHours, startOfToday, endOfToday, set,
} from 'date-fns';
import SliderRail from './components/SliderRail';
import Track from './components/Track';
import Tick from './components/Tick';
import Handle from './components/Handle';
import defaultStyles from './styles';
import railStyles from './components/SliderRail/styles';
import { getFormattedBlockedIntervals, getNowConfig, getTimestamp } from './functions';
import ForwardIcon from './icons/ForwardIcon';
import RewindIcon from './icons/RewindIcon';
import TimeRangeContext from './context';
import defaultColors from './colours';

function TimeRangeSlider({
    ticksNumber,
    selectedInterval: selectedIntervalProp,
    timelineInterval: timelineIntervalProp,
    disabledIntervals: disabledIntervalsReceived,
    disabledIntervalChildrens,
    step,
    formatTick,
    tickMinutesToDiplay,
    tickSecondsToDisplay,
    error,
    mode,
    showNow,
    onUpdateCallback,
    onChangeCallback,
    maximumIntervalStep,
    onForwardCallback,
    onRewindCallback,
    onIncreaseOptionCallback,
    timelineIncreaseOptions,
    selectedIncreaseOption,
    showResetBtn,
    resetCallback,
    styles,
    colours,
    showTimingPresetsFooter,
}) {
    const [selectedInterval, setSelectedInterval] = useState(selectedIntervalProp);
    const [timelineInterval, setTimelineInterval] = useState(timelineIntervalProp);

    useEffect(() => {
        setTimelineInterval(timelineIntervalProp);
    }, [timelineIntervalProp]);

    useEffect(() => {
        setSelectedInterval(selectedIntervalProp);
    }, [selectedIntervalProp]);

    const disabledIntervals = getFormattedBlockedIntervals(
        disabledIntervalsReceived,
        timelineInterval,
    );

    const now = getNowConfig(timelineInterval);

    const onChange = (newTime) => {
        // to avoid bug, changing slider by mouse click triggers 2 onChange events
        let changed = false;
        for (let i = 0; i < newTime.length; i++) {
            const time = newTime[i];
            const oldTime = +selectedInterval[i];
            if (time !== oldTime) {
                changed = true;
                break;
            }
        }
        if (changed) {
            const formattedNewTime = newTime.map((t) => new Date(t));
            selectedInterval.map((t) => +t);
            onChangeCallback(formattedNewTime);
        }
    };

    const checkIsSelectedIntervalNotValid = (newTime, source, target) => {
        if (newTime.length > 0) {
            const start = newTime[0];
            const end = newTime[newTime.length - 1];
            const { value: startInterval } = source;
            const { value: endInterval } = target;

            if ((startInterval > start && endInterval <= end)
                    || (startInterval >= start && endInterval < end)) {
                return true;
            }
            if (start >= startInterval && end <= endInterval) return true;

            const isStartInBlockedInterval = start > startInterval && start < endInterval && end >= endInterval;
            const isEndInBlockedInterval = end < endInterval && end > startInterval && start <= startInterval;

            return isStartInBlockedInterval || isEndInBlockedInterval;
        }
        return false;
    };

    // Maximum interval logic
    const adjustInterval = (formattedNewTime, exceededInterval) => {
        const newSelectedInterval = [...selectedInterval];
        const lastIndex = selectedInterval.length - 1;

        if (getTimestamp(selectedInterval[lastIndex]) !== getTimestamp(formattedNewTime[lastIndex])) {
            // right slider changed
            newSelectedInterval[lastIndex] = formattedNewTime[lastIndex];

            const newStartIntervalTimestamp = getTimestamp(selectedInterval[0]) + exceededInterval;
            const newStartIntervalFormatted = new Date(newStartIntervalTimestamp);
            newSelectedInterval[0] = newStartIntervalFormatted;

            for (let i = 1; i < lastIndex; i++) {
                let currentSliderT = getTimestamp(newSelectedInterval[i]);
                const prevSliderT = getTimestamp(newSelectedInterval[i - 1]);
                if (currentSliderT <= prevSliderT) {
                    const diff = prevSliderT - currentSliderT;
                    currentSliderT += diff + step;
                    newSelectedInterval[i] = new Date(currentSliderT);
                }
            }

            setSelectedInterval(newSelectedInterval);
        } else {
            // left slider changed
            newSelectedInterval[0] = formattedNewTime[0];

            const newStartIntervalTimestamp = getTimestamp(selectedInterval[lastIndex]) - exceededInterval;
            const newStartIntervalFormatted = new Date(newStartIntervalTimestamp);
            newSelectedInterval[lastIndex] = newStartIntervalFormatted;

            for (let i = lastIndex - 1; i > 0; i--) {
                let currentSliderT = getTimestamp(newSelectedInterval[i]);
                const prevSliderT = getTimestamp(newSelectedInterval[i + 1]);
                if (currentSliderT >= prevSliderT) {
                    const diff = prevSliderT - currentSliderT;
                    currentSliderT -= (diff + step);
                    newSelectedInterval[i] = new Date(currentSliderT);
                }
            }

            setSelectedInterval(newSelectedInterval);
        }

        const timestampArray = [];
        newSelectedInterval.forEach((element) => {
            timestampArray.push(getTimestamp(element));
        });
        return [newSelectedInterval, timestampArray];
    };

    const checkNewInterval = (newTime) => {
        const formattedNewTime = newTime.map((t) => new Date(t));
        if (maximumIntervalStep != null) {
            const intervalStep = formattedNewTime[formattedNewTime.length - 1] - formattedNewTime[0];
            if (intervalStep > maximumIntervalStep) {
                const exceededInterval = intervalStep - maximumIntervalStep;
                return adjustInterval(formattedNewTime, exceededInterval);
            }
        }
        return [formattedNewTime, newTime];
    };

    const onUpdate = (newTime) => {
        const [formattedNewTime, verifiedNewTime] = checkNewInterval(newTime);

        if (disabledIntervals?.length) {
            const isValuesNotValid = disabledIntervals.some(({ source, target }) => checkIsSelectedIntervalNotValid(verifiedNewTime, source, target));
            onUpdateCallback({ error: isValuesNotValid, time: formattedNewTime });
        } else {
            onUpdateCallback({ error: false, time: formattedNewTime });
        }
    };

    const dateTicks = scaleTime()
        .domain(timelineInterval)
        .ticks(ticksNumber)
        .map((t) => +t);

    const domain = timelineInterval.map((t) => Number(t));

    const changeIncreaseOption = (option) => {
        onIncreaseOptionCallback(option);
    };

    const handleRewindCallback = () => {
        onRewindCallback(selectedIncreaseOption);
    };

    const handleForwardCallback = () => {
        onForwardCallback(selectedIncreaseOption);
    };

    return (
        <TimeRangeContext.Provider value={{ styles, colours }}>
            <div 
                className="timeRangeWrapper" 
                style={{ ...defaultStyles.timeRangeWrapper, ...styles.timeRangeWrapper }}>
                <div 
                    className="timeRangeContainer" 
                    style={{ ...defaultStyles.timeRangeContainer, ...styles.timeRangeContainer }}>
                    <div 
                        className="timeRangeContent" 
                        style={{ ...defaultStyles.timeRangeContent, ...styles.timeRangeContent }}>
                        <div 
                            style={{ ...defaultStyles.rewindButton, ...styles.rewindButton }} 
                            onClick={handleRewindCallback}>
                            <RewindIcon color={colours.primaryColor || defaultColors.primaryColor} />
                        </div>
                        <Slider
                            mode={mode}
                            step={step}
                            domain={domain}
                            onUpdate={onUpdate}
                            onChange={onChange}
                            values={selectedInterval.map((t) => +t)}
                            rootStyle={{ position: 'relative', width: '100%' }}>
                            <Rail 
                                className="railOuter" 
                                style={{ ...railStyles.railOuter, ...styles.railOuter }}>
                                {({ getRailProps }) => (
                                    <SliderRail
                                        className="railInner"
                                        style={{ ...railStyles.railInner, ...styles.railInner }}
                                        getRailProps={getRailProps} />
                                )}
                            </Rail>
                            <Handles>
                                {({ handles, getHandleProps }) => (
                                    <>
                                        {handles.map((handle, index) => (
                                            <Handle
                                                error={error}
                                                key={handle.id}
                                                handle={handle}
                                                domain={domain}
                                                getHandleProps={getHandleProps}
                                                time={selectedInterval[index]}
                                                index={index} />
                                        ))}
                                    </>
                                )}
                            </Handles>
                            <Tracks left={false} right={false}>
                                {({ tracks, getTrackProps }) => (
                                    <>
                                        {tracks?.map(({ id, source, target }, index) => (
                                            <Track
                                                error={error}
                                                key={id}
                                                source={source}
                                                target={target}
                                                getTrackProps={getTrackProps}
                                                index={index} />
                                        ))}
                                    </>
                                )}
                            </Tracks>
                            {disabledIntervals?.length && (
                                <Tracks left={false} right={false}>
                                    {({ getTrackProps }) => (
                                        <>
                                            {disabledIntervals.map(({ id, source, target }) => (
                                                <Track
                                                    key={id}
                                                    source={source}
                                                    target={target}
                                                    getTrackProps={getTrackProps}
                                                    disabled
                                                    childrens={disabledIntervalChildrens} />
                                            ))}
                                        </>
                                    )}
                                </Tracks>
                            )}
                            {showNow && (
                                <Tracks left={false} right={false}>
                                    {({ getTrackProps }) => (
                                        <Track
                                            key={now?.id}
                                            source={now?.source}
                                            target={now?.target}
                                            getTrackProps={getTrackProps} />
                                    )}
                                </Tracks>
                            )}
                            <Ticks values={dateTicks}>
                                {({ ticks }) => (
                                    <>
                                        {ticks.map((tick) => (
                                            <Tick
                                                key={tick.id}
                                                tick={tick}
                                                count={ticks.length}
                                                format={formatTick}
                                                minutesToDisplay={tickMinutesToDiplay}
                                                secondsToDisplay={tickSecondsToDisplay} />
                                        ))}
                                    </>
                                )}
                            </Ticks>
                        </Slider>
                        <div 
                            style={{ ...defaultStyles.forwardButton, ...styles.forwardButton }} 
                            onClick={handleForwardCallback}>
                            <ForwardIcon color={colours.primaryColor || defaultColors.primaryColor} />
                        </div>
                    </div>
                </div>
                {showTimingPresetsFooter && (
                    <div 
                        className="buttonFooter" 
                        style={{ ...defaultStyles.buttonFooter, ...styles.buttonFooter }}>
                        {timelineIncreaseOptions.map((option) => {
                            if (option.value === selectedIncreaseOption.value) {
                                return (
                                    <div
                                        className="increaseOptionButton"
                                        style={{ ...defaultStyles.increaseOptionButton, ...defaultStyles.selectedIncreaseOption, ...styles.increaseOptionButton }}
                                        key={`option${option.value}`}
                                        onClick={() => { changeIncreaseOption(option); }}>
                                        {option.label}
                                    </div>
                                );
                            }
                            return (
                                <div
                                    className="increaseOptionButton"
                                    style={{ ...defaultStyles.increaseOptionButton, ...styles.increaseOptionButton }}
                                    key={`option${option.value}`}
                                    onClick={() => { changeIncreaseOption(option); }}>
                                    {option.label}
                                </div>
                            );
                        })}
                        {showResetBtn && (
                            <div
                                className="resetBtn"
                                style={{ ...defaultStyles.resetBtn, ...styles.resetBtn }}
                                onClick={resetCallback}>
                                Reset
                            </div>
                        )}
                    </div>
                )}
            </div>
        </TimeRangeContext.Provider>
    );
}

TimeRangeSlider.propTypes = {
    ticksNumber: PropTypes.number.isRequired,
    selectedInterval: PropTypes.arrayOf(PropTypes.object),
    timelineInterval: PropTypes.arrayOf(PropTypes.object),
    disabledIntervals: PropTypes.arrayOf(PropTypes.object),
    disabledIntervalChildrens: PropTypes.array,
    step: PropTypes.number,
    error: PropTypes.bool,
    mode: PropTypes.number,
    showNow: PropTypes.bool,
    formatTick: PropTypes.func,
    tickMinutesToDiplay: PropTypes.array,
    tickSecondsToDisplay: PropTypes.array,
    onUpdateCallback: PropTypes.func,
    onChangeCallback: PropTypes.func,
    maximumIntervalStep: PropTypes.number,
    onForwardCallback: PropTypes.func,
    onRewindCallback: PropTypes.func,
    onIncreaseOptionCallback: PropTypes.func,
    timelineIncreaseOptions: PropTypes.array,
    selectedIncreaseOption: PropTypes.object,
    showResetBtn: PropTypes.bool,
    resetCallback: PropTypes.func,
    styles: PropTypes.object,
    colours: PropTypes.object,
};

TimeRangeSlider.defaultProps = {
    selectedInterval: [
        set(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 }),
        set(addHours(new Date(), 1), { minutes: 0, seconds: 0, milliseconds: 0 }),
    ],
    timelineInterval: [startOfToday(), endOfToday()],
    formatTick: (ms) => format(new Date(ms), 'HH:mm'),
    disabledIntervals: [],
    disabledIntervalChildrens: [],
    step: 1000 * 60 * 30,
    ticksNumber: 48,
    tickMinutesToDiplay: [0],
    tickSecondsToDisplay: [0],
    error: false,
    mode: 3,
    showNow: false,
    onUpdateCallback: () => {},
    onChangeCallback: () => {},
    maximumIntervalStep: null,
    onForwardCallback: () => {},
    onRewindCallback: () => {},
    onIncreaseOptionCallback: () => {},
    timelineIncreaseOptions: [],
    selectedIncreaseOption: {},
    showResetBtn: false,
    resetCallback: () => {},
    styles: {},
    colours: {},
};

export default TimeRangeSlider;
