import React from 'react';
import { Row, Col } from 'antd';
import dayjs from 'dayjs';
import MapNew from '../../../components/MapNew';
import CustomEmpty from '../../../components/CustomEmpty';
import VideoPlayer from '../../../components/elements/VideoPlayer'
import { 
    Channel, DeviceTimelineSegment, GeofenceTimelineSegment, VideoSearchResponse, 
    MarkerType, PopupType, MarkerData, PathSegmentData, DVRChannelLink,
} from '../../../types';

const { DB_DATETIME_FORMAT } = require('../../../core/constants');

interface VideoSearchTabProps {
    data?: VideoSearchResponse | null;
    selectedDate?: string;
    timelineSegments?: DeviceTimelineSegment[] | null;
    geofenceTimelineSegments?: GeofenceTimelineSegment[] | null;
    selectedDvrIsOnline?: boolean;
    selectedChannels?: { channel: string, selected: boolean }[] | null;
    toggleSelectedChannel?: (channel: string) => void;
    onChangeSliderValue?: (value: [number, number, number]) => void;
    isVisibleSearchSidebar?: boolean;
    isVisibleVideoTiles?: boolean;
    channelLinks?: DVRChannelLink[] | null;
    eventTypes?: { id: number, key: string }[];
    parentIsLoading?: boolean;
    sliderTextValue: string;
    videoSearchRequestedStartSeconds: number | null;
}

const VideoSearchTab: React.FC<VideoSearchTabProps> = ({
    data = null,
    selectedDate = dayjs().format(DB_DATETIME_FORMAT),
    timelineSegments = null,
    geofenceTimelineSegments = null,
    selectedDvrIsOnline = false,
    selectedChannels = null,
    toggleSelectedChannel = (channel) => {},
    onChangeSliderValue = (value) => {},
    isVisibleSearchSidebar = true,
    isVisibleVideoTiles = false,
    channelLinks = null,
    eventTypes = [],
    parentIsLoading = false,
    sliderTextValue = '12:00:00',
    videoSearchRequestedStartSeconds = null,
}) => {
    if (data?.info && data?.locations) {
        const markers: MarkerData[] = [{
            type: MarkerType.Asset,
            location: {
                lat: parseFloat(data.info.lat),
                lng: parseFloat(data.info.lng),
            },
            angle: data.info.angle,
            label: data.info.name,
            divisionColor: data.info.division_color,
            deviceStatus: data.info.device_status,
            cachedAvlInfo: data.info.cached_avl_info || '',
            speed: data.info.speed || '',
            popupData: {
                type: PopupType.Asset,
                assetId: String(data.info.id),
                date: selectedDate,
            },
        }];
        const path: PathSegmentData[] = data.locations.map((location) => ({
            location: {
                lat: parseFloat(location.lat),
                lng: parseFloat(location.lng),
            },
            eventType: parseInt(location.event_type),
            icon: location.icon,
            datetime: location.time,
            popupData: location.show_in_events ? {
                type: PopupType.Event,
                eventName: location.event_key,
                speed: location.speed,
                time: location.time,
                driverId: data.info.user_id,
                driverName: data.info.full_name,
                location: {
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lng),
                    address: '',
                },
                avlKeys: location.location_data,
            } : null,
        }));
        const mapComponent = (
            <MapNew
                containerStyle={{ 
                    width: '100%', 
                    height: 'calc(100vh - 125px)',
                }} 
                markers={markers}
                path={path}
                isVisibleSlider={true}
                timelineData={{
                    timelineSegments,
                    geofenceTimelineSegments,
                }}
                selectedDvrIsOnline={selectedDvrIsOnline}
                onChangeSliderValue={onChangeSliderValue}
                selectedDate={selectedDate}
                selectedAssetId={data.info.id}
                isVisibleSearchSidebar={isVisibleSearchSidebar}
                eventTypes={eventTypes}
                parentIsLoading={parentIsLoading}
                sliderTextValue={sliderTextValue}
                videoSearchRequestedStartSeconds={videoSearchRequestedStartSeconds}
                channelLinks={channelLinks}
            />
        );

        if (isVisibleVideoTiles) {
            return (
                <>
                    <Row 
                        className='only-render-on-large-screens'
                        gutter={0}
                        style={{ margin: 0 }}
                    >
                        <Col span={12}>
                            {mapComponent}
                        </Col>
                        <Col span={12}>
                            <Row 
                                gutter={0}
                                style={{ margin: 0 }}
                            >
                                {channelLinks && (
                                    <>
                                        {channelLinks.length ? channelLinks.map((channelLink, i) => {
                                            return (
                                                <Col
                                                    key={`camera-${i}-${channelLink.id}`}
                                                    span={24}
                                                    style={{ backgroundColor: 'var(--cool-gray-80)' }}
                                                >
                                                    {channelLink.url && (
                                                        <VideoPlayer 
                                                            minHeight={300}
                                                            identity={channelLink.id} 
                                                            url={channelLink.url} 
                                                            videoType={channelLink.video_api === 'Howen' ? 'howen' : 'flv'} 
                                                        />
                                                    )}
                                                </Col>
                                            );
                                        }) : (
                                            <div className='no-videos-info'>No available videos.</div>
                                        )}
                                    </>
                                )}
                            </Row>
                        </Col>
                    </Row>
                    <Row 
                        className='only-render-on-small-screens'
                        gutter={0}
                        style={{ margin: 0 }}
                    >
                        <Col span={12}>
                            {mapComponent}
                        </Col>
                        {channelLinks && (
                            <>
                                {channelLinks.length ? channelLinks.map((channelLink, i) => {
                                    return (
                                        <Col
                                            key={`camera-${i}-${channelLink.id}`}
                                            span={12}
                                            style={{ backgroundColor: 'var(--cool-gray-80)' }}
                                        >
                                            {channelLink.url && (
                                                <VideoPlayer 
                                                    minHeight={300}
                                                    identity={channelLink.id} 
                                                    url={channelLink.url} 
                                                    videoType={channelLink.video_api === 'Howen' ? 'howen' : 'flv'} 
                                                />
                                            )}
                                        </Col>
                                    );
                                }) : (
                                    <div className='no-videos-info'>No available videos.</div>
                                )}
                            </>
                        )}
                    </Row>
                </>
                
            )
        } else {
            return mapComponent;
        }
    } 
            
    return (
        <CustomEmpty type='video_search' />
    );
}

export default VideoSearchTab;
