import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Col, Row, Radio, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import Map from '../Map';
import { Link } from 'react-router-dom';
import { isEmpty } from 'underscore';
import ReactToPdf from 'react-to-pdf';
import VideoSelection from './partial/VideoSelection';
import ShareReport from './partial/ShareReport';
import VideoPlayers from './partial/VideoPlayers';
import './detailView.scss';
import { timeToSeconds } from '../../core/utils/functions';
import { getBaseUrl } from '../../core/utils/api';
import { requestMarkerDetails, setMarkerPopoverToLoading } from '../../core/utils/mapUtils';
import { VideoCameraFilled } from '@ant-design/icons';
import ExportVideoStatus from './partial/ExportVideoStatus';
import Spinner from '../Spinner';

const { GOOGLE_MAP_API_KEY } = require('../../core/constants').default;

export default function () {
    const {
        selectedEvent, user, searchedSingleVideoChannels, isFetchingVideoUrls, allowActions,
        showStreamingSectionOnLoad, showDownloadIframe, parent, showTimingPresetsFooter,
    } = this.props;

    const {
        sliderValue, sliderRangeMinValue, sliderRangeMaxValue, streamingVideos, videoMode,
        showResetBtn, markerPopover, videoStreamsReady, exportedCacheVideo
    } = this.state;

    const hasDVR = selectedEvent && selectedEvent.dvrDetails && Object.keys(selectedEvent.dvrDetails).length > 0;
    const mapMinHeight = showDownloadIframe ? '400px' : '800px';
    const mapWidthSpan = streamingVideos ? 12 : !hasDVR && !showStreamingSectionOnLoad ? 24 : 15;
    const mappedDevicePath = this.mapDevicePath(selectedEvent);
    const assets = this.getAssetCurrentPin(mappedDevicePath);
    const disablePlayButton = !((videoMode === 'streaming' && videoStreamsReady) || videoMode !== 'streaming');

    //this is needed since the tooltip will override the original which switches between play / pause
    const playPauseButton = { key: 'play-pause', visible: true, disabled: disablePlayButton };
    if (disablePlayButton) {
        playPauseButton.tooltip = 'Video not ready or not selected';
    }
    const mapToolBarButtons = [
        { key: 'side-bar', visible: false },
        { key: 'info-box', visible: true },
        { key: 'traffic', visible: false },
        { key: 'geo-fence', visible: false },
        { key: 'center-map', visible: false },
        { key: 'satellite', visible: true },
        { key: 'heat-map', visible: false },
        { key: 'live-map', visible: false },
        { key: 'video-search', visible: false },
        playPauseButton,
    ];

    const dvrOnline = selectedEvent?.dvrDetails?.is_online || 0;
    const cachedVideos = selectedEvent?.dvrDetails?.cached_videos || [];
    const discoveredVideos = selectedEvent?.dvrDetails?.discovered_videos || [];
    const canReprocessCacheVideos = (cachedVideos.length > 0 || discoveredVideos.length > 0) && user.role == 'super-admin';

    const channels = selectedEvent?.dvrDetails?.available_channels || [];
    const hasFailedCached = selectedEvent?.dvrDetails?.has_failed_cache || false;
    const { permissions = {} } = user || {};
    const videoSidePanelPermission = permissions?.live_video || permissions?.historic_video_downloading || parent === 'sharedView';
    let eventInfo = [];

    if (selectedEvent && selectedEvent.telematicsData && selectedEvent.telematicsData.eventStart && selectedEvent.telematicsData.eventStartLocation) {
        eventInfo.push(
            <Row 
                key="event-type" 
                className="video-view-info-row"
            >
                <Col xs={5}>
                    <span className='label'>Event Type</span>
                </Col>
                <Col xs={19}>
                    <span className='value'>{selectedEvent.telematicsData.eventType}</span>
                </Col>
            </Row>
        );
        eventInfo.push(
            <Row 
                key="event-start" 
                className="video-view-info-row"
            >
                <Col xs={5}>
                    <span className='label'>Event Time</span>
                </Col>
                <Col xs={19}>
                    <span className='value'>{selectedEvent.telematicsData.eventStart}</span>
                </Col>
            </Row>
        );
        eventInfo.push(
            <Row 
                key="event-location" 
                className="video-view-info-row"
            >
                <Col xs={5}>
                    <span className='label'>Event Location</span>
                </Col>
                <Col xs={19}>
                    <span className='value'>{selectedEvent.telematicsData.eventStartLocation}</span>
                </Col>
            </Row>
        );
    }

    if (eventInfo.length === 0) {
        const identifyEvents = [];
        this.eventsInTrip.forEach((eventRow) => {
            if (!identifyEvents[eventRow.title]) {
                identifyEvents[eventRow.title] = 0;
            }
            identifyEvents[eventRow.title] += 1;
        });
        // eslint-disable-next-line no-restricted-syntax
        for (const eventRowIndex in identifyEvents) {
            if (Object.prototype.hasOwnProperty.call(identifyEvents, eventRowIndex)) {
                const eventRow = identifyEvents[eventRowIndex];
                eventInfo.push(
                    <Row key={`event-info-row-${eventRowIndex}`} className="video-view-info-row">
                        <Col xs={5}><span className='label'>{eventRowIndex}</span></Col>
                        <Col xs={19}><span className='value'>found {eventRow} event(s)</span></Col>
                    </Row>,
                );
            }
        }
    }
    if (eventInfo.length === 0) {
        eventInfo = (
            <Row className="video-view-info-row">
                <Col xs={24}><span className='value'>No event(s) information available</span></Col>
            </Row>
        );
    }

    const highlightSlider = [];
    if (cachedVideos && cachedVideos.length > 0) {
        cachedVideos.forEach((cVideo) => {
            const startTimeParts = cVideo[0].start_time && (cVideo[0].start_time).split(' ');
            const endTimeParts = cVideo[0].end_time && (cVideo[0].end_time).split(' ');
            if (startTimeParts && startTimeParts[1] && endTimeParts && endTimeParts[1]) {
                let cacheVideoStart = timeToSeconds(startTimeParts[1]);
                let cacheVideoEnd = timeToSeconds(endTimeParts[1]);
                if (cacheVideoStart < selectedEvent?.endInterval) {
                    if (cacheVideoStart < selectedEvent?.startInterval) {
                        cacheVideoStart = selectedEvent?.startInterval;
                    }
                    if (cacheVideoEnd > selectedEvent?.endInterval) {
                        cacheVideoEnd = selectedEvent?.endInterval;
                    }
                    highlightSlider.push([cacheVideoStart, cacheVideoEnd]);
                }
            }
        });
    }

    const videoContent = () => {
        const { live_video: canViewLiveVideo, historic_video_downloading: canViewHistoricVideo } = permissions;

        if (canViewLiveVideo && videoMode === 'streaming') {
            if (hasFailedCached) {
                return (
                    <VideoSelection
                        parent={this}
                        title="The cache process failed"
                        content="Please click the below button to request cache again"
                        buttonLabel="Request Cache"
                        channels={[]}
                        onClick={this.requestCacheAgain} />
                );
            }

            return (
                <Spinner loading={isFetchingVideoUrls}>
                    {/* Choose streaming channels (when device is online) */}
                    {(!streamingVideos && +dvrOnline === 1) || (searchedSingleVideoChannels.length === 0 && +dvrOnline === 1)
                        ? (
                            <VideoSelection
                                parent={this}
                                title="Stream Videos"
                                content="Move the middle point on the event slider bar to the location/time you want to view, select channels below, then click 'view'"
                                buttonLabel="View"
                                channels={channels}
                                onClick={this.getSelectedVideosUrl} />
                        )
                        : null}

                    {/* Streaming not available when device is not online */}
                    {!streamingVideos && +dvrOnline === 0
                        ? (
                            <VideoSelection
                                allowActions={canViewHistoricVideo && allowActions}
                                className="extra-height"
                                title="Stream Unavailable"
                                parent={this}
                                content={(
                                    <>
                                        <div>We cannot connect to the asset to stream the video. This might be because the vehicle is offline, not connected to a power source, or
                                            has a weak signal.
                                        </div>
                                        <div>We can download this footage for you automatically when the asset comes back online, select the channels below and click &apos;Request For
                                            Later&apos;.
                                        </div>
                                    </>
                                )}
                                buttonLabel="Request for later"
                                channels={canViewHistoricVideo ? channels : []}
                                onClick={this.requestCacheAndTrimVideos} />
                        )
                        : null}
                    {/* Streaming videos now */}
                    {streamingVideos && searchedSingleVideoChannels.length > 0
                        ? (
                            <Spinner loading={!videoStreamsReady}>
                                <VideoPlayers refs={this.streamingVideosRefs} videos={searchedSingleVideoChannels} onReady={this.onReady.bind(this)} onRemoved={this.onRemoved.bind(this)} minHeight={348} />
                            </Spinner>
                        )
                        : null}
                </Spinner>
            );
        }

        if ((canViewHistoricVideo || parent === 'sharedView') && videoMode === 'cached') {
            const nonCachedChannels = [];

            for (let i = 0; i < channels.length; i++) {
                const channel = channels[i];
                let alreadyCached = false;
                for (let j = 0; j < cachedVideos.length; j++) {
                    const cachedVideo = cachedVideos[j] ? cachedVideos[j][0] : null;
                    if (cachedVideo && channel.channel == cachedVideo.channel) {
                        alreadyCached = true;
                        break;
                    }
                }
                if (!alreadyCached) {
                    nonCachedChannels.push(channel);
                }
            }

            return (
                <Spinner loading={isFetchingVideoUrls}>
                    {cachedVideos ? (
                        <>
                            <VideoPlayers refs={this.cachedVideosRefs} videos={cachedVideos} isFlv={false} />
                        </>
                    ) : null}
                    {parent !== 'sharedView' && nonCachedChannels.length > 0 ? (
                        <VideoSelection
                            allowActions={canViewHistoricVideo && allowActions}
                            className="extra-height"
                            parent={this}
                            content={(
                                <>
                                    <div>To download this footage automatically when the asset comes back online, select the channels below and click &apos;Request For
                                        Later&apos;.
                                    </div>
                                </>
                            )}
                            buttonLabel="Request for later"
                            channels={canViewHistoricVideo ? nonCachedChannels : []}
                            onClick={this.requestCacheAndTrimVideos} />
                    ) : null}
                </Spinner>
            );
        }

        return null;
    };

    const videoSidebar = () => {
        const { live_video: canViewLiveVideo, historic_video_downloading: canViewHistoricVideo } = permissions;
        const defaultTab = !canViewLiveVideo || cachedVideos.length > 0 ? 'cached' : 'streaming';
        const adjustableHeight = videoMode === 'streaming' && !streamingVideos && +dvrOnline === 0;

        if (!videoSidePanelPermission) {
            return null;
        }

        if (!showDownloadIframe) {
            return null;
        }

        // the only instance this should be disabled is when user can choose videoMode and there are no cached videos
        const cachedButtonDisabled = canViewLiveVideo && canViewHistoricVideo && cachedVideos.length === 0;

        return (
            <Col xs={24} md={(mapWidthSpan === 12 ? 12 : 9)}>
                {(selectedEvent && selectedEvent.dvrDetails && Object.keys(selectedEvent.dvrDetails).length > 0) || showStreamingSectionOnLoad
                    ? (
                        <>
                            <Row style={{ marginBottom: '20px' }}>
                                <Col span={24}>
                                    {(cachedVideos.length > 0 && parent !== 'sharedView')
                                        ? (
                                            <div style={{
                                                textAlign: 'right',
                                                marginBottom: '10px',
                                            }}><VideoCameraFilled style={{ marginRight: '10px' }} /> There are downloaded
                                                videos available for this asset.
                                            </div>
                                        )
                                        : null}
                                    <div style={{
                                        textAlign: 'right',
                                        marginBottom: '5px',
                                    }}>
                                        <span
                                            className="video-type-box"
                                            style={{
                                                float: 'left',
                                                marginLeft: 0,
                                            }}>
                                            <Radio.Group
                                                defaultValue={defaultTab}
                                                buttonStyle="solid"
                                                onChange={(e) => {
                                                    const state = {};
                                                    state.videoMode = e.target.value;
                                                    state.streamingVideos = false;
                                                    state.selectedVideoChannels = [];
                                                    this.setState(state);
                                                    if (this.videoTimer != null) {
                                                        this.videoTimer = clearTimeout(this.videoTimer);
                                                    }
                                                }}>
                                                {canViewLiveVideo ? (<Radio.Button value="streaming">Streaming</Radio.Button>) : null}
                                                {canViewHistoricVideo ? (<Radio.Button value="cached" disabled={cachedButtonDisabled}>Downloaded</Radio.Button>) : null}
                                            </Radio.Group>
                                            {videoMode === 'cached' && (
                                                <ExportVideoStatus 
                                                    isShareView={parent === 'sharedView'} 
                                                    requestExportCacheVideo={this.requestExportCacheVideo} 
                                                    exportedCacheVideo={exportedCacheVideo} 
                                                    checkStatus={this.requestExportCacheVideoStatus.bind(this)} 
                                                    channels={channels}
                                                />
                                            )}
                                        </span>
                                        <span className="video-type-box">
                                            {canReprocessCacheVideos ?
                                                (
                                                    <Button
                                                        onClick={this.reprocessCacheVideo}
                                                        className="btn btn-bordered"
                                                        style={{ marginRight: '16px', width: '120px' }}
                                                    >
                                                        Reprocess
                                                    </Button>
                                                )
                                                : null
                                            }
                                            {canViewHistoricVideo && allowActions && isEmpty(cachedVideos) && !hasFailedCached
                                                ? (
                                                    <Button
                                                        type="primary"
                                                        onClick={this.requestCacheAndTrimVideos}
                                                        className="btn"
                                                    >
                                                        Download
                                                    </Button>
                                                )
                                                : null}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <div className={`video-sidebar ${adjustableHeight ? 'extra-height' : ''}`}>
                                {videoContent()}
                            </div>
                        </>
                    )
                    : null}
            </Col>
        );
    };
    let actualShowTimingPresetsFooter = showTimingPresetsFooter;
    if (actualShowTimingPresetsFooter === undefined) {
        actualShowTimingPresetsFooter = true;
    }

    return (
        <div className={showDownloadIframe ? 'video-detail-view' : 'video-detail-view video-detail-view-pdf'} ref={this.pdfDocumentRef}>
            {showDownloadIframe
                ? null
                : (
                    <ReactToPdf
                        x={0}
                        y={0}
                        targetRef={this.pdfDocumentRef}
                        filename={`event_report_${dayjs().format('YYYY_MM_DD_HH_MM_SS')}.pdf`}
                        scale={1}
                        options={{
                            orientation: 'l',
                            unit: 'px',
                            // format: [pdfWidth, pdfHeight],
                            format: [1500, 850],
                        }}>
                        {({ toPdf }) => (
                            <Button
                                id="download-pdf-button"
                                type="primary"
                                className="btn ml-2"
                                onClick={(e) => { this.renderPdf(toPdf); }}>
                                <Spinner loading={false}>
                                    <LegacyIcon name="share2" />
                                    <span>download pdf report</span>
                                </Spinner>
                            </Button>
                        )}
                    </ReactToPdf>
                )
            }

            <Row gutter={16}>
                <Col xs={24} md={showDownloadIframe && videoSidePanelPermission ? mapWidthSpan : 24}>
                    {/* Map and telematics */}
                    <Row className="map-container">
                        <Col span={24} style={{ minHeight: mapMinHeight }}>
                            <Map
                                ref={this.googleMapAccess}
                                allowMultipleRangeSlider
                                sliderRangeMaxValue={sliderRangeMaxValue}
                                sliderRangeMinValue={sliderRangeMinValue}
                                markerOnMouseEnter={(marker) => { setMarkerPopoverToLoading(this); requestMarkerDetails(marker, this) }}
                                markerPopover={markerPopover}
                                highlightSlider={highlightSlider}
                                mapDate={dayjs()
                                    .subtract('1', 'days')
                                    .format('DD/MM/YYYY')}
                                apiKey={GOOGLE_MAP_API_KEY}
                                mapToolBarButtons={mapToolBarButtons}
                                sliderStep={1}
                                sliderValue={parseInt(sliderValue, 10)}
                                sliderMinValue={selectedEvent?.startInterval}
                                sliderMaxValue={selectedEvent?.endInterval}
                                sliderValueUnit="seconds"
                                hideDatePicker
                                onSliderRangeChange={(value, formattedValue, rangeMin, rangeMax, changedByPlay) => this.onSliderRangeChange(value, formattedValue, rangeMin, rangeMax, changedByPlay)}
                                assets={assets}
                                showToolbar={showDownloadIframe}
                                toolbarLoading={!selectedEvent}
                                path={mappedDevicePath}
                                onAutoPlay={this.handleResume}
                                onPause={this.handlePause}
                                allowStreetView={false}
                                showFindCenterButton={false}
                                forwardCallback={this.handleForward}
                                rewindCallback={this.handleRewind}
                                increaseOptionCallback={this.handleIncreaseOptionCallback}
                                resetCallback={this.handleReset}
                                showResetBtn={showResetBtn} 
                                showTimingPresetsFooter={actualShowTimingPresetsFooter}
                            />
                        </Col>
                    </Row>
                    {!hasDVR
                        ? (
                            <p style={{
                                textAlign: 'right',
                                paddingTop: 10,
                                paddingRight: 10,
                            }}>No DVR is connected to the asset.
                            </p>
                        )
                        : null}
                    {showDownloadIframe && (
                        <ShareReport
                            sliderRangeMinValue={sliderRangeMinValue}
                            sliderRangeMaxValue={sliderRangeMaxValue}
                            selectedEvent={selectedEvent}
                            props={this.props} />

                    )}

                </Col>
                {videoSidebar()}
            </Row>

            <Row gutter={[16, 16]} className="asset-info-row">
                <Col xs={12}>
                    <div className="video-view-block-title">Asset & Driver Information</div>
                    <Row gutter={[32, 32]}>
                        <Col span={12}>
                            <img
                                alt="Asset"
                                src={
                                    selectedEvent?.info?.asset_picture_id
                                        ? `${getBaseUrl()}/site/display-file?mediaId=${selectedEvent?.info?.asset_picture_id}`
                                        : user?.userCompany?.company_image
                                }
                                style={{ width: '100%', maxHeight: '400px' }} 
                                className='driver-info-image'
                            />
                            <div style={{ margin: '5px 0' }}>
                                <Link to={`/assets/view/${selectedEvent?.info?.asset_id}`}>
                                    {selectedEvent?.info?.asset_name} [{selectedEvent?.info?.registration}]
                                </Link>
                            </div>
                            <div style={{ margin: '5px 0' }}>
                                <span style={{
                                    fontSize: '12px',
                                    color: 'var(--cool-gray-60)',
                                    fontWeight: 600,
                                }}>
                                    Last Service:
                                </span>
                                &ensp;
                                {selectedEvent?.info?.last_service 
                                    ? dayjs(selectedEvent.info.last_service).format('DD/MM/YYYY') 
                                    : '-'
                                }
                            </div>
                        </Col>
                        <Col span={12}>
                            <img
                                alt="Driver"
                                src={
                                    selectedEvent?.info?.profile_picture_id
                                        ? `${getBaseUrl()}/site/display-file?mediaId=${selectedEvent?.info?.profile_picture_id}`
                                        : user?.userCompany?.company_image
                                }
                                style={{ width: '100%', maxHeight: '400px' }} 
                                className='driver-info-image'
                            />
                            <div style={{ margin: '5px 0' }}>
                                <Link to={`/drivers/view/${selectedEvent?.info?.user_id}`}>{selectedEvent?.info?.driver}</Link>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <div className="video-view-block-title">Event Information</div>
                    {eventInfo}
                </Col>
            </Row>
        </div>
    );
}
