import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { fetchApiAuth } from '../../core/utils/api';
import { isArray } from 'underscore';
import GridView from '../elements/GridView/index';
import { Warning, TrashCan, Renew, Restart } from '@carbon/icons-react';
import { VideoDownloadStatus } from '../../types';

import './videoDownloadStatusModal.scss';

const { confirm } = Modal;

interface VideoDownloadStatusProps {
    videoInternalReference: string | null;
    closeModal: () => void;
}

/**
 * Renders the content of an asset popup.
 */
const VideoDownloadStatusModal: React.FC<VideoDownloadStatusProps> = ({
    videoInternalReference = null,
    closeModal,
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [videoStatusList, setVideoStatusList] = useState<Array<VideoDownloadStatus>>([]);
    const isModelOpenRef = useRef(isModalOpen);

    const pollingInterval = 5000;

    const abortController = new AbortController();
    let pollingTimer = useRef(null);

    const errorHandler = (exception) => {
        console.error(exception);
    }

    const getStatusText = (status): string => {
        let label: any = '';
        switch (status) {
            case 'queued':
            case 'selected':
                label = 'In Queue';
                break;
            case 'file-conversion-failed':
            case 'download-failed':
                label = 'Download failed';
                break;
            case 'device-offline':
                label = 'Device offline';
                break;
            case 'reconnecting':
            case 'uploading':
            case 'pending-file-conversion':
            case 'file-conversion-in-progress':
            case 'downloading':
                label = 'Processing';
                break;
            case 'cached':
                label = 'Ready to view';
                break;
            case 'not-available':
                label = 'Not available';
                break;
            case 'paused':
                label = 'Paused';
                break;
            case null:
                label = 'Not Requested';
                break;
            default:
                label = 'Unknown';
        }

        return label;
    }

    const deleteChannel = (videoStatus: VideoDownloadStatus): void => {
        confirm({
            content: `Are you sure you want to delete download channel "${videoStatus.channel}"?`,
            icon: <Warning />,
            okText: "Delete",
            async onOk() {
                await deleteChannelRequest(videoStatus.stitched_video_id).catch(errorHandler);
                await fetchVideoStatusData().catch(errorHandler);
            },
            onCancel() { },
        });
    }

    const refreshChannel = (videoStatus: VideoDownloadStatus): void => {
        confirm({
            content: `Are you sure you want to restart download channel "${videoStatus.channel}"?`,
            icon: <Warning />,
            async onOk() {
                await refreshChannelRequest(videoStatus.stitched_video_id).catch(errorHandler);
                await fetchVideoStatusData().catch(errorHandler);
            },
            onCancel() { },
        });
    }

    const startChannel = async (videoStatus: VideoDownloadStatus): Promise<void> => {
        confirm({
            content: `Are you sure you want to start download channel "${videoStatus.channel}"?`,
            icon: <Warning />,
            async onOk() {
                await startChannelRequest(videoInternalReference, videoStatus.channel_index).catch(errorHandler);
                await fetchVideoStatusData().catch(errorHandler);
            },
            onCancel() { },
        });
    }

    const deleteChannelRequest = async (stitched_video_id): Promise<void> => {
        const url = 'stitched-video/' + stitched_video_id + '/delete';
        await fetchApiAuth({
            method: 'POST',
            url: url,
            signal: abortController.signal,
        }).catch(errorHandler)
    }

    const refreshChannelRequest = async (stitched_video_id): Promise<void> => {
        const url = 'stitched-video/' + stitched_video_id + '/refresh';
        await fetchApiAuth({
            method: 'GET',
            url: url,
            signal: abortController.signal,
        }).catch(errorHandler)
    }

    const startChannelRequest = async (videoInternalReference, channel): Promise<void> => {
        const url = 'video/' + videoInternalReference + '/cache-video-channel/' + channel;
        await fetchApiAuth({
            method: 'POST',
            url: url,
            signal: abortController.signal,
        }).catch(errorHandler)
    }



    const fetchVideoStatusData = async (silent = false): Promise<void> => {
        if (!silent) {
            setIsLoading(true);
        }
        const url = 'video/' + videoInternalReference + '/statuses'

        const response = await fetchApiAuth({
            method: 'GET',
            url: url,
            signal: abortController.signal,
        })
            .catch(errorHandler)
            .finally(() => {
                if (!silent) {
                    setIsLoading(false);
                }
            });

        if (isArray(response.data)) {
            setVideoStatusList(response.data.map((videoStatus): VideoDownloadStatus => {
                const buttons = [];
                const canStart = ['Not Requested'];

                // const channel_names = (videoStatus.channel_names && videoStatus.channel_names.split(',')) || [];
                // const channel_name = channel_names[videoStatus.channel] ?? `CH${parseInt(videoStatus.channel, 10) + 1}`;

                const data: VideoDownloadStatus = {
                    action: (<>{buttons}</>),
                    channel_name: videoStatus.channel_name,
                    channel: parseInt(videoStatus.channel) + 1,
                    channel_index: videoStatus.channel,
                    file_length: videoStatus.duration,
                    file_size: videoStatus.fileSize ?? "Unknown",
                    status: getStatusText(videoStatus.stitched_video_status),
                    stitched_video_id: videoStatus.stitched_video_id,
                    download_queue_id: videoStatus.download_queue_id,
                };


                const deleteButton = (<Tooltip placement="top" title="Delete Download">
                    <Button
                        type="primary"
                        icon={<TrashCan />}
                        onClick={e => {
                            e.stopPropagation();
                            deleteChannel(data);
                        }}
                        size="small" />
                </Tooltip>);

                const refreshButton = (<Tooltip placement="top" title="Restart Download">
                    <Button
                        type="primary"
                        icon={<Renew />}
                        onClick={e => {
                            e.stopPropagation();
                            refreshChannel(data);
                        }}
                        size="small" />
                </Tooltip>);
                const startButton = (<Tooltip placement="top" title="Start Download">
                    <Button
                        type="primary"
                        icon={<Restart />}
                        onClick={e => {
                            e.stopPropagation();
                            startChannel(data);
                        }}
                        size="small" />
                </Tooltip>);
                if (canStart.includes(data.status)) {
                    buttons.push(startButton);
                } else {
                    buttons.push(refreshButton);
                    buttons.push(deleteButton);
                }


                return data;
            }));
        }
    }
    const clearPollingTimeout = () => {
        if (pollingTimer.current) {
            clearTimeout(pollingTimer.current);
        }
    }

    const pollStatus = async (): Promise<void> => {
        clearPollingTimeout();
        if (!isModelOpenRef.current) {
            return;
        }
        await fetchVideoStatusData(true);
        pollingTimer.current = setTimeout(() => { pollStatus(); }, pollingInterval);
    }

    const watchIsModalOpen = async () => {
        isModelOpenRef.current = isModalOpen;
        clearPollingTimeout();
        if (isModalOpen) {
            pollingTimer.current = setTimeout(() => { pollStatus(); }, pollingInterval);
        }
    }

    const watchVideoInternalReference = () => {
        if (videoInternalReference) {
            setIsModalOpen(true);
            fetchVideoStatusData();
        } else {
            abortController.abort();
            setIsModalOpen(false);
            setVideoStatusList([]);
        }
    }

    useEffect(() => { watchVideoInternalReference() }, [videoInternalReference]);
    useEffect(() => { watchIsModalOpen() }, [isModalOpen]);

    return (
        <Modal
            title="Video Download Status"
            open={isModalOpen}
            loading={isLoading}
            onCancel={closeModal}
            footer={null}
            width={1000}
            className='video-download-status-modal'

        >
            <div className="section">
                <GridView
                    data={videoStatusList}
                    hiddenColumns={['download_queue_id', 'stitched_video_id', 'channel_index']}
                    scroll={null}
                    disablePagination
                />

            </div>
            {/* {videoStatusList?.[0]?.download_queue_status} */}
        </Modal>
    );
}

export default VideoDownloadStatusModal;