/* eslint-disable no-case-declarations */
import { Record } from 'immutable';
import {
    find, isEmpty, contains, findIndex,
} from 'underscore';
import moment from 'moment';

const {
    GET_ASSETS_VIDEO_LIST_REQUEST,
    GET_ASSETS_VIDEO_LIST_SUCCESS,
    GET_ASSETS_VIDEO_LIST_FAILURE,

    RESET_HISTORIC_VIDEOS_REQUEST,
    GET_HISTORIC_VIDEOS_REQUEST,
    GET_HISTORIC_VIDEOS_SUCCESS,
    GET_HISTORIC_VIDEOS_FAILURE,

    GET_REQUESTED_VIDEOS_REQUEST,
    GET_REQUESTED_VIDEOS_SUCCESS,
    GET_REQUESTED_VIDEOS_FAILURE,

    GET_VIDEO_URL_REQUEST,
    GET_VIDEO_URL_SUCCESS,
    GET_VIDEO_URL_FAILURE,

    TOGGLE_VIDEO_SELECTION_SCREEN,
    UPDATE_VIDEO_CAMERA_SELECTION,

    GET_VIDEO_CAMERA_CHANNELS_LINK_REQUEST,
    GET_VIDEO_CAMERA_CHANNELS_LINK_SUCCESS,
    GET_VIDEO_CAMERA_CHANNELS_LINK_FAILURE,

    GET_VIDEO_LIVE_LOCATION_REQUEST,
    GET_VIDEO_LIVE_LOCATION_SUCCESS,
    GET_VIDEO_LIVE_LOCATION_FAILURE,

    GET_VIDEO_LIVE_TAB_REQUEST,
    GET_VIDEO_LIVE_TAB_SUCCESS,
    GET_VIDEO_LIVE_TAB_FAILURE,

    QUEUE_VIDEO_BY_ID_REQUEST,
    QUEUE_VIDEO_BY_ID_SUCCESS,
    QUEUE_VIDEO_BY_ID_FAILURE,

    EXTEND_VIDEO_BY_ID_REQUEST,
    EXTEND_VIDEO_BY_ID_SUCCESS,
    EXTEND_VIDEO_BY_ID_FAILURE,

    EXTEND_STITCHED_VIDEO_BY_ID_REQUEST,
    EXTEND_STITCHED_VIDEO_BY_ID_SUCCESS,
    EXTEND_STITCHED_VIDEO_BY_ID_FAILURE,

    GET_VIDEOS_AROUND_EVENT_REQUEST,
    GET_VIDEOS_AROUND_EVENT_SUCCESS,
    GET_VIDEOS_AROUND_EVENT_FAILURE,

    GET_STITCHED_VIDEO_REQUEST,
    GET_STITCHED_VIDEO_SUCCESS,
    GET_STITCHED_VIDEO_FAILURE,

    UPDATE_VIDEO_REFERENCE_REQUEST,
    UPDATE_VIDEO_REFERENCE_SUCCESS,
    UPDATE_VIDEO_REFERENCE_FAILURE,

    SET_SINGLE_VIDEO_REQUEST,
    RESET_GO_PREVIOUS_PAGE_REQUEST,

    CHECK_DVR_ONLINE_STATUS_REQUEST,
    CHECK_DVR_ONLINE_STATUS_SUCCESS,
    CHECK_DVR_ONLINE_STATUS_FAILURE,

    RESET_SURROUNDING_VIDEOS_REQUEST,

    SEARCH_HISTORIC_VIDEOS_REQUEST,
    SEARCH_HISTORIC_VIDEOS_SUCCESS,
    SEARCH_HISTORIC_VIDEOS_FAILURE,
    RESET_SEARCH_HISTORIC_VIDEOS_REQUEST,

    SELECT_SINGLE_SEARCHED_VIDEO_REQUEST,

    GET_CHANNELS_AND_VIDEOS_REQUEST,
    GET_CHANNELS_AND_VIDEOS_SUCCESS,
    GET_CHANNELS_AND_VIDEOS_FAILURE,

    CACHE_VIDEOS_REQUEST,
    CACHE_VIDEOS_SUCCESS,
    CACHE_VIDEOS_FAILURE,

    QUEUE_SEARCHED_VIDEO_REQUEST,
    QUEUE_SEARCHED_VIDEO_SUCCESS,
    QUEUE_SEARCHED_VIDEO_FAILURE,

    GET_STITCHED_VIDEOS_REQUEST,
    GET_STITCHED_VIDEOS_SUCCESS,
    GET_STITCHED_VIDEOS_FAILURE,

    TRIM_AND_CACHE_VIDEO_REQUEST,
    TRIM_AND_CACHE_VIDEO_SUCCESS,
    TRIM_AND_CACHE_VIDEO_FAILURE,

    RESET_TRIM_AND_CACHE_VIDEO_REQUEST,
    RESET_TRIM_AND_CACHE_VIDEO_SUCCESS,
    RESET_TRIM_AND_CACHE_VIDEO_FAILURE,

    POST_FORCE_DOWNLOAD_REQUEST,
    POST_FORCE_DOWNLOAD_SUCCESS,
    POST_FORCE_DOWNLOAD_FAILURE,

    SET_MAP_SEARCH_LOCATION_BOUNDARY,

    DELETE_CACHED_VIDEO_REQUEST,
    DELETE_CACHED_VIDEO_SUCCESS,
    DELETE_CACHED_VIDEO_FAILURE,

} = require('./videoActions').constants;

const InitialState = Record({
    error: null,
    historic: [],
    requested: [],
    surroundingVideos: [],
    searchedVideos: null,
    searchedSingleVideo: {},
    searchedSingleVideoChannels: [],
    stitchedVideos: [],
    isFetching: false,
    isFetchingVideoUrls: false,
    videoOnlineStatusChecking: false,
    historicVideosIsFetching: false,
    historicVideosIsLoaded: false,
    requestedVideosIsFetching: false,
    searchingVideos: false,
    historicVideosSearchParams: {},
    pagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 16, // Works well with 4 channels
    },
    videoSearchPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 16, // Works well with 4 channels
    },
    requestedPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 16, // Works well with 4 channels
    },
    surroundingPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 16, // Works well with 4 channels
    },
    video_url: {
        stream: '',
        download: '',
        latestDownloadQue: {},
    },
    liveCameras: [],
    liveCamerasPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    showSelectionScreen: true,
    selectedCameras: [],
    cameraLocations: [],
    singleVideo: {},
    goPreviousPage: false,
    requestingTrimAndCacheVideo: false,
    mapSearchLocationBoundary: [],
    mapSearchVideoSelectedDate: null,
    mapSearchVideoAssets: [],
    mapSearchVideoSliderValue: 0,
    forceDownloadFetching: false,
});

const videoInitialState = new InitialState();

// eslint-disable-next-line eqeqeq
const update = (arr, item) => arr.map((i) => (i.id == item.id ? item : i));

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function videoReducer(state = videoInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = videoInitialState.mergeDeep(state);
    switch (type) {
    case GET_VIDEOS_AROUND_EVENT_REQUEST:
    case GET_STITCHED_VIDEO_REQUEST:
    case GET_VIDEO_URL_REQUEST:
    case QUEUE_VIDEO_BY_ID_REQUEST:
    case EXTEND_VIDEO_BY_ID_REQUEST:
    case EXTEND_STITCHED_VIDEO_BY_ID_REQUEST:
    case UPDATE_VIDEO_REFERENCE_REQUEST:
    case QUEUE_SEARCHED_VIDEO_REQUEST:
    case GET_STITCHED_VIDEOS_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case DELETE_CACHED_VIDEO_REQUEST:
        return state.set('isFetching', true)
            .set('requestedVideosIsFetching', true)
            .set('error', null);

    case POST_FORCE_DOWNLOAD_REQUEST:
        return state.set('forceDownloadFetching', true)
            .set('error', false);
    case GET_ASSETS_VIDEO_LIST_REQUEST:
    case CHECK_DVR_ONLINE_STATUS_REQUEST:
        return state
            .set('isFetching',true)
            .set('videoOnlineStatusChecking', true);
    case GET_CHANNELS_AND_VIDEOS_REQUEST:
        return state
            .set('searchedSingleVideoChannels', [])
            .set('isFetchingVideoUrls', true)
            .set('error', null);
    case TRIM_AND_CACHE_VIDEO_REQUEST:
    case RESET_TRIM_AND_CACHE_VIDEO_REQUEST:
        return state
            .set('requestingTrimAndCacheVideo', true)
            .set('error', null);
    case GET_HISTORIC_VIDEOS_REQUEST:
        return state.set('historicVideosIsFetching', true)
            .set('error', null);

    case SEARCH_HISTORIC_VIDEOS_REQUEST:
        return state.set('searchingVideos', true)
            .set('error', null);
    case RESET_SEARCH_HISTORIC_VIDEOS_REQUEST:
        return state.set('videoSearchPagination', {
            currentPage: 1,
            totalRecords: 0,
            pageCount: 0,
            perPageCount: 16, // Works well with 4 channels
        })
            .set('searchedVideos', null);
    case CACHE_VIDEOS_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case SET_MAP_SEARCH_LOCATION_BOUNDARY:
        return state.set('mapSearchLocationBoundary', payload.polygon)
            .set('mapSearchVideoAssets', payload.devices)
            .set('mapSearchVideoSliderValue', payload.mapSliderValue)
            .set('mapSearchVideoSelectedDate', payload.selectedDate);

    case TRIM_AND_CACHE_VIDEO_SUCCESS:
    case RESET_TRIM_AND_CACHE_VIDEO_SUCCESS:
        return state
            .set('requestingTrimAndCacheVideo', false)
            .set('error', null);

    case CHECK_DVR_ONLINE_STATUS_SUCCESS:
        const { liveCameras } = state;
        const onlineDVRs = (payload.data && payload.data.online_dvrs) || [];
        const updatedLiveCameras = liveCameras.map((liveCamera) => {
            const temp = { ...liveCamera };
            temp.status = 0;
            if (contains(onlineDVRs, liveCamera.imei)) {
                temp.status = 1;
            }
            return temp;
        });
        updatedLiveCameras.sort((a, b) => b.status - a.status);
        return state.set('videoOnlineStatusChecking', false)
            .set('liveCameras', updatedLiveCameras);

    case GET_REQUESTED_VIDEOS_REQUEST:
        return state.set('requestedVideosIsFetching', true)
            .set('error', null);

    case EXTEND_VIDEO_BY_ID_SUCCESS: {
        return state.set('isFetching', false);
    }
    case EXTEND_STITCHED_VIDEO_BY_ID_SUCCESS: {
        const { requested } = state;
        const newRequested = [...requested];
        const stitchedVideo = payload.data;
        if (newRequested != null && newRequested.length > 0) {
            for (let i = 0; i < newRequested.length; i += 1) {
                const element = newRequested[i];
                if (Number(element.id) === Number(stitchedVideo.id)) {
                    const removeTime = moment(stitchedVideo.remove_time);
                    const now = moment();
                    now.set({ hour: 0, minute: 0 });
                    const dateDiff = removeTime.diff(now, 'days');

                    element.remove_time = stitchedVideo.remove_time;
                    element.date_diff = dateDiff;
                    newRequested[i] = element;
                    break;
                }
            }
        }

        return state.set('isFetching', false)
            .set('requested', newRequested);
    }
    case QUEUE_VIDEO_BY_ID_SUCCESS: {
        const { video_url, historic } = state;
        video_url.latestDownloadQue = payload.data.queue;

        const videoIndex = findIndex(historic, (val) => val.id === payload.data.video.id);
        if (videoIndex !== -1) {
            historic.splice(videoIndex, 1);
        }

        return state.set('video_url', video_url)
            .set('historic', update(historic, payload.data.video))
            .set('isFetching', false);
    }

    case SET_SINGLE_VIDEO_REQUEST: {
        return state.set('singleVideo', payload)
            .set('goPreviousPage', true)
            .set('isFetching', false);
    }

    case RESET_GO_PREVIOUS_PAGE_REQUEST: {
        return state.set('goPreviousPage', true);
    }

    case GET_HISTORIC_VIDEOS_SUCCESS:
        const { pagination } = state;
        if (payload.headers) {
            if (payload.headers['x-pagination-current-page']) {
                pagination.currentPage = parseInt(payload.headers['x-pagination-current-page'], 10);
            }
            if (payload.headers['x-pagination-page-count']) {
                pagination.pageCount = parseInt(payload.headers['x-pagination-page-count'], 10);
            }
            if (payload.headers['x-pagination-per-page']) {
                pagination.perPageCount = parseInt(payload.headers['x-pagination-per-page'], 10);
            }
            if (payload.headers['x-pagination-total-count']) {
                pagination.totalRecords = parseInt(payload.headers['x-pagination-total-count'], 10);
            }
        }
        return state.set('historicVideosIsFetching', false).set('pagination', pagination)
            .set('historicVideosIsLoaded', true)
            .set('historic', payload.data);

    case GET_REQUESTED_VIDEOS_SUCCESS: {
        const { requestedPagination } = state;
        const {
            page, count, pageCount, perPage, data,
        } = payload;
        requestedPagination.currentPage = page || 1;
        requestedPagination.totalRecords = count || 0;
        requestedPagination.pageCount = pageCount || 0;
        requestedPagination.perPageCount = perPage || 20;
        return state.set('requestedVideosIsFetching', false).set('requestedPagination', requestedPagination)
            .set('requested', data || []);
    }
    case GET_VIDEOS_AROUND_EVENT_SUCCESS:
    case GET_STITCHED_VIDEO_SUCCESS:
        const { surroundingPagination } = state;
        if (payload.headers) {
            if (payload.headers['x-pagination-current-page']) {
                surroundingPagination.currentPage = parseInt(payload.headers['x-pagination-current-page'], 10);
            }
            if (payload.headers['x-pagination-page-count']) {
                surroundingPagination.pageCount = parseInt(payload.headers['x-pagination-page-count'], 10);
            }
            if (payload.headers['x-pagination-per-page']) {
                surroundingPagination.perPage = parseInt(payload.headers['x-pagination-per-page'], 10);
            }
            if (payload.headers['x-pagination-total-count']) {
                surroundingPagination.totalCount = parseInt(payload.headers['x-pagination-total-count'], 10);
            }
        }
        return state.set('isFetching', false)
            .set('surroundingVideos', payload.data);

    case SEARCH_HISTORIC_VIDEOS_SUCCESS:
        const { videoSearchPagination } = state;
        if (payload.headers) {
            if (payload.headers['x-pagination-current-page']) {
                videoSearchPagination.currentPage = parseInt(payload.headers['x-pagination-current-page'], 10);
            }
            if (payload.headers['x-pagination-page-count']) {
                videoSearchPagination.pageCount = parseInt(payload.headers['x-pagination-page-count'], 10);
            }
            if (payload.headers['x-pagination-per-page']) {
                videoSearchPagination.perPageCount = parseInt(payload.headers['x-pagination-per-page'], 10);
            }
            if (payload.headers['x-pagination-total-count']) {
                videoSearchPagination.totalRecords = parseInt(payload.headers['x-pagination-total-count'], 10);
            }
        }
        return state.set('searchingVideos', false).set('videoSearchPagination', videoSearchPagination)
            .set('historicVideosSearchParams', payload.search)
            .set('searchedVideos', payload.data);

    case RESET_SURROUNDING_VIDEOS_REQUEST: {
        return state.set('isFetching', false)
            .set('surroundingVideos', [])
            .set('surroundingPagination', {
                currentPage: 1,
                totalRecords: 0,
                pageCount: 0,
                perPageCount: 16, // Works well with 4 channels
            });
    }

    case UPDATE_VIDEO_REFERENCE_SUCCESS:
        return state.set('isFetching', false)
            .set('error', null);
    case GET_VIDEO_URL_SUCCESS:
        return state.set('isFetching', false)
            .set('video_url', payload.data);

    case GET_CHANNELS_AND_VIDEOS_SUCCESS:
        return state.set('searchedSingleVideoChannels', payload.data)
            .set('isFetchingVideoUrls', false);

    case POST_FORCE_DOWNLOAD_SUCCESS:
        return state.set('forceDownloadFetching', false);

    case CHECK_DVR_ONLINE_STATUS_FAILURE:
        return state.set('videoOnlineStatusChecking', false);
    case QUEUE_VIDEO_BY_ID_FAILURE:
    case EXTEND_VIDEO_BY_ID_FAILURE:
    case EXTEND_STITCHED_VIDEO_BY_ID_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    case POST_FORCE_DOWNLOAD_FAILURE:
        return state.set('forceDownloadFetching', false)
            .set('error', payload);
    case GET_HISTORIC_VIDEOS_FAILURE:
        return state.set('historicVideosIsFetching', false)
            .set('historicVideosIsLoaded', false)
            .set('error', payload)
            .set('historic', []);
    case GET_REQUESTED_VIDEOS_FAILURE:
        return state.set('requestedVideosIsFetching', false)
            .set('error', payload)
            .set('requested', []);
    case GET_VIDEOS_AROUND_EVENT_FAILURE:
    case GET_STITCHED_VIDEO_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload)
            .set('surroundingVideos', []);
    case GET_VIDEO_URL_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload)
            .set('video_url', []);
    case SEARCH_HISTORIC_VIDEOS_FAILURE:
        return state.set('searchingVideos', false)
            .set('error', payload)
            .set('searchedVideos', null);

    case GET_CHANNELS_AND_VIDEOS_FAILURE:
        return state.set('isFetching', false)
            .set('isFetchingVideoUrls', false)
            .set('error', payload);

    case GET_VIDEO_LIVE_LOCATION_REQUEST:
    case GET_VIDEO_LIVE_TAB_REQUEST:
        return state.set('error', null);

    case GET_VIDEO_CAMERA_CHANNELS_LINK_REQUEST: {
        // unseting all camera video links
        const selectedCameras = state.selectedCameras.map((sc) => {
            const selectedCamera = sc;
            selectedCamera.url = '';
            return selectedCamera;
        });

        return state.set('isFetching', true)
            .set('selectedCameras', selectedCameras)
            .set('error', null);
    }

    case TOGGLE_VIDEO_SELECTION_SCREEN:
        return state.set('showSelectionScreen', payload);
    case GET_VIDEO_LIVE_LOCATION_SUCCESS: {
        const videoLocations = payload.data;

        // add empty onClick event so the map function does not throw an error when asset is clicked
        if (!isEmpty(videoLocations)) {
            videoLocations.forEach((element) => {
                if (!element.onClick) {
                    element.onClick = () => { };
                }
            });
        }
        return state.set('cameraLocations', videoLocations);
    }

    case SELECT_SINGLE_SEARCHED_VIDEO_REQUEST: {
        return state.set('searchedSingleVideo', payload);
    }

    case CACHE_VIDEOS_SUCCESS: {
        const { surroundingVideos } = state;

        if (payload.data.cachedVideos) {
            payload.data.cachedVideos.map((video) => {
                const videoIndex = findIndex(surroundingVideos, (val) => val.id === video);
                if (videoIndex !== -1) {
                    surroundingVideos[videoIndex].status = 'queued';
                }
            });
        }

        return state.set('surroundingVideos', surroundingVideos)
            .set('isFetching', false);
    }

    case QUEUE_SEARCHED_VIDEO_SUCCESS:
        return state.set('isFetching', false);

    case GET_STITCHED_VIDEOS_SUCCESS: {
        return state.set('stitchedVideos', payload.data)
            .set('isFetching', false);
    }

    case UPDATE_VIDEO_CAMERA_SELECTION:
        return state.set('isFetching', false)
            .set('selectedCameras', payload);
    case GET_VIDEO_CAMERA_CHANNELS_LINK_SUCCESS:
        const payloadSelectedCameras = payload.data || [];
        const selectedCameras = state.selectedCameras.map((sc) => {
            const selectedCamera = sc;
            selectedCamera.url = '';
            selectedCamera.video_api = '';
            selectedCamera.videro_server = '';
            const findUrl = find(payloadSelectedCameras, (camera) => camera.id === selectedCamera.id);
            if (findUrl) {
                selectedCamera.url = findUrl.url;
                selectedCamera.video_api = findUrl.video_api;
                selectedCamera.video_server = findUrl.video_server;
            }
            return selectedCamera;
        });
        return state.set('isFetching', false)
            .set('selectedCameras', selectedCameras);
    case GET_VIDEO_LIVE_TAB_SUCCESS:
        return state.set('isFetching', false)
            .set('selectedCameras', payload.data.data);
    case GET_ASSETS_VIDEO_LIST_SUCCESS:
        const {
            page, count, pageCount, perPage, data,
        } = payload;
        const liveCamerasPagination = {};
        liveCamerasPagination.currentPage = page || 1;
        liveCamerasPagination.totalRecords = count || 0;
        liveCamerasPagination.pageCount = pageCount || 0;
        liveCamerasPagination.perPageCount = perPage || 20;
        return state
            .set('isFetching', false)
            .set('liveCameras', data)
            .set('videoOnlineStatusChecking', false)
            .set('liveCamerasPagination', liveCamerasPagination)
            .set('assets', data);

    case DELETE_CACHED_VIDEO_SUCCESS: {
        const { requested } = state;

        const requestedIndex = findIndex(requested, (val) => val.internal_reference === payload.internal_reference);
        if (requestedIndex !== -1) {
            requested.splice(requestedIndex, 1);
        }

        return state.set('isFetching', false)
            .set('requested', requested)
            .set('requestedVideosIsFetching', false);
    }

    case GET_ASSETS_VIDEO_LIST_FAILURE:
    case GET_VIDEO_CAMERA_CHANNELS_LINK_FAILURE:
    case GET_VIDEO_LIVE_LOCATION_FAILURE:
    case UPDATE_VIDEO_REFERENCE_FAILURE:
    case CACHE_VIDEOS_FAILURE:
    case QUEUE_SEARCHED_VIDEO_FAILURE:
    case GET_STITCHED_VIDEOS_FAILURE:
    case TRIM_AND_CACHE_VIDEO_FAILURE:
    case RESET_TRIM_AND_CACHE_VIDEO_FAILURE:
    case GET_VIDEO_LIVE_TAB_FAILURE:
        return state.set('isFetching', false)
            .set('requestingTrimAndCacheVideo', false)
            .set('error', payload);

    case DELETE_CACHED_VIDEO_FAILURE:
        return state.set('isFetching', false)
            .set('requestedVideosIsFetching', false)
            .set('error', payload);

    case RESET_HISTORIC_VIDEOS_REQUEST:
        return state.set('historic', [])
            .set('historicVideosIsLoaded', false)
            .set('pagination', {
                currentPage: 1,
                totalRecords: 0,
                pageCount: 0,
                perPageCount: 16, // Works well with 4 channels
            });
    default:
        return state;
    }
}

export {
    videoReducer,
    videoInitialState,
};
