import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';

import './eventMarkerContent.scss';

type EventMarkerContentProps = {
    icon?: string;
};

const EventMarkerContent: React.FC<EventMarkerContentProps> = ({
    icon = 'alert',
}) => {
    if (!icon) {
        icon = 'alert';
    }
    return (
        <div className='event-marker-content'>
            <div className='event-marker'>
                <LegacyIcon 
                    className='event-marker-icon'
                    type={icon}
                    theme='filled'
                />
            </div>
        </div>
    );
}

export default React.memo(EventMarkerContent);