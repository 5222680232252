import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import { isObject } from 'underscore';
import Breadcrumb from '../Breadcrumb';
import Header from '../Header';
import Footer from '../Footer';
import Sidebar from '../Sidebar';
import './layout.css';
import icon from '../../../assets/favicon.ico';
import CompanyCurrentAlertBanner from '../../CompanyCurrentAlertBanner';
import Spinner from '../../Spinner';

const {
    APP_NAME,
} = require('../../../core/constants').default;

export default function () {
    const {
        children, title, toolbar, breadcrumb, className, menu, backLink,
        showHeader, beforeHeader, afterHeader, floatingHeader, headerTitle,
        showFooter, beforeFooter, afterFooter, floatingFooter, footerContent,
        menuPlacement, menuWidth, user, loading, companies
    } = this.props;
    const { sidebarOpened } = this.state;
    const useSidebar = showHeader && (user?.userCompany?.features?.find(feature => feature.id === 'sidebar-nav'));
    return (
        <>
            {user.isLoggedIn && user?.userCompany?.current_alert && (
                <CompanyCurrentAlertBanner alert={user?.userCompany?.current_alert} />
            )}
            <div className={`page-wrap ${className} ${user?.userCompany?.current_alert && 'with-banner'}`}>
                <Helmet
                    title={!isObject(title) ? (title.charAt(0).toUpperCase() + title.slice(1)) : title.props.children}
                    titleTemplate={`%s - ${APP_NAME}`}
                    defaultTitle={`${APP_NAME}`}
                    meta={[
                        { name: 'description', content: `${APP_NAME} website` },
                        { property: 'og:type', content: 'website' },
                    ]}>
                    <link rel="shortcut icon" href={icon} />
                </Helmet>
                {
                    showHeader && !useSidebar
                        ? (<>
                            <Header
                                beforeheader={beforeHeader}
                                afterHeader={afterHeader}
                                floating={floatingHeader}
                                title={headerTitle || title}
                                toolbar={toolbar}
                                backLink={backLink}
                                menu={menu}
                                menuPlacement={menuPlacement}
                                menuWidth={menuWidth} />

                        </>
                        )
                        : null
                }

                {
                    useSidebar && (
                        <Sidebar
                            sidebarOpened={sidebarOpened}
                            toggleSidebar={this.toggleSidebar}
                            companies={companies}
                            user={user}
                        ></Sidebar>
                    )
                }
                <Layout.Content className={`main-content ${useSidebar ? 'sidebar-view' : ''} ${sidebarOpened ? 'sidebar-open' : ''}`}>
                    <Spinner loading={loading}>
                        {breadcrumb ? <Breadcrumb navigation={breadcrumb} /> : null}
                        {children}
                    </Spinner>
                </Layout.Content>
                {
                    showFooter && !useSidebar
                        ? (
                            <Footer
                                beforeFooter={beforeFooter}
                                afterFooter={afterFooter}
                                floating={floatingFooter}>
                                {footerContent}
                            </Footer>
                        )
                        : null
                }
            </div>
        </>
    );
}
