import { ReactElement } from 'react';

/*
    This file is for storing public, re-usable types/enums/interfaces that are used in multiple components/pages.
    Specific types e.g. component prop types should exist in the component/page file itself.
*/


/*
    Enums: immutable, constant values (usually strings) that are used in multiple places.
*/
export enum MarkerType {
    Asset = 'asset',
    PathStart = 'path_start',
    PathEnd = 'path_end',
    Event = 'event',
    SearchResult = 'search_result',
}
export enum PathMarkerType {
    Start = 'start',
    End = 'end',
}
export enum PopupType {
    Asset = 'asset',
    Event = 'event',
}
export enum UnitOfMeasurement {
    Metric = 'metric',
    BritishImperial = 'british-imperial',
}
export enum DeviceStatus {
    Online = 'In Transit',
    Idling = 'Idling',
    Stopped = 'Stopped',
    Offline = 'Offline',
}
/*
    Interfaces: reusable data structures that are used in multiple places. Helps to enforce data shape and avoid bugs.
*/
export interface VideoSearchResponse {
    info: {
        id: number;
        device_status: string;
        lat: string;
        lng: string;
        angle: number;
        name: string;
        division_color: string;
        cached_avl_info: string;
        speed: string;
        user_id: number;
        full_name: string;
    };
    locations: {
        lat: string;
        lng: string;
        event_type: string;
        icon: string;
        time: string;
        event_key: string;
        speed: number;
        show_in_events: boolean | null;
        location_data: AvlKey[];
    }[];
}
export interface AvlKey {
    description: string;
    value: string;
}
export interface Location { // TODO: replace all lat/lng with this type
    lat: number;
    lng: number;
    address?: string;
}
export interface Channel {
    channel: string;
    label: string;
}
export interface DVRChannelLink {
    channel: number;
    device: string;
    duration: number;
    end_time: string;
    id: string;
    start_time: string;
    url: string;
    video_api: string;
}
export interface Event {
    date: string;
    event_id: string;
    event_title: string;
    event_icon: string;
    device_id: string;
    asset_name: string;
}
export interface Division {
    id: string,
    name: string,
}
export interface GeofencePoint {
    id: string,
    lat: string,
    lng: string,
}
export interface Geofence {
    divisions: Division[],
    id: number,
    name: string,
    trigger_points: GeofencePoint[],
    type: number, // TODO: replace with enum?
}
export interface HeatmapData { // combines minimal record data with events that occurred during that record and a weighting value
    events: Event[],
    latitude: number,
    longitude: number,
    record_id: number,
    registration: string,
    title: string,
    weight: number,
}
export enum DeviceTimelineBlockType {
    Idle = 'idle',
    Moving = 'moving',
    Stopped = 'stopped',
}
export interface DeviceTimelineData {
    timelineSegments: DeviceTimelineSegment[];
    geofenceTimelineSegments: GeofenceTimelineSegment[];
}
export interface DeviceTimelineSegment {
    start: number;
    start_time: string;
    end: number;
    end_time: string;
    location: string;
    status: string;
}
export interface GeofenceTimelineSegment {
    start: number;
    start_time: string;
    end: number;
    end_time: string;
    geofence_names: string[];
}
export interface AssetPopupContentData {
    type: PopupType.Asset;
    assetId?: string | null;
    date?: string | null;
}
export interface EventPopupContentData {
    type: PopupType.Event;
    speed: number;
    eventName: string;
    time: string;
    driverId: number;
    driverName: string;
    location: Location;
    avlKeys: AvlKey[];
}
export interface MarkerData {
    type: MarkerType;
    location: Location;
    divisionColor?: string;
    angle?: number;
    label?: string;
    deviceStatus?: string;
    cachedAvlInfo?: string;
    speed?: string;
    icon?: string;
    popupData?: AssetPopupContentData | EventPopupContentData | null;
}

export interface PathSegmentData {
    location: Location;
    eventType: number;
    icon: string;
    datetime: string;
    popupData?: EventPopupContentData | null;
}
export interface VideoDownloadStatus {
    action?: ReactElement,
    channel?: Number,
    channel_index?: Number,
    channel_name?: string,
    file_length?: string,
    file_size?: string, // TODO: camelcase?
    status?: any, // TODO: determine type
    stitched_video_id?: string,
    download_queue_id?: string,
}
export interface DvrStatusData {
    deviceId: number, 
    imei: string, 
    status: 0 | 1,
}
