import React from 'react';
import { Row, Col, DatePicker } from 'antd';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { Calendar } from '@carbon/icons-react';

interface DateRangePickerProps {
    getFieldDecorator: (field: string, opts: GetFieldDecoratorOptions) => any;
    getFieldValue: (field: string) => any;
    minDate?: dayjs.Dayjs | null;
    dateFromInitialValue? : dayjs.Dayjs | null;
    dateFromOnChange? : (value: dayjs.Dayjs) => void | null;
    dateToInitialValue? : dayjs.Dayjs | null;
    dateToOnChange? : (value: dayjs.Dayjs) => void | null;
}

/**
 * Wraps two date pickers in a single component that enforces certain standards for date range selection.
 */
const DateRangePicker: React.FC<DateRangePickerProps> = ({ 
    getFieldDecorator = () => {},
    getFieldValue = () => {},
    minDate = null,
    dateFromInitialValue = null,
    dateFromOnChange = null,
    dateToInitialValue = null,
    dateToOnChange = null
}) => {
    
    const dateTo: dayjs.Dayjs = getFieldValue('date_to');
    const dateFrom: dayjs.Dayjs = getFieldValue('date_from');
    let actualMinDate = minDate;
    if (dateFrom && actualMinDate && dateFrom.isAfter(actualMinDate)) {
        actualMinDate = dateFrom;
    }
    
    return (
        <Row>
            <Col span={12}>
                <Form.Item label="Date from">
                    {getFieldDecorator('date_from', { initialValue: dateFromInitialValue || dayjs() })(
                        <DatePicker 
                            format="DD/MM/YYYY" 
                            minDate={minDate}
                            maxDate={dateTo || null}
                            onChange={dateFromOnChange || null}
                            allowClear={false}
                            suffixIcon={<Calendar />}
                            style={{ borderRadius: '8px' }}
                        />
                    )}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Date to">
                    {getFieldDecorator('date_to', { initialValue: dateToInitialValue || dayjs() })(
                        <DatePicker 
                            format="DD/MM/YYYY" 
                            minDate={actualMinDate}
                            maxDate={dayjs()}
                            onChange={dateToOnChange || null}
                            allowClear={false}
                            suffixIcon={<Calendar />}
                            style={{ borderRadius: '8px' }}
                        />
                    )}
                </Form.Item>
            </Col>
        </Row>
    );
}

export default DateRangePicker;
