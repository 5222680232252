/* eslint-disable react/no-this-in-sfc */
import React from 'react';

import {
    ResponsiveContainer,
    ScatterChart,
    XAxis,
    Tooltip,
    Scatter,
    YAxis, Area,
} from 'recharts';

import ChartHeader from '../ChartHeader';
import ChartMultiDropDown from '../ChartMultiDropDown';
import './simpleScatterChart.scss';

import { getRandomColor } from '../../../../core/utils/functions';

export default function () {
    const {
        data,
        rightMenuItems,
        rightMenuOnClick,
        tooltipText,
        onFirstOptionChange,
        onSecondOptionChange,
        onThirdOptionChange,
        onFourOptionChange,
        onAllOptionChange,
        defaultFirstOptionValue,
        defaultSecondOptionValue,
        defaultThirdOptionValue,
        defaultFourOptionValue,
        divisions,
        userDivisions,
        drivers,
        assets,
        heightRatio,
        dotOnClick,
        chartKey,
    } = this.props;

    const { timeframe, colors } = this.state;
    let accessors = [];
    let colorOptions = [];
    let unit = [];
    if (data[0]) {
        accessors = Object.keys(data[0]).filter((k) => k !== 'x' && k !== 'colors' && k !== 'rules' && k !== 'unit');
        if (data[0].unit) {
            unit = data[0].unit;
        }
        if (data[0].colors) {
            colorOptions = data[0].colors;
        }
    }
    const xAxisAngle = (['hourly', 'monthly'].includes(timeframe) ? -80 : 0);
    let interval = 0;
    if (timeframe === 'daily') {
        interval = 'preserveStartEnd';
    }

    let widgetHeight = heightRatio * 200;
    if (heightRatio > 1) {
        widgetHeight += ((heightRatio - 1) * 95);
    }

    return (
        <div className="new-chart-widget bar-chart-widget" style={{}}>
            <ChartHeader
                timeframe={timeframe}
                onTimeframeChange={(timeframeData) => this.onTimeframeChange(timeframeData)}
                rightMenuOnClick={(rightMenuData) => rightMenuOnClick(rightMenuData)}
                rightMenuItems={rightMenuItems}
                tooltipText={tooltipText} />
            <div style={{ width: '100%', height: widgetHeight }} id={`modalContent_${chartKey}`}>
                <ResponsiveContainer width="100%" height="100%">
                    <ScatterChart
                        margin={{
                            top: 20, right: 30, left: 20, bottom: 10,
                        }}
                        data={data}>
                        <XAxis dataKey="x" dy={10} angle={xAxisAngle} interval={interval} />
                        <YAxis />
                        <Tooltip />
                        {
                            accessors.map((a, i) => {
                                let color = 'rgba(128,128,128,0.5)';
                                if (colorOptions[i]) {
                                    color = colorOptions[i];
                                } else if (colors[i]) {
                                    color = colors[i];
                                } else {
                                    color = getRandomColor();
                                }
                                return (
                                    <Scatter
                                        key={a}
                                        dataKey={a}
                                        fill={color}
                                        onClick={(payload) => {
                                            if (payload && payload.payload) {
                                                const event = payload.payload;
                                                const index = data.indexOf(event.payload);
                                                dotOnClick(timeframe, index);
                                            }
                                        }} />
                                );
                            })
                        }
                    </ScatterChart>
                </ResponsiveContainer>
            </div>
            <div className="new-chart-widget-footer">
                <ChartMultiDropDown
                    onFirstOptionChange={(firstOption) => onFirstOptionChange(firstOption)}
                    onSecondOptionChange={(secondOption) => onSecondOptionChange(secondOption)}
                    onThirdOptionChange={(thirdOption) => onThirdOptionChange(thirdOption)}
                    onFourOptionChange={(fourOption) => onFourOptionChange(fourOption)}
                    onDataChange={(optionsData) => onAllOptionChange(optionsData)}
                    defaultFirstOptionValue={defaultFirstOptionValue}
                    defaultSecondOptionValue={defaultSecondOptionValue}
                    defaultThirdOptionValue={defaultThirdOptionValue}
                    defaultFourOptionValue={defaultFourOptionValue}
                    divisions={divisions}
                    userDivisions={userDivisions}
                    drivers={drivers}
                    assets={assets} />
            </div>
        </div>
    );
}
