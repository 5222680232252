/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Spin, Dropdown, Button, Menu,
    Tooltip,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isEmpty } from 'underscore';
import { Link } from 'react-router-dom';
import { te } from 'date-fns/locale';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import Icon from '../../../../../components/elements/Icon';
import DeviceTimeline from '../../../../../components/DeviceTimeline';
import CustomEmpty from '../../../../../components/CustomEmpty';
import { View } from '@carbon/icons-react';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            convertedReport: [],
        };
    }

    toggleUnit = () => {
        const { imperialUnit } = this.state;
        this.setState({ imperialUnit: !imperialUnit });
    };

    render() {
        const {
            timeSheetReport,
            isFetching,
            actions,
            timeSheetReportPagination,
            imperialUnit,
            reportHasBeenFetched,
            companyFeatures,
            onPaginationChange,
            journeyOrDay,
        } = this.props;

        if (!reportHasBeenFetched) {
            return null;
        }
        let listReports = null;
        if (timeSheetReport && timeSheetReport.length > 0) {
            listReports = timeSheetReport.map((report) => {
                let tempReport = {};
                if (!isEmpty(report)) {
                    if (journeyOrDay !== 'days') {
                        tempReport.actions = (
                            <Tooltip title="View Journey">
                                <Link
                                    to={`/journey/${report.id}`}
                                    className="ml-1"
                                >
                                    <View/>
                                </Link>
                            </Tooltip>
                        );
                    }
                }
                tempReport = {
                    ...tempReport,
                    ...report,
                };
                const keys = Object.keys(tempReport);
                const mileageConversionRate = 0.621371;
                const mpgConversionRate = 282.481; // L/100km to UK mpg
                return keys.reduce((acc, key) => {
                    acc[key] = tempReport[key];
                    let newKey = key;
                    switch (key) {
                        case 'average_speed':
                        case 'max_speed':
                        case 'trip_odometer_mileage':
                            acc[key] = parseFloat(acc[key]);
                            if (acc[key] && imperialUnit) acc[key] = (acc[key] * mileageConversionRate).toFixed(2);
                            acc[key] = acc[key].toString();
                            if (imperialUnit) {
                                newKey = `${key}_in_Mi`;
                            } else {
                                newKey = `${key}_in_Km`;
                            }
                            acc[newKey] = acc[key];
                            delete acc[key];
                            break;

                        case 'mpg':
                            acc[key] = parseFloat(acc[key]);
                            if (acc[key] && imperialUnit) acc[key] = (mpgConversionRate / acc[key]).toFixed(2);
                            acc[key] = acc[key].toString();
                            if (!imperialUnit) {
                                newKey = 'L_/_100_Km';
                                acc[newKey] = acc[key];
                                delete acc[key];
                            }
                            break;
                    }

                    return acc;
                }, {});
            });
        }
        const SECONDS_IN_A_DAY = 86400;
        const TIMELINE_WIDTH = 1000;
        if (listReports && listReports.length > 0) {
            listReports = listReports.map((report) => {
                let timeDriven = report.time_driven;
                if (!timeDriven) timeDriven = '-';
                let { shift } = report;
                if (!shift) shift = '-';
                let idleTime = report.idle_time;
                if (!idleTime) idleTime = '-';
                let trueIdleTime = report.true_idle_time;
                if (!trueIdleTime) trueIdleTime = '-';
                let tempReport = {
                    ...report,
                    shift,
                    time_driven: timeDriven,
                    idle_time: idleTime,
                    true_idle: trueIdleTime,
                };

                let tripOdometerMileage = imperialUnit ? report.trip_odometer_mileage_in_Mi : report.trip_odometer_mileage_in_Km;
                if (!parseFloat(tripOdometerMileage)) tripOdometerMileage = '-';
                let mpg = imperialUnit ? report.mpg : report['L_/_100_Km'];
                if (!parseFloat(mpg)) mpg = '-';
                let averageSpeed = imperialUnit ? report.average_speed_in_Mi : report.average_speed_in_Km;
                if (!parseFloat(averageSpeed)) averageSpeed = '-';
                let maxSpeed = imperialUnit ? report.max_speed_in_Mi : report.max_speed_in_Km;
                if (!parseFloat(maxSpeed)) maxSpeed = '-';

                if (imperialUnit) {
                    tempReport.mileage_Mi = tripOdometerMileage;
                    tempReport.mpg = mpg;
                    tempReport.avg_speed_Mi = parseFloat(averageSpeed).toFixed(0);
                    tempReport.max_speed_Mi = parseFloat(maxSpeed).toFixed(0);
                } else {
                    tempReport.mileage_Km = tripOdometerMileage;
                    tempReport['L_/_100_Km'] = mpg;
                    tempReport.avg_speed_Km = parseFloat(averageSpeed).toFixed(0);
                    tempReport.max_speed_Km = parseFloat(maxSpeed).toFixed(0);
                }

                delete tempReport.erroneous;
                // Filter and arrange the columns based on the filter input
                const isFilteredByAssets = tempReport.asset && tempReport.registration;
                if (journeyOrDay === 'days') {
                    tempReport.date = moment(tempReport.start).format('DD-MM-YYYY (ddd)'); // Craft a date to go beside the timeline
                    tempReport.timeline = ( // Add the timeline
                        <div>
                            {isFetching ? null : (
                                <DeviceTimeline
                                    timelineWidth={SECONDS_IN_A_DAY}
                                    scaleX={TIMELINE_WIDTH / SECONDS_IN_A_DAY}
                                    deviceDetails={{
                                        assetId: tempReport.asset_id,
                                        date: tempReport.start,
                                    }}
                                    isInline/>
                            )}
                        </div>
                    );
                    if (isFilteredByAssets) {
                        tempReport = {
                            asset: tempReport.asset,
                            driver: tempReport.driver,
                            division: tempReport.division,
                            registration: tempReport.registration,
                            date: tempReport.date,
                            timeline: tempReport.timeline,
                            start_date: tempReport.start.split(' ')[0],
                            start_time: tempReport.start.split(' ')[1],
                            end_date: tempReport.end.split(' ')[0],
                            end_time: tempReport.end.split(' ')[1],
                            shift: tempReport.shift,
                            time_driven: tempReport.time_driven,
                            idle_time: tempReport.idle_time,
                            true_idle: tempReport.true_idle_time,
                            start_area_address: tempReport.start_area_address,
                            end_area_address: tempReport.end_area_address,
                            start_area: tempReport.start_area,
                            end_area: tempReport.end_area,
                            [imperialUnit ? 'mileage_Mi' : 'mileage_Km']: tripOdometerMileage,
                            [imperialUnit ? 'mpg' : 'L_/_100_Km']: imperialUnit ? tempReport.mpg : tempReport['L_/_100_Km'],
                            [imperialUnit ? 'avg_speed_Mi' : 'avg_speed_Km']: imperialUnit ? tempReport.avg_speed_Mi : tempReport.avg_speed_Km,
                            [imperialUnit ? 'max_speed_Mi' : 'max_speed_Km']: imperialUnit ? tempReport.max_speed_Mi : tempReport.max_speed_Km,
                        };
                    } else {
                        tempReport = {
                            driver: tempReport.driver,
                            asset: tempReport.asset,
                            division: tempReport.division,
                            date: tempReport.date,
                            timeline: tempReport.timeline,
                            start_date: tempReport.start.split(' ')[0],
                            start_time: tempReport.start.split(' ')[1],
                            end_date: tempReport.end.split(' ')[0],
                            end_time: tempReport.end.split(' ')[1],
                            shift: tempReport.shift,
                            time_driven: tempReport.time_driven,
                            idle_time: tempReport.idle_time,
                            true_idle: tempReport.true_idle_time,
                            start_area_address: tempReport.start_area_address,
                            end_area_address: tempReport.end_area_address,
                            start_area: tempReport.start_area,
                            end_area: tempReport.end_area,
                            [imperialUnit ? 'mileage_Mi' : 'mileage_Km']: tripOdometerMileage,
                            [imperialUnit ? 'mpg' : 'L_/_100_Km']: imperialUnit ? tempReport.mpg : tempReport['L_/_100_Km'],
                            [imperialUnit ? 'avg_speed_Mi' : 'avg_speed_Km']: imperialUnit ? tempReport.avg_speed_Mi : tempReport.avg_speed_Km,
                            [imperialUnit ? 'max_speed_Mi' : 'max_speed_Km']: imperialUnit ? tempReport.max_speed_Mi : tempReport.max_speed_Km,
                        };
                    }
                    const companyFeatureIds = companyFeatures.map((feature) => feature.id);
                    if (!companyFeatureIds.includes('timeline-on-timesheet-report')) {
                        delete tempReport.timeline;
                        delete tempReport.date;
                    }
                } else {
                    if (isFilteredByAssets) {
                        tempReport = {
                            actions: tempReport.actions,
                            asset: tempReport.asset,
                            driver: tempReport.driver,
                            division: tempReport.division,
                            registration: tempReport.registration,
                            start_date: tempReport.start.split(' ')[0],
                            start_time: tempReport.start.split(' ')[1],
                            end_date: tempReport.end.split(' ')[0],
                            end_time: tempReport.end.split(' ')[1],
                            shift: tempReport.shift,
                            time_driven: tempReport.time_driven,
                            idle_time: tempReport.idle_time,
                            true_idle: tempReport.true_idle_time,
                            start_area_address: tempReport.start_area_address,
                            end_area_address: tempReport.end_area_address,
                            start_area: tempReport.start_area,
                            end_area: tempReport.end_area,
                            [imperialUnit ? "mileage_Mi" : "mileage_Km"]: tripOdometerMileage,
                            [imperialUnit ? "mpg" : "L_/_100_Km"]: imperialUnit ? tempReport.mpg : tempReport['L_/_100_Km'],
                            [imperialUnit ? "avg_speed_Mi" : "avg_speed_Km"]: imperialUnit ? tempReport.avg_speed_Mi : tempReport.avg_speed_Km,
                            [imperialUnit ? "max_speed_Mi" : "max_speed_Km"]: imperialUnit ? tempReport.max_speed_Mi : tempReport.max_speed_Km,
                            action: tempReport.action,
                        };
                    } else {
                        tempReport = {
                            actions: tempReport.actions,
                            driver: tempReport.driver,
                            asset: tempReport.asset,
                            division: tempReport.division,
                            start_date: tempReport.start.split(' ')[0],
                            start_time: tempReport.start.split(' ')[1],
                            end_date: tempReport.end.split(' ')[0],
                            end_time: tempReport.end.split(' ')[1],
                            shift: tempReport.shift,
                            time_driven: tempReport.time_driven,
                            idle_time: tempReport.idle_time,
                            true_idle: tempReport.true_idle_time,
                            start_area_address: tempReport.start_area_address,
                            end_area_address: tempReport.end_area_address,
                            start_area: tempReport.start_area,
                            end_area: tempReport.end_area,
                            [imperialUnit ? "mileage_Mi" : "mileage_Km"]: tripOdometerMileage,
                            [imperialUnit ? "mpg" : "L_/_100_Km"]: imperialUnit ? tempReport.mpg : tempReport['L_/_100_Km'],
                            [imperialUnit ? "avg_speed_Mi" : "avg_speed_Km"]: imperialUnit ? tempReport.avg_speed_Mi : tempReport.avg_speed_Km,
                            [imperialUnit ? "max_speed_Mi" : "max_speed_Km"]: imperialUnit ? tempReport.max_speed_Mi : tempReport.max_speed_Km,
                            action: tempReport.action,
                        };
                    }
                }

                return tempReport;
            });
        }

        const exportableColumns = listReports && listReports[0]
            ? (Object.keys(listReports[0]).filter((oK) => oK.toLowerCase() !== 'actions'))
            : [];

        if (listReports) {
            if (listReports.length > 0) {
                return (
                    <div className="report-table">
                        <span 
                            className='report-title'
                            style={{
                                left: 384,
                                top: 24,
                            }}
                        >
                            Timesheet Report
                        </span>
                        <Dropdown overlay={(
                            <Menu onClick={this.props.setUnit}>
                                <Menu.Item key="imperial">Miles</Menu.Item>
                                <Menu.Item key="metric">Kilometers</Menu.Item>
                            </Menu>
                        )}>
                            <Button
                                type='primary'
                                className='export-dropdown-button'
                                style={{
                                    right: 112,
                                    top: 16
                                }}
                            >
                                {imperialUnit ? 'Miles' : 'Kilometers'}
                                <DownOutlined/>
                            </Button>
                        </Dropdown>
                        <Button
                            type="primary"
                            className='export-csv-button'
                            disabled={this.props.isExportingTimesheetReportCsv}
                            onClick={() => { this.props.onExportCSV(); }}
                            style={{ top: '16px', right: '16px' }}
                        >
                            <Spin
                                size="small"
                                spinning={this.props.isExportingTimesheetReportCsv}
                            >
                                Export
                            </Spin>
                        </Button>
                        <br/>
                        <GridView
                            data={listReports}
                            onChange={this.props.onTableChange}
                            sortableColumns={['asset', 'driver', 'division', 'registration', 'start_date', 'end_date', 'shift', 'time_driven', 'idle_time', 'true_idle', 'mileage_Km', 'avg_speed_Km', 'max_speed_Km']}
                            hiddenColumns={['id', 'asset_id', 'driver_id', 'action']}
                            exportableColumns={exportableColumns}
                            pagination={{
                                total: timeSheetReportPagination.totalRecords,
                                current: timeSheetReportPagination.currentPage,
                                pageSize: timeSheetReportPagination.perPageCount,
                                showSizeChanger: false,
                                onChange: onPaginationChange,
                            }}
                            scroll={{
                                x: 'max-content',
                                y: 'calc(100vh - 376px)',
                            }}
                        />
                    </div>
                );
            } else {
                return (<CustomEmpty/>);
            }
        } else {
            return (<></>);
        }
    }
}

ReportTab.defaultProps = {
    timeSheetReport: [],
    timeSheetReportPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    onTableChange: () => null,
};

ReportTab.propTypes = {
    timeSheetReport: PropTypes.array,
    timeSheetReportPagination: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        timeSheetReport: state.report.timeSheetReport,
        timeSheetReportPagination: state.report.timeSheetReportPagination,
        isFetching: state.report.isFetching,
        isExportingTimesheetReportCsv: state.report.isExportingTimesheetReportCsv,
        companyFeatures: state.user.userCompany.features,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
