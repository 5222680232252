import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import './sidebar.scss';
import srcFleetclearLogo from '../../../assets/images/fleetclear-logo.svg';
import srcFleetclearLogoMini from '../../../assets/images/fleetclear-logo-icon.png';
import { Dropdown, Menu as AntMenu, Select, Modal } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import Icon from '../../elements/Icon';
import { getIbmIconComponent } from '../../../core/utils/iconUtils';
import { Enterprise, Erase, Event, Help, Logout, MapBoundary, OverflowMenuHorizontal, User, UserMultiple } from '@carbon/icons-react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '../../../core/user/userActions';
import * as globalActions from '../../../core/global/globalActions';
import * as deviceActions from '../../../core/device/deviceActions';
import * as assetActions from '../../../core/asset/assetActions';
import moment from 'moment';
import { getTodayInServerFormat, getYesterdayInServerFormat } from '../../../core/utils/dateUtils';
import lockr from 'lockr';
import history from '../../../core/utils/history';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
const { SHARD_KEY } = require('../../../core/constants').default;
const { Option } = Select;

export interface SidebarProps {
    sidebarOpened: boolean;
    toggleSidebar: () => void;

}

const SidebarComponent: React.FC<SidebarProps> = ({
    sidebarOpened = true,
    toggleSidebar
}) => {
    const dispatch = useDispatch();
    const actionOptions: any = {
        ...userActions,
        ...globalActions,
        ...deviceActions,
        ...assetActions,
    };

    const actions: any = bindActionCreators(
        actionOptions,
        dispatch,
    );

    const user = useSelector((state: any) => state.user);
    const companies = useSelector((state: any) => state.company.companies);
    const { deviceGroups, userCompany } = user;
    const [shardId, setShardId] = useState<number>(lockr.get(SHARD_KEY));
    const [companyModalVisible, setCompanyModalVisible] = useState<boolean>(false);
    const [selectedUserCompany, setSelectedUserCompany] = useState<number>(userCompany?.id);
    let selectedDeviceGroup: any = {};

    const logout = () => {
        actions.updateMapDate(moment().format('YYYY-MM-DD'));
        actions.updateEventSearch({ assetId: null });
        actions.setAssetJourneyFilters({
            dateRange: [
                getYesterdayInServerFormat(),
                getTodayInServerFormat(),
            ],
        });
        actions.logoutRequest();
    };

    const handleShardChange = (e) => {
        const shardId = lockr.get(SHARD_KEY);
        if (e.key != null && e.key != shardId) {
            const newShardId = parseInt(e.key, 10);
            lockr.set(SHARD_KEY, newShardId);
            setShardId(newShardId);
            actions.setShardId(newShardId);
            actions.resetMapDataRequest();
            history.replace('/map');
        }
    };

    const handleChangeCompany = () => {
        if (selectedUserCompany === userCompany.id) {
            return;
        }

        actions.updateUserCompanyRequest({ company_id: selectedUserCompany });
        actions.mapAssetSearchRequest();
        actions.resetMapDataRequest();
        setCompanyModalVisible(false);
        history.replace('/map');
    };

    const selectShard = () => {
        if (deviceGroups.length > 1) {
            for (let i = 0; i < deviceGroups.length; i++) {
                const deviceGroup = deviceGroups[i];
                if (deviceGroup.id == shardId) {
                    selectedDeviceGroup = deviceGroup;
                }
            }
        }
    }

    useEffect(() => selectShard, [shardId]);
    useEffect(() => handleChangeCompany, [selectedUserCompany]);
    selectShard();


    const regionMenu = (
        <AntMenu>
            {deviceGroups.map((deviceGroup) => {
                if (deviceGroup.id != shardId) {
                    return <AntMenu.Item key={deviceGroup.id} onClick={handleShardChange}>{deviceGroup.label ? deviceGroup.label : deviceGroup.name}</AntMenu.Item>;
                }
                return null;
            })}
        </AntMenu>
    );

    const allowedRoles = ['super-admin', 'admin', 'manager'];
    const customAllowedRoles = ['super-admin', 'admin', 'manager', 'user'];

    let isVideoVisible = false;
    if (user && user.permissions && (user.permissions.live_video === true || user.permissions.historic_video_downloading === true)) {
        isVideoVisible = true;
    }

    const navlist = [
        {
            label: 'Map', link: '/map', pathName: '/map', iconName: 'map', visible: true,
        },
        {
            label: 'Events', link: '/events', pathName: '/events', iconName: 'events', visible: true,
        },
        {
            label: 'Video', link: '/video/video-search', pathName: '/video', iconName: 'video', visible: isVideoVisible, forceRefresh: true,
        },
        {
            label: 'Dashboard', link: '/dashboard', pathName: '/dashboard', iconName: 'data', visible: true,
        },
        {
            label: 'Assets', link: '/assets', pathName: '/assets', iconName: 'assets', visible: (customAllowedRoles.length ? customAllowedRoles.includes(user.role) : true),
        },
        {
            label: 'Drivers', link: '/drivers', pathName: '/drivers', iconName: 'user', visible: (customAllowedRoles.length ? customAllowedRoles.includes(user.role) : true),
        },
        {
            label: 'Reports', link: '/reports', pathName: '/reports', iconName: 'reports', visible: true,
        },
    ];

    const menu = (
        <AntMenu>
            <AntMenu.Item
                key="0"
                onClick={() => history.push('/profile')}
                style={{ lineHeight: '20px' }}
            >
                <User size={16} />
                &ensp;&ensp;
                My profile
            </AntMenu.Item>
            {allowedRoles.length && allowedRoles.includes(user.role) && (
                <AntMenu.Item
                    key="1"
                    onClick={() => history.push('/users')}
                    style={{ lineHeight: '20px' }}
                >
                    <UserMultiple size={16} />
                    &ensp;&ensp;
                    User management
                </AntMenu.Item>
            )}
            <AntMenu.Item
                key="2"
                onClick={() => window.location = 'https://help.fleetclear.com/'}
                style={{ lineHeight: '20px' }}
            >
                <Help size={16} />
                &ensp;&ensp;
                Fleetclear Help
            </AntMenu.Item>
            <AntMenu.Item
                key="3"
                onClick={() => window.open('https://outlook.office365.com/owa/calendar/FleetclearTraining@fleetclear.com/bookings/', '_blank').focus()}
                style={{ lineHeight: '20px' }}
            >
                <Event size={16} />
                &ensp;&ensp;
                Book training session
            </AntMenu.Item>

            <AntMenu.Item
                key="4"
                onClick={logout}
                style={{ lineHeight: '20px' }}
            >
                <Logout size={16} />
                &ensp;&ensp;
                Logout
            </AntMenu.Item>
        </AntMenu>
    );

    const companiesSelect = (
        <Select
            onChange={(val) => { setSelectedUserCompany(val); }}
            showSearch
            allowClear={{ clearIcon: <Erase size={12} /> }}
            optionFilterProp="children"
            filterOption={(input, option) => option?.props?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
            style={{ width: '100%' }}
            placeholder="Select Company"
        >
            {
                companies.map((v, i) => {
                    if (v.id !== userCompany.id) {
                        /* eslint-disable-next-line react/no-array-index-key */
                        return <Option key={i} value={v.id}>{v.title}</Option>;
                    }
                    return null;
                })
            }

        </Select>
    );

    const visibleItems = navlist.filter((item) => item.visible === true);

    return (
        <div className={`layout-sidebar ${sidebarOpened ? 'sidebar-open' : ''}`}>
            <div className='container'>
                <div className="sidebar__logo">
                    <div className='toggle-sidebar'>
                        {sidebarOpened ? <DoubleLeftOutlined size={16} onClick={toggleSidebar} /> : <DoubleRightOutlined size={16} onClick={toggleSidebar} />}
                    </div>
                    <img src={srcFleetclearLogo} className="sidebar__logo-fleet" alt="Fleetclear" />
                    <img src={srcFleetclearLogoMini} className="sidebar__logo-mini" alt="Fleetclear" />
                </div>
                <div className='nav-menu'>
                    {visibleItems.map((item: any, itemIndex) => (
                        <NavLink
                            to={item.link}
                            activeClassName="is-active"
                            key={item.key || `nav-item-${itemIndex}`}
                            isActive={(match, location) => location.pathname.startsWith(item.pathName)}
                        >
                            <div className='nav-icon'>
                                {item.iconName && (
                                    <div>
                                        {getIbmIconComponent(item.iconName, 24)}
                                    </div>
                                )}
                            </div>
                            <span className='nav-label'>
                                {item.label}
                            </span>
                        </NavLink>))}
                </div>
                {(deviceGroups.length > 1 || user.role === 'super-admin') && (
                    <div className='region-profile'>
                        {deviceGroups.length > 1 && (
                            <div className="region-menu-container">
                                <Dropdown overlay={regionMenu} className='region-menu' trigger={['click']} placement='topRight'>
                                    <div>
                                        <div className='region-icon'>
                                            <MapBoundary size={24} />
                                        </div>
                                        <div className='region-label-container'>
                                            <div className="region-label">Region</div>
                                            <div className="region-selected">{selectedDeviceGroup.label ? selectedDeviceGroup.label : selectedDeviceGroup.name}</div>
                                        </div>

                                    </div>

                                </Dropdown>
                            </div>
                        )}
                        {user.role === 'super-admin' && (
                            <div className='company-select-container'>
                                <div className='company-display' onClick={() => setCompanyModalVisible(true)}>
                                    <div className='company-icon'>
                                        <Enterprise size={24} />
                                    </div>
                                    <div className='company-label-container'>
                                        <div className="company-label">Company</div>
                                        <div className="company-selected">{userCompany.title}</div>
                                    </div>
                                </div>

                            </div>
                        )}
                    </div>
                )}
                <div className='footer-profile'>
                    <Dropdown overlay={menu} trigger={['click']}>

                        <div className="profile">
                            <div className='profile-image'>
                                {user?.userCompany?.company_image ? (
                                    <img
                                        src={user?.userCompany?.company_image}
                                        alt='Profile'
                                    />
                                ) : (
                                    <Icon name="person" />
                                )}
                            </div>
                            <div className="profile__user" onClick={() => { }}>
                                <div className="profile__username">{(user.profile && user.profile.full_name) || user.loginUser}</div>
                                <div className="profile__user-email">{user.loginUser}</div>
                                <div className="profile__user-role">{user.role}</div>
                            </div>
                        </div>
                    </Dropdown>
                    <Modal
                        open={companyModalVisible}
                        onCancel={() => setCompanyModalVisible(false)}
                        onOk={handleChangeCompany}
                        okText="Change"
                        onClose={() => setCompanyModalVisible(false)}
                    >
                        <h3>Change Company</h3>
                        {companiesSelect}
                        <div style={{ height: '16px' }} />
                    </Modal>
                </div>
            </div>
        </div>
    );
}

export default SidebarComponent;
