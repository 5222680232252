import React from 'react';

import './deviceStatusTag.scss';

interface DeviceStatusTagProps {
    isOnline: boolean;
    style?: React.CSSProperties;
}

/**
 * Wraps two date pickers in a single component that enforces certain standards for date range selection.
 */
const DeviceStatusTag: React.FC<DeviceStatusTagProps> = ({ 
    isOnline = false,
    style = {},
}) => {
    return (
        <div 
            className="dvr-status"
            style={style}
        >
            {isOnline ? (
                <div className='online'>DVR Online</div>
            ) : (
                <div className='offline'>DVR Offline</div>
            )}
        </div>
    );
}

export default DeviceStatusTag;
