import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import GridView from '../../../components/elements/GridView/index';
import * as driverActions from '../../../core/driver/driverActions';
import * as globalActions from '../../../core/global/globalActions';
import { secondsToHms } from '../../../core/utils/functions';
import LocationFetcher from '../../../components/elements/LocationFetcher';
import { View } from '@carbon/icons-react';
import dayjs from 'dayjs';
import { isoStandardDateTimeFormat } from '../../../core/utils/dateUtils';

const { DB_DATETIME_FORMAT } = require('../../../core/constants').default;

class DriverJourneyHistoryTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        const {
            actions, setLoadedState, journeyHistoryTabLoaded, driverId, driverJourneyFilters,
        } = this.props;

        const params = { driverId, page: 1 };
        if (!isEmpty(driverJourneyFilters)) {
            const { is_authorised, dateRange } = driverJourneyFilters;
            params.is_authorised = is_authorised;
            if (!isEmpty(dateRange)) {
                const [dateFrom, dateTo] = dateRange;
                params.date_from = dateFrom;
                params.date_to = dateTo;
            }
        } else {
            // Do the default for the params, Todays date and all authorised
            params.date_from = dayjs().startOf('day').format(isoStandardDateTimeFormat);
            params.date_to = dayjs().endOf('day').format(isoStandardDateTimeFormat);
            params.is_authorised = 'all';
        }

        if (!journeyHistoryTabLoaded) {
            actions.getDriverJourneyRequest(params);
            setLoadedState();
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;

        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange, driverJourneyPagination } = this.props;

        if (pagination && pagination.current == driverJourneyPagination.currentPage) {
            onTableChange(pagination, filters, sorter, extra);
        }
    };

    updateLocation(id) {
        const { actions } = this.props;

        actions.updateJourneyLocationRequest({ id });
    }

    render() {
        const {
            driverJourney, driverJourneyPagination,
        } = this.props;

        const listDriverJourneys = driverJourney ? driverJourney.map((journey) => {
            const circleColour = journey.events > 0 ? 'red' : 'green';
            const tempJourney = {};
            const timeDriven = secondsToHms(journey.time_driven);

            tempJourney.start_time = moment(journey.start, DB_DATETIME_FORMAT).format('ddd DD/MM/YYYY HH:mm:ss');
            tempJourney.end_time = journey.end != null ? moment(journey.end, DB_DATETIME_FORMAT).format('ddd DD/MM/YYYY HH:mm:ss') : '';
            tempJourney.time_driven = timeDriven || '0 secs';
            const startLat = journey.start_area.split(',')[0];
            const startLng = journey.start_area.split(',')[1];
            tempJourney.start_location = (
                <LocationFetcher
                    key={`start-${journey.id}`}
                    lat={startLat}
                    lon={startLng}
                />
            );
            const endLat = journey.end_area.split(',')[0];
            const endLng = journey.end_area.split(',')[1];
            tempJourney.end_location = (
                <LocationFetcher
                    key={`end-${journey.id}`}
                    lat={endLat}
                    lon={endLng}
                />
            );
            tempJourney.score = `${journey.score}%`;
            tempJourney.events = <span className={`circle ${circleColour}`}>{journey.events}</span>;
            tempJourney.actions = (
                <Tooltip title="View Journey">
                    <Link
                        to={`/journey/${journey.id}`}
                        className='actions-btn'
                    >
                        <View />
                    </Link>
                </Tooltip>
            );
            return tempJourney;
        }) : [];

        return (
            <GridView
                data={listDriverJourneys}
                onChange={this.onTableChange}
                sortableColumns={['start_time', 'end_time', 'time_driven', 'driver', 'area_driven', 'score', 'events']}
                pagination={{
                    total: parseInt(driverJourneyPagination.totalRecords, 10),
                    showSizeChanger: false,
                    pageSize: parseInt(driverJourneyPagination.perPageCount, 10),
                    onChange: this.onPageChange,
                }} 
            />
        );
    }
}

DriverJourneyHistoryTab.propTypes = {
    actions: PropTypes.object.isRequired,
    driverJourneyFilters: PropTypes.object.isRequired,
    driverJourney: PropTypes.any,
};

DriverJourneyHistoryTab.defaultProps = {
    driverJourney: false,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        driverJourney: state.driver.driverJourney,
        driverJourneyPagination: state.driver.driverJourneyPagination,
        driverJourneyFilters: state.driver.driverJourneyFilters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...driverActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DriverJourneyHistoryTab);
