/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import { size } from 'underscore';
import GridView from '../../../components/elements/GridView/index';

export default function () {
    const {
        asset,
        user,
        selectedAssets,
        actions,
    } = this.props;
    const {
        data,
        currentTabKey,
    } = this.state;

    let sortableColumns = [];
    if (data && data[0]) {
        sortableColumns = Object.keys(data[0]).filter((o) => ['actions', 'action', 'select'].indexOf(o) < 0);
    } 

    return (
        <div className='assets-tab'>
            <div className="section__action-bar">
                {selectedAssets.length > 0
                    ? (
                        <Link
                            to="#"
                            onClick={() => actions.clearSelectedAssets()}
                            className="btn ant-btn-primary page-header-button"
                            style={{ right: '0px' }}
                        >
                            &ensp;&ensp;Clear selection&ensp;&ensp;
                        </Link>
                    )
                    : null}
                {selectedAssets.length > 0
                    ? (
                        <Link
                            to={{ pathname: '/map/' }}
                            className="btn ant-btn-primary page-header-button"
                            onClick={() => actions.mapAssetSearchRequest({ search_ids: selectedAssets })}
                            style={{ right: '157px' }}
                        >
                            &ensp;&ensp;View selected&ensp;&ensp;
                        </Link>
                    )
                    : null}
            </div>
            <div className='assets-grid'>
                <GridView
                    data={data}
                    onChange={this.handleTableChange}
                    sortableColumns={sortableColumns}
                    hiddenColumns={['id']}
                    pagination={{
                        total: asset.pagination.totalCount,
                        pageSize: asset.pagination.perPage,
                        showSizeChanger: false,
                        onChange: (page) => this.paginationChange(page),
                        current: asset.pagination.currentPage,
                    }}
                />
            </div>
        </div>
    );
}
