import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import GridView from '../../../components/elements/GridView/index';
import * as driverActions from '../../../core/driver/driverActions';
import { isoStandardDateTimeFormat } from '../../../core/utils/dateUtils';
import { LocationFilled, NotificationFilled, PlugFilled, View, FlagFilled } from '@carbon/icons-react';

const { DB_DATE_FORMAT } = require('../../../core/constants').default;

class DriverEventHistoryTab extends Component {
    componentDidMount() {
        const {
            actions,
            setLoadedState,
            eventsHistoryTabLoaded,
            driverId,
        } = this.props;

        if (!eventsHistoryTabLoaded) {
            actions.getDriverEventsRequest({
                driverId,
                page: 1,
                date_from: moment(new Date()).startOf('day').format(isoStandardDateTimeFormat),
                date_to: moment(new Date()).format(isoStandardDateTimeFormat),
            });
            setLoadedState();
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;

        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange, driverEventsPagination } = this.props;

        if (pagination && pagination.current == driverEventsPagination.currentPage) {
            onTableChange(pagination, filters, sorter, extra);
        }
    };

    render() {
        const {
            driverEvents, driverEventsPagination,
        } = this.props;

        const listDriverEventHistory = driverEvents ? driverEvents.map((event) => {
            const iconStyle = {};
            iconStyle.fontSize = '16px';
            iconStyle.color = '#CF2B28';
            const tempEvent = {};
            // tempEvent.event_id = event.id;
            let eventTitle = event.event_name;

            if (event.event_type == 253) {
                if (event.green_driving_type == 0) {
                    eventTitle = 'Eco-friendly driving';
                } else if (event.green_driving_type == 1) {
                    eventTitle = 'Harsh accelerating';
                } else if (event.green_driving_type == 2) {
                    eventTitle = 'Harsh braking';
                } else if (event.green_driving_type == 3) {
                    eventTitle = 'Harsh cornering';
                }
            }

            tempEvent.event_type = (
                <Tooltip 
                    placement="top" 
                    title={eventTitle}
                >
                    <NotificationFilled 
                        size={20} 
                        style={{ 
                            color: 'var(--red)',
                            verticalAlign: 'middle',
                        }} 
                    />
                    &ensp;
                    {event.event_name}
                </Tooltip>
            );
            if (event.icon && event.icon !== '') {
                switch (event.icon) {
                    case 'alert':
                    case 'bell':
                        tempEvent.event_type = (
                            <Tooltip 
                                placement="top" 
                                title={eventTitle}
                            >
                                <NotificationFilled 
                                    size={20} 
                                    style={{ 
                                        color: 'var(--red)',
                                        verticalAlign: 'middle',
                                    }} 
                                />
                                &ensp;
                                {event.event_name}
                            </Tooltip>
                        );
                        break;
                    case 'api':
                        tempEvent.event_type = (
                            <Tooltip 
                                placement="top" 
                                title={eventTitle}
                            >
                                <PlugFilled 
                                    size={20} 
                                    style={{ 
                                        color: 'var(--red)',
                                        verticalAlign: 'middle',
                                    }}
                                />
                                &ensp;
                                {event.event_name}
                            </Tooltip>
                        );
                        break;
                    case 'environment':
                        tempEvent.event_type = (
                            <Tooltip 
                                placement="top" 
                                title={eventTitle}
                            >
                                <LocationFilled 
                                    size={20} 
                                    style={{ 
                                        color: 'var(--red)',
                                        verticalAlign: 'middle',
                                    }}
                                />
                                &ensp;
                                {event.event_name}
                            </Tooltip>
                        );
                        break;
                    case 'flag':
                        tempEvent.event_type = (
                            <Tooltip 
                                placement="top" 
                                title={eventTitle}
                            >
                                <FlagFilled
                                    size={20} 
                                    style={{ 
                                        color: 'var(--red)',
                                        verticalAlign: 'middle',
                                    }}
                                />
                                &ensp;
                                {event.event_name}
                            </Tooltip>
                        );
                        break;
                    default:
                        tempEvent.event_type = (
                            <Tooltip 
                                placement="top" 
                                title={eventTitle}
                            >
                                <NotificationFilled 
                                    size={20} 
                                    style={{ 
                                        color: 'var(--red)',
                                        verticalAlign: 'middle',
                                    }} 
                                />
                                &ensp;
                                {event.event_name}
                            </Tooltip>
                        );
                }
            }
            tempEvent.date = moment(event.date, DB_DATE_FORMAT).format('DD/MM/YYYY');
            tempEvent.time = moment(event.time, 'hh:mm A').format('hh:mm A');
            tempEvent.location = event.location_name;
            tempEvent.reg = <Link to={`/assets/view/${event.asset_id}`}>{event.registration}</Link>;
            tempEvent.actions = (
                <Tooltip title="View Event">
                    <Link 
                        to={`/events/custom-view/${event.id}/${event.device_id}`} 
                        className='actions-btn'
                    >
                        <View />
                    </Link>
                </Tooltip>
            );
            return tempEvent;
        }) : [];

        return (
            <GridView
                data={listDriverEventHistory}
                onChange={this.onTableChange}
                sortableColumns={['event_type', 'date', 'time', 'reg']}
                pagination={{
                    total: parseInt(driverEventsPagination.totalRecords, 10),
                    showSizeChanger: false,
                    pageSize: parseInt(driverEventsPagination.perPageCount, 10),
                    onChange: this.onPageChange,
                }} 
            />
        );
    }
}

DriverEventHistoryTab.defaultProps = {
    actions: {},
    setLoadedState: () => null,
    eventsHistoryTabLoaded: false,
    driverId: '',
    onPageChange: () => null,
    onTableChange: () => null,
    driverEvents: [],
    driverEventsPagination: {},
};

DriverEventHistoryTab.propTypes = {
    actions: PropTypes.object,
    setLoadedState: PropTypes.func,
    eventsHistoryTabLoaded: PropTypes.bool,
    driverId: PropTypes.string,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
    driverEvents: PropTypes.array,
    driverEventsPagination: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        driverEvents: state.driver.driverEvents,
        driverEventsPagination: state.driver.driverEventsPagination,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...driverActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DriverEventHistoryTab);
