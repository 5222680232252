import React, { Component, Fragment } from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';

import { Button } from 'antd';
import PropTypes from 'prop-types';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';

class DynamicFields extends Component {
    id = 1;

    count = 1;

    add = () => {
        const { getFieldValue, setFieldsValue, name } = this.props;
        const keys = getFieldValue(`${name}List`);

        const nextKeys = keys?.concat(this.id++);
        this.count++;

        setFieldsValue({
            [`${name}List`]: nextKeys,
        });
    };

    remove = (k) => () => {
        const {
            getFieldValue, setFieldsValue, name, onRemove,
        } = this.props;
        const keys = getFieldValue(`${name}List`);

        if (keys.length === 1) return;
        this.count--;

        setFieldsValue({
            [`${name}List`]: keys.filter((key) => key !== k),
        });

        onRemove(k);
    };

    defaultValidation = (name) => ({
        validateTrigger: ['onChange', 'onBlur'],
        rules: [],
    });

    addSingleField = () => {
        const {
            getFieldDecorator, getFieldValue, setFieldsValue, fields: obj, name, min, max, data,
        } = this.props;

        const count = Math.max(min, data.length);
        const initialValue = [];
        for (let i = 0; i < count; i++) {
            initialValue.push(i);
        }

        getFieldDecorator(`${name}List`, { initialValue });

        const fieldCounter = getFieldValue(`${name}List`);

        // remove extra data
        if (fieldCounter.length > max) {
            this.count = max;

            setFieldsValue({
                [`${name}List`]: fieldCounter.slice(0, max),
            });
        }

        return fieldCounter.map((k) => {
            const validation = obj.validation || this.defaultValidation(name);
            validation.initialValue = data[k];

            return (
                <Form.Item className="dynamic-field" key={k}>
                    {getFieldDecorator(
                        `${name}[${k}]`,
                        validation,
                    )(obj.field())}
                    {fieldCounter.length > 1 ? (
                        <CloseCircleOutlined className="dynamic-delete-button" onClick={this.remove(k)} />
                    ) : null}
                </Form.Item>
            );
        });
    };

    addMultipleFields = () => {
        const {
            setFieldsValue, getFieldDecorator, getFieldValue, fields, name, min, max, data,
        } = this.props;

        const count = Math.max(min, data.length);
        const initialValue = [];
        for (let i = 0; i < count; i++) {
            initialValue.push(i);
        }

        getFieldDecorator(`${name}List`, { initialValue });

        const fieldCounter = getFieldValue(`${name}List`);

        // remove extra data
        if (fieldCounter.length > max) {
            this.count = max;

            setFieldsValue({
                [`${name}List`]: fieldCounter.slice(0, max),
            });
        }

        return fieldCounter.reduce((preResult, k) => {
            const row = fields.map((obj, i) => {
                const validation = obj.validation || this.defaultValidation(name);
                validation.initialValue = data[k];

                return (
                    <Form.Item className="dynamic-field" key={`${k}${obj.name}`}>
                        {getFieldDecorator(
                            `${name}[${k}][${obj.name}]`,
                            validation,
                        )(obj.field())}
                        {fieldCounter.length > min && fields.length - 1 === i ? (
                            <CloseCircleOutlined className="dynamic-delete-button" onClick={this.remove(k)} />
                        ) : null}
                    </Form.Item>
                );
            });

            return [...preResult, ...row];
        }, []);
    };

    render() {
        const {
            getFieldValue, fields, max, min, data, name,
        } = this.props;
        const keys = getFieldValue(`${name}List`);

        if (this.count < min) {
            this.add();
        }

        return (
            <>
                {Array.isArray(fields)
                    ? this.addMultipleFields()
                    : this.addSingleField()}
                { this.count < max ? (
                    <Form.Item style={{ textAlign: 'left' }}>
                        <Button onClick={this.add} shape="circle" className="add-button">
                            <PlusOutlined />
                        </Button> Add
                    </Form.Item>
                ) : null }
            </>
        );
    }
}

DynamicFields.defaultProps = {
    max: 99,
    min: 1,
    data: [],
    onRemove: () => {},
};

DynamicFields.propTypes = {
    name: PropTypes.string.isRequired,
    fields: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
        // TODO: add object shape validation.
    ]).isRequired,
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    default: PropTypes.number,
    onRemove: PropTypes.func,
};

export default DynamicFields;
