import React from 'react';
import { Empty } from 'antd';

import './customEmpty.scss';

enum CustomEmptyType {
    Report = 'report',
    VideoDevices = 'video_devices',
    Videos = 'videos',
    VideoSearch = 'video_search',
}

const DEFAULT_TITLE = 'No data'
const getTitleContent = (type: `${CustomEmptyType.Report}` | `${CustomEmptyType.VideoDevices}` | `${CustomEmptyType.Videos}` | `${CustomEmptyType.VideoSearch}`): string => {
    if (!type) {
        return DEFAULT_TITLE;
    } else {
        switch (type) {
            case CustomEmptyType.Report:
                return 'Enter your search requirements to generate a report';
            case CustomEmptyType.VideoDevices:
                return 'No video devices found for the asset';
            case CustomEmptyType.Videos:
                return 'Cannot load videos: device may be offline';
            case CustomEmptyType.VideoSearch:
                return 'Enter your requirements to search videos for an asset';
            default:
                return DEFAULT_TITLE;
        }
    }

}
const getDescriptionContent = (type: `${CustomEmptyType.Report}` | `${CustomEmptyType.VideoDevices}` | `${CustomEmptyType.Videos}` | `${CustomEmptyType.VideoSearch}`): string | null => {
    if (!type) {
        return null;
    } else {
        switch (type) {
            case CustomEmptyType.Report:
                return 'Use the filter bar on the left to select Asset(s)/Driver(s), the divison and the date (optional) of the data you would like to display';
            case CustomEmptyType.VideoDevices:
                return null;
            case CustomEmptyType.Videos:
                return null;
            case CustomEmptyType.VideoSearch:
                return null;
            default:
                return DEFAULT_TITLE;
        }
    }
}

interface CustomEmptyProps {
    type: `${CustomEmptyType.Report}` | `${CustomEmptyType.VideoDevices}` | `${CustomEmptyType.Videos}` | `${CustomEmptyType.VideoSearch}` | null;
    showIcon?: boolean;
}

const CustomEmpty: React.FC<CustomEmptyProps> = ({
    type = null,
    showIcon = true,
}) => {
    const description = getDescriptionContent(type);
    return (
        <Empty
            description={(
                <>
                    <div className='title'>{getTitleContent(type)}</div>
                    {description && (<div className='description'>{description}</div>)}
                </>
            )}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{ display: showIcon ? 'block' : 'none' }} 
        />
    );
}

export default CustomEmpty;