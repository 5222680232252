import React, { Key } from 'react';
import { Tooltip, Button, Input, Menu, Dropdown, Tree } from 'antd';
import { useSelector } from 'react-redux';
import { getIbmIconComponent } from '../../../../core/utils/iconUtils';
import { DeviceTimelineData, DVRChannelLink } from '../../../../types';
import { Close } from '@carbon/icons-react';
import DeviceTimeline from '../../../DeviceTimeline';
import ThreeHandleSlider from '../../../ThreeHandleSlider';
import { secondsToTime } from '../../../../core/utils/dateUtils.js';
import DeviceStatusTag from '../../../DeviceStatusTag';

const { ZERO_INDEXED_SECONDS_IN_A_DAY } = require('../../../../core/constants').default;

interface VideoToolbarProps { // TODO: cleanup these props when finished video search epic and I know what I need
    toggleAssetLabels?: () => void;
    toggleTrafficOverlay?: () => void;
    toggleGeofences?: () => void;
    setSliderTextValue: (value: string) => void;
    selectedDvrIsOnline: boolean;
    setSelectedHeatmapKeys: (keys: Key[]) => void;
    selectedHeatmapKeys: Key[];
    eventTypes: { id: number, key: string }[];
    toggleMapSearch: () => void;
    sliderScaleX: number;
    sliderWidth: number;
    timelineData: DeviceTimelineData | null;
    isVisibleSlider: boolean;
    sliderValue: [number, number, number];
    setSliderValue: (value: [number, number, number]) => void;
    formatSliderTooltip: (value: number, timelineData: DeviceTimelineData) => any;
    startVideoPlayback: () => void;
    pauseVideoPlayback: () => void;
    resumeVideoPlayback: () => void;
    stopVideoPlayback: () => void;
    videoSearchRequestedStartSeconds: number | null;
    channelLinks?: DVRChannelLink[] | null;
}

/**
 * Video focused toolbar with standard map controls plus playback controls.
 */
const VideoToolbar: React.FC<VideoToolbarProps> = ({ 
    toggleAssetLabels = () => {},
    toggleTrafficOverlay = () => {},
    toggleGeofences = () => {},
    setSliderTextValue = (value) => {},
    selectedDvrIsOnline = false,
    setSelectedHeatmapKeys = (keys) => {},
    selectedHeatmapKeys = [],
    eventTypes = [],
    toggleMapSearch = () => {},
    sliderScaleX = 1,
    sliderWidth = 1,
    timelineData = null,
    isVisibleSlider = false,
    sliderValue = [0, 0, 0],
    setSliderValue = () => {},
    formatSliderTooltip = () => {},
    startVideoPlayback = () => {},
    pauseVideoPlayback = () => {},
    resumeVideoPlayback = () => {},
    stopVideoPlayback = () => {},
    videoSearchRequestedStartSeconds = null,
    channelLinks = null,
}) => {
    const globalShowAssetLabels = useSelector((state: any) => state.user.profile.show_info_preference);
    const [ isVisibleAssetLabels, setIsVisibleAssetLabels ] = React.useState<boolean>(globalShowAssetLabels > 0);
    const [ isVisibleTrafficOverlay, setIsVisibleTrafficOverlay ] = React.useState<boolean>(false);
    const [ isVisibleGeofences, setIsVisibleGeofences ] = React.useState<boolean>(false);
    const [ isVisibleMapSearch, setIsVisibleMapSearch ] = React.useState<boolean>(false);
    const [ searchValue, setSearchValue ] = React.useState<string>('');
    const [ isVideoPlaybackStarted, setIsVideoPlaybackStarted ] = React.useState<boolean>(false);
    const [ isVideoPlaybackPaused, setIsVideoPlaybackPaused ] = React.useState<boolean>(false);

    const tooltipStyle = { zIndex: 1002 };
    const children = [];
    eventTypes?.forEach(eventType => {
        children.push({
            title: eventType.key,
            key: eventType.id,
        });
    });
    const treeData = [
        {
            title: 'All',
            key: 'all',
            children,
        },
    ];
    const isVideoControlsDisabled = (videoSearchRequestedStartSeconds === null) || (!channelLinks || !channelLinks.length);
    
    return (
        <div 
            className='toolbar'
            style={{ 
                height: '56px',
                paddingRight: 0,
            }}
        >
            <ul 
                className="button-list"
                style={{ 
                    padding: 0, 
                    height: '56px',
                }}
            >
                <li>
                    <Tooltip 
                        title={isVisibleAssetLabels ? 'Hide Asset Names' : 'Show Asset Names'} 
                        style={tooltipStyle}
                    >
                        <Button
                            className={`btn btn-empty ${isVisibleAssetLabels ? 'btn-selected' : ''}`}
                            onClick={() => {
                                setIsVisibleAssetLabels(!isVisibleAssetLabels);
                                toggleAssetLabels();
                            }}
                        >
                            {getIbmIconComponent('info', 16)}
                        </Button>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip 
                        title={isVisibleTrafficOverlay ? 'Hide Traffic Overlay' : 'Show Traffic Overlay'} 
                        style={tooltipStyle}
                    >
                        <Button
                            className={`btn btn-empty ${isVisibleTrafficOverlay ? 'btn-selected' : ''}`}
                            onClick={() => {
                                setIsVisibleTrafficOverlay(!isVisibleTrafficOverlay);
                                toggleTrafficOverlay();
                            }}
                        >
                            {getIbmIconComponent('car', 16)}
                        </Button>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip 
                        title={isVisibleGeofences ? 'Hide Geo-fences' : 'Show Geo-fences'} 
                        style={tooltipStyle}
                    >
                        <Button
                            className={`btn btn-empty ${isVisibleGeofences ? 'btn-selected' : ''}`}
                            onClick={() => {
                                setIsVisibleGeofences(!isVisibleGeofences);
                                toggleGeofences();
                            }}
                        >
                            {getIbmIconComponent('earth', 16)}
                        </Button>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip 
                        title='Heatmaps'
                        style={tooltipStyle}
                    >
                        <Dropdown overlay={
                            <Menu>
                                <div style={{ 
                                    height: 400,
                                    overflow: 'auto', 
                                }}>
                                    <Tree
                                        defaultExpandedKeys={['all']}
                                        checkable
                                        onCheck={setSelectedHeatmapKeys}
                                        treeData={treeData} 
                                    />
                                </div>
                            </Menu>
                        }>
                            <Button
                                className={`btn btn-empty ${selectedHeatmapKeys?.length ? 'btn-selected' : ''}`}
                                onClick={(e) => { e.preventDefault(); }}
                            >
                                {getIbmIconComponent('fire', 16)}
                            </Button>
                        </Dropdown>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip 
                        title={isVisibleMapSearch ? 'Hide Map Search' : 'Show Map Search'} 
                        style={tooltipStyle}
                    >
                        <Button
                            className={`btn btn-empty ${isVisibleMapSearch ? 'btn-selected' : ''}`}
                            onClick={() => {
                                setIsVisibleMapSearch(!isVisibleMapSearch);
                                toggleMapSearch();
                            }}
                        >
                            {getIbmIconComponent('location', 16)}
                        </Button>
                    </Tooltip>
                </li>
                <div className="search-box">
                    <Input
                        type="text"
                        id="map-search-box"
                        className="search"
                        size="small"
                        autoComplete="off"
                        placeholder="Search Address/Postcode"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        data-form-type="other" // Hide Dashlane and other password managers
                        style={{
                            fontSize: '12px',
                            margin: isVisibleMapSearch ? '3px 4px 0 4px' : '0 0 0 -30px',
                            width: isVisibleMapSearch ? '200px' : 0,
                            minWidth: isVisibleMapSearch ? '200px' : 0,
                            pointerEvents: isVisibleMapSearch ? 'auto' : 'none',
                            opacity: isVisibleMapSearch ? 1 : 0,
                            zIndex: isVisibleMapSearch ? 1000 : 0,
                        }}
                        suffix={
                            <>
                                {searchValue && (
                                    <Close onClick={() => setSearchValue('')} />
                                )}
                            </>
                        }
                    />
                </div>
                <li>
                    <Tooltip 
                        title={videoSearchRequestedStartSeconds ? isVideoControlsDisabled ? 'No DVR channels found' : 'Play' : 'Click Stream to use video controls'} 
                        style={tooltipStyle}
                    >
                        <Button
                            className={`btn btn-empty ${isVideoPlaybackStarted || isVideoPlaybackPaused ? 'btn-selected' : ''}`}
                            onClick={() => {
                                if (isVideoPlaybackStarted) {
                                    if (isVideoPlaybackPaused) {
                                        setIsVideoPlaybackPaused(false);
                                        resumeVideoPlayback();
                                    } else {
                                        setIsVideoPlaybackPaused(true);
                                        pauseVideoPlayback();
                                    }
                                } else {
                                    setIsVideoPlaybackStarted(true);
                                    setIsVideoPlaybackPaused(false);
                                    startVideoPlayback();
                                }
                            }}
                            disabled={isVideoControlsDisabled}
                        >
                            {getIbmIconComponent(isVideoPlaybackStarted 
                                ? isVideoPlaybackPaused 
                                    ? 'play'
                                    : 'pause'
                                : 'play', 
                                16
                            )}
                        </Button>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip 
                        title={videoSearchRequestedStartSeconds ? isVideoControlsDisabled ? 'No DVR channels found' : 'Stop' : 'Click Stream to use video controls'} 
                        style={tooltipStyle}
                    >
                        <Button
                            className='btn btn-empty'
                            onClick={() => {
                                setIsVideoPlaybackStarted(false);
                                setIsVideoPlaybackPaused(false);
                                stopVideoPlayback();
                            }}
                            disabled={isVideoControlsDisabled}
                        >
                            {getIbmIconComponent('stop', 16)}
                        </Button>
                    </Tooltip>
                </li>
            </ul>
            {timelineData && isVisibleSlider && (
                <div style={{ 
                    marginTop: '-10px', 
                    marginLeft: '8px',
                }}>
                    <ThreeHandleSlider 
                        min={0}
                        max={ZERO_INDEXED_SECONDS_IN_A_DAY}
                        value={sliderValue}
                        defaultValue={sliderValue}
                        step={1}
                        onChange={(value: [number, number, number]) => {
                            setSliderValue(value);
                            setSliderTextValue(secondsToTime(value[1]));
                        }}
                        tooltipFormatter={(value) => formatSliderTooltip(value, timelineData)} 
                        showTimingLabels
                        sliderWidth={sliderWidth}
                        style={{ zIndex: 1000 }}
                    />
                    <DeviceTimeline
                        timelineWidth={ZERO_INDEXED_SECONDS_IN_A_DAY}
                        scaleX={sliderScaleX}
                        data={timelineData.timelineSegments} 
                        geofenceData={timelineData.geofenceTimelineSegments}
                        style={{ 
                            borderRadius: '0px',
                            height: '32px',
                            position: 'relative',
                            top: 0,
                            left: 0,
                            marginTop: '-18px',
                            marginLeft: '5px',
                            pointerEvents: 'none',
                        }}
                    />
                </div>
            )}
            <DeviceStatusTag 
                isOnline={selectedDvrIsOnline} 
                style={{ marginTop: '16px' }}
            />
        </div>
    );
}

export default React.memo(VideoToolbar);