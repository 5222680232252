import React from 'react';
import { PathMarkerType } from '../../../../../types';

import './pathMarkerContent.scss';

type PathMarkerContentProps = {
    position?: PathMarkerType;
};

const PathMarkerContent: React.FC<PathMarkerContentProps> = ({
    position = PathMarkerType.Start,
}) => {
    return (
        <div className='path-marker-content'>
            <div className='path-marker'>
                <div className='path-marker-text'>
                    {position}
                </div>
            </div>
        </div>
    );
}

export default React.memo(PathMarkerContent);