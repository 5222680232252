import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tooltip } from 'antd';
import { isEmpty } from 'underscore';
import moment from 'moment';
import GridView from '../../components/elements/GridView/index';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import FilterForm from './FilterForm';
import './drivers.scss';
import { View } from '@carbon/icons-react';

export default function () {
    const { driver, division, user } = this.props;
    const { searchParams } = this.state;

    const formattedData = [];
    if (!isEmpty(driver.drivers)) {
        driver.drivers.map((item, key) => {
            formattedData.push({
                driver: item.full_name,
                division: !isEmpty(item.division_name) ? item.division_name : '-',
                last_journey: item.last_online ? moment(item.last_online).format('DD/MM/YYYY HH:mm') : '-',
                avg_score: `${Math.round(item.average_score)}%`,
                actions: (
                    <Tooltip title="View Driver">
                        <Link
                            to={`/drivers/view/${item.id}`}
                            className="actions-btn"
                        >
                            <View />
                        </Link>
                    </Tooltip>
                ),
            });
        });
    }

    return (
        <Layout
            data-test="pages-drivers"
            className="drivers"
            title={<div>Drivers</div>}
            headerTitle={<div>Drivers Page</div>}
            showFooter={false}
            loading={driver?.driversFetching}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <FilterSidebar displayFilter={true}>
                    <FilterForm 
                        searchParams={searchParams} 
                        onSubmit={(filterData) => this.filterDrivers(filterData)} 
                        departments={driver.departments} 
                        divisions={division.divisionOptions} 
                    />
                </FilterSidebar>
                <div 
                    className="content"
                    style={{ opacity: driver?.driversFetching ? 0 : 1 }}
                >
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <div style={{ 
                                    marginTop: '-32px', 
                                    minHeight: '200px', 
                                }}>
                                    <GridView
                                        data={formattedData}
                                        onChange={this.handleTableChange}
                                        sortableColumns={['driver_id', 'driver', 'last_journey', 'avg_score', 'department']}
                                        pagination={{
                                            total: driver.pagination.totalCount,
                                            pageSize: driver.pagination.perPage,
                                            current: driver.pagination.currentPage,
                                            showSizeChanger: false,
                                            onChange: (page) => this.paginationChange(page),
                                        }}
                                        scroll={{
                                            x: 'max-content',
                                            y: 'calc(63vh)',
                                        }}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
