/* eslint-disable react/no-this-in-sfc */
import React from 'react';

import {
    ResponsiveContainer,
    ComposedChart,
    XAxis,
    Tooltip,
    Line,
    Scatter,
    YAxis,
} from 'recharts';

import ChartHeader from '../ChartHeader'
import ChartMultiDropDown from '../ChartMultiDropDown'
import './scatterAndLineOfBestFit.scss';

import { getRandomColor } from '../../../../core/utils/functions'

export default function () {
    const {
        data,
        rightMenuItems,
        rightMenuOnClick,
        tooltipText,
        onFirstOptionChange,
        onSecondOptionChange,
        onThirdOptionChange,
        onFourOptionChange,
        onAllOptionChange,
        defaultFirstOptionValue,
        defaultSecondOptionValue,
        defaultThirdOptionValue,
        defaultFourOptionValue,
        divisions,
        drivers,
        assets,
        lineKeys,
        scatterKeys,
        heightRatio,
        chartKey,
    } = this.props;

    const { timeframe, colors } = this.state;

    const xAxisAngle = (['hourly', 'monthly'].includes(timeframe) ? -80 : 0);
    let interval = 0;
    if (timeframe === 'daily') {
        interval = 'preserveStartEnd';
    }

    let widgetHeight = heightRatio * 200;
    if (heightRatio > 1) {
        widgetHeight += ((heightRatio - 1) * 95);
    }

    return (
        <div className="new-chart-widget bar-chart-widget" style={{}}>
            <ChartHeader
                timeframe={timeframe}
                onTimeframeChange={(timeframeData) => this.onTimeframeChange(timeframeData) }
                rightMenuOnClick={(rightMenuData) => rightMenuOnClick(rightMenuData)}
                rightMenuItems={rightMenuItems}
                tooltipText={tooltipText} />
            <div style={{ width: '100%', height: widgetHeight }} id={'modalContent_'+chartKey}>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        margin={{
                            top: 20, right: 30, left: 20, bottom: 10,
                        }}
                        data={data}>
                        <XAxis dataKey="x" dy={10} angle={xAxisAngle} interval={interval} />
                        <YAxis />
                        <Tooltip />
                        {
                            lineKeys.map((a, i) => {
                                let color = 'rgba(128,128,128,0.5)';
                                if (colors[i]) {
                                    color = colors[i];
                                } else {
                                    color = getRandomColor();
                                }
                                return (
                                    <Line key={`line-key-${a}`} dataKey={a} stroke={color} dot={false} activeDot={false} legendType="none" />
                                );
                            })
                        }

                        {
                            scatterKeys.map((a, i) => {
                                let color = 'rgba(128,128,128,0.5)';
                                if (i === 0) {
                                    color = '#FDCE7D';
                                } else if (colors[i]) {
                                    color = colors[i];
                                } else {
                                    color = getRandomColor();
                                }
                                return (
                                    <Scatter key={`scatter-key-${a}`} dataKey={a} fill={color} />
                                );
                            })
                        }
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
            <div className="new-chart-widget-footer">
                <ChartMultiDropDown
                    onFirstOptionChange={(firstOption) => onFirstOptionChange(firstOption)}
                    onSecondOptionChange={(secondOption) => onSecondOptionChange(secondOption)}
                    onThirdOptionChange={(thirdOption) => onThirdOptionChange(thirdOption)}
                    onFourOptionChange={(fourOption) => onFourOptionChange(fourOption)}
                    onDataChange={(optionsData) => onAllOptionChange(optionsData)}
                    defaultFirstOptionValue={defaultFirstOptionValue}
                    defaultSecondOptionValue={defaultSecondOptionValue}
                    defaultThirdOptionValue={defaultThirdOptionValue}
                    defaultFourOptionValue={defaultFourOptionValue}
                    divisions={divisions}
                    drivers={drivers}
                    assets={assets} />
            </div>
        </div>
    );
}
