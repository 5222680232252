/* eslint-disable */
import PropTypes from "prop-types";
import { Component } from "react";
import DOM from "./layout";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Layout extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            sidebarOpened: JSON.parse(localStorage.getItem('sidebarOpened')) ?? true
        }

    }

    toggleSidebar = () => {
        this.setState({
            sidebarOpened: !this.state.sidebarOpened
        }, () => {
            localStorage.setItem('sidebarOpened', JSON.stringify(this.state.sidebarOpened));
        });
    }

    render() {
        return this.view(this.props);
    }
}

Layout.defaultProps = {
    breadcrumb: null,
    toolbar: [],
    className: "",
    showHeader: true,
    beforeHeader: null,
    afterHeader: null,
    floatingHeader: false,
    headerTitle: null,
    showFooter: false,
    beforeFooter: null,
    afterFooter: null,
    floatingFooter: false,
    footerContent: null,
    menu: [],
    backLink: null,
    logo: null,
    menuPlacement: "left",
    menuWidth: 280
};

Layout.propTypes = {
    breadcrumb: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    title: PropTypes.node.isRequired,
    toolbar: PropTypes.array,
    className: PropTypes.string,
    showHeader: PropTypes.bool,
    beforeHeader: PropTypes.object,
    afterHeader: PropTypes.object,
    floatingHeader: PropTypes.bool,
    headerTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    showFooter: PropTypes.bool,
    beforeFooter: PropTypes.object,
    afterFooter: PropTypes.object,
    floatingFooter: PropTypes.bool,
    footerContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menu: PropTypes.array,
    backLink: PropTypes.object,
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menuPlacement: PropTypes.string,
    menuWidth: PropTypes.number
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user,
        companies: state.company.companies,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);
