/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';
import { notification } from 'antd';
import moment from 'moment';

import DOM from './geofenceReport';

import * as eventsActions from '../../../../core/events/eventsActions';
import * as reportActions from '../../../../core/report/reportActions';
import * as assetActions from '../../../../core/asset/assetActions';
import * as userActions from '../../../../core/user/userActions';
import * as deviceActions from '../../../../core/device/deviceActions';
import * as globalActions from '../../../../core/global/globalActions';

import { isoStandardDateTimeFormat } from '../../../../core/utils/dateUtils';

class GeofenceReport extends Component {
    constructor(props) {
        super(props);
        this.googleMapRef = React.createRef();
        this.handleMapRef = this.handleMapRef.bind(this);

        this.state = {
            filters: {},
            filterSidebar: true,
            reportHasBeenFetched: false,
            newGeoFencePath: [],
            points: [],
            geofenceDrawingEnabled: false,
            geofenceSaved: false,
            showSaveGeofence: false,
        };
        this.view = DOM;
    }

    setGoogleMapRef(ref) {
        // this.googleMapRef = ref;
    }

    onMapMounted() {
        // Do something with the ref here if needed
        if (this.state.points?.length && this.googleMapRef) {
            this.googleMapRef.executeAutoZoom();
        }
    }

    handleMapRef(ref) {
        window.ss = this;
        // Now you have access to the ref in your parent component
        this.googleMapRef = ref;
        // Do something with the ref here if needed
    }

    componentDidMount() {
        const { actions, dataEventToReportParams, allTriggers } = this.props;
        const { filters, newGeoFencePath } = this.state;
        
        actions.resetReportsRequest();
        if (dataEventToReportParams !== null) {
            if (dataEventToReportParams.existingGeofenceId) {
                const trigger = allTriggers.find((item) => item.id == dataEventToReportParams.existingGeofenceId);
                this.predefinedGeoFenceSelected(trigger, false);
                filters.existingGeofenceId = dataEventToReportParams.existingGeofenceId;
                filters.existing_or_custom_geofence = "existing";
            } else if (dataEventToReportParams.geoPoints?.length && !newGeoFencePath?.length) {
                const { geoPoints } = dataEventToReportParams;
                const triggers = [{
                    coordinates: geoPoints,
                    id: 'new',
                }];
                this.googleMapRef.showGeoFenceOnMap(true);
                this.googleMapRef.refreshCenter(geoPoints[0].lat, geoPoints[0].lng);
                this.setState({
                    newGeoFencePath: triggers,
                    points: geoPoints,
                    geofenceDrawingEnabled: false,
                });
                filters.existingGeofenceId = null;
                filters.existing_or_custom_geofence = "custom";
            }
        }
        let dateRange = [];
        if (isEmpty(filters.dateRange)) {
            dateRange = [
                (new moment()).startOf('day').format(isoStandardDateTimeFormat),
                (new moment()).endOf('day').format(isoStandardDateTimeFormat),
            ];
        } else {
            dateRange = [
                (new moment(filters.dateRange[0])).startOf('day').format(isoStandardDateTimeFormat),
                (new moment(filters.dateRange[1])).endOf('day').format(isoStandardDateTimeFormat),
            ];
        }
        filters.dateRange = dateRange;

        this.setState({ filters });
    }

    onGeofenceTabFilterChange = (values) => {
        if (!this.state.points?.length) {
            notification.error({ message: 'Error', description: 'Please select a geo fence' });
            return false;
        }

        const filters = values;

        const dateRange = [
            values.date_from.startOf('day').format(isoStandardDateTimeFormat),
            values.date_to.endOf('day').format(isoStandardDateTimeFormat),
        ];
        filters.dateRange = dateRange;
        if (filters.asset_id) {
            filters.asset_info = values.asset_id;
        }

        if (filters.division_id) {
            filters.division_id = values.division_id;
        }
        if (!filters.existingGeofenceId) {
            filters.geo_points = this.state.points;
        } else {
            filters.geo_points = null;
        }

        filters.page = 1;

        const { actions } = this.props;
        const params = { ...filters };
        actions.getGeofenceReportRequest(params);
        this.setState({
            filters,
            journeyOrDay: this.state.softJourneyOrDay,
            reportHasBeenFetched: true,
        });
    };

    onPaginationChange = (page, pageSize) => {
        const { actions, dataEventToReportParams } = this.props;
        const { filters } = this.state;
        filters.page = page;
        const params = { ...filters };
        actions.getGeofenceReportRequest(params);
        actions.setDataEventToReportParams({ ...dataEventToReportParams, page });
    };

    onExportCSV = () => {
        if (this.props.isExportingGeofenceReportCsv) {
            return false;
        }
        const { actions } = this.props;
        const { filters, imperialUnit } = this.state;
        filters.page = 1;
        filters.imperial_unit = imperialUnit ? 1 : 0;
        const params = { ...filters };

        actions.getGeofenceReportCsvRequest(params);
    };

    getDivisions = () => {
        const { divisions, actions } = this.props;
        if (divisions.length === 0) {
            actions.getUserDivisionsRequest();
        }
    };

    async toggleDrawGeoFence() {
        const { drawGeoFence } = this.googleMapRef.state;
        this.clearGeoFence();
        this.googleMapRef.toggleDrawGeoFenceControl();
        return this.setState({
            geofenceDrawingEnabled: !drawGeoFence,
            geofenceSaved: false,
            showSaveGeofence: false,
        });
    }

    saveGeoFence() {
        this.setState({
            showSaveGeofence: true,
        });
    }

    createNewTrigger = (values) => {
        const { actions } = this.props;
        const { newGeoFencePath } = this.state;
        actions.createTriggerRequest({
            geoFenceCords: newGeoFencePath[0].coordinates,
            trigger_name: values.trigger_name,
            selection: values.selection,
            divisions: values.divisions,
        });
        this.setState({
            geofenceSaved: true,
            showSaveGeofence: false,
        });
    };

    cancelGeoFence() {
        this.setState({
            showSaveGeofence: false,
        });
    }

    clearGeofenceParams() {
        let { dataEventToReportParams } = this.props;
        let { filters } = this.state;
        if (dataEventToReportParams) {
            dataEventToReportParams.existingGeofenceId = null;
            dataEventToReportParams.geoPoints = null;
        }
        if (filters) {
            filters.existingGeofenceId = null;
            filters.geoPoints = null;
        }

        this.props.actions.setDataEventToReportParams({ ...dataEventToReportParams });

        this.setState({ ...filters });
    }



    onChangeViewType(payload) {
        let { filters } = this.state;
        filters = { ...filters, ...payload };
        this.setState({ filters });
        this.clearGeofenceParams();
    }

    clearGeoFence() {
        const googleMap = this.googleMapRef;
        const { geoFencePolygon, polyLineMarkers } = googleMap.state;
        let tempPolyLineMarkers = polyLineMarkers;
        if (googleMap.state.mapPolyLines) {
            googleMap.state.mapPolyLines.setMap(null);
            tempPolyLineMarkers.forEach((tempPolyLineMarker) => {
                tempPolyLineMarker.setMap(null);
            });
            tempPolyLineMarkers = [];
            const newlyCreatedPolygon = geoFencePolygon.pop();
            if (newlyCreatedPolygon) {
                newlyCreatedPolygon.setMap(null);
            }
        }
        this.setState({
            newGeoFencePath: [],
            points: [],
        });
        this.clearGeofenceParams();
        this.googleMapRef.showGeoFenceOnMap(false);
        googleMap.setState({
            mapPolyLines: null,
            geoFencePolyLines: [],
            geoFenceCords: [],
            geoFencePolygon,
            polyLineMarkers: [],
            drawGeoFence: false,
        });
    }

    geFenceCompleted = (status, points) => {
        if (status == 1) {
            const triggers = [{
                coordinates: points,
                id: 'new',
            }];
            this.googleMapRef.showGeoFenceOnMap(true);
            this.setState({
                newGeoFencePath: triggers,
                points,
                geofenceDrawingEnabled: false,
            });
        }
    };

    predefinedGeoFenceSelected(geofence, clearParams = true) {
        if (clearParams) {
            this.clearGeofenceParams();
        }
        if (!geofence) {
            this.clearGeoFence();
        } else {
            const points = geofence.trigger_points.map((point) => ({ lng: parseFloat(point.lng), lat: parseFloat(point.lat) }));
            const triggers = [{
                coordinates: points,
                id: 'new',
            }];
            this.googleMapRef.showGeoFenceOnMap(true);
            this.googleMapRef.refreshCenter(points[0].lat, points[0].lng);
            this.googleMapRef.refreshCenter(points[0].lat, points[0].lng);
            this.googleMapRef.executeAutoZoom();

            this.googleMapRef.refreshCenter(points[0].lat, points[0].lng);
            this.googleMapRef.executeAutoZoom();

            this.setState({
                newGeoFencePath: triggers,
                points,
            }, () => {
                this.googleMapRef.refreshMap(true);
                this.googleMapRef.executeAutoZoom();
            });
        }
    }

    render() {
        return this.view(
            {
                update: (newVals) => (
                    this.props.actions.UpdateEventNotifications(newVals)
                ),
            },
        );
    }
}

GeofenceReport.propTypes = {
    actions: PropTypes.object.isRequired,
    geofenceReport: PropTypes.array.isRequired,
    geofenceReportPagination: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        geofenceReport: state.report.geofenceReport,
        geofenceReportPagination: state.report.geofenceReportPagination,
        user: state.user,
        filterSidebar: state.filterSidebar,
        dataEventToReportParams: state.global.dataEventToReportParams,
        uomPreference: state.user.profile.unit_of_measurement_preference,
        isExportingGeofenceReportCsv: state.report.isExportingGeofenceReportCsv,
        divisions: state.events.divisions,
        allTriggers: state.device.allTriggers,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
            ...assetActions,
            ...userActions,
            ...deviceActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GeofenceReport);
