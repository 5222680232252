import React, { ReactNode, useState, useEffect, Key } from 'react';
import { APIProvider, Map as ReactGoogleMap, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday'
dayjs.extend(isToday);
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import AssetMarkerContent from './elements/Marker/AssetMarkerContent';
import PathMarkerContent from './elements/Marker/PathMarkerContent';
import EventMarkerContent from './elements/Marker/EventMarkerContent';
import SearchResultMarkerContent from './elements/Marker/SearchResultMarkerContent';
import Marker from './elements/Marker';
import VideoToolbar from './elements/VideoToolbar';
import { timeToSeconds, secondsToTime } from '../../core/utils/dateUtils.js';
import { 
    DeviceTimelineBlockType, DeviceTimelineData, PopupType,
    DeviceTimelineSegment, Location, MarkerType, DeviceStatus, PathMarkerType, 
    PathSegmentData, MarkerData, HeatmapData, Geofence, DVRChannelLink,
} from '../../types';
import { fetchApiAuth } from '../../core/utils/api';
import Spinner from '../Spinner';

import './map.scss';

const { 
    GOOGLE_MAP_API_KEY, DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM,
    ZERO_INDEXED_SECONDS_IN_A_DAY, PADDING_2, PADDING_1, DB_TIME_FORMAT,
} = require('../../core/constants').default;

const MAP_ID: string = '6225007a6dfb173e'; // TODO: move this to .env?
// We use the following refs to track things drawn on the map using setMap(), so we can clean them off when redrawing, etc.
let existingPathRef: google.maps.Polyline | null = null;
let existingTrafficLayerRef: google.maps.TrafficLayer | null = null;
let existingHeatmapRef: google.maps.visualization.HeatmapLayer | null = null;
let existingGeofenceRefs: google.maps.Polygon[] | null = null;
const SIDEBAR_WIDTH = 360; // sidebar width in pixels
const SIDEBAR_COLLAPSED_WIDTH = 60; // sidebar width in pixels when collapsed
const BUTTON_LIST_WIDTH = 320; // button list width in pixels
const SEARCH_BOX_WIDTH = 200; // search box width in pixels
const DEVICE_STATUS_TAG_WIDTH = 70; // device status tag width in pixels

/**
 * Verify the given data constitutes a valid path.
 * @param path array of PathSegmentData objects
 * @param map the map object, provided by useMap() hook
 * @param mapsLib the mapsLib object, provided by the useMapsLibrary("maps") hook
 * @returns boolean
 */
const verifyPathIsValid = (
    path: PathSegmentData[], 
    map: google.maps.Map, 
    mapsLib: google.maps.MapsLibrary,
): boolean => {
    if (path && path.length && map && mapsLib) {
        // Ensure not to render paths with one single (different) segment or less
        //   Since it's possible to have path data consisting of multiple of the same point only (if the vehicle didn't move on the selected date)
        let differentSegments: PathSegmentData[] = [];
        path.forEach(segment => {
            if (differentSegments.length > 1) return;
            if (differentSegments.length === 0) {
                differentSegments.push(segment);
            } else {
                differentSegments.forEach(differentSegment => {
                    if (
                        differentSegment.location.lat !== segment.location.lat || 
                        differentSegment.location.lng !== segment.location.lng
                    ) {
                        differentSegments.push(segment);
                    }
                });
            }
        });
        return differentSegments.length > 1;
    }
    return false;
}

/**
 * Returns a filtered array of PathSegmentData objects based on the provided slider values.
 * @param path array of PathSegmentData objects
 * @param sliderValue the current value of the slider
 * @returns PathSegmentData[] | null
 */
const filterPath = (
    path: PathSegmentData[] | null,
    sliderValue: [number, number, number],
): PathSegmentData[] | null => {
    if (path) {
        return path.filter((segment) => {
            const segmentTime: string = dayjs(segment.datetime).format(DB_TIME_FORMAT);
            const seconds: number = timeToSeconds(segmentTime);
            return seconds >= sliderValue[0] && seconds <= sliderValue[2];
        });
    }
}

/**
 * Returns an array of rendered markers from the given marker and path data.
 * @param markers array of Marker objects
 * @param path array of PathSegmentData objects
 * @param pathIsValid whether path consitutes a valid path, provided by verifyPathIsValid()
 * @param sliderValue the current value of the slider
 * @param showAssetLabels whether to render asset labels for asset markers
 * @param searchBounds the bounds of the view after a successful search
 * @returns ReactNode[]
 */
const renderMarkers = (
    markers: MarkerData[], 
    path: PathSegmentData[] | null, 
    pathIsValid: boolean,
    sliderValue: [number, number, number],
    showAssetLabels: boolean,
    searchBounds: google.maps.LatLngBounds | null,
): ReactNode[] => {
    const markersWithExtra: MarkerData[] = [ ...markers ];
    if (pathIsValid) {
        const sliderFilteredPath: PathSegmentData[] = filterPath(path, sliderValue);
        markersWithExtra.push({
            type: MarkerType.PathStart,
            location: {
                lat: sliderFilteredPath[sliderFilteredPath.length - 1].location.lat,
                lng: sliderFilteredPath[sliderFilteredPath.length - 1].location.lng,
            },
        });
        markersWithExtra.push({
            type: MarkerType.PathEnd,
            location: {
                lat: sliderFilteredPath[0].location.lat,
                lng: sliderFilteredPath[0].location.lng,
            },
        });
        sliderFilteredPath.forEach(segment => {
            if (segment.eventType > 0 && segment.popupData) {
                markersWithExtra.push({
                    type: MarkerType.Event,
                    location: {
                        lat: segment.location.lat,
                        lng: segment.location.lng,
                    },
                    icon: segment.icon,
                    popupData: segment.popupData ? {
                        type: PopupType.Event,
                        speed: segment.popupData.speed,
                        eventName: segment.popupData.eventName,
                        time: segment.popupData.time,
                        driverId: segment.popupData.driverId,
                        driverName: segment.popupData.driverName,
                        location: segment.popupData.location,
                        avlKeys: segment.popupData.avlKeys,
                    } : null,
                });
            }
        });
    }
    if (searchBounds && !searchBounds.isEmpty()) {
        markersWithExtra.push({
            type: MarkerType.SearchResult,
            location: {
                lat: searchBounds.getCenter().lat(),
                lng: searchBounds.getCenter().lng(),
            },
        });
    }
    return markersWithExtra.map((marker) => {
        let markerContent: ReactNode;
        let zIndex: number;
        switch (marker.type) {
            case MarkerType.Asset:
                let status: DeviceStatus = DeviceStatus.Offline;
                if (parseInt(marker.deviceStatus) > 0) {
                    status = DeviceStatus.Online;
                } else {
                    if (marker.cachedAvlInfo) {
                        const parsedAvls = JSON.parse(marker.cachedAvlInfo);
                        if (parsedAvls && typeof parsedAvls === 'object' && parsedAvls['engine_rpm'] !== undefined) {
                            if (marker.speed && parseInt(marker.speed) === 0 && parseInt(parsedAvls['engine_rpm']) > 0) {
                                status = DeviceStatus.Idling;
                            }
                        }
                    }
                }
                markerContent = (
                    <AssetMarkerContent
                        divisionColor={marker.divisionColor}
                        status={status}
                        angle={marker.angle}
                        label={showAssetLabels ? marker.label : ''}
                    />
                );
                zIndex = 1000;
                break;
            case MarkerType.Event:
                markerContent = (<EventMarkerContent icon={marker.icon} />);
                zIndex = 998;
                break;
            case MarkerType.PathStart:
            case MarkerType.PathEnd:
                markerContent = (<PathMarkerContent position={marker.type === MarkerType.PathStart ? PathMarkerType.Start : PathMarkerType.End} />);
                zIndex = 999;
                break;
            case MarkerType.SearchResult:
                markerContent = (<SearchResultMarkerContent />);
                zIndex = 999;
                break;
            default:
                markerContent = null;
                zIndex = 0;
                break;
        }
        if (markerContent) {
            let lat: number = marker.location.lat;
            let lng: number = marker.location.lng;
            if (pathIsValid && marker.type === MarkerType.Asset) {
                let nearestSegment: PathSegmentData | null = null;
                path.forEach(segment => {
                    if (!nearestSegment) {
                        const segmentDatetime = dayjs(segment.datetime);
                        const sliderValueDateTime = dayjs(segment.datetime).startOf('day').add(sliderValue[1], 'seconds');
                        if (segmentDatetime.isBefore(sliderValueDateTime)) {  
                            nearestSegment = segment;
                        }
                    }
                });
                if (nearestSegment) {
                    lat = nearestSegment.location.lat;
                    lng = nearestSegment.location.lng;
                }
            }
            return (
                <Marker
                    location={{ 
                        lat, 
                        lng,
                    }}
                    zIndex={zIndex}
                    markerContent={markerContent}
                    popupData={marker.popupData}
                />
            );
        }
    });
}

/**
 * Draws a Polyline path on the map based on the given path data.
 * @param path array of PathSegmentData objects
 * @param map the map object, provided by useMap() hook
 * @param mapsLib the mapsLib object, provided by the useMapsLibrary("maps") hook
 * @param sliderValue the current value of the slider
 * @returns google.maps.Polyline | null
 */
const renderPath = (
    path: PathSegmentData[], 
    map: google.maps.Map, 
    mapsLib: google.maps.MapsLibrary, 
    sliderValue: [number, number, number], 
    existingPathRef: google.maps.Polyline | null = null,
): google.maps.Polyline | null => {
    const sliderFilteredPath: PathSegmentData[] = filterPath(path, sliderValue);
    if (sliderFilteredPath.length) {
        if (existingPathRef) {
            existingPathRef.setMap(null);
            existingPathRef.setVisible(false);
            
        }
        // We have to use maps lib directly as vis.gl/react-google-maps is yet to implement geometry components, e.g. <Polyline />
        //   Can be replaced if a future update introduces geometry native components
        /* eslint-disable no-new */
        const pathRef = new mapsLib.Polyline({
            path: sliderFilteredPath.map((segment) => ({
                lat: segment.location.lat,
                lng: segment.location.lng,
            })),
            map, // passing this sets it to the map, we don't need to pass it into the component via JSX
            clickable: false,
            draggable: false,
            editable: false,
            geodesic: false, // relative to earth's angle
            strokeColor: "#1890ff",
            strokeOpacity: 1,
            strokeWeight: 5,
        });
        return pathRef;
    }
}

/**
 * Center and zoom the map based on the given markers and path data.
 * @param markers array of Marker objects
 * @param map the map object, provided by useMap() hook
 * @param coreLib the coreLib object, provided by the useMapsLibrary("core") hook
 * @param path array of PathSegmentData objects
 * @param pathIsValid whether path consitutes a valid path, provided by verifyPathIsValid()
 * @returns void
 */
const performAutoZoom = (
    markers: MarkerData[] | null, 
    map: google.maps.Map, 
    coreLib: google.maps.CoreLibrary, 
    path: PathSegmentData[] | null, 
    pathIsValid: boolean = false
): void => {
    let bounds: Location[] | null;
    if (markers && markers.length) {
        if (pathIsValid) {
            bounds = path.map((segment) => {
                return {
                    lat: segment.location.lat, 
                    lng: segment.location.lng,
                };
            });
        } else {
            if (markers.length === 1) {
                bounds = [{
                    lat: markers[0].location.lat,
                    lng: markers[0].location.lng,
                }];
            } else {
                bounds = markers.map((marker) => {
                    return {
                        lat: marker.location.lat, 
                        lng: marker.location.lng,
                    };
                });
            }
        }
    }
    if (!bounds || !bounds.length) {
        bounds = [ DEFAULT_MAP_CENTER ];
    }
    const latLngBounds = new coreLib.LatLngBounds();
    bounds.forEach((bound) => {
        latLngBounds.extend(bound);
    });
    if (!pathIsValid && markers && markers.length === 1) {
        // We must do this because fitBounds doesn't work well with single markers as it zooms in too far
        // This forces the zoom to a certain level
        map.setOptions({ 
            maxZoom: 16, 
            zoom: 16 
        });
        map.fitBounds(latLngBounds);
    } else {
        map.fitBounds(latLngBounds);
    }
}

/**
 * Returns a rendered tooltip for the slider based on the given value and data.
 * @param sliderValue the current value of the slider
 * @param timelineData a valid TimelineData object
 * @returns ReactNode
 */
const formatSliderTooltip = (
    sliderValue: number, 
    timelineData: DeviceTimelineData
): ReactNode => {
    let formattedSliderValue = secondsToTime(sliderValue);
    if (formattedSliderValue.indexOf('T') > -1 || formattedSliderValue.indexOf('+') > -1 || formattedSliderValue.indexOf('-') > -1) {
        formattedSliderValue = formattedSliderValue.slice(11, 19); // will always work as date format is consistent, see constants
    }
    if (timelineData?.timelineSegments && timelineData?.geofenceTimelineSegments) {
        let matches: any[] = [];
        let journeyIndex: number = 0;
        for (let i = 0; i < timelineData.timelineSegments.length; i++) {
            const segment: DeviceTimelineSegment = timelineData.timelineSegments[i];
            const isMoving = segment.status === DeviceTimelineBlockType.Moving;
            if (isMoving) {
                journeyIndex += 1;
            }
            if (sliderValue >= segment.start && sliderValue <= segment.end) {
                matches.push({
                    status: segment.status,
                    location: segment.location,
                    journeyIndex: isMoving ? journeyIndex : null,
                });
            }
        }
        let highestPriority: any = null;
        let idleOverlappingMovingPrefix: any = null;
        for (let j = 0; j < matches.length; j++) {
            if (matches[j].status === DeviceTimelineBlockType.Idle) {
                idleOverlappingMovingPrefix = {
                    location: matches[j].location,
                };
            }
            if (!highestPriority) {
                highestPriority = matches[j];
            } else {
                if (matches[j].status === DeviceTimelineBlockType.Moving) {
                    highestPriority = matches[j];
                } else if (matches[j].status === DeviceTimelineBlockType.Idle && highestPriority.status === DeviceTimelineBlockType.Stopped) {
                    highestPriority = matches[j];
                }
            }
        }
        if (!highestPriority) {
            highestPriority = {
                status: DeviceTimelineBlockType.Stopped,
                location: null,
                journeyIndex: null,
            };
        }
        const statusText: string = highestPriority.status === DeviceTimelineBlockType.Moving 
            ? `Journey ${journeyIndex}` 
            : highestPriority.status === DeviceTimelineBlockType.Idle
                ? "Idle"
                : "Stopped"; 
        let geofences: string = '';
        for (let k = 0; k < timelineData.geofenceTimelineSegments.length; k++) {
            const segment = timelineData.geofenceTimelineSegments[k];
            if (sliderValue >= segment.start && sliderValue <= segment.end) {
                geofences = segment.geofence_names.join(', ');
            }
        }
        return (
            <div style={{ textAlign: 'center' }}>
                <span style={{ whiteSpace: 'nowrap' }}>{statusText}</span>
                <br />
                {formattedSliderValue}
                <br />
                {idleOverlappingMovingPrefix && highestPriority.status === DeviceTimelineBlockType.Moving && (
                    <>
                        <span>Idle</span>
                        <br />
                        <span>{idleOverlappingMovingPrefix.location}</span>
                        <br />
                    </>
                )}
                {geofences !== '' ? (
                    <>
                        <span style={{ whiteSpace: 'nowrap' }}>
                            In Geo-fence{geofences.split(',').length > 1 ? 's' : ''}:
                            <br />
                            {geofences}
                        </span>
                    </>
                ) : highestPriority.status !== DeviceTimelineBlockType.Moving && highestPriority.location ? (
                    <>
                        <span>{highestPriority.location}</span>
                    </>
                ) : null}
            </div>
        );
    } else {
        return (
            <div>{secondsToTime(sliderValue)}</div>
        );
    }
}

/**
 * Undraws any existing geofences on the map using global exisitingGeofenceRefs and sets existingGeofenceRefs to null.
 * Since exisitingGeofenceRefs is an array rather than a single value so we have to ensure we do proper cleanup to avoid mem leaks.
 */
const undrawExistingGeofences = () => {
    if (existingGeofenceRefs?.length) {
        existingGeofenceRefs.forEach(polygon => {
            polygon.setPaths([]);
            polygon.setMap(null);
        });
    }
    existingGeofenceRefs = null;
}

/**
 * Starts video playback and sets an interval to tick the slider up by 1 every second which will also move the marker(s).
 * Since we fetch a video at a specific time but the slider can be moved at any point after that, we always reset the slider 
 * to the start time that was fetched from the video data and then play the video.
 */
const restartPlayback = () => {
    // console.log(`(re)started playback from ${}`);
    // set slider to start time that was requested
    // setInterval(() => {
    // send play signal to video players
}

const resumePlayback = () => {
    console.log('resume playback');
    // setInterval
}

const pausePlayback = () => {
    console.log('pause playback');
    // clearInterval
}

const stopPlayback = () => {
    console.log('stop playback');
    // clearInterval
}

interface MapProps {
    markers?: MarkerData[];
    path?: PathSegmentData[];
    isVisibleSlider?: boolean;
    timelineData?: DeviceTimelineData | null;
    selectedDvrIsOnline?: boolean;
    onChangeSliderValue?: (sliderValue: [number, number, number]) => void;
    selectedDate?: string | null;
    selectedAssetId?: number | null;
    isVisibleSearchSidebar?: boolean;
    eventTypes?: { id: number, key: string }[];
    parentIsLoading?: boolean;
    sliderTextValue: string;
    videoSearchRequestedStartSeconds: number | null;
    channelLinks?: DVRChannelLink[] | null;
}

const Map: React.FC<MapProps> = ({ 
    markers = [],
    path = [],
    isVisibleSlider = false,
    timelineData = null,
    selectedDvrIsOnline = false,
    onChangeSliderValue = (sliderValue) => {},
    selectedDate = null,
    selectedAssetId = null,
    isVisibleSearchSidebar = true,
    eventTypes = [],
    parentIsLoading = false,
    sliderTextValue = '12:00:00',
    videoSearchRequestedStartSeconds = null,
    channelLinks = null,
}) => {
    const map: google.maps.Map = useMap(); //  provides a reference to the map that we can interact with
    const mapsLib: google.maps.MapsLibrary = useMapsLibrary("maps"); // provides a reference to the maps sub-lib
    const coreLib: google.maps.CoreLibrary = useMapsLibrary("core"); // provides a reference to the core sub-lib
    const placesLib: google.maps.PlacesLibrary = useMapsLibrary('places'); // provides a reference to the places sub-lib
    const visualizationLib: google.maps.VisualizationLibrary = useMapsLibrary('visualization'); // provides a reference to the visualization sub-lib
    const user = useSelector((state: any) => state.user);
    let initialMiddleSliderValue = Math.round(ZERO_INDEXED_SECONDS_IN_A_DAY / 2);
    if (selectedDate && dayjs(selectedDate).isToday()) {
        initialMiddleSliderValue = timeToSeconds(dayjs(selectedDate).format(DB_TIME_FORMAT));
    }
    const [ sliderValue, setSliderValue ] = useState<[number, number, number]>([0, initialMiddleSliderValue, ZERO_INDEXED_SECONDS_IN_A_DAY]);
    const [ internalSliderTextValue, setInternalSliderTextValue ] = useState<string>(secondsToTime(initialMiddleSliderValue, true)); // used to keep text input separate from sliderValue until a valid input is detected
    const [ showAssetLabels, setShowAssetLabels ] = React.useState<boolean>(user.profile.show_info_preference > 0);
    const [ showTrafficOverlay, setShowTrafficOverlay ] = React.useState<boolean>(false);
    const [ selectedHeatmapKeys, setSelectedHeatmapKeys ] = React.useState<Key[]>([]);
    const [ heatmapEvents, setHeatmapEvents ] = React.useState<HeatmapData[]>([]);
    const [ isLoadingHeatmaps, setIsLoadingHeatmaps ] = React.useState<boolean>(false);
    const [ showGeofences, setShowGeofences ] = React.useState<boolean>(false);
    const [ isLoadingGeofences, setIsLoadingGeofences ] = React.useState<boolean>(false);
    const [ geofences, setGeofences ] = React.useState<Geofence[]>([]);
    const [ searchBounds, setSearchBounds ] = React.useState<google.maps.LatLngBounds | null>(null);
    const [ showMapSearch, setShowMapSearch ] = React.useState<boolean>(false);
    const [ internalIsVisibleSearchSidebar, setInternalIsVisibleSearchSidebar ] = React.useState<boolean>(isVisibleSearchSidebar);
    
    const pathIsValid = verifyPathIsValid(path, map, mapsLib);
    const renderedMarkers = renderMarkers(markers, path, pathIsValid, sliderValue, showAssetLabels, searchBounds);
    if (pathIsValid) {
        const newPathRef = renderPath(path, map, mapsLib, sliderValue, existingPathRef);
        if (newPathRef) {
            existingPathRef = newPathRef;
        }
    }
    // Timeline is drawn using static width and scale values so we calculate a static width and scale here
    let sliderWidth = window.innerWidth - BUTTON_LIST_WIDTH - (PADDING_2 * 3) - DEVICE_STATUS_TAG_WIDTH; // base elements with standard padding between
    if (showMapSearch) { // then add the search box width and another small padding
        sliderWidth -= (SEARCH_BOX_WIDTH + PADDING_1);
    }
    if (internalIsVisibleSearchSidebar) { // then account for the sidebar and allow a normal padding, we use state to trigger re-render on sidebar open/close
        sliderWidth -= (SIDEBAR_WIDTH + PADDING_2);
    } else { // then account for the collapsed sidebar and allow a small padding
        sliderWidth -= (SIDEBAR_COLLAPSED_WIDTH + PADDING_1);
    }
    const sliderScaleX = sliderWidth / ZERO_INDEXED_SECONDS_IN_A_DAY; // scale allows us to scale down 86400 possible points inside sliderWidth pixels
    const isLoading = isLoadingHeatmaps || isLoadingGeofences;

    useEffect(() => {
        let newSliderTextValue = sliderTextValue;
        if (newSliderTextValue.indexOf('T') > -1 || newSliderTextValue.indexOf('+') > -1 || newSliderTextValue.indexOf('-') > -1) {
            newSliderTextValue = newSliderTextValue.slice(11, 19); // will always work as date format is consistent, see constants
        }
        if (newSliderTextValue?.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/)) {
            const newSliderValue: [number, number, number] = [sliderValue[0], timeToSeconds(newSliderTextValue), sliderValue[2]];
            setSliderValue(newSliderValue);
            if (onChangeSliderValue) {
                onChangeSliderValue(newSliderValue);
            }
        }
    }, [sliderTextValue]);
    useEffect(() => {
        setInternalIsVisibleSearchSidebar(isVisibleSearchSidebar);
    }, [isVisibleSearchSidebar])
    useEffect(() => {
        if (!parentIsLoading) {
            if (coreLib && map) {
                performAutoZoom(markers, map, coreLib, path, pathIsValid);
            }
            if (placesLib && map) {
                const inputEl = document.getElementById('map-search-box') as HTMLInputElement;
                if (inputEl) {
                    const searchBox = new google.maps.places.SearchBox(inputEl);
                    searchBox.addListener('places_changed', () => {
                        const places = searchBox.getPlaces();
                        if (places.length === 0) {
                            return;
                        }
                        const bounds = new google.maps.LatLngBounds();
                        places.forEach((place) => {
                            if (!place.geometry) {
                                return;
                            }
                            if (place.geometry.viewport) {
                                bounds.union(place.geometry.viewport);
                            } else {
                                bounds.extend(place.geometry.location);
                            }
                        });
                        map.fitBounds(bounds);
                        setSearchBounds(bounds);
                    });
                }
            }
        }
    }, [parentIsLoading, coreLib, placesLib]);
    useEffect(() => {
        if (!existingHeatmapRef && visualizationLib && map) {
            existingHeatmapRef = new google.maps.visualization.HeatmapLayer({
                radius: 25,
                opacity: 0.8,
            });
        }
    }, [visualizationLib, map]);
    useEffect(() => {
        if (selectedHeatmapKeys?.length) {
            setIsLoadingHeatmaps(true);
            const eventKeys = selectedHeatmapKeys.filter(key => key !== 'all');
            fetchApiAuth({
                method: 'GET',
                url: '/device/heat-map',
                params: {
                    date: selectedDate.split(' ')[0],
                    eventTypes: eventKeys,
                    assets: [ selectedAssetId ],
                },
            })
                .then((res) => {
                    setIsLoadingHeatmaps(false);
                    if (res?.data) {
                        setHeatmapEvents(res.data);
                    }
                })
                .catch(() => {
                    setIsLoadingHeatmaps(false);
                    notification.error({
                        message: 'Error',
                        description: 'Failed to fetch heatmap data',
                    });
                });
        } else {
            setHeatmapEvents([]);
        }
    }, [selectedHeatmapKeys]);
    useEffect(() => {
        if (existingHeatmapRef) {
            if (heatmapEvents?.length && mapsLib && map) {
                existingHeatmapRef.setData(heatmapEvents.map(heatmapEvent => {
                    return {
                        location: new google.maps.LatLng(heatmapEvent.latitude, heatmapEvent.longitude),
                        weight: heatmapEvent.weight,
                    }
                }));
                existingHeatmapRef.setMap(map);
            } else {
                existingHeatmapRef.setData([]);
                existingHeatmapRef.setMap(null);
            }
        }
    }, [heatmapEvents]);
    useEffect(() => {
        if (showGeofences) {
            setIsLoadingGeofences(true);
            fetchApiAuth({
                method: 'GET',
                url: '/get-all-triggers',
            })
                .then((res) => {
                    setIsLoadingGeofences(false);
                    if (res?.data) {
                        setGeofences(res.data);
                    }
                })
                .catch(() => {
                    setIsLoadingGeofences(false);
                    undrawExistingGeofences();
                    setGeofences([]);
                    notification.error({
                        message: 'Error',
                        description: 'Failed to fetch geo-fence data',
                    });
                });
        } else {
            undrawExistingGeofences();
            setGeofences([]);
        }
    }, [showGeofences]);
    useEffect(() => {
        if (geofences?.length && mapsLib && map) {
            undrawExistingGeofences();
            existingGeofenceRefs = [];
            geofences.forEach(geofence => {
                existingGeofenceRefs.push(new google.maps.Polygon({
                    paths: geofence.trigger_points.map(point => {
                        return new google.maps.LatLng(parseFloat(point.lat), parseFloat(point.lng));
                    }),
                    strokeColor: '#343a3f', // var(--cool-gray-80) doesn't work here but should match
                    strokeWeight: 1.5,
                    fillColor: '#343a3f88',
                    map,
                }));
            })
        }
    }, [geofences]);
    useEffect(() => {
        let newSliderTextValue = internalSliderTextValue;
        if (newSliderTextValue.indexOf('T') > -1 || newSliderTextValue.indexOf('+') > -1 || newSliderTextValue.indexOf('-') > -1) {
            newSliderTextValue = newSliderTextValue.slice(11, 19); // will always work as date format is consistent, see constants
        }
        if (
            newSliderTextValue?.length && 
            newSliderTextValue?.length === 8 && 
            newSliderTextValue?.match(/[0-9][0-9]:[0-9][0-9]:[0-9][0-9]/g)?.length
        ) {
            const newSliderValue: [number, number, number] = [sliderValue[0], timeToSeconds(newSliderTextValue), sliderValue[2]];
            setSliderValue(newSliderValue);
            if (onChangeSliderValue) onChangeSliderValue(newSliderValue)

        }
    }, [internalSliderTextValue]);
    useEffect(() => {
        if (mapsLib && map) {
            if (existingTrafficLayerRef) {
                existingTrafficLayerRef.setMap(null);
                existingTrafficLayerRef = null;
            }
            if (showTrafficOverlay) {
                const trafficLayer = new mapsLib.TrafficLayer();
                trafficLayer.setMap(map);
                existingTrafficLayerRef = trafficLayer;
            }
        }
    }, [showTrafficOverlay]);
    useEffect(() => () => {
        existingPathRef?.setMap(null);
        existingPathRef = null;
        existingTrafficLayerRef?.setMap(null);
        existingTrafficLayerRef = null;
        existingHeatmapRef?.setMap(null);
        existingHeatmapRef = null;
        if (existingGeofenceRefs?.length) {
            existingGeofenceRefs.forEach(polygon => {
                polygon.setPaths([]);
                polygon.setMap(null);
            });
        }
        existingGeofenceRefs = null;
    }, []);

    console.log('----- RENDER MAP -----') // keep for debug til map is done
    return (
        <>
            <div style={{ display: isLoading ? 'initial' : 'none' }}>
                <Spinner loading={isLoading}>
                    <div />
                </Spinner>
            </div>
            <div style={{ display: isLoading ? 'none' : 'initial' }}>
                <VideoToolbar
                    toggleAssetLabels={() => setShowAssetLabels(!showAssetLabels) }
                    toggleTrafficOverlay={() => setShowTrafficOverlay(!showTrafficOverlay)}
                    setSliderTextValue={setInternalSliderTextValue}
                    selectedDvrIsOnline={selectedDvrIsOnline}
                    setSelectedHeatmapKeys={setSelectedHeatmapKeys}
                    selectedHeatmapKeys={selectedHeatmapKeys}
                    eventTypes={eventTypes}
                    toggleGeofences={() => setShowGeofences(!showGeofences)}
                    toggleMapSearch={() => setShowMapSearch(!showMapSearch)}
                    sliderScaleX={sliderScaleX}
                    sliderWidth={sliderWidth}
                    timelineData={timelineData}
                    isVisibleSlider={isVisibleSlider}
                    sliderValue={sliderValue}
                    setSliderValue={setSliderValue}
                    formatSliderTooltip={formatSliderTooltip}
                    startVideoPlayback={restartPlayback}
                    pauseVideoPlayback={pausePlayback}
                    resumeVideoPlayback={resumePlayback}
                    stopVideoPlayback={stopPlayback}
                    videoSearchRequestedStartSeconds={videoSearchRequestedStartSeconds}
                    channelLinks={channelLinks}
                />
                <div id='inner-map-container'>
                    <ReactGoogleMap
                        mapId={MAP_ID} // used with reuseMaps for caching
                        reuseMaps // cache map where possible to improve perf
                        defaultCenter={DEFAULT_MAP_CENTER} // defined in case coreLib is undefined for some reason
                        defaultZoom={DEFAULT_MAP_ZOOM} // defined in case coreLib is undefined for some reason
                        gestureHandling='greedy' // enables zoom via mouse wheel
                        disableDoubleClickZoom
                    >
                        {renderedMarkers}
                    </ReactGoogleMap>
                </div>
            </div>
        </>
    );
};

const MemoizedMap = React.memo(Map);

interface MapWithApiProps extends MapProps {
    containerStyle?: React.CSSProperties;
}

/**
 * Renders a Google Map with optional data: markers, path, search, geofences, heatmaps. 
 * Can also be rendered empty. Needs height to render, provide via containerStyle.
 */
const MapWithApi: React.FC<MapWithApiProps> = ({ 
    markers = [],
    path = [],
    isVisibleSlider = false,
    timelineData = null,
    containerStyle = {},
    selectedDvrIsOnline = false,
    onChangeSliderValue = (sliderValue) => {},
    selectedDate = null,
    selectedAssetId = null,
    isVisibleSearchSidebar = true,
    eventTypes = [],
    parentIsLoading = false,
    sliderTextValue = '12:00:00',
    videoSearchRequestedStartSeconds = null,
    channelLinks = [],
}) => {
    // Has to be wrapped with the API so we do it once in a single file instead of having to do it each time we instantiate the map
    return (
        <div 
            id='map-container'
            style={{
                ...containerStyle,
                paddingTop: isVisibleSlider ? '8px' : '16px',
                paddingBottom: '96px',
            }}
        >
            <APIProvider
                apiKey={GOOGLE_MAP_API_KEY}
                libraries={['marker', "maps"]}
            >
                <MemoizedMap
                    markers={markers}
                    path={path}
                    isVisibleSlider={isVisibleSlider}
                    timelineData={timelineData}
                    selectedDvrIsOnline={selectedDvrIsOnline}
                    onChangeSliderValue={onChangeSliderValue}
                    selectedDate={selectedDate}
                    selectedAssetId={selectedAssetId}
                    isVisibleSearchSidebar={isVisibleSearchSidebar}
                    eventTypes={eventTypes}
                    parentIsLoading={parentIsLoading}
                    sliderTextValue={sliderTextValue}
                    videoSearchRequestedStartSeconds={videoSearchRequestedStartSeconds}
                    channelLinks={channelLinks}
                />
            </APIProvider>
        </div>
    );
}

export default React.memo(MapWithApi);

// TODO: rename parent dir after removing old map