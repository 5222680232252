/* global window */
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import {
    Modal,
} from 'antd';
import { Responsive, WidthProvider } from 'react-grid-layout';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import BarChart from '../../../components/SmartWidget/ChartComponents/BarChart';
import CustomActiveShapePieChart from '../../../components/SmartWidget/ChartComponents/CustomActiveShapePieChart';
import LineChart from '../../../components/SmartWidget/ChartComponents/LineChart';
import StackedAreaChart from '../../../components/SmartWidget/ChartComponents/StackedAreaChart';
import ScatterAndLineOfBestFit from '../../../components/SmartWidget/ChartComponents/ScatterAndLineOfBestFit';
import SimpleScatterChart from '../../../components/SmartWidget/ChartComponents/SimpleScatterChart';
import PieChartWithCustomizedLabel from '../../../components/SmartWidget/ChartComponents/PieChartWithCustomizedLabel';
import IconChart from '../../../components/SmartWidget/ChartComponents/IconChart';
import BestDriverChart from '../../../components/SmartWidget/ChartComponents/BestDriverChart';
import PieChart from '../../../components/SmartWidget/ChartComponents/PieChart';
import MainIconWithSecondaryIconsChart from '../../../components/SmartWidget/ChartComponents/MainIconWithSecondaryIconsChart';
import MultipleIconsChart from '../../../components/SmartWidget/ChartComponents/MultipleIconsChart';
import VorChart from '../../../components/SmartWidget/ChartComponents/VorChart';
import IconWithValues from '../../../components/SmartWidget/ChartComponents/IconWithValues';

import './smartWidgetItem.scss';
import Icon from '../../../components/elements/Icon';

export default function () {
    const {
        smartWidget, data, division, driver, asset, chartKey,
    } = this.props;

    const { visible, meters } = this.state;
    const currentTimeframe = this.state.timeframe;
    const assetDivisionOptions = [...division.divisionOptionsStrict];
    const userDivisionOptions = [...division.userDivisions];
    const assetOptions = [...asset.options];
    const driverOptions = [...driver.driverListStrict];

    let loading = false;
    if (data.id && smartWidget.loadingWidgets && smartWidget.loadingWidgets.includes(data.id)) {
        loading = true;
    }

    let chartHeight = 1;
    if (data.h > 1) {
        chartHeight = 2;
    }

    const meterList = meters.map((v) => (
        <p>
            <Link to={v.url} onClick={() => this.selectMeter(v)}>
                {v.title}
            </Link>
        </p>
    ));

    return (
        <Spinner 
            loading={loading}
            minHeight={200}
            size={20}
        >
            <Modal
                width="90%"
                title="Select Meter"
                footer={null}
                open={visible}
                style={{ top: 20 }}
                onOk={() => this.setState({ visible: false })}
                onCancel={() => this.setState({ visible: false })}
                getContainer={`#modalContent_${chartKey}`}>
                <p>Please select which meter you would like to see.</p>

                { meterList }
            </Modal>
            {data.chart_type_id === 1 ? (
                <BarChart
                    chartKey={chartKey}
                    dotOnClick={(timeframe, index) => { this.goToDot(timeframe, index, data.start); }}
                    heightRatio={chartHeight}
                    timeframe={currentTimeframe}
                    onTimeframeChange={(timeframe) => this.changeTimeframe(timeframe)}
                    rightMenuOnClick={(rightMenu) => this.actionRightMenu(rightMenu)}
                    rightMenuItems={['edit widget', 'delete', 'see data in table']}
                    tooltipText={data.title}
                    data={data.chartData}
                    defaultFirstOptionValue={data.datatype_a}
                    defaultSecondOptionValue={data.datatype_b}
                    defaultThirdOptionValue={data.datatype_c}
                    defaultFourOptionValue={data.datatype_d}
                    onAllOptionChange={(optionsData) => { this.saveDatatypeValues(optionsData, data.chart_type_id); }}
                    divisions={assetDivisionOptions}
                    userDivisions={userDivisionOptions}
                    drivers={driverOptions}
                    assets={assetOptions} />
            )
                : null}
            {data.chart_type_id === 2 ? (
                <LineChart
                    chartKey={chartKey}
                    dotOnClick={(timeframe, index) => { this.goToDot(timeframe, index, data.start); }}
                    heightRatio={chartHeight}
                    timeframe={currentTimeframe}
                    onTimeframeChange={(timeframe) => this.changeTimeframe(timeframe)}
                    rightMenuOnClick={(rightMenu) => this.actionRightMenu(rightMenu)}
                    rightMenuItems={['edit widget', 'delete', 'see data in table']}
                    tooltipText={data.title}
                    data={data.chartData}
                    defaultFirstOptionValue={data.datatype_a}
                    defaultSecondOptionValue={data.datatype_b}
                    defaultThirdOptionValue={data.datatype_c}
                    defaultFourOptionValue={data.datatype_d}
                    onAllOptionChange={(optionsData) => { this.saveDatatypeValues(optionsData, data.chart_type_id); }}
                    divisions={assetDivisionOptions}
                    userDivisions={userDivisionOptions}
                    drivers={driverOptions}
                    assets={assetOptions} />
            )
                : null}
            {data.chart_type_id === 6 ? (
                <StackedAreaChart
                    chartKey={chartKey}
                    dotOnClick={(timeframe, index) => { this.goToDot(timeframe, index, data.start); }}
                    heightRatio={chartHeight}
                    timeframe={currentTimeframe}
                    onTimeframeChange={(timeframe) => this.changeTimeframe(timeframe)}
                    rightMenuOnClick={(rightMenu) => this.actionRightMenu(rightMenu)}
                    rightMenuItems={['edit widget', 'delete', 'see data in table']}
                    tooltipText={data.title}
                    data={data.chartData}
                    defaultFirstOptionValue={data.datatype_a}
                    defaultSecondOptionValue={data.datatype_b}
                    defaultThirdOptionValue={data.datatype_c}
                    defaultFourOptionValue={data.datatype_d}
                    onAllOptionChange={(optionsData) => { this.saveDatatypeValues(optionsData, data.chart_type_id); }}
                    divisions={assetDivisionOptions}
                    userDivisions={userDivisionOptions}
                    drivers={driverOptions}
                    assets={assetOptions} />
            )
                : null}
            {data.chart_type_id === 10 ? (
                <IconChart
                    chartKey={chartKey}
                    heightRatio={chartHeight}
                    timeframe={currentTimeframe}
                    onTimeframeChange={(timeframe) => this.changeTimeframe(timeframe)}
                    rightMenuOnClick={(rightMenu) => this.actionRightMenu(rightMenu)}
                    rightMenuItems={['edit widget', 'delete', 'see data in table']}
                    tooltipText={data.title}
                    data={data.chartData}
                    defaultFirstOptionValue={data.datatype_a}
                    defaultSecondOptionValue={data.datatype_b}
                    defaultThirdOptionValue={data.datatype_c}
                    defaultFourOptionValue={data.datatype_d}
                    onAllOptionChange={(optionsData) => { this.saveDatatypeValues(optionsData, data.chart_type_id); }}
                    divisions={assetDivisionOptions}
                    userDivisions={userDivisionOptions}
                    drivers={driverOptions}
                    assets={assetOptions} />
            )
                : null}
            {data.chart_type_id === 5 ? (
                <MultipleIconsChart
                    chartKey={chartKey}
                    dotOnClick={(timeframe, index) => { this.goToInputType(timeframe, index, data.start); }}
                    heightRatio={chartHeight}
                    timeframe={currentTimeframe}
                    onTimeframeChange={(timeframe) => this.changeTimeframe(timeframe)}
                    rightMenuOnClick={(rightMenu) => this.actionRightMenu(rightMenu)}
                    rightMenuItems={['edit widget', 'delete', 'see data in table']}
                    tooltipText={data.title}
                    data={data.chartData}
                    defaultFirstOptionValue={data.datatype_a}
                    defaultSecondOptionValue={data.datatype_b}
                    defaultThirdOptionValue={data.datatype_c}
                    defaultFourOptionValue={data.datatype_d}
                    onAllOptionChange={(optionsData) => { this.saveDatatypeValues(optionsData, data.chart_type_id); }}
                    divisions={assetDivisionOptions}
                    userDivisions={userDivisionOptions}
                    drivers={driverOptions}
                    assets={assetOptions} />
            )
                : null}
            {data.chart_type_id === 7 ? (
                <IconWithValues
                    chartKey={chartKey}
                    dotOnClick={(timeframe, index) => { this.goToInputType(timeframe, index, data.start); }}
                    heightRatio={chartHeight}
                    timeframe={currentTimeframe}
                    onTimeframeChange={(timeframe) => this.changeTimeframe(timeframe)}
                    rightMenuOnClick={(rightMenu) => this.actionRightMenu(rightMenu)}
                    rightMenuItems={['edit widget', 'delete', 'see data in table']}
                    tooltipText={data.title}
                    data={data.chartData}
                    defaultFirstOptionValue={data.datatype_a}
                    defaultSecondOptionValue={data.datatype_b}
                    defaultThirdOptionValue={data.datatype_c}
                    defaultFourOptionValue={data.datatype_d}
                    onAllOptionChange={(optionsData) => { this.saveDatatypeValues(optionsData, data.chart_type_id); }}
                    divisions={assetDivisionOptions}
                    userDivisions={userDivisionOptions}
                    drivers={driverOptions}
                    assets={assetOptions} />
            )
                : null}
            {data.chart_type_id === 12 ? (
                <SimpleScatterChart
                    chartKey={chartKey}
                    dotOnClick={(timeframe, index) => { this.goToDot(timeframe, index, data.start); }}
                    heightRatio={chartHeight}
                    timeframe={currentTimeframe}
                    onTimeframeChange={(timeframe) => this.changeTimeframe(timeframe)}
                    rightMenuOnClick={(rightMenu) => this.actionRightMenu(rightMenu)}
                    rightMenuItems={['edit widget', 'delete', 'see data in table']}
                    tooltipText={data.title}
                    data={data.chartData}
                    defaultFirstOptionValue={data.datatype_a}
                    defaultSecondOptionValue={data.datatype_b}
                    defaultThirdOptionValue={data.datatype_c}
                    defaultFourOptionValue={data.datatype_d}
                    onAllOptionChange={(optionsData) => { this.saveDatatypeValues(optionsData, data.chart_type_id); }}
                    divisions={assetDivisionOptions}
                    userDivisions={userDivisionOptions}
                    drivers={driverOptions}
                    assets={assetOptions} />
            )
                : null}
            {data.chart_type_id === 13 ? (
                <CustomActiveShapePieChart
                    chartKey={chartKey}
                    dotOnClick={(timeframe, index) => { this.goToInputType(timeframe, index, data.start); }}
                    heightRatio={chartHeight}
                    timeframe={currentTimeframe}
                    onTimeframeChange={(timeframe) => this.changeTimeframe(timeframe)}
                    rightMenuOnClick={(rightMenu) => this.actionRightMenu(rightMenu)}
                    rightMenuItems={['edit widget', 'delete', 'see data in table']}
                    tooltipText={data.title}
                    data={data.chartData}
                    defaultFirstOptionValue={data.datatype_a}
                    defaultSecondOptionValue={data.datatype_b}
                    defaultThirdOptionValue={data.datatype_c}
                    defaultFourOptionValue={data.datatype_d}
                    onAllOptionChange={(optionsData) => { this.saveDatatypeValues(optionsData, data.chart_type_id); }}
                    divisions={assetDivisionOptions}
                    userDivisions={userDivisionOptions}
                    drivers={driverOptions}
                    assets={assetOptions} />
            )
                : null}
            {data.chart_type_id === 8 ? (
                <PieChartWithCustomizedLabel
                    chartKey={chartKey}
                    dotOnClick={(timeframe, index) => { this.goToInputType(timeframe, index, data.start); }}
                    heightRatio={chartHeight}
                    timeframe={currentTimeframe}
                    onTimeframeChange={(timeframe) => this.changeTimeframe(timeframe)}
                    rightMenuOnClick={(rightMenu) => this.actionRightMenu(rightMenu)}
                    rightMenuItems={['edit widget', 'delete', 'see data in table']}
                    tooltipText={data.title}
                    data={data.chartData}
                    defaultFirstOptionValue={data.datatype_a}
                    defaultSecondOptionValue={data.datatype_b}
                    defaultThirdOptionValue={data.datatype_c}
                    defaultFourOptionValue={data.datatype_d}
                    onAllOptionChange={(optionsData) => { this.saveDatatypeValues(optionsData, data.chart_type_id); }}
                    divisions={assetDivisionOptions}
                    userDivisions={userDivisionOptions}
                    drivers={driverOptions}
                    assets={assetOptions} />
            )
                : null}
            {data.chart_type_id === 11 ? (
                <VorChart
                    chartKey={chartKey}
                    heightRatio={chartHeight}
                    timeframe={currentTimeframe}
                    onTimeframeChange={(timeframe) => this.changeVORTimeframe(timeframe)}
                    rightMenuOnClick={(rightMenu) => this.actionRightMenu(rightMenu)}
                    rightMenuItems={['edit widget', 'delete', 'see data in table']}
                    tooltipText={data.title}
                    data={data.chartData}
                    defaultFirstOptionValue={data.datatype_a}
                    defaultSecondOptionValue={data.datatype_b}
                    defaultThirdOptionValue={data.datatype_c}
                    defaultFourOptionValue={data.datatype_d}
                    onAllOptionChange={(optionsData) => { this.saveDatatypeValues(optionsData, data.chart_type_id); }}
                    divisions={assetDivisionOptions}
                    userDivisions={userDivisionOptions}
                    drivers={driverOptions}
                    assets={assetOptions} />
            )
                : null}
            {data.chart_type_id === 14 ? (
                <BestDriverChart
                    chartKey={chartKey}
                    heightRatio={chartHeight}
                    timeframe={currentTimeframe}
                    onTimeframeChange={(timeframe) => this.changeTimeframe(timeframe)}
                    rightMenuOnClick={(rightMenu) => this.actionRightMenu(rightMenu)}
                    rightMenuItems={['edit widget', 'delete', 'see data in table']}
                    tooltipText={data.title}
                    data={data.chartData}
                    defaultFirstOptionValue={data.datatype_a}
                    defaultSecondOptionValue={data.datatype_b}
                    defaultThirdOptionValue={data.datatype_c}
                    defaultFourOptionValue={data.datatype_d}
                    onAllOptionChange={(optionsData) => { this.saveBestDriverDatatypeValues(optionsData); }}
                    userDivisions={userDivisionOptions}
                    drivers={driverOptions}
                    assets={assetOptions} />
            )
                : null}
            {data.chart_type_id === 4 ? (
                <PieChart
                    chartKey={chartKey}
                    dotOnClick={(timeframe, index) => { this.goToInputType(timeframe, index, data.start); }}
                    heightRatio={chartHeight}
                    timeframe={currentTimeframe}
                    onTimeframeChange={(timeframe) => this.changeTimeframe(timeframe)}
                    rightMenuOnClick={(rightMenu) => this.actionRightMenu(rightMenu)}
                    rightMenuItems={['edit widget', 'delete', 'see data in table']}
                    tooltipText={data.title}
                    data={data.chartData}
                    defaultFirstOptionValue={data.datatype_a}
                    defaultSecondOptionValue={data.datatype_b}
                    defaultThirdOptionValue={data.datatype_c}
                    defaultFourOptionValue={data.datatype_d}
                    onAllOptionChange={(optionsData) => { this.saveDatatypeValues(optionsData, data.chart_type_id); }}
                    divisions={assetDivisionOptions}
                    userDivisions={userDivisionOptions}
                    drivers={driverOptions}
                    assets={assetOptions} />
            )
                : null}
        </Spinner>
    );
}
