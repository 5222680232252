/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { connect } from 'react-redux';
import { notification } from 'antd';
import * as videoActions from '../../core/video/videoActions';
import * as eventsActions from '../../core/events/eventsActions';
import * as assetActions from '../../core/asset/assetActions';
import * as driverActions from '../../core/driver/driverActions';
import * as globalActions from '../../core/global/globalActions';
import * as deviceActions from '../../core/device/deviceActions';
import history from '../../core/utils/history';
import DOM from './video';
import moment from 'moment';
import { fetchApiAuth } from '../../core/utils/api';
import { timeToSeconds, secondsToTime } from '../../core/utils/dateUtils';

const { DB_DATETIME_FORMAT, DB_DATE_FORMAT, DB_TIME_FORMAT } = require('../../core/constants').default;

class Video extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.googleMapAccess = React.createRef();
        this.loadingDvrsRequest = null;

        this.state = {
            selectedTabKey: 'video-search',
            locationSearch: false,
            mapOpen: false,
            isVisibleSearchSidebar: true,
            isFetchingSearchedVideo: false,
            videoSearchData: null,
            videoSearchChannelLinks: null,
            selectedDate: null,
            latitude: null,
            longitude: null,
            geometry: null,
            openSidebar: true,
            showFilterSidebar: true,
            isVisibleVideoTiles: false,
            isFetchingVideoStreams: false,
            isFetchingCacheQueueStatus: false,
            eventTypes: null,
            videoSearchSliderTextValue: '12:00:00',
            videoSearchRequestedStartSeconds: null,
            channelList: null,
        };
        this.onFilterCallback = () => null;
        this.onRequestFilterCallback = () => null;
        this.onLocationSearchCallback = () => null;
    }

    setVideoSearchSliderTextValue = (videoSearchSliderTextValue) => {
        this.setState({ videoSearchSliderTextValue });
    }

    checkDvrStatus() {
        if (!this.loadingDvrsRequest) {
            const opts = {
                method: 'POST',
                url: '/video/get-dvr-online-status',
            };
            this.loadingDvrsRequest = fetchApiAuth(opts);
        }
        return this.loadingDvrsRequest;
    }

    componentWillUnmount() {
        this.loadingDvrsRequest = null;
    }

    async componentDidMount() {
        const {
            actions, match,
        } = this.props;
        const { params } = match;
        const selectedTabKey = params?.selectedTab;
        if (selectedTabKey) {
            this.setState({ selectedTabKey });

        }
        await this.checkDvrStatus();
        actions.getAssetOptionsRequest();
        actions.getDriverListRequest();
        actions.getDivisionListRequest();
        actions.getVehicleTypesRequest();
        actions.setRetainFilter(false);
    }

    componentDidUpdate() {
        window.onpopstate = (e) => {
            const path = window.location.pathname;

            if (path.includes('video-search')) {
                this.setState({ selectedTabKey: 'video-search' });
            } else if (path.includes('cached-videos')) {
                this.setState({ selectedTabKey: 'cached-videos' });
            } else if (path.includes('live-videos')) {
                this.setState({ selectedTabKey: 'live-videos' });
            }

            const { actions } = this.props;

            actions.updateVideoCameraSelection([]);
            actions.toggleVideoSelectionScreen(true);
        };
    }

    changeTab(selectedTabKey, isVisibleSearchSidebar = null) {
        this.setState({ selectedTabKey });

        if (isVisibleSearchSidebar !== null) {
            this.setState({ isVisibleSearchSidebar });
        }
    }

    closeSidebar() {
        this.setState({ isVisibleSearchSidebar: false });
    }

    toggleVideoSearchSidebar = () => {
        const { isVisibleSearchSidebar } = this.state;

        this.setState({ isVisibleSearchSidebar: !isVisibleSearchSidebar });
    }

    setLocationSearch = (locationSearch) => {
        this.setState({ 
            locationSearch, 
            mapOpen: locationSearch 
        });
    };

    setMapOpen = (mapOpen) => {
        this.setState({ mapOpen });
    };

    registerOnFilterCallback = (fn) => {
        this.onFilterCallback = fn;
    };

    registerOnRequestFilterCallback = (fn) => {
        this.onRequestFilterCallback = fn;
    };

    registerOnLocationSearchCallback = (fn) => {
        this.onLocationSearchCallback = fn;
    };

    setShouldShowSidebar = (shouldShow) => {
        this.setState({ 
            isVisibleSearchSidebar: shouldShow, 
            openSidebar: shouldShow 
        });
    };

    liveVideoFilterSubmit = (values) => {
        const { actions } = this.props;

        actions.getAssetsVideoListRequest(values);
    };

    handleHistoricFilter = (a) => this.onFilterCallback(a);

    onChangeSelectedDivisionId = (selectedDivisionId) => {
        this.setState({ 
            selectedDivisionId,
            selectedAssetImei: null,
        });
    }

    onChangeSelectedAssetImei = (selectedAssetImei) => {
        this.setState({ selectedAssetImei });
    }

    onChangeSelectedDate = (selectedDate) => {
        this.setState({ selectedDate });
    }

    performVideoSearch = (params) => {
        this.setState({ 
            isFetchingSearchedVideo: true,
            videoSearchSelectedDate: params.date.format(DB_DATETIME_FORMAT),
            isVisibleVideoTiles: false,
            channelList: null,
        });
        const imei = params.imei;
        const assetId = params.assetId;
        const promises = [];
        promises.push(new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'GET',
                url: `/device/${imei}`,
                params: {
                    imei,
                    date: params.date.format(DB_DATETIME_FORMAT),
                },
            })
                .then((res) => {
                    if (res?.data) {
                        this.setState({ 
                            videoSearchData: res?.data,
                            selectedDate: params.date.format(DB_DATETIME_FORMAT),
                        });
                        resolve();
                    } else {
                        reject('Tracker data not found');
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        }));
        promises.push(new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'POST',
                url: '/device/timeline',
                body: {
                    imei,
                    date: params.date.format(DB_DATE_FORMAT),
                },
            })
                .then((res) => {
                    if (res?.data) {
                        this.setState({ timelineSegments: res?.data });
                        resolve();
                    } else {
                        reject('Timeline data not found');
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        }));
        promises.push(new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'POST',
                url: '/device/geofence-timeline',
                body: {
                    imei,
                    date: params.date.format(DB_DATE_FORMAT),
                },
            })
                .then((res) => {
                    if (res?.data) {
                        this.setState({ geofenceTimelineSegments: res?.data });
                        resolve();
                    } else {
                        reject('Geofence timeline data not found');
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        }));
        promises.push(new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'GET',
                url: '/video/get-company-dvrs',
            })
                .then((res) => {
                    if (res?.data?.data?.length) {
                        const selectedDvr = res.data.data.filter(dvr => dvr.asset_id === assetId);
                        if (selectedDvr && selectedDvr.length) {
                            this.setState({ 
                                channelList: selectedDvr[0].available_channels,
                                selectedChannels: selectedDvr[0].available_channels.map(channel => ({ channel: channel.channel, selected: true })),
                                selectedDvrIsOnline: selectedDvr[0].status,
                                selectedDvrId: selectedDvr[0].device_id,
                            });
                        }
                        resolve();
                    } else {
                        reject('DVR data not found');
                    }
                    
                })
                .catch((err) => {
                    reject(err);
                });
        }));
        promises.push(new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'GET',
                url: '/asset/event_types',
            })
                .then((res) => {
                    if (res?.data) {
                        this.setState({ eventTypes: res?.data });
                        resolve();
                    } else {
                        reject('Event types not found');
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        }));
        Promise.all(promises)
            .then(() => {
                this.setState({ isFetchingSearchedVideo: false });
                setInterval(() => {
                    fetchApiAuth({
                        method: 'GET',
                        url: '/video/get-company-dvrs',
                    })
                        .then((res) => {
                            if (res?.data?.data?.length) {
                                const selectedDvr = res.data.data.filter(dvr => dvr.asset_id === assetId);
                                if (selectedDvr && selectedDvr.length) {
                                    this.setState({ selectedDvrIsOnline: selectedDvr[0].status });
                                }
                            }
                        })
                        .catch(() => {
                            notification.error({
                                message: 'Error',
                                description: 'Failed to fetch DVR status',
                            });
                        });
                }, 30000);
            })
            .catch(() => {
                this.setState({
                    isFetchingSearchedVideo: false,
                    videoSearchData: null,
                    timelineSegments: null,
                    geofenceTimelineSegments: null,
                    channelList: null,
                    selectedChannels: null,
                    selectedDvrIsOnline: null,
                    selectedDvrId: null,
                    eventTypes: null,
                });
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch DVR data',
                });
            });
    };

    toggleSelectedChannel = (channel) => {
        const { selectedChannels } = this.state;

        const newSelectedChannels = selectedChannels.map((sc) => {
            if (sc.channel === channel) {
                return { channel, selected: !sc.selected };
            }
            return sc;
        })
        this.setState({ selectedChannels: newSelectedChannels });
    }

    onChangeSliderValue = (sliderValue) => {
        const { videoSearchSelectedDate } = this.state;
        console.log('onChangeSliderValue')
        if (videoSearchSelectedDate && sliderValue?.length === 3) {
            this.setState({ 
                sliderMiddleValue: sliderValue[1], // TODO: rename some of these state vars (prepend with videoSearch etc.)
                videoSearchDateFrom: moment(videoSearchSelectedDate).startOf('day').add((sliderValue[1] - 300), 'seconds').format(DB_DATETIME_FORMAT), // TODO: don't need this anymore?
                videoSearchDateTo: moment(videoSearchSelectedDate).startOf('day').add((sliderValue[1] + 300), 'seconds').format(DB_DATETIME_FORMAT),
                videoSearchSliderTextValue: secondsToTime(sliderValue[1]),
            });
        }
    }

    handleRequestedFilter = (a) => this.onRequestFilterCallback(a);

    handleLocationSearchFilter = (a) => this.onLocationSearchCallback(a);

    handleTabClick = (key) => {
        const { selectedTabKey } = this.state;
        if (selectedTabKey !== key) {
            this.setState({ selectedTabKey: key });
            history.push(`/video/${key}`);
            const { actions } = this.props;
            actions.updateVideoCameraSelection([]);
            actions.toggleVideoSelectionScreen(true);
            actions.updateVideoSearch({ assetReg: '' });
            this.setShouldShowSidebar(true);
        }
    };

    getVideoStreams = (params) => {
        const { selectedChannels, selectedDvrId } = this.state;

        this.setState({ isFetchingVideoStreams: true });
        const date = params.date;
        const startTime = params.start_time;
        const startDate = dayjs(`${date.format(DB_DATE_FORMAT)} ${startTime}`);
        const videoLength = params.video_length;
        const endDate = startDate.add(videoLength, 'seconds');
        fetchApiAuth({
            method: 'POST',
            url: '/historic-video/get-channels-and-videos',
            body: {
                date_from: startDate.format(DB_DATETIME_FORMAT),
                date_to: endDate.format(DB_DATETIME_FORMAT),
                channels: params.channels.join(','),
                device_id: selectedDvrId,
            },
        })
            .then((res) => {
                this.setState({ 
                    isFetchingVideoStreams: false,
                    videoSearchRequestedStartSeconds: timeToSeconds(startTime, true),
                });
                if (res?.data?.length) {
                    this.setState({ 
                        videoSearchChannelLinks: res?.data?.map(d => d[0]),
                        isVisibleVideoTiles: true,
                    });
                } else {
                    this.setState({ 
                        videoSearchChannelLinks: [],
                        isVisibleVideoTiles: true,
                    });
                }
            })
            .catch(() => {
                this.setState({ 
                    isFetchingVideoStreams: false,
                    videoSearchRequestedStartSeconds: null,
                });
                notification.error({
                    message: 'Error',
                    description: 'Unable to get camera links, check the DVR is online and try again.',
                });
            });
    }

    queueCachedVideo = (params) => {
        const { selectedDvrId } = this.state;

        this.setState({ isFetchingCacheQueueStatus: true });
        const date = params.date;
        const startTime = params.start_time;
        const startDate = dayjs(`${date.format(DB_DATE_FORMAT)} ${startTime}`);
        const videoLength = params.video_length;
        const endDate = startDate.add(videoLength, 'seconds');
        const endTime = endDate.format(DB_TIME_FORMAT);
        fetchApiAuth({
            method: 'POST',
            url: '/video/trim-and-cache',
            body: {
                deviceId: selectedDvrId,
                videoDate: startDate.parse(DB_DATETIME_FORMAT),
                selectedVideoChannels: params.channels,
                sliderRangeMinValue: timeToSeconds(startTime, true),
                sliderRangeMaxValue: timeToSeconds(endTime, true),
                location: '',
            }
        })
            .then((res) => {
                if (res && res.data) {
                    notification.success({
                        message: 'Success',
                        description: 'Your video has been queued for caching. Once finished we will notify you via email.',
                    });
                }
            })
            .catch(() => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to queue video for caching, please try again.',
                });
            });
    }

    render() {
        console.log('videoSearchSliderTextValue', this.state.videoSearchSliderTextValue);
        return this.view();
    }
}

Video.defaultProps = {
    assetId: 0,
};

Video.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    assetId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

function mapStateToProps(state, ownProps) {
    const assetId = ownProps.match.params && ownProps.match.params.assetId ? ownProps.match.params.assetId : 0;
    return {
        ...ownProps,
        showSelectionScreen: state.video.showSelectionScreen,
        user: state.user,
        assetReg: state.global.videoSearchFilters.assetReg,
        retainFilter: state.global.retainFilter,
        isFetchingSearchedVideo: state.video.searchingVideos,
        isFetchingCachedVideo: state.video.requestedVideosIsFetching,
        isFetchingLiveVideo: state.video.isFetching,
        isFetchingDeviceLocation: state.device.isFetchingDeviceLocation,
        assetId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...videoActions,
                ...eventsActions,
                ...assetActions,
                ...driverActions,
                ...globalActions,
                ...deviceActions,
            },
            dispatch,
        ),
    };
}
export const VideoTest = Video;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Video);
