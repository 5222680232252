/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { findIndex } from 'underscore';
import DOM from './usersView';
import './usersView.scss';

import * as DriverActions from '../../core/driver/driverActions';
import * as AssetActions from '../../core/asset/assetActions';
import * as UserActions from '../../core/user/userActions';

const { DB_DATE_FORMAT } = require('../../core/constants').default;

class UsersView extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            divisionId: 0,
            divisionName: '',
            modalAssignToAssets: false,
            modalEdit: false,
            selectedAsset: {},
            currentTabKey: '1',
            journeyHistoryTabLoaded: false,
            journeyHistoryTabFilter: {
                asset_info: '',
                division: '',
                registration_year: '',
                make: '',
                fuel_type: '',
                current_location: '',
                page: 1,
            },
            eventsHistoryTabLoaded: false,
            eventsHistoryTabFilter: {
                asset_info: '',
                division: '',
                registration_year: '',
                make: '',
                fuel_type: '',
                current_location: '',
                page: 1,
            },
        };
        this.getAccountDetails = () => null;
    }

    componentDidMount() {
        const {
            userId, divisionId, divisionName, actions,
        } = this.props;
        this.setState({ divisionId, divisionName });
        actions.clearAssetPermissionsRequest();
        actions.clearDriverPermissionsRequest();
        actions.getUserDetailRequest({ user_id: userId });
        actions.getAssetListRequest();
        actions.getGeneralDataRequest();
    }

    setJourneyHistoryTabLoaded = () => {
        this.setState({ journeyHistoryTabLoaded: true });
    };

    setEventsHistoryTabLoaded = () => {
        this.setState({ eventsHistoryTabLoaded: true });
    };

    onJourneyHistoryTabPageChange = (page) => {
        const { journeyHistoryTabFilter } = this.state;
        journeyHistoryTabFilter.page = page;
        this.setState({ journeyHistoryTabFilter });
    };

    onJourneyHistoryTabFilterChange = (values) => {
        const filters = values;
        filters.page = 1;
        this.setState({ journeyHistoryTabFilter: filters });
    };

    onJourneyHistoryTabSortChange = () => {
    };

    onEventsHistoryTabPageChange = (page) => {
        const { eventsHistoryTabFilter } = this.state;
        eventsHistoryTabFilter.page = page;
        this.setState({ eventsHistoryTabFilter });
    };

    onEventsHistoryTabFilterChange = (values) => {
        const filters = values;
        if (filters.date_from) {
            filters.date_from = values.date_from.format(DB_DATE_FORMAT);
        }
        if (filters.date_to) {
            filters.date_to = values.date_to.format(DB_DATE_FORMAT);
        }
        filters.page = 1;
        this.setState({ eventsHistoryTabFilter: filters });
    };

    onEventsHistoryTabSortChange = () => {
    };

    updateDriverPicture(data) {
        const { actions, userId } = this.props;
        actions.uploadImageRequest({ image: data, driverId: userId });
    }

    updateProfile = () => {
        const { actions, userId } = this.props;

        const profile = this.getAccountDetails();
        actions.setUserDetailRequest({ 
            user_id: userId, 
            division_id: profile.division, 
            ...profile,
        });
    };

    selectAsset(id) {
        const { asset } = this.props;

        const singleAssetKey = findIndex(asset.assetList, (val) => val.id === id);
        if (singleAssetKey !== -1) {
            this.setState({
                selectedAsset: asset.assetList[singleAssetKey],
            });
        }
    }

    assignAsset() {

    }

    updateDriver = () => {
        this.setState({ modalEdit: true });
    };

    render() {
        return this.view();
    }
}

UsersView.propTypes = {
    actions: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired,
    departments: PropTypes.array.isRequired,
    divisions: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    const userId = parseInt(ownProps.match.params.userId, 10);
    return {
        ...ownProps,
        driver: state.driver,
        asset: state.asset,
        user: state.user,
        departments: state.driver.departments,
        userId,
        divisions: state.asset.divisions,
        divisionId: state.user.divisionId,
        divisionName: state.user.divisionName,
        isFetching: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...DriverActions,
                ...AssetActions,
                ...UserActions,
            },
            dispatch,
        ),
    };
}
export const UsersViewTest = UsersView;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UsersView);
