import React from 'react';
import { Spin } from 'antd';
import { ClipLoader, HashLoader, PulseLoader } from 'react-spinners';

interface SpinnerProps {
    loading: boolean;
    children: React.ReactElement | null;
    minHeight?: number;
    size?: number;
}

const Spinner: React.FC<SpinnerProps> = ({
    loading = false,
    children = null,
    minHeight = 400,
    size = 48,
}) => {
    return (
        <Spin
            spinning={loading} 
            indicator={
                <ClipLoader
                    loading={true} 
                    color='#ff6900' // NOTE: var(--safety-orange) doesn't work here for secondary color
                    speedMultiplier={1.5}
                    size={size}
                />
            }
        >
            <div style={{ minHeight }}>
                {children}
            </div>
        </Spin>
    )
};

export default Spinner;