import { Record } from 'immutable';
import moment from 'moment';
import {
    each, findIndex,
} from 'underscore';
import populateMapDevices from './helpers';

const { DB_DATETIME_FORMAT } = require('../constants');

const {
    SELECT_MAP_DEVICE,
    FLUSH_MAP_DEVICE,
    UPDATE_MAP_DEVICE_REQUEST_FLAG,

    MAP_DEVICE_LIST_REQUEST,
    MAP_DEVICE_LIST_SUCCESS,
    MAP_DEVICE_LIST_FAILURE,

    MAP_DEVICES_LAST_LOCATION_REQUEST,
    MAP_DEVICES_LAST_LOCATION_SUCCESS,
    MAP_DEVICES_LAST_LOCATION_FAILURE,

    MAP_DEVICE_LIST_BY_LOCATION_REQUEST,
    MAP_DEVICE_LIST_BY_LOCATION_SUCCESS,
    MAP_DEVICE_LIST_BY_LOCATION_FAILURE,

    MAP_DEVICE_LOCATIONS_REQUEST,
    MAP_DEVICE_LOCATIONS_SUCCESS,
    MAP_DEVICE_LOCATIONS_FAILURE,

    GET_DEVICE_LOCATION_DETAILED_INFO_REQUEST,
    GET_DEVICE_LOCATION_DETAILED_INFO_SUCCESS,
    GET_DEVICE_LOCATION_DETAILED_INFO_FAILURE,

    GET_MAP_EVENTS_HEAT_MAP_REQUEST,
    GET_MAP_EVENTS_HEAT_MAP_SUCCESS,
    GET_MAP_EVENTS_HEAT_MAP_FAILURE,

    GET_COMPANY_ALL_TRIGGERS_REQUEST,
    GET_COMPANY_ALL_TRIGGERS_SUCCESS,
    GET_COMPANY_ALL_TRIGGERS_FAILURE,

    CREATE_TRIGGER_REQUEST,
    CREATE_TRIGGER_SUCCESS,
    CREATE_TRIGGER_FAILURE,

    GET_DEVICE_HISTORIC_VIDEOS_REQUEST,
    GET_DEVICE_HISTORIC_VIDEOS_SUCCESS,
    GET_DEVICE_HISTORIC_VIDEOS_FAILURE,

    UPDATE_MAP_DATE,

    MAP_ASSET_SEARCH_REQUEST,
    MAP_ASSET_SEARCH_SUCCESS,
    MAP_ASSET_SEARCH_FAILURE,

    MAP_GET_LIVE_ASSETS_LOCATION_REQUEST,
    MAP_GET_LIVE_ASSETS_LOCATION_SUCCESS,
    MAP_GET_LIVE_ASSETS_LOCATION_FAILURE,

    DELETE_GEOFENCE_REQUEST,
    DELETE_GEOFENCE_SUCCESS,
    DELETE_GEOFENCE_FAILURE,

    UPDATE_DEVICE_TRIGGER_REQUEST,
    UPDATE_DEVICE_TRIGGER_SUCCESS,
    UPDATE_DEVICE_TRIGGER_FAILURE,

    RESET_MAP_LIVE_ASSETS_REQUEST,
    RESET_MAP_DATA_REQUEST,
    SET_HEAT_MAP_DATA_UPDATED,
    RESET_HEAT_MAP_DATA,

    UPDATE_MAP_HEAT_MAP_REQUEST_FLAG,

    UPDATE_MAP_DEVICE_LOADED,

    GET_TRIP_INFORMATION_REQUEST,
    GET_TRIP_INFORMATION_SUCCESS,
    GET_TRIP_INFORMATION_FAILURE,

    SET_MAP_DEVICES,
    SET_CHECKED_ASSETS,
    SET_SELECTED_ASSET,

    GET_DEVICE_TIMELINE_REQUEST,
    GET_DEVICE_TIMELINE_SUCCESS,
    GET_DEVICE_TIMELINE_FAILURE,

    GET_DEVICE_GEOFENCE_TIMELINE_REQUEST,
    GET_DEVICE_GEOFENCE_TIMELINE_SUCCESS,
    GET_DEVICE_GEOFENCE_TIMELINE_FAILURE,

    RESET_FETCHINGS,
} = require('./deviceActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isFetchingDeviceLocation: false,
    isFetchingTripInformation: false,
    mapDeviceListIsFetching: false,
    mapDeviceListByLocationIsFetching: false,
    isFetchingLastLocation: false,
    mapDevices: [],
    mapDevicesLastLocation: [],
    mapDevicesByLocation: [],
    mapDevicesFetchTime: '2021-06-01 00:00:00',
    mapDevicesByLocationFetchTime: '2021-06-01 00:00:00',
    mapSelectedDeviceLocations: [],
    mapSelectedDeviceRecord: [],
    mapSelectedDeviceInfo: {},
    mapSelectedDeviceTelematicsData: {},
    selectMapDevice: null,
    mapDeviceHeatMap: [],
    mapAssets: [],
    mapAssetsPagination: {
        currentPage: 1,
        pageCount: 0,
        perPage: 10,
        totalCount: 0,
    },
    mapDeviceHistoricVideos: [],
    mapDeviceHistoricPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 8,
    },
    allTriggers: [],
    mapLiveAssetsLocation: [],
    mapDeviceLocationRequest: false,
    mapHeatMapDataUpdated: false,
    mapDeviceHeatMapRequest: false,
    mapDevicesLoaded: false,
    mapDevicesByLocationLoaded: false,
    deviceDetailedLocationInfo: {},
    deviceDetailedLocationFetching: false,
    checkedAssets: [],
    selectedAsset: null,
    deviceTimeline: [],
    deviceGeofenceTimeline: [],
});

const deviceInitialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function deviceReducer(state = deviceInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = deviceInitialState.mergeDeep(state);
    switch (type) {
        case SELECT_MAP_DEVICE:
            return state.set('selectMapDevice', payload);
        case FLUSH_MAP_DEVICE:
            return state.set('selectMapDevice', null);
        case MAP_DEVICE_LIST_REQUEST:
            return state.set('mapDeviceListIsFetching', true)
                .set('mapDevicesFetchTime', moment().format(DB_DATETIME_FORMAT))
                .set('mapDevicesLoaded', false);
        case MAP_DEVICE_LIST_BY_LOCATION_REQUEST:
            return state.set('mapDeviceListByLocationIsFetching', true)
                .set('mapDevicesByLocationFetchTime', moment().format(DB_DATETIME_FORMAT))
                .set('mapDevicesByLocation', [])
                .set('mapDevicesByLocationLoaded', false);
        case GET_DEVICE_HISTORIC_VIDEOS_REQUEST:
        case GET_COMPANY_ALL_TRIGGERS_REQUEST:
        case CREATE_TRIGGER_REQUEST:
        case MAP_ASSET_SEARCH_REQUEST:
        case DELETE_GEOFENCE_REQUEST:
        case UPDATE_DEVICE_TRIGGER_REQUEST:
        case GET_DEVICE_LOCATION_DETAILED_INFO_REQUEST:
            return state.set('deviceDetailedLocationFetching', true)
                .set('error', null);
        case MAP_DEVICES_LAST_LOCATION_REQUEST:
            return state.set('isFetchingLastLocation', true)
                .set('error', null);
        case GET_TRIP_INFORMATION_REQUEST:
            return state.set('isFetchingTripInformation', true);
        case MAP_DEVICE_LOCATIONS_REQUEST:
            return state.set('isFetchingDeviceLocation', true);

    case GET_MAP_EVENTS_HEAT_MAP_REQUEST:
        return state.set('isFetching', true)
            .set('mapDeviceHeatMap', [])
            .set('error', null)
            .set('mapHeatMapDataUpdated', false);

        case GET_DEVICE_TIMELINE_REQUEST:
            return state.set('error', null)
                .set('isFetching', true)
                .set('deviceTimeline', []);

        case GET_DEVICE_GEOFENCE_TIMELINE_REQUEST:
            return state.set('error', null)
                .set('isFetching', true)
                .set('deviceGeofenceTimeline', []);

    case UPDATE_MAP_DEVICE_REQUEST_FLAG:
        return state.set('mapDeviceLocationRequest', payload);

    case GET_TRIP_INFORMATION_SUCCESS: {
        const { mapDevices } = state;
        const {
            asset_index, marker_index, time_driven, trip_odometer_mileage,
        } = payload.data;
        if (mapDevices[asset_index]?.markers[marker_index]?.time_driven != undefined) {
            mapDevices[asset_index].markers[marker_index].time_driven = time_driven;
        }
        if (mapDevices[asset_index]?.markers[marker_index]?.trip_odometer_mileage != undefined) {
            mapDevices[asset_index].markers[marker_index].trip_odometer_mileage = time_driven;
        }
        return state.set('isFetchingTripInformation', false)
            .set('mapDevices', mapDevices);
    }
    case MAP_ASSET_SEARCH_SUCCESS:
        const mapAssetsPagination = {};
        mapAssetsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        mapAssetsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        mapAssetsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        mapAssetsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 8;
        mapAssetsPagination.onlineAssets = payload.headers['x-online-assets'] || 0;
        return state.set('isFetching', false)
            .set('mapAssetsPagination', mapAssetsPagination)
            .set('mapAssets', payload.data);
    case MAP_DEVICE_LIST_SUCCESS:
    {
        const { mapDevices, selectedAsset } = state;
        const { date, data } = payload.data;
        const newMapDevices = populateMapDevices(data, 10, selectedAsset);
        const filteredMapDevices = [];
        for (let i = 0; i < mapDevices.length; i++) {
            const deviceData = mapDevices[i];
            const duplicate = newMapDevices.some((obj) => obj.asset_id === deviceData.asset_id);
            if (!duplicate && deviceData.date == date) {
                filteredMapDevices.push(deviceData);
            }
        }
        const mergedMapDevices = filteredMapDevices.concat(newMapDevices);
        return state.set('mapDeviceListIsFetching', false)
            .set('mapDevicesLoaded', true)
            .set('mapDevices', mergedMapDevices);
    }
    case MAP_DEVICES_LAST_LOCATION_SUCCESS: {
        const { data } = payload.data;
        const mapDevices = populateMapDevices(data);
        return state.set('isFetchingLastLocation', false)
            .set('mapDevicesLastLocation', mapDevices);
    }
    case MAP_DEVICE_LIST_BY_LOCATION_SUCCESS:
    {
        return state.set('mapDeviceListByLocationIsFetching', false)
            .set('mapDevicesByLocationLoaded', true)
            .set('mapDevicesByLocation', payload.data);
    }
    case UPDATE_MAP_DEVICE_LOADED:
        return state.set('mapDevicesLoaded', payload);

        case GET_COMPANY_ALL_TRIGGERS_SUCCESS:
        case CREATE_TRIGGER_SUCCESS:
            return state.set('isFetching', false)
                .set('allTriggers', payload.data);
        case UPDATE_MAP_HEAT_MAP_REQUEST_FLAG:
            return state.set('mapDeviceHeatMapRequest', payload);
        case GET_MAP_EVENTS_HEAT_MAP_SUCCESS:
            return state.set('mapDeviceHeatMap', payload.data)
                .set('mapDeviceHeatMapRequest', true)
                .set('mapHeatMapDataUpdated', true)
                .set('isFetching', false);
        case MAP_DEVICE_LOCATIONS_SUCCESS:
            return state.set('isFetchingDeviceLocation', false)
                .set('mapDeviceLocationRequest', true)
                .set('mapSelectedDeviceLocations', payload.data.locations)
                .set('mapSelectedDeviceRecord', payload.data.record)
                .set('mapSelectedDeviceTelematicsData', payload.data.telematicsData)
                .set('mapSelectedDeviceInfo', payload.data.info);

    case GET_DEVICE_LOCATION_DETAILED_INFO_SUCCESS:
        return state.set('deviceDetailedLocationInfo', payload.data)
            .set('deviceDetailedLocationFetching', false);

    case GET_DEVICE_HISTORIC_VIDEOS_SUCCESS: {
        const pagination = {};
        pagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        pagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        pagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        pagination.perPageCount = payload.headers['x-pagination-per-page'] || 16;
        return state.set('isFetching', false)
            .set('mapDeviceHistoricPagination', pagination)
            .set('mapDeviceHistoricVideos', payload.data);
    }
    case MAP_GET_LIVE_ASSETS_LOCATION_SUCCESS:
        const { mapLiveAssetsLocation } = state;
        let tempMapLiveAssetsLocations = mapLiveAssetsLocation || [];
        if (tempMapLiveAssetsLocations.length === 0) {
            tempMapLiveAssetsLocations = payload.data.locations;
        } else {
            const newLocations = (payload.data && payload.data.locations) || [];
            each(newLocations, (newLocation, newLocationKey) => {
                const tempFindKey = findIndex(tempMapLiveAssetsLocations, (tempMapLiveAssetsLocation) => tempMapLiveAssetsLocation.imei == newLocation.imei);
                if (tempFindKey !== -1) {
                    tempMapLiveAssetsLocations[tempFindKey].division_color = newLocation.division_color;
                    each(newLocation.markers, (marker, markerKey) => {
                        tempMapLiveAssetsLocations[tempFindKey].markers[markerKey] = marker;
                    });
                } else {
                    tempMapLiveAssetsLocations.push(newLocation);
                }
            });
        }
        return state.set('isFetching', false)
            .set('mapLiveAssetsLocation', tempMapLiveAssetsLocations);

    case DELETE_GEOFENCE_SUCCESS: {
        const { allTriggers } = state;

        const triggerIndex = findIndex(allTriggers, (val) => val.id === payload.id);
        if (triggerIndex !== -1) {
            allTriggers.splice(triggerIndex, 1);
        }

        return state.set('allTriggers', allTriggers)
            .set('isFetching', false);
    }

        case UPDATE_DEVICE_TRIGGER_SUCCESS: {
            const { allTriggers } = state;

        const triggerIndex = findIndex(allTriggers, (val) => val.id === payload.data.id);
        if (triggerIndex !== -1) {
            allTriggers[triggerIndex] = payload.data;
        }

        return state.set('allTriggers', allTriggers)
            .set('isFetching', false);
    }

        case GET_DEVICE_TIMELINE_SUCCESS: {
            return state.set('isFetching', false)
                .set('deviceTimeline', payload.data);
        }

        case GET_DEVICE_GEOFENCE_TIMELINE_SUCCESS: {
            return state.set('isFetching', false)
                .set('deviceGeofenceTimeline', payload.data);
        }

    case RESET_MAP_LIVE_ASSETS_REQUEST:
        return state.set('mapLiveAssetsLocation', []);

    case RESET_MAP_DATA_REQUEST:
        return state.set('mapLiveAssetsLocation', [])
            .set('mapDevices', [])
            .set('mapSelectedDeviceLocations', [])
            .set('mapSelectedDeviceRecord', [])
            .set('mapSelectedDeviceInfo', {})
            .set('mapAssets', [])
            .set('allTriggers', []);

    case GET_DEVICE_LOCATION_DETAILED_INFO_FAILURE:
        return state.set('error', payload)
            .set('deviceDetailedLocationFetching', false);

    case MAP_DEVICES_LAST_LOCATION_FAILURE:
        return state.set('isFetchingLastLocation', false)
            .set('error', payload);

        case MAP_DEVICE_LIST_FAILURE:
        case GET_COMPANY_ALL_TRIGGERS_FAILURE:
        case MAP_ASSET_SEARCH_FAILURE:
        case CREATE_TRIGGER_FAILURE:
        case MAP_GET_LIVE_ASSETS_LOCATION_FAILURE:
        case DELETE_GEOFENCE_FAILURE:
        case UPDATE_DEVICE_TRIGGER_FAILURE:
            return state.set('isFetching', false)
                .set('mapDevicesLoaded', false)
                .set('error', payload);

        case MAP_DEVICE_LOCATIONS_FAILURE:
            return state.set('isFetchingDeviceLocation', false);

        case GET_MAP_EVENTS_HEAT_MAP_FAILURE:
            return state.set('isFetching', false)
                .set('error', payload)
                .set('mapHeatMapDataUpdated', false);

    case MAP_DEVICE_LIST_BY_LOCATION_FAILURE:
        return state.set('mapDevicesByLocation', [])
            .set('mapDeviceListByLocationIsFetching', false)
            .set('mapDevicesByLocationLoaded', false)
            .set('error', payload);
    case GET_DEVICE_HISTORIC_VIDEOS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload)
            .set('mapDeviceHistoricVideos', [])
            .set('mapDeviceHistoricPagination', {
                currentPage: 1,
                totalRecords: 0,
                pageCount: 0,
                perPageCount: 16,
            });
    case GET_TRIP_INFORMATION_FAILURE:
        return state.set('isFetchingTripInformation', false);

    case GET_DEVICE_TIMELINE_FAILURE:
        return state.set('deviceTimeline', { timeline: [], geofence_timeline: [] })
            .set('isFetching', false)
            .set('error', payload);

        case GET_DEVICE_GEOFENCE_TIMELINE_FAILURE:
            return state.set('deviceGeofenceTimeline', [])
                .set('isFetching', false)
                .set('error', payload);

        case SET_HEAT_MAP_DATA_UPDATED:
            return state.set('mapHeatMapDataUpdated', payload);
        case RESET_HEAT_MAP_DATA:
            return state.set('mapDeviceHeatMap', [])
                .set('mapHeatMapDataUpdated', true);

    case SET_MAP_DEVICES:
        return state.set('mapDevices', payload);
    case SET_CHECKED_ASSETS:
        return state.set('checkedAssets', payload);
    case SET_SELECTED_ASSET:
        return state.set('selectedAsset', payload)
            .set('mapSelectedDeviceLocations', []);

        case RESET_FETCHINGS:
            return state.set('isFetching', false)
                .set('isFetchingDeviceLocation', false)
                .set('isFetchingTripInformation', false)
                .set('mapDeviceListIsFetching', false)
                .set('mapDeviceListByLocationIsFetching', false)
                .set('deviceDetailedLocationFetching', false);       

        default:
            return state;
    }
}

export {
    deviceReducer,
    deviceInitialState,
};
