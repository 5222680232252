import React from 'react';
import PropTypes from 'prop-types';
import spriteSrc from '../../../assets/images/sprite.svg';

function Icon({ name, className, visible }) {
    return (
        <svg className={`icon icon-${name.toLowerCase()}  ${className} ${!visible ? 'hidden' : ''}`}>
            <use xlinkHref={`${spriteSrc}#icon-${name.toLowerCase()}`} />
        </svg>
    );
}

Icon.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    visible: PropTypes.bool,
};
export default Icon;
