import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    groupBy, isNull, pluck, isArray, isEmpty,
} from 'underscore';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as assetActions from '../../core/asset/assetActions';
import * as globalActions from '../../core/global/globalActions';
import '../../pages/Dashboard/dashboard.css';
import './assetSearchTree.scss';
import DOM from './assetSearchTree';

const { DB_DATE_FORMAT, DB_DATETIME_FORMAT } = require('../../core/constants');

class AssetSearchTree extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            filters: {},
            expandedKeys: [],
            checkedKeys: props.checkedAssets,
            searchValue: '',
            autoExpandParent: true,
            treeData: null,
        };
        this.dataList = [];
    }

    componentDidMount() {
        const {
            actions, assets, checkedAssets,
        } = this.props;

        if (!isEmpty(checkedAssets) && checkedAssets[0] === '999') {
            this.populateTreeFromAssets(assets);
        }

        actions.getBasicAssetListRequest();

        this.setState({ treeData: this.populateTree(assets) });
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            assets, clearSelection, checkedAssets,
        } = this.props;
        const { checkedKeys } = this.state;

        if (prevProps.clearSelection !== clearSelection) {
            // toggle selection
            if (clearSelection) {
                this.onCheck([]);
            } else {
                const treeData = this.populateTree(assets);
                this.generateList(treeData);
                const checkedKeys = [];
                treeData.forEach((treeRow) => {
                    checkedKeys.push(treeRow.key);
                    if (treeRow.children && isArray(treeRow.children)) {
                        (treeRow.children).forEach((tC) => {
                            checkedKeys.push(tC.key);
                        });
                    }
                });
                this.setState({
                    checkedKeys,
                    autoExpandParent: true,
                });
                this.onCheck(checkedKeys);
            }
        }

        if (checkedAssets !== prevProps.checkedAssets) {
            if (!isEmpty(checkedAssets) && checkedAssets[0] == '999') {
                this.populateTreeFromAssets(assets);
            } else {
                this.setState({ checkedKeys: checkedAssets });
            }
        }

        if (checkedKeys !== prevState.checkedKeys) {
            const parentKeys = [];
            const tree = this.populateTree(assets);
            for (let i = 0; i < checkedKeys.length; i++) {
                const parentKey = this.getParentKey(checkedKeys[i], tree);
                if (parentKey) parentKeys.push(parentKey);
            }
            if (parentKeys.length) this.setState({ expandedKeys: parentKeys });
        }

        if (assets.length !== prevProps.assets.length) {
            if (!isEmpty(checkedAssets) && checkedAssets[0] == '999') {
                this.populateTreeFromAssets(assets);
            }
        }
    }

    populateTreeFromAssets = (assets) => {
        const { selectedAssets } = this.props;

        const treeData = this.populateTree(assets);
        this.generateList(treeData);
        let newCheckedKeys;
        if (selectedAssets && selectedAssets.length) {
            const filteredAssets = assets.filter((asset) => selectedAssets.includes(asset.asset_id));
            newCheckedKeys = filteredAssets.map((asset) => `asset-${asset.division_id}-${asset.asset_id}`);
        } else {
            newCheckedKeys = pluck(treeData, 'key');
        }

        this.setState({
            checkedKeys: newCheckedKeys,
            autoExpandParent: true,
        });
    };

    onLeafSelect = (selectedKeys, e, treeData) => {
        const {
            leafOnSelect, actions, isMapLive, selectedAsset,
        } = this.props;
        const { checkedKeys } = this.state;
        
        if (selectedAsset) {
            this.onCheck([...checkedKeys, ...selectedKeys], e);
            return;
        }

        if (!selectedKeys || !selectedKeys.length) {
            if (e?.node?.props?.eventKey) selectedKeys = [e?.node?.props?.eventKey];
            else return;
        }
        if (selectedKeys[0].indexOf('division') > -1) return;
        if (selectedKeys[0].indexOf('select-all') > -1) return;

        actions.setPreviousMapState(isMapLive);

        const assetData = treeData[0].key === 'select-all'
            ? treeData[0].children.map((t) => t.children)
            : treeData.map((t) => t.children);
        let asset;
        assetData.map((a) => {
            for (let i = 0; i < a.length; i++) {
                const thisAsset = a[i];
                if (thisAsset.key === selectedKeys[0]) {
                    asset = thisAsset;
                }
            }
            return a;
        });
        leafOnSelect(asset);
    };

    populateTree = (divisions) => {
        const {
            userRole, basicAssetList, company, actualCheckedAssets, totalAssets, 
        } = this.props;

        const filteredDivisions = divisions; // .filter((division) => division.division != null);
        // group the assets by the division name
        const groupedDivisions = groupBy(filteredDivisions, 'division');
        let data = [];

        // loop through divisions and assets and create the tree structure array
        Object.keys(groupedDivisions).forEach((division) => {
            const children = [];
            let title = division;
            let key = division;
            const icon = '';

            if (groupedDivisions[division]) {
                // this creates the children - so in this case it will create assets as children of the divisions
                Object.keys(groupedDivisions[division]).forEach((asset) => {
                    let asset_icon = (
                        <Loading3QuartersOutlined style={{
                            fontSize: '12px', borderRadius: '12px', color: 'red', backgroundColor: 'red',
                        }} />
                    );
                    const division_id = groupedDivisions[division][asset].division_id || 0;
                    let asset_id = 0;
                    if (groupedDivisions[division][asset].id) {
                        asset_id = groupedDivisions[division][asset].id;
                    } else {
                        asset_id = groupedDivisions[division][asset].asset_id;
                    }

                    if (parseInt(groupedDivisions[division][asset].device_status, 10) === 1) {
                        asset_icon = (
                            <Loading3QuartersOutlined style={{
                                fontSize: '12px', borderRadius: '12px', color: 'green', backgroundColor: 'green',
                            }} />
                        );
                    }
                    if (parseInt(groupedDivisions[division][asset].device_status, 10) === 0) {
                        asset_icon = (
                            <Loading3QuartersOutlined style={{
                                fontSize: '12px', borderRadius: '12px', color: 'red', backgroundColor: 'red',
                            }} />
                        );
                    }
                    const thisId = groupedDivisions[division][asset].asset_id;
                    const thisAsset = basicAssetList && basicAssetList.length
                        ? basicAssetList.filter((a) => a.id == thisId)[0]
                        : null;
                    const driverName = thisAsset?.driver;
                    const assetName = groupedDivisions[division][asset].name;
                    children.push({
                        title: assetName ? (driverName ? `${assetName} - ${driverName}` : assetName) : '-',
                        key: `asset-${division_id}-${asset_id}`,
                        reg: groupedDivisions[division][asset].registration,
                        driver: groupedDivisions[division][asset].driver,
                        online: groupedDivisions[division][asset].status == '1',
                        isLeaf: true,
                        icon: asset_icon,
                        marker: {
                            id: groupedDivisions[division][asset].record_id || 0,
                            imei: groupedDivisions[division][asset].imei,
                            lat: groupedDivisions[division][asset].lat,
                            lng: groupedDivisions[division][asset].lng,
                            time: groupedDivisions[division][asset].time,
                            date: moment(groupedDivisions[division][asset].time, DB_DATETIME_FORMAT).format(DB_DATE_FORMAT),
                        },
                    });
                    key = `division-${division_id}`;
                });
            }

            if (isNull(division) || division === null || division == 'null') {
                title = 'Unassigned division';
            }
            data.push({
                title,
                key,
                icon,
                children,
            });
        });
        data = data.sort((a, b) => a.title.localeCompare(b.title));
        const safeData = data.filter((datum) => !(userRole == 'manager' && datum.title == 'Unassigned division'));
        const dataWithCompanyAsParent = [{
            title: `${company.title} (${actualCheckedAssets}/${totalAssets})`,
            key: 'select-all',
            icon: null,
            children: safeData,
        }];

        return dataWithCompanyAsParent;
    };

    // this function flattens the tree data to get one parent at a time
    generateList = (data) => {
        if (data) {
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                if (node) {
                    const { key, title, icon } = node;
                    this.dataList.push({ key, title, icon });
                    if (node.children) {
                        this.generateList(node.children);
                    }
                }
            }
        }
    };

    getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentKey = node.key;
                } else if (this.getParentKey(key, node.children)) {
                    parentKey = this.getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    onChange = (e) => {
        const { value } = e.target;
        const { assets } = this.props;

        const data = this.populateTree(assets);
        const searchTerm = value.toLowerCase();
        const expandedKeys = this.dataList;

        let parentKeys = [];
        if (expandedKeys) {
            expandedKeys.map((item) => {
                if (item.title.toLowerCase().indexOf(searchTerm) > -1) {
                    parentKeys.push(this.getParentKey(item.key, data));
                    return this.getParentKey(item.key, data);
                }
                return null;
            })
                .filter((item, i, self) => item && self.indexOf(item) === i);

            if (searchTerm == '') {
                parentKeys = [];
            }

            this.setState({
                expandedKeys: parentKeys,
                searchValue: searchTerm,
                autoExpandParent: true,
            });
        }
    };

    onCheck = (checkedKeys, e) => {
        const { assetSearch } = this.props;

        assetSearch(checkedKeys, true);
        this.setState({
            checkedKeys,
            autoExpandParent: true,
        });
    };

    loop = (data) => data.map((item) => {
        const { searchValue, expandedKeys } = this.state;
        const { assetOnMouseOver, assetOnMouseOut } = this.props;

        if (item.title) {
            const index = item.title.toLowerCase().indexOf(searchValue);
            const beforeStr = item.title.substr(0, index);
            const afterStr = item.title.substr(index + searchValue.length);
            const originalTerm = item.title.substr(index, searchValue.length);
            const title = index > -1 ? (
                <span
                    onMouseOut={() => assetOnMouseOut()}
                    // onClick={() => assetOnClickHover(item.key)}
                    onMouseOver={() => assetOnMouseOver(item.key)}>
                    {beforeStr}
                    <span className="site-tree-search-value">{originalTerm}</span>
                    {afterStr}
                </span>
            ) : (
                <span>{item.title}</span>
            );
            if (item.children) {
                return {
                    title, key: item.key, children: this.loop(item.children), icon: item.icon,
                };
            }

            return {
                title,
                key: item.key,
                children: [],
                icon: item.icon,
            };
        }
    });

    render() {
        return this.view();
    }
}

AssetSearchTree.defaultProps = {
    assets: [],
    assetSearch: () => {},
    leafOnSelect: () => {},
    isMapLive: false,
    checkedAssets: ['999'],
    backLinkClick: () => {},
    actualCheckedAssets: 0,
    totalAssets: 0,
};

AssetSearchTree.propTypes = {
    assets: PropTypes.array,
    assetSearch: PropTypes.func,
    leafOnSelect: PropTypes.func,
    isMapLive: PropTypes.bool,
    checkedAssets: PropTypes.array,
    backLinkClick: PropTypes.func,
    actualCheckedAssets: PropTypes.number,
    totalAssets: PropTypes.number,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        userRole: state.user.role,
        basicAssetList: state.asset.basicAssetList,
        company: state.user.userCompany,
        selectedAssets: state.asset.selectedAssets,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssetSearchTree);
