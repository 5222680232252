/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Spin, Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import Layout from '../../components/layout/Layout';
import { Close } from '@carbon/icons-react';
import './divisionsView.scss';
import LeftSide from './partials/LeftSide';
import GridView from '../../components/elements/GridView';
import { Tooltip } from 'antd/lib';

export default function () {
    const {
        divisionAssets,
        divisionAssetsPagination,
        divisionAssetsIsFetching,
        divisionDetail,
        otherAssets,
        user,
    } = this.props;

    const data = divisionAssets.map((d) => ({
        id: d.id,
        name: d.name,
        registration: d.asset_reg,
        last_online: d.last_online,
        status: parseInt(d.status, 10) === 1 ? 'Active' : 'Not Active',
        actions: (
            <Tooltip title="Remove Asset">
                <Button
                    className="btn btn-empty ml-1"
                    onClick={() => this.removeAsset(d.id)}
                    style={{ 
                        width: '24px', 
                        height: '24px', 
                        verticalAlign: 'middle'
                    }}
                >
                    <Close
                        style={{ color: '#f5222d' }}
                        size={24}
                    />
                </Button>
            </Tooltip>
        ),
    }));

    const filterOptions = (inputValue, option) => option.key.includes(inputValue);

    return (
        <Layout
            data-test="pages-divisions-view"
            className="events-view"
            title={<div>{divisionDetail.name}</div>}
            headerTitle={<div>{divisionDetail.name}</div>}
            showFooter={false}
            loading={divisionAssetsIsFetching}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <div 
                    className="content"
                    style={{ opacity: divisionAssetsIsFetching ? 0 : 1 }}
                >
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <div className="right-header-wrapper">
                                    <div
                                        className='asset-name-label'
                                        style={{ textAlign: 'center' }}
                                    >
                                        {divisionDetail.name}
                                    </div>
                                    <Button
                                        onClick={() => this.submitUpdateDepartmentForm()}
                                        type="primary"
                                        className="btn"
                                    >
                                        <SaveOutlined />
                                        <span>Save</span>
                                    </Button>
                                </div>
                                <div className="division-wrapper">
                                    <div className="left-side-wrapper">
                                        <LeftSide
                                            registerSubmitHandler={(f) => { this.submitUpdateDepartmentForm = f; }}
                                            divisionOptions={filterOptions}
                                            nonDivisionAssets={otherAssets}
                                            assignAsset={(id) => this.assignAsset(id)}
                                        />
                                    </div>
                                    <div className="right-side-wrapper" style={{ maxWidth: '60%'}}>
                                        <GridView
                                            data={data}
                                            onChange={this.onGridChange}
                                            sortableColumns={[]}
                                            pagination={{
                                                total: parseInt(divisionAssetsPagination.totalRecords, 10) || 0,
                                                pageSize: parseInt(divisionAssetsPagination.perPageCount, 10) || 10,
                                                showSizeChanger: false,
                                                onChange: this.paginationChange,
                                            }}
                                            scroll={{
                                                x: 'max-content',
                                                y: 512,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
