import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Calendar } from "@carbon/icons-react";
import { DatePicker } from 'antd';

/**
 * Wrapper function that allows us to ignore dates that are the same as the previous one provided.
 * @param date the date value
 * @param actualValue the date stored in this component's state
 * @param setter setActualValue
 * @param callback callback as provided by parent component
 */
const actualOnChange = (
    date: dayjs.Dayjs, 
    actualValue: dayjs.Dayjs,
    setter: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>, 
    callback: (date: dayjs.Dayjs | null) => void
) => {
    if (!date) return;
    if (date.isSame(actualValue, 'day')) {
        return;
    }
    setter(date);
    callback(date);
}

type MemoizedDatePickerProps = {
    value: dayjs.Dayjs;
    format: string;
    onChange: (date: dayjs.Dayjs | null) => void;
    onFocus: () => void;
    className: string;
    minDate: dayjs.Dayjs | null;
    maxDate?: dayjs.Dayjs | null;
};

/**
 * Wraps native antd DatePicker component with memoization to reduce re-renders when the value hasn't changed.
 * This also removes a bug whereby sometimes the calendar gets reset on selecting a date, forcing the wrong date to be selected.
 */
const MemoizedDatePicker: React.FC<MemoizedDatePickerProps> = ({
    value = dayjs(),
    format = 'DD/MM/YYYY',
    onChange = () => {},
    onFocus = () => {},
    className = '',
    minDate = null,
    maxDate = null,
}) => {
    const [ actualValue, setActualValue ] = useState(value);
    
    return (
        <DatePicker
            value={actualValue}
            format={format}
            onChange={(date) => { actualOnChange(date, actualValue, setActualValue, onChange); }}
            onFocus={onFocus}
            className={className}
            minDate={minDate}
            maxDate={maxDate}
            allowClear={false}
            suffixIcon={<Calendar />}
        />
    );
}

export default React.memo(MemoizedDatePicker);