/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import Layout from '../../../../components/layout/Layout';
import FilterSidebar from '../../../../components/FilterSidebar';
import ReportFilter, { DATE_MODE_FREQUENCY } from '../../ReportFilter';
import ReportTab from './tabs/ReportTab';

export default function () {
    const {
        eventsTabLoaded, eventsTabFilter, filterSidebar, filters,
    } = this.state;
    const { user, isFetching } = this.props;

    return (
        <Layout
            className="events"
            title={<div>Carbon Report</div>}
            headerTitle={<div>Carbon Report</div>}
            loading={isFetching}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <FilterSidebar displayFilter={filterSidebar}>
                    <ReportFilter
                        onSubmit={this.onEventsTabFilterChange}
                        filters={filters}
                        showDivision
                        dateMode={DATE_MODE_FREQUENCY}
                    />
                </FilterSidebar>
                <div 
                    className="content"
                    style={{ opacity: isFetching ? 0 : 1 }}
                >
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <ReportTab
                                    toggleFilterSidebar={() => this.setState({ filterSidebar: !filterSidebar })}
                                    eventsTabFilter={eventsTabFilter}
                                    setLoadedState={this.eventsTabLoaded}
                                    eventsTabLoaded={eventsTabLoaded}
                                    onTableChange={this.onEventsTabSortChange}
                                    onPageChange={this.paginationChange}
                                />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
