
import React, { useState } from 'react';
import { VideoCameraOutlined } from '@ant-design/icons';
import { Tooltip, Radio, notification } from 'antd';
import Spinner from '../Spinner';
import moment from 'moment';
import { isEmpty } from 'underscore';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import GoogleStreetIcon from '../../assets/images/street-view-icon.png';
import RoadWithClock from '../../assets/images/road-with-clock.png';
import Icon from '../elements/Icon';
import { fetchApiAuth, getAddressFromLatLong } from '../../core/utils/api';
import { Video, Time, Notification, Report, Download } from '@carbon/icons-react';

import './mapAssetPopup.scss';

const { DB_DATETIME_FORMAT, DB_DATE_FORMAT } = require('../../core/constants');

let activeLat = null;
let activeLng = null;
let activeLocation = '';

function MapAssetPopupHead(props) {
    const [isExportingTimesheetCsv, setIsExportingTimesheetCsv] = useState(false);
    const assetId = props.assetId ? props.assetId : null;
    const selectedDate = props.selectedDate ? props.selectedDate : null;
    const registration = props.registration ? props.registration : null;
    const deviceDetailedLocationInfo = props.deviceDetailedLocationInfo ? props.deviceDetailedLocationInfo : null;

    let driverScore = '-';
    if (deviceDetailedLocationInfo && deviceDetailedLocationInfo.driver_score) {
        driverScore = deviceDetailedLocationInfo.driver_score;
        driverScore = Number(driverScore).toFixed(0);
    }

    const handleHistoricVideosClick = () => {
        if (props.onUpdateVideoSearch) {
            props.onUpdateVideoSearch({
                assetReg: registration,
            });
        }
    };

    const exportRecordsCsv = () => {
        if (props.isExportingCsv) {
            return;
        }

        if (assetId) {
            const date = moment(selectedDate, 'YYYYMMDD').format(DB_DATE_FORMAT);
            props.onGetCsvAssetRecordsByDayRequest({
                assetId,
                date,
            });
        }
        return true;
    };

    const exportTimesheetCsv = async () => {
        if (isExportingTimesheetCsv || !assetId) {
            return;
        }
        if (assetId) {
            setIsExportingTimesheetCsv(true);
            const date = moment(selectedDate, 'YYYYMMDD').format(DB_DATE_FORMAT);
            let params = {
                date_from: date,
                date_to: date,
                asset_or_driver: "assets",
                asset_id: [assetId],
                journey_or_day: "journeys",
            }

            const opts = {
                method: 'GET',
                url: `/report/get-timesheet-report-csv`,
                params,
            };

            const response = await fetchApiAuth(opts)
                .catch((err) => {
                    notification.error({ message: 'Error', description: 'Failed to create timesheet csv.' });
                    return null;
                });
            if (response?.data) {
                const blob = new Blob([response.data], { type: 'application/csv' });
                saveAs(blob, 'Timesheet.csv');
                notification.success({
                    message: 'Success',
                    description: 'File downloaded successfully.',
                });
            }
            setIsExportingTimesheetCsv(false);
        }
        return true;
    }

    function videoLink() {
        return (
            <Tooltip
                placement="topLeft"
                title="Live Video Feed"
            >
                <Radio.Button value="default">
                    <Link
                        to={`/video/live-videos/${props.assetId}?imei=${props.imei}`}
                        style={{
                            verticalAlign: 'middle',
                        }}
                    >
                        <Video />
                    </Link>
                </Radio.Button>
            </Tooltip>
        );
    }

    function historicVideoLink() {
        return (
            <Tooltip
                placement="topLeft"
                title="Historic Videos"
            >
                <Radio.Button value="default">
                    <Link
                        to="/video/cached-videos"
                        onClick={handleHistoricVideosClick}
                        style={{
                            verticalAlign: 'middle',
                        }}
                    >
                        <Time />
                    </Link>
                </Radio.Button>
            </Tooltip>
        );
    }

    function eventSearchLink() {
        if (deviceDetailedLocationInfo.act_as_tracker) {
            const title = (<>Event History not available for this Asset - <a href="https://www.fleetclear.com/contact" target="_blank" rel="noreferrer">Click Here</a> to find out more</>);

            return (
                <Tooltip
                    placement="topLeft"
                    title={title}
                >
                    <Radio.Button value="default" style={{fontFamily: 'inherit'}} disabled>
                        <Link
                            to="#"
                            style={{
                                verticalAlign: 'middle',
                                fontFamily: 'inherit',
                            }}
                        >
                            <Notification />
                        </Link>
                    </Radio.Button>
                </Tooltip>
            );
        }

        return (
            <Tooltip
                placement="topLeft"
                title="Event History"
            >
                <Radio.Button value="default">
                    <Link
                        to="/events/search"
                        onClick={() => {
                            props.onEventSearch({
                                assetId,
                                dateFrom: selectedDate,
                            });
                        }}
                        style={{
                            verticalAlign: 'middle',
                        }}
                    >
                        <Notification />
                    </Link>
                </Radio.Button>
            </Tooltip>
        );
    }

    function timeSheetReportLink() {
        if (deviceDetailedLocationInfo.act_as_tracker) {
            const title = (<>Timesheet Report not available for this Asset - <a href="https://www.fleetclear.com/contact" target="_blank" rel="noreferrer">Click Here</a> to find out more</>);

            return (
                <Tooltip
                    placement="topLeft"
                    title={title}
                >
                    <Radio.Button value="default" style={{fontFamily: 'inherit'}} disabled>
                        <Link
                            to="#"
                            style={{
                                verticalAlign: 'middle',
                                fontFamily: 'inherit',
                            }}
                        >
                            <Report />
                        </Link>
                    </Radio.Button>
                </Tooltip>
            );
        }

        return (
            <Tooltip
                placement="topLeft"
                title="Timesheet Report"
            >
                <Radio.Button
                    value="default"
                    disabled={isExportingTimesheetCsv}
                >
                    <Link
                        to="#"
                        onClick={exportTimesheetCsv}
                        style={{
                            verticalAlign: 'middle',
                        }}
                    >
                        {isExportingTimesheetCsv ? (
                            <Spinner
                                size={16}
                                minHeight={20}
                                loading={isExportingTimesheetCsv}
                            >
                                <Report />
                            </Spinner>
                        ) : (
                            <Report />
                        )}
                    </Link>
                </Radio.Button>
            </Tooltip>
        );
    }

    function recordsExportLink() {
        if (deviceDetailedLocationInfo.act_as_tracker) {
            const title = (<>Download Records not available for this Asset - <a href="https://www.fleetclear.com/contact" target="_blank" rel="noreferrer">Click Here</a> to find out more</>);

            return (
                <Tooltip
                    placement="topLeft"
                    title={title}
                >
                    <Radio.Button value="default" style={{fontFamily: 'inherit'}} disabled>
                        <Link
                            to="#"
                            style={{
                                verticalAlign: 'middle',
                                fontFamily: 'inherit',
                            }}
                        >
                            <Download />
                        </Link>
                    </Radio.Button>
                </Tooltip>
            );
        }

        return (
            <Tooltip
                placement="topLeft"
                title="Download Records"
            >
                <Radio.Button
                    value="default"
                    disabled={props.isExportingCsv}
                >
                    <Link
                        to="#"
                        onClick={exportRecordsCsv}
                        style={{
                            verticalAlign: 'middle',
                        }}
                    >
                        {props.isExportingCsv ? (
                            <Spinner
                                size={16}
                                minHeight={20}
                                loading={props.isExportingCsv}
                            >
                                <Download />
                            </Spinner>
                        ) : (
                            <Download />
                        )}
                    </Link>
                </Radio.Button>
            </Tooltip>
        );
    }

    return (
        <>
            <div className="map-popup__head">

                <div className="d-flex align-center">
                    <h5 className="h-5">{props.heading || '[not-set]'}</h5>
                    &nbsp;
                    {props.devices && (props.devices).find((device) => device == '0')
                        ? <Icon name="speedometer" /> : null}
                    {props.devices && (props.devices).find((device) => device == '1') ? <Icon name="video" /> : null}
                </div>
                <p className="map-popup__subtitle">
                    {props.showQuickActionButtons && deviceDetailedLocationInfo && (
                        <>

                        </>
                    )}
                </p>

                {props.showQuickActionButtons && (
                    <div style={{ position: 'absolute', top: '32px', right: '32px' }}>
                        <Radio.Group value="large">
                            {props.user && props.user.permissions.live_video === true && props.assetId && props.imei
                                ? videoLink()
                                : null}
                            {props.user && props.user.permissions.historic_video_downloading === true
                                ? historicVideoLink() : null}
                            {selectedDate && props.user && props.user.permissions.event_searching === true
                                ? eventSearchLink()
                                : null}
                            {timeSheetReportLink()}
                            {recordsExportLink()}
                        </Radio.Group>
                    </div>
                )}

                {props.hasEvents && props.priority === '1' && (
                    <button
                        type="button"
                        className="btn btn-empty">
                        <Icon name="youtube" />
                    </button>
                )}
            </div>
            {props.showQuickActionButtons && deviceDetailedLocationInfo && (
                <p style={{
                    padding: '0 16px 16px 16px',
                }}>
                    Driver Score: {driverScore}
                </p>
            )}
        </>
    );
}

function MapEventPopupHead(props) {
    return (
        <div className="map-popup__head">
            <div>
                <div className="d-flex align-center">
                    <h5 className="h-5">{props.heading || '[not-set]'}</h5>&nbsp;
                </div>
                <p className="map-popup__subtitle">
                    {props.registration || '[not-set]'}
                </p>

            </div>
        </div>
    );
}

function MapAssetPopupContent(props) {
    const loading = (props.showLoading && props.showLoading === true) || false;

    if (props.showNoData) {
        return (
            <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100px', minWidth: '650px',
            }}>
                <p style={{ color: 'var(--cool-gray-80)' }}>No record data available</p>
            </div>
        );
    }

    // this will eliminate the call every time component been rerendered, only it will call if the lat and lng been updated
    if ((props.lat != null && props.lng != null) && (activeLat !== props.lat || activeLng !== props.lng)) {
        activeLat = props.lat;
        activeLng = props.lng;
        activeLocation = getAddressFromLatLong(props.lat, props.lng);
    }

    let trip_odometer = null;
    const odometerData = props.location_data ? props.location_data.filter((ld) => ld.description === 'Odometer')[0] : null;
    if (odometerData) {
        trip_odometer = parseFloat(odometerData.value);
        if (props.speedUnit && props.speedUnit === 'mph') {
            // trip_odometer is in metres, convert it to Miles
            trip_odometer = parseFloat(trip_odometer / 1000);
            trip_odometer = parseFloat(trip_odometer / 1.609344);
            if (trip_odometer > 999999) {
                trip_odometer = trip_odometer.toFixed(0);
            } else {
                trip_odometer = trip_odometer.toFixed(2);
            }
        } else {
            // trip_odometer is in metres, convert it to Km
            trip_odometer = parseFloat(trip_odometer / 1000);
            if (trip_odometer > 999999) {
                trip_odometer = trip_odometer.toFixed(0);
            } else {
                trip_odometer = trip_odometer.toFixed(2);
            }
        }
    }

    let speedUnit = 'mph';
    let distanceUnit = 'miles';
    if (props.speedUnit && props.speedUnit !== 'mph') {
        speedUnit = 'kmh';
        distanceUnit = 'km';
    }

    const {
        speed, live, online, event_key,
    } = props;
    let showSpeed = false;

    if (live && online == '1') {
        showSpeed = true;
    } else if (event_key != null) {
        showSpeed = true;
    }

    return (
        <Spinner 
            loading={loading}
            minHeight={128}
            size={32}
        >
            <div style={{
                display: 'flex',
                minHeight: '250px',
                minWidth: '650px',
            }}>
                <div className="map-popup__body">
                    <div className="map-popup__datalist">
                        {
                            props.showPicture ? (
                                <div className="map-popup__picture">
                                    <div style={{
                                        width: 100,
                                        height: 100,
                                        backgroundImage: `url("${props.assetPicture || props.defaultImage}")`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }} />
                                    {props.userPicture && (
                                        <div style={{
                                            width: 100,
                                            height: 100,
                                            backgroundImage: `url("${props.userPicture}")`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            marginLeft: 5,
                                        }} />
                                    )}
                                </div>
                            )
                                : null
                        }
                        <p>{props.driver || ''}</p>
                        <p style={{
                            whiteSpace: 'pre-line',
                            width: 200,
                        }}>
                            {`${activeLocation || ''}`}
                        </p>
                        <p>{props.lat && props.lng ? `(${props.lat}, ${props.lng})` : ''}</p>
                        {props.dateTime != null
                            ? <p>Time: {moment(props.dateTime, DB_DATETIME_FORMAT).format('DD/MM/YYYY HH:mm:ss')}</p>
                            : null}
                        {props.event_key != null
                            ? <p style={{ color: 'rgb(255, 135, 51)', fontWeight: 'bold' }}>Event: {props.event_key}</p>
                            : null}
                        <p>
                            {props.online_status !== undefined ? (
                                props.online_status ? `Currently ${props.online_status}` : 'Currently Offline'
                            ) : null}
                        </p>
                    </div>

                    <div className="map-popup-icons">
                        {
                            showSpeed
                                ? (
                                    <>
                                        {
                                            props.isFetching ? <div>Fetching speed...</div>
                                                : (
                                                    <>
                                                        {
                                                            speed != null ? (
                                                                <div className="map-popup-icon">
                                                                    <span className="map-popup__speedometer">{speed}</span>
                                                                    <span className="ml-1">Speed ({speedUnit})</span>
                                                                </div>
                                                            ) : (
                                                                <div className="map-popup-icon">
                                                                    <span>Speed not available</span>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                        }
                                    </>
                                )
                                : <div className="map-popup-icon" />
                        }

                        <div
                            className="map-popup-icon"
                            style={{
                                float: 'right',
                                display: loading ? 'none' : 'block',
                                backgroundColor: '#fff',
                            }}
                            onClick={() => props.streetViewIconClicked(props.lat, props.lng, props.angle, props.googleMapAccessRef)}>
                            <Tooltip title="Show Asset's StreetView">
                                <span className="map-popup__streetview">
                                    <img src={GoogleStreetIcon} />
                                </span>
                            </Tooltip>
                        </div>
                        {props.showTimeToDestinationBtn && props.lat && props.lng ? (
                            <div
                                className="map-popup-icon"
                                style={{
                                    float: 'right',
                                    display: loading ? 'none' : 'block',
                                    marginRight: '15px',
                                }}>
                                <Tooltip title="Calculate Time to Destination">
                                    <a
                                        href={`https://www.google.com/maps/dir/?api=1&origin=${parseFloat(props.lat)},${parseFloat(props.lng)}`}
                                        target="_blank"
                                        rel="noreferrer">
                                        <span className="map-popup__streetview">
                                            <img src={RoadWithClock} />
                                        </span>
                                    </a>
                                </Tooltip>
                            </div>
                        ) : null}
                        {props && props.videoFunc ? (
                            <div
                                className="map-popup-icon"
                                style={{
                                    float: 'right',
                                    display: loading ? 'none' : 'block',
                                    fontSize: '23px',
                                    color: '#666',
                                    marginRight: '15px',
                                }}
                                onClick={props.videoFunc}>
                                <Tooltip title="Video Cameras">
                                    <span className="map-popup__camera">
                                        <VideoCameraOutlined />
                                    </span>
                                </Tooltip>
                            </div>
                        ) : null}
                    </div>
                </div>

                {!isEmpty(props.location_data) && Array.isArray(props.location_data)
                    ? (
                        <div className="map-popup__datatable">
                            <table>
                                <tbody>
                                    {props.location_data && props.location_data.map((element) => (
                                        <tr key={element.value}>
                                            <td>{element.description}</td>
                                            <td>{element.description === 'Odometer'
                                                ? `${trip_odometer} ${distanceUnit}`
                                                : element.value}
                                            </td>
                                        </tr>
                                    ))}
                                    {/* <tr key='tr-idle-time'>
                                        <td>Idle Time</td>
                                        <td>{props.deviceDetailedLocationInfo.driver_idle_time}</td>
                                    </tr>
                                    <tr key='tr-driving-time'>
                                        <td>Driving Time</td>
                                        <td>{props.deviceDetailedLocationInfo.driver_time_driven}</td>
                                    </tr>
                                    <tr key='tr-offline-time'>
                                        <td>Offline Time</td>
                                        <td>{props.deviceDetailedLocationInfo.driver_offline_time}</td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    ) : null}
            </div>
        </Spinner>
    );
}

// TODO: replace it with MapAssestPopupContent
function MapEventPopupContent(props) {
    // this will eliminate the call every time component been rerendered, only it will call if the lat and lng been updated
    if (activeLat !== props.lat || activeLng !== props.lng) {
        activeLat = props.lat;
        activeLng = props.lng;
        activeLocation = getAddressFromLatLong(props.lat, props.lng);
    }

    return (
        <div className="map-popup__body">
            <div className="map-popup__datalist">
                {props.dateTime != null ? <p>Time: {moment(props.dateTime, DB_DATETIME_FORMAT).format('DD/MM/YYYY HH:mm:ss')}</p> : null}
                <p>{props.driver || '-'}</p>
                <p>{`${activeLocation} (${props.lat}, ${props.lng})`}</p>
                {props.event_key != null ? <p>Event: {props.event_key}</p> : null}
            </div>
            <div className="map-popup-icon">
                <span className="map-popup__speedometer">{props.speed}</span>
                <span className="ml-1">Speed ({props.speedUnit || 'mph'})</span>
            </div>
            <div className="map-popup-icon" style={{ float: 'right' }} onClick={() => props.streetViewIconClicked(props.lat, props.lng, props.angle, props.googleMapAccessRef)}>
                <span className="map-popup__streetview">
                    <img src={GoogleStreetIcon} />
                </span>
            </div>
            {props && props.videoFunc
                ? (
                    <div
                        className="map-popup-icon"
                        style={{
                            float: 'right', fontSize: '23px', color: '#666', marginTop: '15px', marginRight: '15px',
                        }}
                        onClick={props.videoFunc}>
                        <span className="map-popup__camera">
                            <VideoCameraOutlined />
                        </span>
                    </div>
                )
                : null}
        </div>
    );
}

export default {
    MapAssetPopupHead,
    MapAssetPopupContent,
    MapEventPopupHead,
    MapEventPopupContent,
};
