/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Tabs } from 'antd';
import AdvancedTab from './Tabs/AdvancedTab';
import PresetsTab from './Tabs/PresetsTab';
import Layout from '../layout/Layout';

const { TabPane } = Tabs;

export default function () {
    const { currentTabKey, editWidgetData } = this.state;
    const { user, isFetchingUserWidgets, isFetchingPresetWidgets } = this.props;

    const isLoading = isFetchingUserWidgets || isFetchingPresetWidgets;

    return (
        <Layout
            data-test="pages-dashboard"
            className="assets smart-widget"
            title={<div>Add New Widget</div>}
            headerTitle={<div>Add New Widget</div>}
            showFooter={false}
            loading={isLoading}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <div 
                    className="content"
                    style={{ opacity: isLoading ? 0 : 1 }}
                >
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <br />
                                <br />
                                <Tabs
                                    onTabClick={(key) => this.clickTab(key)}
                                    defaultActiveKey="1"
                                    activeKey={currentTabKey}
                                    animated={false}
                                    className="tabs-restyled"
                                >
                                    <TabPane 
                                        tab="Presets" 
                                        key="1"
                                    >
                                        <PresetsTab onTabSwitch={(tabKey) => this.setState({ currentTabKey: tabKey })}/>
                                    </TabPane>
                                    <TabPane 
                                        tab="Advanced" 
                                        key="2"
                                    >
                                        <AdvancedTab editWidgetData={editWidgetData} />
                                    </TabPane>
                                </Tabs>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
