/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Modal } from 'antd';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import FilterForm from './FilterForm';
import AddUserForm from './forms/AddUserForm';

import './users.scss';

export default function () {
    const { currentTabKey, userCreateModalOpen } = this.state;
    const { user, usersIsFetching } = this.props;

    return (
        <Layout 
            data-test="pages-users"
            className="users"
            title={<div>Users</div>}
            headerTitle={<div>Users Page</div>}
            showFooter={false}
            loading={usersIsFetching}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <FilterSidebar displayFilter>
                    <FilterForm
                        key={`filter_form_1`}
                        type='user'
                        onSubmit={(filterData) => (this.filterUsers(filterData))} 
                    />
                </FilterSidebar>
                <div 
                    className="content"
                    style={{ opacity: usersIsFetching ? 0 : 1 }}
                >
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                {currentTabKey === '1' ? this.usersTabContent() : null}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Modal
                open={userCreateModalOpen}
                title="Create user"
                onCancel={() => this.setState({ userCreateModalOpen: false })}
                footer={null}
            >
                <AddUserForm />
            </Modal>
        </Layout>
    );
}
