/* global document */
/* global window:true */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { FlagFilled } from '@ant-design/icons';
import {
    Button, Col, Row, Input, Spin, Tooltip, Card, notification, 
} from 'antd';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty, map, each } from 'underscore';
import { replaceAll } from 'underscore.string';
import Map from '../Map';
import Icon from '../elements/Icon';
import DefaultDrivePicture from '../../assets/images/default-image.png';
import * as eventsActions from '../../core/events/eventsActions';
import * as videoActions from '../../core/video/videoActions';
import * as reportActions from '../../core/report/reportActions';
import history from '../../core/utils/history';
import DetailView from '../DetailView';
import { calculateSpeedAndUnit, getMapAssetPopupContent, getMapAssetPopupHead } from '../../core/utils/mapUtils';

const { GOOGLE_MAP_API_KEY, DEBUG, DB_DATETIME_FORMAT } = require('../../core/constants').default;

class EventCustomViewPage extends Component {
    constructor(props) {
        super(props);
        this.googleMapAccess = React.createRef();
        this.textFieldRef = React.createRef();
        this.pdfDocumentRef = React.createRef();
        this.centerMap = {};

        let start_time = '';
        let end_time = '';

        if (props.selectedEvent && props.selectedEvent.startInterval) {
            start_time = this.formatSliderSecondsToEventDateTime(props.selectedEvent.startInterval);
        }

        if (props.selectedEvent && props.selectedEvent.endInterval) {
            end_time = this.formatSliderSecondsToEventDateTime(props.selectedEvent.endInterval);
        }

        this.state = {
            copySuccess: '',
            gettingPdf: false,
            sliderValue: props.params.slider_value || props.selectedEvent.interval || props.selectedEvent.startInterval,
            asset_picture: '',
            profile_picture: '',
            cacheds: [],
            otherVideos: [],
            start_time,
            end_time,
            rangeMinTimeString: '',
            rangeMaxTimeString: '',
        };

        this.cachedVideosRefs = [];
        this.timeout = 0;
    }

    componentDidMount() {
        const {
            actions, params, selectedEvent, showStitchVideo,
        } = this.props;
        const { eventId, deviceId } = params;

        if (showStitchVideo) {
            actions.getStitchedVideoRequest({
                page: 1,
                event_id: eventId,
                device_id: deviceId,
                page_size: 60,
            });
        } else {
            actions.getVideosAroundEventRequest({
                page: 1,
                event_id: eventId,
                device_id: deviceId,
                page_size: 60,
            });
        }

        setTimeout(() => {
            if (this.googleMapAccess && this.googleMapAccess.current) {
                this.googleMapAccess.current.refreshMap();
            }
        }, 2500);

        if (selectedEvent.info) {
            if (selectedEvent.info.asset_picture) {
                this.asset_picture = selectedEvent.info.asset_picture;
                this.setState({
                    asset_picture: selectedEvent.info.asset_picture,
                });
            } else {
                this.asset_picture = DefaultDrivePicture;
                this.setState({
                    asset_picture: DefaultDrivePicture,
                });
            }
            if (selectedEvent.info.profile_picture) {
                this.profile_picture = selectedEvent.info.profile_picture;
                this.setState({
                    profile_picture: selectedEvent.info.profile_picture,
                });
            } else {
                this.setState({
                    profile_picture: DefaultDrivePicture,
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedEvent, updateMapEventData, actions } = this.props;
        if (updateMapEventData === true) {
            actions.resetUpdateMapEventDataFlagRequest();
            setTimeout(() => {
                if (this.googleMapAccess && this.googleMapAccess.current) {
                    this.googleMapAccess.current.setState({ sliderMaxValue: selectedEvent.endInterval });
                    this.googleMapAccess.current.executeAutoZoom();
                }
            }, 500);
        }

        if (this.props.videos != prevProps.videos) {
            this.setCachedVideos();
            setTimeout(() => this.onVideosLoaded(), 200);
        }
    }

    setCachedVideos() {
        const { videos } = this.props;
        const cachedVideos = videos.filter((video) => video.status === 'cached');
        const otherVideos = videos.filter((video) => video.status !== 'cached');

        this.setState({
            cachedVideos,
            otherVideos,
        });
    }

    paginationChange = (page) => {
        const {
            actions, pagination, params, showStitchVideo,
        } = this.props;
        const { eventId, deviceId } = params;
        const { currentPage } = pagination;
        if (page !== currentPage) {
            if (showStitchVideo) {
                actions.getStitchedVideoRequest({
                    page,
                    event_id: eventId,
                    device_id: deviceId,
                    page_size: 60,
                });
            } else {
                actions.getVideosAroundEventRequest({
                    page,
                    event_id: eventId,
                    device_id: deviceId,
                    page_size: 60,
                });
            }
        }
    };

    handleTableChange = (pagination) => {
        const {
            actions, pagination: p, params, showStitchVideo,
        } = this.props;
        const { eventId, deviceId } = params;
        const { currentPage: page } = p;
        // checking when only order changes
        if (pagination && pagination.current === page) {
            if (showStitchVideo) {
                actions.getStitchedVideoRequest({
                    page,
                    event_id: eventId,
                    device_id: deviceId,
                    page_size: 60,
                });
            } else {
                actions.getVideosAroundEventRequest({
                    page,
                    event_id: eventId,
                    device_id: deviceId,
                    page_size: 60,
                });
            }
        }
    };

    /**
     * Converts seconds since midnight to a formatted MM:SS or HH:MM:SS string
     * @param {*} secondsSinceMidnight Number of seconds since midnight
     */
    convertFromSeconds(secondsSinceMidnight) {
        const t = secondsSinceMidnight;
        const hours = Math.floor(t / 3600);
        const minutes = `${Math.floor((t / 60) % 60)}`.padStart(2, '0');
        const seconds = `${Math.floor(t % 60)}`.padStart(2, '0');
        if (hours !== 0) {
            return `${hours}:${minutes}:${seconds}`;
        }
        return `${minutes}:${seconds}`;
    }

    mapDevicePath = () => {
        const { selectedEvent } = this.props;
        let foundFirstEvent = false;

        return selectedEvent && selectedEvent.path ? selectedEvent.path.map((event, eventIndex) => {
            const tempEvent = {};
            tempEvent.time_interval = event.time_interval;
            tempEvent.lat = event.lat;
            tempEvent.lng = event.lng;
            tempEvent.angle = event.angle;
            tempEvent.speed = event.speed;
            tempEvent.position = 'path';
            tempEvent.time = event.time;

            if ((event.priority === 1 && foundFirstEvent === false) || eventIndex === 0) {
                this.centerMap.lat = parseFloat(event.lat);
                this.centerMap.lng = parseFloat(event.lng);
                if (eventIndex > 0) {
                    foundFirstEvent = true;
                }
            }
            if (event.show_in_events === 1) {
                this.centerMap.lat = parseFloat(event.lat);
                this.centerMap.lng = parseFloat(event.lng);
                const iconStyle = {};
                iconStyle.fontSize = '16px';
                iconStyle.color = '#CF2B28';
                tempEvent.position = 'marker';
                tempEvent.currentMarker = {};
                let eventTitle = event.event_name;

                if (event.event_type == 253) {
                    if (event.green_driving_type == 0) {
                        eventTitle = 'Eco-friendly driving';
                    } else if (event.green_driving_type == 1) {
                        eventTitle = 'Harsh accelerating';
                    } else if (event.green_driving_type == 2) {
                        eventTitle = 'Harsh braking';
                    } else if (event.green_driving_type == 3) {
                        eventTitle = 'Harsh cornering';
                    }
                }

                if (event.icon && event.icon !== '') {
                    tempEvent.currentMarker.icon = <Tooltip placement="top" title={eventTitle}><LegacyIcon style={iconStyle} theme="filled" type={event.icon} /></Tooltip>;
                } else {
                    tempEvent.currentMarker.icon = <Tooltip placement="top" title={eventTitle}><FlagFilled style={iconStyle} /></Tooltip>;
                }
                // tempEvent.currentMarker.icon = event.icon && event.icon !== ''
                //     ? (
                //         <Tooltip title={event.event_name}><AntIcon
                //             type={event.icon}
                //             theme="filled"
                //             style={iconStyle} />
                //         </Tooltip>
                //     )
                //     : <AntIcon type="flag" theme="filled" />;
            }
            return tempEvent;
        })
            : [];
    };

    getAssetCurrentPin = (mappedDevicePath) => {
        const { sliderValue } = this.state;
        const { selectedEvent, user } = this.props;
        const assets = mappedDevicePath.filter((path) => path.time_interval == sliderValue);
        if (assets && assets[0]) {
            const { speed, unit } = calculateSpeedAndUnit(assets[0], user);
            const title = getMapAssetPopupHead(assets[0]);
            const content = getMapAssetPopupContent(this, assets[0], speed, unit, user?.userCompany?.company_image);

            assets[0].arrowIcon = true;
            assets[0].hasEvents = true;
            assets[0].imei = '';
            assets[0].online = 0;
            assets[0].registration = '';
            assets[0].title = '';
            assets[0].markers = [];
            assets[0].currentMarker = {};
            assets[0].currentMarker.lat = parseFloat(assets[0].lat);
            assets[0].currentMarker.lng = parseFloat(assets[0].lng);
            assets[0].currentMarker.angle = assets[0].angle;
            assets[0].currentMarker.imei = '';
            assets[0].currentMarker.showArrowIcon = true;
            assets[0].currentMarker.speed = speed;
            assets[0].currentMarker.time_interval = assets[0].time_interval;
            assets[0].currentMarker.title = '';
            assets[0].markers[assets[0].time_interval] = {};
            assets[0].markers[assets[0].time_interval].lat = parseFloat(assets[0].lat);
            assets[0].markers[assets[0].time_interval].lng = parseFloat(assets[0].lng);
            assets[0].markers[assets[0].time_interval].angle = assets[0].angle;
            assets[0].markers[assets[0].time_interval].imei = '';
            assets[0].markers[assets[0].time_interval].showArrowIcon = true;
            assets[0].markers[assets[0].time_interval].speed = speed;
            assets[0].markers[assets[0].time_interval].time_interval = assets[0].time_interval;
            assets[0].markers[assets[0].time_interval].title = title;
            assets[0].markers[assets[0].time_interval].content = content;
        }
        return assets;
    };

    renderPdf(toPdf) {
        window.scrollTo(0, 0);
        setTimeout(() => {
            toPdf();
            this.setState({
                gettingPdf: true,
            });
        }, 200);
    }

    renderVideo(video) {
        const { loading } = this.props;

        return (
            <Col
                className="grid-video"
                key={`camera-${video.id}`}
                xl={12}
                md={12}
                style={{
                    overflow: 'hidden',
                    paddingLeft: 5,
                    paddingRight: 5,
                }}>

                <div>
                    <Spin spinning={loading}>
                        { /* video.aws_url_converted ? <VideoPayer identity={video.id} url={video.aws_url_converted} /> : null */}

                        {video.aws_url_converted ? (
                            <video ref={(ref) => this.createVideoRef(ref, video.id)} controls width="100%">
                                <source src={video.aws_url_converted} type="video/mp4" />
                            </video>
                        )
                            : null}
                    </Spin>
                </div>
            </Col>
        );
    }

    renderOtherVideo(video) {
        const { loading } = this.props;

        return (
            <Col
                className="grid-video"
                key={`camera-${video.id}`}
                xl={12}
                md={12}
                style={{
                    overflow: 'hidden',
                    paddingLeft: 5,
                    paddingRight: 5,
                }}>

                <div className="other-video-block">
                    <div className="video-bg">
                        <div className="video-icon">
                            <Icon name="video" fill="white" style={{ fill: 'white', fontSize: 80 }} />
                        </div>
                        <div className="video-title">
                            Uncached video
                        </div>
                        <div className="video-subtitle">
                            {video.start_time}
                            <br />
                            {video.status}
                        </div>
                    </div>
                </div>
            </Col>
        );
    }

    createVideoRef = (ref, video_id) => {
        if (!this.cachedVideosRefs[video_id]) {
            this.cachedVideosRefs[video_id] = ref;
        }
    };

    formatSliderSecondsToEventDateTime(seconds) {
        const { selectedEvent } = this.props;

        const eventDateDayString = moment(selectedEvent.startTime, DB_DATETIME_FORMAT).format('YYYY-MM-DD 00:00:00');
        const datetime = moment(eventDateDayString).add(seconds, 'seconds').format(DB_DATETIME_FORMAT);

        return datetime;
    }

    onSliderChange(value, formattedValue, rangeMin, rangeMax) {
        const { cachedVideos, start_time, end_time } = this.state;
        const {
            selectedEvent, params, actions, showStitchVideo,
        } = this.props;

        const rangeMinTimeString = this.formatSliderSecondsToEventDateTime(rangeMin);
        const rangeMaxTimeString = this.formatSliderSecondsToEventDateTime(rangeMax);

        if (start_time !== rangeMinTimeString || end_time !== rangeMaxTimeString) {
            // this stops to call get videos on every slider step only gets when user stop sliding
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                if (showStitchVideo) {
                    actions.getStitchedVideoRequest({
                        page: 1,
                        event_id: params.eventId,
                        device_id: params.deviceId,
                        start_time: rangeMinTimeString,
                        end_time: rangeMaxTimeString,
                        page_size: 60,
                    });
                } else {
                    actions.getVideosAroundEventRequest({
                        page: 1,
                        event_id: params.eventId,
                        device_id: params.deviceId,
                        start_time: rangeMinTimeString,
                        end_time: rangeMaxTimeString,
                        page_size: 60,
                    });
                }

                actions.getSideEventsRequest({
                    event_id: params.eventId,
                    start_time: rangeMinTimeString,
                    end_time: rangeMaxTimeString,
                });
            }, 1000);
        }

        if (!isEmpty(cachedVideos)) {
            each(cachedVideos, (singleVideo) => {
                const timeDifference = moment(selectedEvent.startTime).diff(moment(singleVideo.start_time));
                const videoTimeDifferenceInSeconds = timeDifference / 1000;

                // getting timeframe difference
                const selectedTimeString = this.formatSliderSecondsToEventDateTime(value);
                const selectedTimeDiffFromEventStartInSeconds = (moment(selectedTimeString).diff(moment(selectedEvent.startTime))) / 1000;

                const addSecToVideo = videoTimeDifferenceInSeconds + selectedTimeDiffFromEventStartInSeconds;

                if (addSecToVideo > 0 && this.cachedVideosRefs[singleVideo.id]) {
                    this.cachedVideosRefs[singleVideo.id].currentTime = addSecToVideo;
                }
            });
        }

        this.setState({ sliderValue: value, rangeMinTimeString, rangeMaxTimeString });
    }

    onVideosLoaded() {
        const { cachedVideos } = this.state;
        const { selectedEvent } = this.props;

        if (!isEmpty(cachedVideos)) {
            each(cachedVideos, (singleVideo) => {
                const timeDifference = moment(selectedEvent.startTime).diff(moment(singleVideo.start_time));
                const videoTimeDifferenceInSeconds = timeDifference / 1000;

                // getting timeframe difference
                const selectedTimeString = moment(selectedEvent.startTime).format(DB_DATETIME_FORMAT);
                const selectedTimeDiffFromEventStartInSeconds = (moment(selectedTimeString).diff(moment(selectedEvent.startTime))) / 1000;

                const addSecToVideo = videoTimeDifferenceInSeconds + selectedTimeDiffFromEventStartInSeconds;

                if (addSecToVideo > 0 && this.cachedVideosRefs[singleVideo.id]) {
                    this.cachedVideosRefs[singleVideo.id].currentTime = addSecToVideo;
                }
            });
        }
    }

    requestVideoCache() {
        const { otherVideos } = this.state;
        const { actions } = this.props;

        const videosToCache = [];

        otherVideos.map((video) => {
            if (video.status == 'discovered') {
                videosToCache.push(video.id);
            }
        });

        if (!isEmpty(videosToCache)) {
            actions.cacheVideosRequest({ videosToCache });
        } else {
            notification.warning({ message: 'Warning', description: 'Cache has been already requested' });
        }
    }

    render() {
        const {
            copySuccess, sliderValue, gettingPdf, cachedVideos, otherVideos, rangeMinTimeString, rangeMaxTimeString,
        } = this.state;

        const {
            actions, selectedEvent, onTimeDataChange, allowEdit, params, before_time, after_time,
            loading, pagination, videos, user, sideEvents, showStitchVideo,
        } = this.props;
        let shareLink = null;
        let sharePassword = null;
        if (selectedEvent && selectedEvent.share && selectedEvent.share.unique_code && selectedEvent.share.expires > 0) {
            shareLink = DEBUG || window.location.hostname === 'localhost'
                ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/custom-event/${selectedEvent.share.unique_code}`
                : `${window.location.protocol}//${window.location.hostname}/custom-event/${selectedEvent.share.unique_code}`;

            sharePassword = selectedEvent.share.password;
        }

        const isSharedAndValid = selectedEvent.share && selectedEvent.share.expires && selectedEvent.share.expires > 0;
        const isSharedAndExpired = selectedEvent.share && selectedEvent.share.expires && selectedEvent.share.expires <= 0;

        const mappedDevicePath = this.mapDevicePath();
        const assets = this.getAssetCurrentPin(mappedDevicePath);

        let idleTime = 0;
        let trueIdleTime = 0;
        let score = 0;
        let timeDrivenFormatted = 0;
        let averageSpeed = 0;
        let averageMpg = 0;
        let fuelLevel = 0;
        let eventType = '';
        let eventStart = '';
        let eventStartLocation = '';
        let driverAverageScore = '';
        let speedUnit = 'kph';
        let mpgUnit = 'L/100km';

        if (!isEmpty(selectedEvent.telematicsData) && !isEmpty(selectedEvent.telematicsData.journey)) {
            idleTime = selectedEvent.telematicsData.journey.idle_time;
            trueIdleTime = selectedEvent.telematicsData.journey.true_idle_time;
            score = selectedEvent.telematicsData.journey.score;
            timeDrivenFormatted = selectedEvent.telematicsData.journey.time_driven_formatted;
            averageSpeed = selectedEvent.telematicsData.journey.average_speed;
            averageMpg = selectedEvent.telematicsData.journey.mpg;
            fuelLevel = selectedEvent.telematicsData.journey.fuel_level;
        }
        if (!isEmpty(selectedEvent.telematicsData)) {
            eventType = selectedEvent.telematicsData.eventType;
            eventStart = selectedEvent.telematicsData.eventStart;
            eventStartLocation = selectedEvent.telematicsData.eventStartLocation;
            eventStartLocation = replaceAll(eventStartLocation, ',', ', ');
            driverAverageScore = selectedEvent.telematicsData.driverAverageScore;
            if (user && user.profile && user.profile.unit_of_measurement_preference === 'british-imperial') {
                speedUnit = 'mph';
                averageSpeed = Math.floor(averageSpeed * 0.62);
                mpgUnit = 'UK mpg';
                averageMpg = averageMpg > 0 ? (282.481 / averageMpg).toFixed(2) : 0;
            }
        }

        const chartData = [
            {
                name: 'Idle Time',
                pv: idleTime || 0,
            },
            {
                name: 'True Idle',
                pv: trueIdleTime || 0,
            },
        ];

        const columns = (videos && videos.map((v) => ({
            video_id: v.id,
            date: v.start_time && moment(v.start_time)
                .format('DD/MM/YYYY'),
            time: v.start_time && v.start_time.substring(11),
            location: (
                <div className="d-flex align-center">
                    <Icon name="location" />
                    <span className="ml-1">{v.location || 'Not specified'}</span>
                </div>
            ),
            duration: (
                <div className="d-flex align-center">
                    <Icon name="clock" />
                    <span className="ml-1">{this.convertFromSeconds(v.duration)} </span>
                </div>
            ),
            actions: (
                <Button type="link" onClick={() => { actions.setSingleVideoRequest(v); history.push(`/video/historic-videos/${v.id}`); }}>
                    <Icon name="chevron-right" className="ml-2" />
                </Button>
            ),
        }))) || [];

        const mapToolBarButtons = [
            {
                key: 'side-bar',
                visible: false,
            },
            {
                key: 'info-box',
                visible: true,
            },
            {
                key: 'traffic',
                visible: false,
            },
            {
                key: 'geo-fence',
                visible: false,
            },
            {
                key: 'center-map',
                visible: false,
            },
            {
                key: 'satellite',
                visible: true,
            },
            {
                key: 'heat-map',
                visible: false,
            },
            {
                key: 'live-map',
                visible: false,
            },
        ];

        const pdfRef = React.createRef();

        return (
            <div
                id="pdfDocumentRef"
                ref={pdfRef}
                style={{ paddingLeft: 50 }}>
                <Spin spinning={loading}>
                    <DetailView
                        showShareBlock={false}
                        selectedEvent={selectedEvent} />
                </Spin>
                <Row
                    gutter={40}
                    type="flex"
                    justify="space-between"
                    className="data-row map-row">
                    <Col
                        xl={15}
                        md={24}>
                        <Row
                            gutter={40}
                            type="flex"
                            justify="space-between"
                            className="data-row map-row">
                            <Col
                                xl={24}
                                style={{
                                    height: 350,
                                    width: '100%',
                                    marginBottom: '35px',
                                }}>
                                <Map
                                    ref={this.googleMapAccess}
                                    mapDate={moment()
                                        .subtract('1', 'days')
                                        .format('DD/MM/YYYY')}
                                    apiKey={GOOGLE_MAP_API_KEY}
                                    mapToolBarButtons={mapToolBarButtons}
                                    sliderStep={1}
                                    sliderValue={parseInt(sliderValue, 10)}
                                    sliderMinValue={selectedEvent.startInterval}
                                    sliderMaxValue={selectedEvent.endInterval}
                                    sliderRangeMinValue={selectedEvent.startInterval}
                                    sliderRangeMaxValue={selectedEvent.endInterval}
                                    sliderValueUnit="seconds"
                                    hideDatePicker
                                    center={this.centerMap}
                                    onSliderRangeChange={(value, formattedValue, rangeMin, rangeMax) => this.onSliderChange(value, formattedValue, rangeMin, rangeMax)}
                                    // heatMap={heatMap}
                                    assets={assets}
                                    // showToolbar={false}
                                    allowMultipleRangeSlider
                                    allowStreetView={false}
                                    showFindCenterButton={false}
                                    path={mappedDevicePath} />
                            </Col>
                        </Row>
                        <div className="event-view-summary-block-custom">
                            {allowEdit
                                ? (
                                    <Row gutter={[24, 24]} className="data-row" data-html2canvas-ignore="true">
                                        {allowEdit
                                            ? (
                                                <Col
                                                    sm={24}
                                                    md={24}
                                                    lg={24}
                                                    style={{ textAlign: 'right' }}
                                                    className="action-column">

                                                    {user.permissions.web_report_sharing === true ? (

                                                        <Button
                                                            type="primary"
                                                            className="btn"
                                                            onClick={() => {
                                                                if (!isSharedAndExpired && !isSharedAndValid) {
                                                                    const { actions } = this.props;
                                                                    actions.shareWebReportRequest({
                                                                        eventId: params.eventId,
                                                                        deviceId: params.deviceId,
                                                                        before_time,
                                                                        after_time,
                                                                        sliderValue,
                                                                        start_time: rangeMinTimeString,
                                                                        end_time: rangeMaxTimeString,
                                                                    });
                                                                } else if (isSharedAndExpired) {
                                                                    // renew the web report, add 30 days to generated_on
                                                                    const { actions } = this.props;
                                                                    actions.renewSharedReportRequest({
                                                                        eventId: params.eventId,
                                                                        deviceId: params.deviceId,
                                                                        before_time,
                                                                        after_time,
                                                                        uniqueCode: selectedEvent.share.unique_code,
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {isSharedAndValid ? <span>{`shared, expires in ${selectedEvent.share.expires} days`}</span> : null}
                                                            {isSharedAndExpired ? <span>Renew web report link</span> : null}
                                                            {!isSharedAndExpired && !isSharedAndValid ? <span>Share</span> : null}

                                                        </Button>

                                                    )
                                                        : null}
                                                </Col>
                                            )
                                            : (
                                                <Col
                                                    sm={12}
                                                    md={12}
                                                    lg={6}
                                                    style={{ textAlign: 'center' }}
                                                    className="action-column">
                                                    <Button
                                                        type="primary"
                                                        className="btn ml-2"
                                                        onClick={() => {
                                                            if (params && params.code) {
                                                                const { actions } = this.props;
                                                                actions.downloadPdfReportRequest({
                                                                    code: params.code,
                                                                    file: 'event-report.pdf',
                                                                });
                                                            }
                                                        }}>
                                                        <Icon name="share2" />
                                                        <span>download pdf report</span>
                                                    </Button>
                                                </Col>
                                            )}
                                    </Row>
                                )
                                : null}

                            {allowEdit && shareLink && sharePassword
                                ? (
                                    <Row className="data-row">
                                        <Col sm={{ span: 20, offset: 1 }} md={15}>
                                            <Input ref={this.textFieldRef} readOnly value={shareLink} />
                                        </Col>
                                        <Col sm={2} md={3} style={{ padding: '8px' }}>
                                            <Button
                                                type="primary"
                                                size="small"
                                                style={{
                                                    cursor: 'pointer',
                                                    fontSize: '12px',
                                                }}
                                                onClick={(e) => {
                                                    this.textFieldRef.current.select();
                                                    document.execCommand('copy');
                                                    e.target.focus();
                                                    this.setState({ copySuccess: 'Copied' });
                                                    setTimeout(() => {
                                                        this.setState({ copySuccess: '' });
                                                    }, 3000);
                                                }}>
                                                <span style={{
                                                    fontSize: '10px',
                                                    lineHeight: '18px',
                                                }}>COPY LINK
                                                </span>
                                                <span
                                                    style={{ color: '#FF0000' }}>{copySuccess}
                                                </span>
                                            </Button>
                                        </Col>
                                        <Col sm={2} md={2}>
                                            Password: {sharePassword}
                                        </Col>

                                    </Row>
                                )
                                : null}
                        </div>

                        <Row className="data-row" />
                        <Row gutter={[24, 24]} className="data-row event-view-driver-info" ref={this.pdfDocumentRef}>
                            <Col xs={24} sm={24} md={12} style={{ verticalAlign: 'top' }}>
                                <div className="data-row-header">DRIVER INFORMATION</div>
                                <div className="data-block">
                                    <Row gutter={[24, 24]} className="">
                                        <Col xs={12} sm={24} md={12} lg={10}>
                                            <img
                                                style={{
                                                    width: '200',
                                                    height: 'auto',
                                                }}
                                                alt={(selectedEvent.info && selectedEvent.info.driver) || '[not-set]'}
                                                // src={(selectedEvent.info && selectedEvent.info.profile_picture) || DefaultDrivePicture}
                                                src={this.state.profile_picture} />
                                        </Col>
                                        <Col xs={12} sm={8} md={12} lg={14}>
                                            <div className="title">
                                                <p> {selectedEvent.info && selectedEvent.info.user_id
                                                    ? (
                                                        <Link
                                                            to={`/drivers/view/${(selectedEvent.info && selectedEvent.info.user_id) || null}`}>{(selectedEvent.info && selectedEvent.info.driver) || '[not-set]'}
                                                        </Link>
                                                    )
                                                    : (selectedEvent.info && selectedEvent.info.driver) || '[not-set]'}
                                                </p>
                                            </div>
                                            <p><span className="driver">Driver</span></p>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="data-block">

                                    <Row gutter={[24, 24]} className="">
                                        <Col xs={12} sm={8} md={12} lg={10}>
                                            <img
                                                style={{
                                                    width: '200',
                                                    height: 'auto',
                                                }}
                                                alt={(selectedEvent.info && selectedEvent.info.registration) || '[not-set]'}
                                                // src={(selectedEvent.info && selectedEvent.info.asset_picture) || DefaultAssetPicture}
                                                src={this.state.asset_picture} />
                                        </Col>
                                        <Col xs={12} sm={8} md={12} lg={14}>
                                            <div className="title">
                                                {selectedEvent.info && selectedEvent.info.asset_id
                                                    ? (
                                                        <Link
                                                            to={`/assets/view/${(selectedEvent.info && selectedEvent.info.asset_id) || null}`}>{(selectedEvent.info && selectedEvent.info.registration) || '[not-set]'}
                                                        </Link>
                                                    )
                                                    : (selectedEvent.info && selectedEvent.info.registration) || '[not-set]'}
                                            </div>
                                            <Row gutter={[24, 24]} className="">
                                                <Col 
                                                    xs={12} 
                                                    sm={12} 
                                                    md={24} 
                                                    lg={12}
                                                >
                                                    <div className="asset-info-heading">Last service:</div>
                                                </Col>
                                                <Col 
                                                    xs={12} 
                                                    sm={12} 
                                                    md={12} 
                                                    lg={12}
                                                >
                                                    <div className="asset-info-heading">Registration:</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={[24, 24]} className="">
                                                <Col xs={12} sm={12} md={24} lg={12}>
                                                    <div
                                                        className="asset-info">{(selectedEvent.info && moment(selectedEvent.info.last_service).format('DD-MM-YYYY')) || 'Unknown'}
                                                    </div>
                                                </Col>

                                                <Col xs={12} sm={4} md={24} lg={12}>
                                                    <div
                                                        className="asset-info">{(selectedEvent.info && selectedEvent.info.registration) || '[not-set]'}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} style={{ verticalAlign: 'top' }}>
                                <div className="data-block">
                                    <div className="data-row-header">EVENT INFORMATION</div>
                                    <Row className="journey-info-row">
                                        <Col xs={5}><span>Event type</span></Col>
                                        <Col xs={19}>{eventType}</Col>
                                    </Row>
                                    <Row className="journey-info-row">
                                        <Col xs={5}><span>Event time</span></Col>
                                        <Col xs={19}>{eventStart}</Col>
                                    </Row>
                                    <Row className="journey-info-row">
                                        <Col xs={5}><span>Event location</span></Col>
                                        <Col xs={19}>{eventStartLocation}</Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={24} sm={5} md={0} lg={5} style={{ verticalAlign: 'top' }} />

                            <Col xs={32} sm={4} md={18} lg={28}>
                                <Row>
                                    {/* ISS-1283 */}
                                    {/*
                                    <Col xs={32} sm={4} md={9} lg={5} className="telematics-data-card-col fuel-progress middle">
                                        <Card style={{ height: 121 }}>
                                            <div className="telematic-box-stat-icon">
                                                <img src={fuelIcon} alt="journey score" />
                                            </div>
                                            <div className="telematic-box-stat-text">
                                                FUEL LEVEL
                                            </div>
                                            <div className="telematic-box-stat">
                                                {fuelLevel}%
                                                <Progress
                                                    className="telematics-fuel-level-progress"
                                                    type="line"
                                                    percent={parseFloat(fuelLevel)}
                                                    showInfo={false}
                                                    strokeColor="#FF8733"
                                                    strokeLinecap="square"
                                                    strokeWidth={10} />

                                            </div>

                                        </Card>
                                    </Col>
                                    */}
                                    {/* ISS-1283 */}
                                    {/*
                                    <Col xs={32} sm={4} md={9} lg={9} xl={5} className="telematics-data-card-col first">
                                        <div className="telematics-data-card-title">
                                            <span
                                                className="telematics-data-card-title-text">Activations
                                            </span>
                                        </div>
                                        <Card className="telematics-data-card" style={{ height: 100 }}>

                                            <Row gutter={[24, 24]}>

                                                <Col xs={8} sm={4} md={14} lg={14}>
                                                    <div className="activations-title">
                                                        Reverse Activations
                                                    </div>
                                                </Col>
                                                <Col xs={8} sm={4} md={2} lg={2}>
                                                    <div className="activations-stat">
                                                        2
                                                    </div>
                                                </Col>

                                            </Row>

                                        </Card>
                                    </Col>
                                    */}
                                </Row>
                            </Col>

                        </Row>
                    </Col>
                    <Col
                        xl={9}
                        md={24}>
                        <Row
                            className="data-row">
                            <Col
                                xs={24}
                                style={{
                                    verticalAlign: 'top',
                                    width: '100%',
                                }}>
                                <div className="data-row-header">VIDEOS AROUND THE TIME OF THE EVENT</div>
                            </Col>
                        </Row>
                        <Spin spinning={loading} style={{ width: '100%' }}>
                            <Row
                                className="data-row event-videos">
                                <Col
                                    xs={24}
                                    md={24}
                                    style={{
                                        verticalAlign: 'top',
                                    }}>
                                    {showStitchVideo && isEmpty(videos) ? (
                                        'No stitched videos is found. Please check later.'
                                    )
                                        : null}
                                </Col>
                                <Col
                                    xs={24}
                                    md={24}
                                    style={{
                                        verticalAlign: 'top',
                                    }}>
                                    {!isEmpty(cachedVideos) ? (
                                        <Row>
                                            <Col
                                                style={{
                                                    width: '100%',
                                                    marginBottom: 15,
                                                    paddingLeft: 0,
                                                }}>
                                                <Row
                                                    gutter={0}
                                                    type="flex">

                                                    {map(cachedVideos, ((video) => (
                                                        video && video.id ? this.renderVideo(video) : null
                                                    )))}
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                        : null}
                                </Col>
                                <Col
                                    xs={24}
                                    md={24}
                                    style={{
                                        verticalAlign: 'top',
                                        textAlign: 'center',
                                    }}>

                                    {!isEmpty(otherVideos) ? (
                                        <Button
                                            type="primary"
                                            className="btn"
                                            style={{ marginBottom: 20 }}
                                            onClick={() => {
                                                this.requestVideoCache();
                                            }}>
                                            Request Video Cache
                                        </Button>
                                    )
                                        : null}
                                </Col>
                                <Col
                                    xs={24}
                                    md={24}
                                    style={{
                                        verticalAlign: 'top',
                                    }}>
                                    {!isEmpty(otherVideos) ? (
                                        <Row>
                                            <Col
                                                style={{
                                                    width: '100%',
                                                    marginBottom: 15,
                                                    paddingLeft: 0,
                                                }}>
                                                <Row
                                                    gutter={0}
                                                    type="flex">

                                                    {map(otherVideos, ((video) => (
                                                        video && video.id ? this.renderOtherVideo(video) : null
                                                    )))}
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                        : null}
                                </Col>
                            </Row>
                        </Spin>
                    </Col>
                </Row>
            </div>
        );
    }
}

EventCustomViewPage.propTypes = {
    selectedEvent: PropTypes.object.isRequired,
    onTimeDataChange: PropTypes.func.isRequired,
    allowEdit: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    before_time: PropTypes.number.isRequired,
    after_time: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
    videos: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    updateMapEventData: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        updateMapEventData: state.events.updateMapEventData,
        selectedEvent: state.events.selectedEvent,
        loading: state.video.isFetching,
        pagination: state.video.surroundingPagination,
        videos: state.video.surroundingVideos,
        user: state.user,
        sideEvents: state.events.sideEvents,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...videoActions,
                ...reportActions,
                ...eventsActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventCustomViewPage);
