import React from 'react';
import { LocationFilled } from '@carbon/icons-react';

import './searchResultMarkerContent.scss';

const SearchResultMarkerContent: React.FC = () => {
    return (
        <>
            <LocationFilled 
                size={36}
                color='var(--red)'
            />
            <div style={{
                position: 'absolute',
                top: 10,
                left: 13,
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor: 'white',
            }} />
        </>
            
    );
}

export default React.memo(SearchResultMarkerContent);