import React, { useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { DatePicker, Select, Button, Input, Col, Row, Tooltip } from 'antd';
import { Calendar } from '@carbon/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import * as assetActions from '../../../core/asset/assetActions';
import { Channel, DvrStatusData } from '../../../types';

const { DB_TIME_FORMAT } = require('../../../core/constants');

let buttonClicked = null;

interface VideoSearchFilterProps {
    videoSearchHasData: boolean;
    searchVideos: (values: React.FormEventHandler<HTMLFormElement>) => void;
    form: any; // TODO: determine type
    selectedDivisionId?: number;
    onChangeSelectedDivisionId: (id: number) => void;
    onChangeSelectedAssetImei: (imei: string) => void;
    selectedDate?: string;
    onChangeSelectedDate: (date: dayjs.Dayjs) => void;
    streamVideos: (values: React.FormEventHandler<HTMLFormElement>) => void;
    sliderTextValue: string;
    onChangeSliderInput: (val: string) => void;
    cacheVideos: (values: React.FormEventHandler<HTMLFormElement>) => void;
    channelList: Channel[] | null;
    selectedDvrIsOnline: boolean;
}

const VideoSearchFilter: React.FC<VideoSearchFilterProps> = ({
    videoSearchHasData,
    searchVideos,
    streamVideos,
    form,
    selectedDivisionId,
    onChangeSelectedDivisionId,
    onChangeSelectedAssetImei,
    selectedDate,
    onChangeSelectedDate,
    sliderTextValue,
    onChangeSliderInput,
    cacheVideos,
    channelList,
    selectedDvrIsOnline,
}) => {
    const dispatch = useDispatch();
    const divisions = useSelector((state: any) => state.asset.divisions);
    const assetOptions = useSelector((state: any) => state.asset.options);
    const videoRetentionDays = useSelector((state: any) => state.user.userCompany.video_retention_days);
    const [ assets, setAssets ] = React.useState<any[]>(assetOptions);
    const [ channels, setChannels ] = React.useState<Channel[]>(channelList);

    const submitForm = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }

        form.validateFields((err, values) => {
            if (!err) {
                if (buttonClicked === 'search') {
                    searchVideos({
                        ...values,
                        assetId: assets.filter((a) => a.imei === values.imei)[0].id,
                    });
                } else if (buttonClicked === 'stream') {
                    streamVideos(values);
                } else {
                    cacheVideos(values);
                }
            }
        });
    };
    const { getFieldDecorator, setFieldsValue } = form;
    let dateInitialVal = selectedDate ? dayjs(selectedDate) : dayjs();
    let filteredAssetOptions = selectedDivisionId
        ? assets.filter((a) => a.division_id === selectedDivisionId)
        : assets;
    filteredAssetOptions = filteredAssetOptions.filter(a => a.dvr_status !== null);

    useEffect(() => {
        dispatch(assetActions.getDivisionListRequest());
        dispatch(assetActions.getAssetOptionsRequest());
    }, [dispatch]);
    useEffect(() => {
        let newSliderTextValue = sliderTextValue;
        if (newSliderTextValue.indexOf('T') > -1 || newSliderTextValue.indexOf('+') > -1 || newSliderTextValue.indexOf('-') > -1) {
            newSliderTextValue = newSliderTextValue.slice(11, 19); // will always work as date format is consistent, see constants
        }
        console.log('newSliderTextValue', newSliderTextValue);
        form.setFieldsValue({ start_time: newSliderTextValue });
    }, [sliderTextValue])
    useEffect(() => {
        setAssets(assetOptions);
    }, [assetOptions]);
    useEffect(() => {
        setChannels(channelList);
        setFieldsValue({ channels: channelList });
    }, [channelList]);

    return (
        <Form 
            onSubmit={submitForm} 
            layout="vertical"
        >
            <Form.Item label="Division">
                {getFieldDecorator('division_id', {})(
                    <Select
                        placeholder="Select Division"
                        filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: '100%' }}
                        onChange={(divisionId) => {
                            onChangeSelectedDivisionId(divisionId);
                            form.setFieldsValue({ imei: undefined });
                        }}
                        showSearch
                        allowClear
                    >
                        {divisions.map((v, i) => (
                            <Select.Option 
                                key={`${i}`} 
                                value={v.id}
                            >
                                {v.name}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
            <Form.Item label="Asset">
                {getFieldDecorator('imei', {
                    rules: [
                        {
                            required: true,
                            message: 'Please select an asset',
                        },
                    ],
                })(
                    <Select
                        showSearch
                        allowClear
                        filterOption={(txt, obj) => obj?.props.children[2].toLowerCase().includes(txt.toLowerCase())}
                        placeholder="Select Asset"
                        onChange={onChangeSelectedAssetImei}
                    >
                        {filteredAssetOptions.map((a) => (
                            <Select.Option 
                                key={a.id} 
                                value={a.imei}
                            >
                                {a.dvr_status === 1 ? (
                                    <div style={{
                                        display: 'inline-block',
                                        width: '9px',
                                        height: '9px',
                                        marginTop: '2px',
                                        borderRadius: '50%',
                                        backgroundColor: 'var(--green-dark)',
                                    }} />
                                ) : (
                                    <div style={{
                                        display: 'inline-block',
                                        width: '9px',
                                        height: '9px',
                                        marginTop: '2px',
                                        borderRadius: '50%',
                                        backgroundColor: 'var(--red)',
                                    }} />
                                )}
                                &ensp;
                                {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
            <Form.Item label="Date">
                {getFieldDecorator('date', {
                    initialValue: dateInitialVal,
                    rules: [
                        {
                            required: true,
                            message: 'Please select a date',
                        },
                    ],
                })(
                    <DatePicker
                        format="DD/MM/YYYY"
                        onChange={(date) => {
                            onChangeSelectedDate(date);
                        }}
                        disabledDate={(current) => current && current < dayjs().subtract(videoRetentionDays, 'days')}
                        allowClear={false}
                        suffixIcon={<Calendar />}
                    />
                )}
            </Form.Item>
            <div className="mt-2 text-center">
                <Button 
                    htmlType="submit" 
                    type="primary"
                    onClick={() => { buttonClicked = 'search'; }}
                >
                    Search
                </Button>
            </div>
            {videoSearchHasData && (
                <>
                    <br />
                    <br />
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Start Time">
                                {getFieldDecorator('start_time', {
                                    initialValue: sliderTextValue,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input start time',
                                        },
                                        {
                                            pattern: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
                                            message: 'Time format should be HH:MM:SS',
                                        }
                                    ],
                                })(
                                    <Input
                                        type='text'
                                        placeholder={DB_TIME_FORMAT}
                                        onChange={(e) => onChangeSliderInput(e.target.value)}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Video Length">
                                {getFieldDecorator('video_length', {
                                    initialValue: 30,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select video length to request',
                                        },
                                    ],
                                })(
                                    <Select>
                                        <Select.Option value={30}>
                                            30 Seconds
                                        </Select.Option>
                                        <Select.Option value={60}>
                                            1 Minute
                                        </Select.Option>
                                        <Select.Option value={120}>
                                            2 Minutes
                                        </Select.Option>
                                        <Select.Option value={300}>
                                            5 Minutes
                                        </Select.Option>
                                        <Select.Option value={600}>
                                            10 Minutes
                                        </Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Channels">
                        {getFieldDecorator('channels', {
                            initialValue: channels?.length ? channels.map((channel) => channel.channel) : [],
                            rules: [
                                {
                                    required: true,
                                    message: 'Please select video length to request',
                                },
                            ],
                        })(
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Select Channels"
                            >
                                {channels?.length && channels.map((channel) => (
                                    <Select.Option 
                                        key={`channel-option-${channel.channel}`} 
                                        value={channel.channel}
                                    >
                                        {channel.label}
                                    </Select.Option>   
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <div className="mt-2 mb-1 text-center">
                        {selectedDvrIsOnline ? (
                            <Button 
                                htmlType="submit" 
                                type="primary"
                                onClick={() => { buttonClicked = 'stream'; }}
                            >
                                Stream
                            </Button>
                        ) : (
                            <Tooltip title="Cannot stream when DVR is offline, try caching instead">
                                <Button 
                                    htmlType="submit" 
                                    type="primary"
                                    disabled
                                >
                                    Stream
                                </Button>
                            </Tooltip>
                        )}
                        <br />
                        <Button 
                            htmlType="submit" 
                            type="primary"
                            onClick={() => { buttonClicked = 'cache'; }}
                        >
                            Cache
                        </Button>
                    </div>
                </>
            )}
        </Form>
    );
};

export default Form.create<VideoSearchFilterProps>({ name: 'video_search_filter_form' })(VideoSearchFilter);
