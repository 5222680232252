import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    Input,
    Checkbox,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as eventsActions from '../../../core/events/eventsActions';
import { FlagFilled, LocationFilled, NotificationFilled, PlugFilled } from '@carbon/icons-react';

class AlertsTab extends Component {
    componentDidMount() {
        const { actions } = this.props;

        actions.resetCompanyEvents();
        actions.getCompanyEventsRequest();
    }

    change = (data) => {
        const { onChange } = this.props;
        onChange({
            id: data.key,
            name: data.name,
            email: data.email,
            notify: data.notify,
            icon: data.icon,
        });
    };

    switchChange = (e, obj) => {
        const myObj = obj;
        myObj.notify = e.target.checked;
        this.change(myObj);
    };

    inputChange = (val, obj) => {
        const myObj = obj;
        myObj.email = val.target.value;
        this.change(myObj);
    };

    render() {
        const { isFetching, value, alertsTabFilter } = this.props;

        const columnsAlert = [
            {
                title: 'enabled',
                key: 'date',
                align: 'center',
                width: 100,
                render: (val) => {
                    return (
                        <Checkbox onChange={(n) => this.switchChange(n, val)} defaultChecked={val.notify} size="small" />
                    );
                },
            },
            {
                title: 'icon',
                key: 'icon',
                align: 'center',
                width: 100,
                render: (i) => {
                    if (i.icon !== '' && i.icon !== null) {
                        switch (i.icon) {
                            case 'alert':
                            case 'bell':
                                return (
                                    <NotificationFilled 
                                        size={20} 
                                        style={{ 
                                            color: 'var(--red)',
                                            verticalAlign: 'middle',
                                        }} 
                                    />
                                );
                            case 'api':
                                return (
                                    <PlugFilled 
                                        size={20} 
                                        style={{ 
                                            color: 'var(--red)',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                );
                            case 'environment':
                                return (
                                    <LocationFilled 
                                        size={20} 
                                        style={{ 
                                            color: 'var(--red)',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                );
                            case 'flag':
                                return (
                                    <FlagFilled 
                                        size={20} 
                                        style={{ 
                                            color: 'var(--red)',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                );
                            default:
                                return (
                                    <NotificationFilled 
                                        size={20} 
                                        style={{ 
                                            color: 'var(--red)',
                                            verticalAlign: 'middle',
                                        }} 
                                    />
                                );
                        }
                    } else {
                        return (
                            <NotificationFilled 
                                size={20} 
                                style={{ 
                                    color: 'var(--red)',
                                    verticalAlign: 'middle',
                                }} 
                            />
                        );
                    }
                },
            },
            {
                title: 'event trigger',
                key: 'telematicsEvent',
                dataIndex: 'name',
                align: 'left',
                width: 264,
            },
            {
                title: 'Target Email Addresses',
                key: 'email',
                align: 'left',
                render: (val) => <Input onBlur={(n) => this.inputChange(n, val)} defaultValue={val.email} size="small" />,
            },
        ];
        let dataAlert = value.map((v) => ({
            key: v.id,
            name: v.name,
            email: v.email,
            notify: Boolean(v.notify),
            icon: v.icon,
        }));
        if (alertsTabFilter?.enabled !== -1) {
            dataAlert = dataAlert.filter((d) => Number(d.notify) === alertsTabFilter.enabled);
        }
        if (alertsTabFilter?.icon !== 'all') {
            dataAlert = dataAlert.filter((d) => d.icon === alertsTabFilter.icon);
        }

        return (
            <div
                style={{
                    width: '100%',
                    minHeight: 'calc(66vh)',
                    minWidth: '700px',
                }}>
                {isFetching && dataAlert.length === 0 && (
                    <div style={{ textAlign: 'center', backgroundColor: 'red', height: '90%' }}>

                    </div>
                )}
                {!isFetching && dataAlert.length > 0 && (
                    <Table
                        columns={columnsAlert}
                        dataSource={dataAlert}
                        pagination={false}
                        scroll={{
                            x: false,
                            y: 'calc(66vh)',
                        }}
                    />
                )}
            </div>
        );
    }
}

AlertsTab.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            name: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            notify: PropTypes.oneOf(['0', '1']).isRequired,
            icon: PropTypes.string.isRequired,
        }),
    ).isRequired,
    alertsTabFilter: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        events: state.events.events,
        eventsPagination: state.events.eventsPagination,
        isFetching: state.events.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertsTab);
