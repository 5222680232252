import moment from 'moment';
import dayjs from 'dayjs';

const { DB_DATETIME_FORMAT, DB_TIME_FORMAT } = require('../constants');

const isoStandardDateTimeFormat = DB_DATETIME_FORMAT;
const isoStandardTimeFormat = DB_TIME_FORMAT;

const dailyFrequencyFormat = 'DD MMMM YYYY';
const weeklyFrequencyFormat = 'DD/MM/YY';
const monthlyFrequencyFormat = 'MMMM YYYY';

const convertUtcToBst = (time, format = isoStandardDateTimeFormat, shouldFormat = true) => {
    let result = time;
    if (time !== null && moment(time).isDST()) {
        result = moment(time).add(1, 'hour');
        if (shouldFormat) {
            result = result.format(format);
        }
    }
    return result;
};

const getFormattedDatetime = (datetimeStr, format = isoStandardDateTimeFormat) => moment(datetimeStr).format(format);

const getMomentDateFromString = (dateStr, format = isoStandardDateTimeFormat) => moment(dateStr, format);

const convertBstToUtc = (datetimeStr) => moment(datetimeStr).valueOf();

const getTodayInServerFormat = () => moment(new Date()).format(isoStandardDateTimeFormat);

const getTodayStartInServerFormat = () => moment(new Date()).startOf('day').format(isoStandardDateTimeFormat);

const getYesterdayInServerFormat = () => moment(new Date()).subtract(1, 'days').format(isoStandardDateTimeFormat);

const secondsToHms = (seconds, format = 'string') => { // Carbon copy of the same method in CustomHelpers in backend
    if (seconds > 0) {
        const d = parseInt(seconds);
        const h = Math.floor(d / 3600);
        const m = Math.floor(d % 3600 / 60);
        const s = Math.floor(d % 3600 % 60);

        if (format == 'time') {
            return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
        }

        const hDisplay = h > 0 ? h + (h == 1 ? ' h, ' : ' hrs, ') : '';
        const mDisplay = m > 0 ? m + (m == 1 ? ' m, ' : ' mins, ') : '';
        const sDisplay = s > 0 ? s + (s == 1 ? ' s' : ' secs') : '';

        return hDisplay + mDisplay + sDisplay;
    }

    if (format == 'time') {
        return '00:00:00';
    }

    return '0 secs';
};

/**
 * Converts seconds passed in a day since 00:00 to HH:MM format
 * 
 * @param input the number of seconds passed in a day since 00:00, maximum 86399 (seconds in a day - 1)
 * @param appendDaytime optional, append am / pm to the end, defaults to false
 * @return string
 */
const secondsToTwentyFourHours = (input, appendDaytime = false) => {
    let seconds = input;
    if (seconds === null || seconds === undefined) return null;
    if (typeof seconds !== 'number') seconds = String(seconds);
    if (seconds > 86399) return `23:59${appendDaytime ? ' PM' : ''}`;
    if (seconds < 1) return `00:00${appendDaytime ? ' AM' : ''}`;
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}${appendDaytime ? (hours < 12 ? ' AM' : ' PM') : ''}`;
};

/**
 * Converts time in HH:MM format to seconds passed in a day since 00:00
 * @param {string} time 
 * @returns number
 */
const timeToSeconds = (time, includeSeconds = false) => {
    const parts = time.split(':');
    if (includeSeconds) {
        return (+parts[0]) * 3600 + (+parts[1]) * 60 + (+parts[2]);
    } else {
        return (+parts[0]) * 3600 + (+parts[1]) * 60;
    }
}

/**
 * Convert seconds passed in a day since 00:00 to time in HH:MM:SS format
 * @param {number} seconds 
 * @returns string
 */
const secondsToTime = (seconds) => {
    return dayjs().startOf('day').add(seconds, 'seconds').format(DB_TIME_FORMAT);
}

export {
    isoStandardDateTimeFormat,
    isoStandardTimeFormat,
    dailyFrequencyFormat,
    weeklyFrequencyFormat,
    monthlyFrequencyFormat,

    convertUtcToBst,
    getFormattedDatetime,
    getMomentDateFromString,
    convertBstToUtc,
    getTodayInServerFormat,
    getTodayStartInServerFormat,
    getYesterdayInServerFormat,
    secondsToHms,
    secondsToTwentyFourHours,
    timeToSeconds,
    secondsToTime,
};
