/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { notification } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'underscore';
import DOM from './events';
import * as eventsActions from '../../core/events/eventsActions';
import * as globalActions from '../../core/global/globalActions';
import * as deviceActions from '../../core/device/deviceActions';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter);

const { DB_DATE_FORMAT } = require('../../core/constants').default;

class Events extends Component {
    constructor(props) {
        super(props);

        let defaultKey = '1';
        if (props.user.permissions.event_searching !== true) {
            defaultKey = '2';
        } else if (props.location.state && props.location.state.currentTabKey && props.location.state.currentTabKey == 2) {
            defaultKey = '2';
        }
        this.state = {
            currentTabKey: defaultKey,
            eventsTabLoaded: false,
            triggersTabLoaded: false,
            triggersTabFilter: {
                trigger_name: '',
                trigger_type: '',
                trigger_division: '',
            },
            filterSidebar: true,
            iconOptions: null,
            alertsTabFilter: {
                enabled: -1,
                icon: "all",
            },
            fetchingMap: false,
        };
        this.view = DOM;
    }

    componentDidUpdate(prevProps) {
        const { events } = this.props;

        const icons = events.map(event => event.icon);
        const uniqueIcons = [...new Set(icons)];
        if (events && prevProps.events !== events) {
            this.setState({ iconOptions: uniqueIcons });
        }
    }

    setFetchingMap = (bool) => {
        this.setState({ fetchingMap: bool });
    }

    eventsTabLoaded = () => {
        this.setState({ eventsTabLoaded: true });
    };

    triggersTabLoaded = () => {
        this.setState({ triggersTabLoaded: true });
    };

    updateEvents = (event) => {
        const { actions } = this.props;
        actions.updateCompanyEventsRequest({ ...event, notify: event.notify ? 1 : 0 });
    };

    onEventsTabPageChange = (page, pageSize) => {
        const { eventsSearchParams, eventsPagination, actions } = this.props;
        eventsSearchParams.page = page;
        eventsSearchParams.pageSize = pageSize;
        eventsSearchParams.totalRecords = eventsPagination?.totalRecords || null;
        actions.saveEventsSearchParamsRequest(eventsSearchParams);
        actions.getEventsRequest(eventsSearchParams);
    };

    onEventsTabFilterChange = (values) => {
        const { actions } = this.props;
        const filters = values;

        if (filters.postcode && !filters.miles) {
            notification.error({ message: 'Error', description: 'Please enter a radius to search by postcode' });
            return;
        }
        if (filters.miles && !filters.postcode) {
            notification.error({ message: 'Error', description: 'Please enter a postcode to search by postcode radius' });
            return;
        }

        const { date_from, date_to } = filters;
        filters.date_from = date_from ? date_from.format(DB_DATE_FORMAT) : dayjs().format(DB_DATE_FORMAT);
        filters.date_to = date_to ? date_to.format(DB_DATE_FORMAT) : dayjs().format(DB_DATE_FORMAT);

        if (dayjs(values.date_to).isSameOrAfter(dayjs(values.date_from).add(31, 'day'))) {
            notification.warning({ message: 'Warning', description: 'Filter date to is more than 30 days, the maximum allowance time difference is 30 days' });
            filters.date_to = dayjs(values.date_from).add(30, 'day').format(DB_DATE_FORMAT);
        }
        filters.page = 1;
        const assets = filters.asset_info
            ? Array.isArray(filters.asset_info)
                ? filters.asset_info
                : [filters.asset_info]
            : []
        actions.saveEventsSearchParamsRequest(filters);
        actions.getEventsRequest(filters);
        actions.getMapEventsHeatMapRequest({
            date_from: filters.date_from,
            date_to: filters.date_to,
            start_time: filters.start_time,
            end_time: filters.end_time,
            assets,
            eventTypes: filters.event_types,
            drivers: filters.driver,
            divisions: filters.division,
            postcode: filters.postcode,
            miles: filters.miles,
        });
    };

    onEventsTableChange = (pagination, filters, sorter, extra) => {
        const { actions, eventsSearchParams, eventsPagination } = this.props;
        if (pagination && pagination.current == eventsPagination.currentPage) {
            const params = eventsSearchParams;
            if (!isEmpty(sorter)) {
                const sortColumn = `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`;
                params.orderBy = sortColumn;
            }
            params.pageSize = pagination.pageSize;
            actions.saveEventsSearchParamsRequest(params);
            actions.getEventsRequest(params);
        }
    };

    onTriggersTabPageChange = (page, pageSize) => {
        const { triggersTabFilter } = this.state;
        triggersTabFilter.page = page;
        const { actions } = this.props;
        this.setState({ triggersTabFilter });
        const params = { ...triggersTabFilter };
        actions.getAllTriggersRequest(params);
    };

    onTriggersTabSortChange = (pagination, filters, sorter, extra) => {
        const { triggersTabFilter } = this.state;
        const { actions } = this.props;
        const params = { orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`, ...triggersTabFilter };
        actions.getAllTriggersRequest(params);
    };

    onTriggersTabFilterChange = (values) => {
        const filters = values;
        filters.page = 1;
        this.setState({ triggersTabFilter: filters });
        const { actions } = this.props;
        const params = { ...filters };
        actions.getAllTriggersRequest(params);
    };

    onAlertsFilterChange = (values) => {
        this.setState({ alertsTabFilter: values });
    }

    render() {
        return this.view(
            {
                update: (newVals) => (
                    this.props.actions.UpdateEventNotifications(newVals)
                ),
            },
        );
    }
}

Events.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    eventsSearchParams: PropTypes.object.isRequired,
    eventsPagination: PropTypes.object.isRequired,
    reload: PropTypes.bool.isRequired,

};

function mapStateToProps(state, ownProps) {
    let reload = false;
    if (ownProps && ownProps.match && ownProps.match.params && ownProps.match.params.reload) {
        reload = true;
    }
    return {
        ...ownProps,
        events: state.events.companyEvents,
        user: state.user,
        eventsSearchParams: state.events.eventsSearchParams,
        assetId: state.global.eventSearchFilters.assetId,
        dateFrom: state.global.eventSearchFilters.dateFrom,
        eventsPagination: state.events.eventsPagination,
        reload,
        isFetching: state.events.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...globalActions,
            ...deviceActions,
        }, dispatch),
    };
}
export const EventsTest = Events;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Events);
