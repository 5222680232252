/* global window:true */
/* global document:true */
import React from 'react';
import DetailView from '../../components/DetailView';
import Layout from '../../components/layout/Layout';

import './videoCachedView.scss';

export default function () {
    const { sliderValue } = this.state;
    const { selectedEvent, isFetching, user } = this.props;
    
    return (
        <Layout
            data-test="pages-video"
            className="video-page"
            title={<div>Video</div>}
            headerTitle={<div>Video Page</div>}
            menuPlacement="right"
            showFooter={false}
            loading={isFetching}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <div 
                    className="content"
                    style={{ opacity: isFetching ? 0 : 1 }}
                >
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <DetailView
                                    shareReportType="cache"
                                    selectedEvent={selectedEvent} 
                                    sliderValue={sliderValue}
                                    onForward={this.requestEventInfo}
                                    onRewind={this.requestEventInfo}
                                    onIncreaseOptionClick={this.requestEventInfo}
                                    onReset={this.requestEventInfo}
                                    showStreamingSectionOnLoad 
                                    showTimingPresetsFooter={false}
                                />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
