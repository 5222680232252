/* eslint-disable no-case-declarations */

import { Record } from 'immutable';
import { findIndex } from 'underscore';
import {
    removeWidget,
    updateWidget,
} from './helpers';

const {
    GET_LEFT_WIDGET_DATA_REQUEST,
    GET_LEFT_WIDGET_DATA_SUCCESS,
    GET_LEFT_WIDGET_DATA_FAILURE,

    GET_RIGHT_WIDGET_DATA_REQUEST,
    GET_RIGHT_WIDGET_DATA_SUCCESS,
    GET_RIGHT_WIDGET_DATA_FAILURE,

    GET_COMPARE_TARGETS_REQUEST,
    GET_COMPARE_TARGETS_SUCCESS,
    GET_COMPARE_TARGETS_FAILURE,

    REMOVE_COMPARE_WIDGET,
    ADD_COMPARE_WIDGET,
    UPDATE_COMPARE_WIDGET,
    SET_SELECTED,

    RESET_COMPARE_ASSETS_TARGETS_REQUEST,

    GET_PRINT_PDF_COMPARE_REQUEST,
    GET_PRINT_PDF_COMPARE_SUCCESS,
    GET_PRINT_PDF_COMPARE_FAILURE,

} = require('./comparisonActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isFetchingLeftData: false,
    isFetchingRightData: false,
    widgets: [],
    targets: [],
    leftSelection: '',
    rightSelection: '',
});

const comparisonInitialState = new InitialState();

/**
 * ## comparisonReducer function
 * @param {Object} oldState - initialState
 * @param {Object} action - type and payload
 */

/*

function deviceReducer(state = deviceInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) return deviceInitialState.mergeDeep(state);
    if (!(state instanceof InitialState)) return comparisonInitialState.mergeDeep(state);

 */
function comparisonReducer(state = comparisonInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) {
        state = comparisonInitialState.mergeDeep(state);
    }

    switch (type) {
    case GET_LEFT_WIDGET_DATA_REQUEST:
        return state.set('isFetching', true).set('isFetchingLeftData', true)
            .set('error', null);
    case GET_RIGHT_WIDGET_DATA_REQUEST:
        return state.set('isFetching', true).set('isFetchingRightData', true)
            .set('error', null);
    case GET_COMPARE_TARGETS_REQUEST:
    case GET_PRINT_PDF_COMPARE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_LEFT_WIDGET_DATA_SUCCESS: {
        const { widgets } = state;
        const widgetId = payload.id;
        const matchWidgetId = findIndex(widgets, (val) => val.id === widgetId);
        if (matchWidgetId !== -1) {
            widgets[matchWidgetId].leftData = payload.data;
            widgets[matchWidgetId].loaded = true;
        }
        return state.set('widgets', widgets).set('isFetching', false).set('isFetchingLeftData', false);
    }

    case GET_RIGHT_WIDGET_DATA_SUCCESS: {
        const { widgets } = state;
        const widgetId = payload.id;
        const matchWidgetId = findIndex(widgets, (val) => val.id === widgetId);
        if (matchWidgetId !== -1) {
            widgets[matchWidgetId].rightData = payload.data;
            widgets[matchWidgetId].loaded = true;
        }
        return state.set('widgets', widgets).set('isFetching', false).set('isFetchingRightData', false);
    }

    case GET_COMPARE_TARGETS_SUCCESS:
    case GET_PRINT_PDF_COMPARE_SUCCESS:
        return state.set('isFetching', false)
            .set('targets', payload);

    case GET_LEFT_WIDGET_DATA_FAILURE:
    case GET_RIGHT_WIDGET_DATA_FAILURE:
    case GET_COMPARE_TARGETS_FAILURE:
    case GET_PRINT_PDF_COMPARE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case REMOVE_COMPARE_WIDGET:
        return state.set('widgets', removeWidget(state.widgets, payload));
    case ADD_COMPARE_WIDGET: {
        const { widgets } = state;
        widgets.push(payload);
        return state.set('widgets', widgets);
    }

    case UPDATE_COMPARE_WIDGET:
        return state.set('widgets', updateWidget(state.widgets, payload));
    case SET_SELECTED: {
        return state.set(payload.side, payload.value);
    }

    case RESET_COMPARE_ASSETS_TARGETS_REQUEST:
        return state.set('targets', []);

    default:
        return state;
    }
}

export {
    comparisonReducer,
    comparisonInitialState,
};
