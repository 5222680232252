import React from 'react';
import {
    ApiOutlined,
    BugOutlined,
    CameraOutlined,
    CarOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    DashboardOutlined,
    EnvironmentOutlined,
    FireOutlined,
    HeartOutlined,
    HeatMapOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    QuestionCircleOutlined,
    StarOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { map } from 'underscore';
import { notification } from 'antd';
import moment from 'moment';

import Icon from '../../components/elements/Icon';
import CarbonReportSVG from '../../assets/icons/CarbonReportSVG';
import AutoStopLogoColourSVG from '../../assets/icons/AutoStopLogoColourSVG';
import CyclearLogoGreySVG from '../../assets/icons/CyclearLogoGreySVG';
import DriverBehaviourSVG from '../../assets/icons/DriverBehaviourSVG';
import IDClearLogoColourSVG from '../../assets/icons/IDClearLogoColourSVG';
import LowFuelSVG from '../../assets/icons/LowFuelSVG';
import AdvancedGPSAccuracySVG from '../../assets/icons/AdvancedGPSAccuracySVG';
import AntiTamperHardwareSVG from '../../assets/icons/AntiTamperHardwareSVG';
import AutomaticHealthStatusReportsSVG from '../../assets/icons/AutomaticHealthStatusReportsSVG';
import CustomisableWidgetDashboardSVG from '../../assets/icons/CustomisableWidgetDashboardSVG';
import DriverIdentificationSVG from '../../assets/icons/DriverIdentificationSVG';
import EasyReportAndAdminControlSVG from '../../assets/icons/EasyReportAndAdminControlSVG';
import EventReportingSVG from '../../assets/icons/EventReportingSVG';
import FnolSVG from '../../assets/icons/FnolSVG';
import FuelEfficiencySVG from '../../assets/icons/FuelEfficiencySVG';
import FuelOptimisationSVG from '../../assets/icons/FuelOptimisationSVG';
import GeoFencingSVG from '../../assets/icons/GeoFencingSVG';
import ISSInstallationAndServiceQualityAssuranceSVG from '../../assets/icons/ISSInstallationAndServiceQualityAssuranceSVG';
import MapAndAssetDisplaySVG from '../../assets/icons/MapAndAssetDisplaySVG';
import MultiAssetActivitySideBarSVG from '../../assets/icons/MultiAssetActivitySideBarSVG';
import NearestVehicleAndPostcodeSearchSVG from '../../assets/icons/NearestVehicleAndPostcodeSearchSVG';
import OperationalAlertsSVG from '../../assets/icons/OperationalAlertsSVG';
import PTOConnectionGuaranteeSVG from '../../assets/icons/PTOConnectionGuaranteeSVG';
import RealTimeTrackingTelematicsAndVideoSVG from '../../assets/icons/RealTimeTrackingTelematicsAndVideoSVG';
import RealTimeTrafficOverlaySVG from '../../assets/icons/RealTimeTrafficOverlaySVG';
import RemoteTachographDownloadSVG from '../../assets/icons/RemoteTachographDownloadSVG';
import RoadRelatedSpeedingAlertsSVG from '../../assets/icons/RoadRelatedSpeedingAlertsSVG';
import RouteOptimisationSVG from '../../assets/icons/RouteOptimisationSVG';
import RoutePlanningWithLiveTrafficSVG from '../../assets/icons/RoutePlanningWithLiveTrafficSVG';
import RXLiteIntegrationSVG from '../../assets/icons/RXLiteIntegrationSVG';
import UKInHouseDevelopmentTeamSVG from '../../assets/icons/UKInHouseDevelopmentTeamSVG';
import WebBasedTrackingPlatformSVG from '../../assets/icons/WebBasedTrackingPlatformSVG';
import MapAssetPopup from '../../components/MapAssetPopup';

const { MAP_SLIDER_INTERVAL, DB_TIME_FORMAT } = require('../constants').default;

const {
    MapAssetPopupHead,
    MapAssetPopupContent,
} = MapAssetPopup;

Array.prototype.removeByValue = function () {
    let what; const a = arguments; let L = a.length; let
        ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};

/**
 * Converts seconds since midnight to a formatted MM:SS or HH:MM:SS string
 * @param {*} secondsSinceMidnight Number of seconds since midnight
 */
const convertFromSeconds = (secondsSinceMidnight) => {
    const t = secondsSinceMidnight;
    const hours = Math.floor(t / 3600);
    const minutes = `${Math.floor((t / 60) % 60)}`.padStart(2, '0');
    const seconds = `${Math.floor(t % 60)}`.padStart(2, '0');
    if (hours !== 0) {
        return `${hours}:${minutes}:${seconds}`;
    }
    return `${minutes}:${seconds}`;
};

function getMappedDevicePath() {
    const zoom = 16;
    const { mapSelectedDeviceLocations } = this.props;
    // Map zoom bounds 3 - 22
    const countLocationDots = mapSelectedDeviceLocations.length;
    let threshold = parseInt(((((22 - zoom) / 22) * countLocationDots) / 10), 10);
    if (zoom > 10 && zoom <= 15) {
        threshold = parseInt(threshold / zoom, 10);
    } else if (zoom > 15) {
        threshold = parseInt(threshold / 20, 10);
    }
    if (threshold <= 0) {
        threshold = 1;
    }
    const filteredSelectedDeviceLocations = mapSelectedDeviceLocations.filter((mapSelectedDeviceLocation, mapSelectedDeviceLocationIndex) => {
        if (mapSelectedDeviceLocationIndex == 0) {
            return true;
        } if (mapSelectedDeviceLocationIndex % threshold == 0) {
            return true;
        } if (mapSelectedDeviceLocationIndex === mapSelectedDeviceLocations.length - 1) {
            return true;
        }
        return false;
    });

    return filteredSelectedDeviceLocations.map((path, pathIndex) => {
        let position = 'path';
        // position = pathIndex === 0 ? 'start' : position;
        position = pathIndex === (filteredSelectedDeviceLocations.length - 1) ? 'marker' : position;
        const temp = {
            id: path.id,
            lat: path.lat,
            lng: path.lng,
            position,
            title: path.time,
            content: <div>path.time</div>,
        };
        if (position === 'marker') {
            temp.currentMarker = { icon: <Icon type="flag" theme="filled" /> };
        }

        return temp;
    });
}

function convertMinsIntoHoursAndMins(minutes, interval = 1) {
    const hours = (minutes * interval) / 60;
    const rHours = (Math.floor(hours)).toString();
    const mins = (hours - rHours) * 60;
    const rMins = (Math.round(mins)).toString();
    const safeHours = (rHours.toString()).padStart(2, '0'); // rHours.length < 2 ? (`0${rHours}`).slice(-2).toString() : rHours.toString();
    const safeMinutes = (rMins.toString()).padStart(2, '0'); // rMins.length < 2 ? (`0${rMins}`).slice(-2).toString() : rMins.toString();
    return `${safeHours}:${safeMinutes}`;
}

function convertSecIntoHoursAndMins(seconds, interval = 1) {
    const hours = (seconds * interval) / 3600;
    const rHours = (Math.floor(hours)).toString();
    const mins = (hours - rHours) * 60;
    const rMins = (Math.round(mins)).toString();
    const secs = (mins - rMins);
    const safeHours = rHours.length < 2 ? (`0${rHours}`).slice(-2).toString() : rHours.toString();
    const safeMinutes = rMins.length < 2 ? (`0${rMins}`).slice(-2).toString() : rMins.toString();
    return `${safeHours}:${safeMinutes}`;
}

function convertSecondsIntoDatetime(seconds, interval = 1) {
    return moment.utc(seconds * interval * 1000).format(DB_TIME_FORMAT);
}

function secondsToHms(number, format = 'string') {
    const d = Number(number);
    if (!d) {
        if (format == 'time') {
            return '00:00:00';
        }
        return '0 hrs, 0 mins, 0 secs';
    }
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);
    if (format == 'time') {
        return `${(h.toString()).padStart(2, '0')}:${(m.toString()).padStart(2, '0')}:${(s.toString()).padStart(2, '0')}`;
    }
    const hDisplay = h > 0 ? h + (h == 1 ? ' hr, ' : ' hrs, ') : '';
    const mDisplay = m > 0 ? m + (m == 1 ? ' min, ' : ' mins, ') : '';
    const sDisplay = s > 0 ? s + (s == 1 ? ' sec' : ' secs') : '';
    return hDisplay + mDisplay + sDisplay;
}

function formatEventTypes(event_types) {
    const updatedEventTypes = [];
    event_types.map((eventType) => {
        if (parseInt(eventType.id, 10) === 253) {
            const data1 = { ...eventType };
            data1.id = '253_1';
            data1.key = 'Harsh accelerating';
            data1.label = 'Harsh accelerating';

            const data2 = { ...eventType };
            data2.id = '253_2';
            data2.key = 'Harsh braking';
            data2.label = 'Harsh braking';

            const data3 = { ...eventType };
            data3.id = '253_3';
            data3.key = 'Harsh cornering';
            data3.label = 'Harsh cornering';

            updatedEventTypes.push(data1);
            updatedEventTypes.push(data2);
            updatedEventTypes.push(data3);
        } else {
            const temp = { ...eventType };
            temp.id = String(temp.id);
            updatedEventTypes.push(temp);
        }
    });

    return updatedEventTypes;
}

function emptyCache() {
    if ('caches' in window) {
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        localStorage.clear();
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
    }
}

function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

/**
 * Convert RGB to hexidecimal color e.g. (0,0,0) => '#000000'
 * @param {number} r red
 * @param {number} g green
 * @param {number} b blue
 * @returns {string} hex color
 */
function rgbToHex(r, g, b) {
    function componentToHex(c) {
        const hex = c.toString(16);
        return hex.length == 1 ? `0${hex}` : hex;
    }
    return `#${componentToHex(parseInt(r))}${componentToHex(parseInt(g))}${componentToHex(parseInt(b))}`;
}

function getChartIconList() {
    function CarbonIcon(props) {
        return <Icon component={CarbonReportSVG} {...props} />;
    }
    function AutoStopLogoColourIcon(props) {
        return <Icon component={AutoStopLogoColourSVG} {...props} />;
    }
    function CyclearLogoGreyIcon(props) {
        return <Icon component={CyclearLogoGreySVG} {...props} />;
    }
    function DriverBehaviourIcon(props) {
        return <Icon component={DriverBehaviourSVG} {...props} />;
    }
    function IDClearLogoColourIcon(props) {
        return <Icon component={IDClearLogoColourSVG} {...props} />;
    }
    function LowFuelIcon(props) {
        return <Icon component={LowFuelSVG} {...props} />;
    }
    // any changes please also update iconPicker const icons
    // id 17 and onwards are custom charts
    const icons = [
        {
            id: 1,
            title: <ClockCircleOutlined />,
        },
        {
            id: 2,
            title: <InfoCircleOutlined />,
        },
        {
            id: 3,
            title: <QuestionCircleOutlined />,
        },
        {
            id: 4,
            title: <WarningOutlined />,
        },
        {
            id: 5,
            title: <CheckCircleOutlined />,
        },
        {
            id: 6,
            title: <HeatMapOutlined />,
        },
        {
            id: 7,
            title: <ApiOutlined />,
        },
        {
            id: 8,
            title: <BugOutlined />,
        },
        {
            id: 9,
            title: <CameraOutlined />,
        },
        {
            id: 10,
            title: <CarOutlined />,
        },
        {
            id: 11,
            title: <DashboardOutlined />,
        },
        {
            id: 12,
            title: <EnvironmentOutlined />,
        },
        {
            id: 13,
            title: <FireOutlined />,
        },
        {
            id: 14,
            title: <HeartOutlined />,
        },
        {
            id: 15,
            title: <StarOutlined />,
        },
        {
            id: 16,
            title: <HomeOutlined />,
        },
        {
            id: 17,
            title: CarbonReportSVG, // <CarbonIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
        },
        {
            id: 18,
            title: AutoStopLogoColourSVG, // <AutoStopLogoColourIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
        },
        {
            id: 19,
            title: CyclearLogoGreySVG, // <CyclearLogoGreyIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
        },
        {
            id: 20,
            title: DriverBehaviourSVG, // <DriverBehaviourIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
        },
        {
            id: 21,
            title: IDClearLogoColourSVG, // <IDClearLogoColourIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
        },
        {
            id: 22,
            title: LowFuelSVG, // <LowFuelIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
        },
        {
            id: 23,
            title: AdvancedGPSAccuracySVG,
        },
        {
            id: 24,
            title: AntiTamperHardwareSVG,
        },
        {
            id: 25,
            title: AutomaticHealthStatusReportsSVG,
        },
        {
            id: 26,
            title: CustomisableWidgetDashboardSVG,
        },
        {
            id: 27,
            title: DriverIdentificationSVG,
        },
        {
            id: 28,
            title: EasyReportAndAdminControlSVG,
        },
        {
            id: 29,
            title: EventReportingSVG,
        },
        {
            id: 30,
            title: FnolSVG,
        },
        {
            id: 31,
            title: FuelEfficiencySVG,
        },
        {
            id: 32,
            title: FuelOptimisationSVG,
        },
        {
            id: 33,
            title: GeoFencingSVG,
        },
        {
            id: 34,
            title: ISSInstallationAndServiceQualityAssuranceSVG,
        },
        {
            id: 35,
            title: MapAndAssetDisplaySVG,
        },
        {
            id: 36,
            title: MultiAssetActivitySideBarSVG,
        },
        {
            id: 37,
            title: NearestVehicleAndPostcodeSearchSVG,
        },
        {
            id: 38,
            title: OperationalAlertsSVG,
        },
        {
            id: 39,
            title: PTOConnectionGuaranteeSVG,
        },
        {
            id: 40,
            title: RealTimeTrackingTelematicsAndVideoSVG,
        },
        {
            id: 41,
            title: RealTimeTrafficOverlaySVG,
        },
        {
            id: 42,
            title: RemoteTachographDownloadSVG,
        },
        {
            id: 43,
            title: RoadRelatedSpeedingAlertsSVG,
        },
        {
            id: 44,
            title: RouteOptimisationSVG,
        },
        {
            id: 45,
            title: RoutePlanningWithLiveTrafficSVG,
        },
        {
            id: 46,
            title: RXLiteIntegrationSVG,
        },
        {
            id: 47,
            title: UKInHouseDevelopmentTeamSVG,
        },
        {
            id: 48,
            title: WebBasedTrackingPlatformSVG,
        },
    ];
    return icons.reduce((a, { id, title }) => {
        a[id] = title;
        return a;
    }, Object.create(null));
}

function goToEventPage(event_id, device_id) {
    window.location.href = `/events/custom-view/${event_id}/${device_id}`;
}

const assignPopUpsToMapPoints = (
    mapDevices,
    user,
    mapDeviceListIsFetching,
    isMapLive,
    drawGeoFence,
    currentHoveredAsset,
    timeSheetReport,
    isExportingCsv,
    selectedDate,
    actions,
    deviceDetailedLocationInfo,
) => {
    let selectedMapDevices = mapDevices;
    if (isMapLive === true || drawGeoFence === true) {
        selectedMapDevices = [];
    }

    return map(selectedMapDevices, (mapDevice) => {
        const tempDevice = { ...mapDevice };
        tempDevice.arrowIcon = true;
        tempDevice.hasEvents = false;
        tempDevice.className = '';
        tempDevice.markers = [];
        if (tempDevice.markers) {
            tempDevice.markers = mapDevice && mapDevice.markers ? map(mapDevice.markers, (mapDeviceMarker) => {
                const tempMarker = { ...mapDeviceMarker };
                tempMarker.imei = tempDevice.imei;
                tempDevice.className = '';
                tempDevice.visible = false;

                if (currentHoveredAsset === tempDevice.asset_id) {
                    tempDevice.className += ' asset-marker-hovered';
                    tempDevice.visible = false;
                }

                if (parseInt(tempDevice.online, 10) === 0) {
                    tempDevice.className = 'asset-marker-offline';
                }

                if (tempDevice.lastLocation) {
                    tempDevice.className = 'asset-marker-last-location';
                }

                tempMarker.title = (
                    <MapAssetPopupHead
                        heading={tempDevice.title}
                        devices={tempDevice.devices}
                        priority={tempMarker.priority}
                        hasEvents={tempDevice.hasEvents}
                        registration={tempDevice.registration}
                        showQuickActionButtons
                        timeSheetReport={timeSheetReport}
                        isExportingCsv={isExportingCsv}
                        user={user}
                        onEventSearch={(params) => {
                            actions.updateEventSearch(params);
                        }}
                        onUpdateVideoSearch={(params) => {
                            actions.updateVideoSearch(params);
                        }}
                        onGetCsvAssetRecordsByDayRequest={(params) => {
                            actions.getCsvAssetRecordsByDayRequest(params);
                        }}
                        selectedDate={selectedDate}
                        imei={tempDevice.imei}
                        assetId={tempDevice.asset_id}
                        deviceDetailedLocationInfo={deviceDetailedLocationInfo}
                        lat={tempDevice?.currentMarker?.lat}
                        lng={tempDevice?.currentMarker?.lng}
                    />
                );

                tempMarker.content = (
                    <MapAssetPopupContent showLoading />
                );

                return tempMarker;
            }) : [];
        }
        return tempDevice;
    });
};

const openStreetView = (lat, lng, angle, googleMapRef) => {
    if (googleMapRef && googleMapRef.current && googleMapRef.current._googleMap) {
        const trueAngle = parseInt(angle, 10);
        const position = { lat: parseFloat(lat), lng: parseFloat(lng) };
        const { maps } = googleMapRef.current.state;
        const sv = new maps.StreetViewService();
        sv.getPanoramaByLocation(position, 50, (data, status) => {
            if (status === maps.StreetViewStatus.OK) {
                const { map_ } = googleMapRef.current._googleMap;
                const panorama = map_.getStreetView();
                panorama.setPosition(position);
                panorama.setPov(({
                    heading: trueAngle,
                    pitch: 0,
                }));
                panorama.setVisible(true);
            } else {
                notification.warning({
                    message: 'Google Street View Alert',
                    description: 'No street view available for this location.',
                });
            }
        });
    }
};

const insidePolygon = (point, vs) => {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

    const x = point[0]; const
        y = point[1];

    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i][0]; const
            yi = vs[i][1];
        const xj = vs[j][0]; const
            yj = vs[j][1];

        const intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
};

/**
 * Convert time string or hours.mins and seconds to seconds
 * @param time
 * @param hours
 * @param minutes
 * @param seconds
 * @returns {number}
 */
const timeToSeconds = (time = '', hours = 0, minutes = 0, seconds = 0) => {
    if (time != '') {
        // Expecting time string as HH:MM:SS
        const timeParts = time.split(':');
        hours = timeParts[0] || 0;
        minutes = timeParts[1] || 0;
        seconds = timeParts[2] || 0;
    }
    return ((parseInt(hours, 10) * (60 * 60)) + (parseInt(minutes, 10) * 60) + (parseInt(seconds, 10) * 1));
};

const getMinutesSliderValueFromDate = (dateObj = moment()) => {
    const HoursToMins = (parseInt(dateObj.format('HH')) * 60) + parseInt(dateObj.format('mm'));
    const sliderValue = Math.floor(HoursToMins / MAP_SLIDER_INTERVAL);
    return sliderValue;
};

const getSecondsSliderValueFromDate = (dateObj = moment()) => {
    const HoursToSeconds = (
        (parseInt(dateObj.format('HH')) * 3600)
        + (parseInt(dateObj.format('mm') * 60))
        + parseInt(dateObj.format('ss'))
    );
    return HoursToSeconds;
};

const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font || getComputedStyle(document.body).font;

    return context.measureText(text).width;
}

// eslint-disable-next-line import/prefer-default-export
export {
    convertFromSeconds, getMappedDevicePath, convertMinsIntoHoursAndMins, convertSecIntoHoursAndMins,
    secondsToHms, formatEventTypes, emptyCache, getRandomColor, rgbToHex, getChartIconList, assignPopUpsToMapPoints,
    openStreetView, goToEventPage, insidePolygon, timeToSeconds, getMinutesSliderValueFromDate,
    getSecondsSliderValueFromDate, convertSecondsIntoDatetime, getTextWidth,
};
