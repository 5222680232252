import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';

import { isEmpty } from 'underscore';

import { assetsForm, divisionsForm } from './filterForm';
import * as assetActions from '../../../core/asset/assetActions';

class TheFilterForm extends React.Component {
    constructor(props) {
        super(props);
        this.view = props.type === 'asset' ? assetsForm : divisionsForm;
    }

    componentDidMount() {
        const {
            actions,
        } = this.props;
        actions.getAssetOptionsRequest();
        actions.getVehicleMakesRequest();
        actions.getAllAssetOptionsRequest();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(['asset', 'registration_year', 'make', 'fuel_type', 'divisions', 'vehicle_type'], (err, values) => {
            if (!err) {
                const { onSubmit } = this.props;
                onSubmit(values);
            }
        });
    };

    render() {
        return this.view();
    }
}

TheFilterForm.defaultProps = {
    type: 'asset',
};

TheFilterForm.propTypes = {
    form: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    type: PropTypes.string,
    divisions: PropTypes.array.isRequired,
    searchParams: PropTypes.object.isRequired,
};

const FilterForm = Form.create({
    name: 'assetsFilterForm',
    mapPropsToFields(props) {
        const initialData = {};

        if (!isEmpty(props.searchParams)) {
            Object.keys(props.searchParams).map((item) => {
                if (!isEmpty(item)) {
                    initialData[item] = Form.createFormField({
                        value: props.searchParams[item],
                    });
                }
            });
        }

        return initialData;
    },
})(TheFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        divisions: state.asset.divisions,
        vehicleTypes: state.asset.vehicleTypes,
        vehicleMakes: state.asset.vehicleMakes,
        searchParams: state.asset.assetSearchParams,
        assetOptions: state.asset.allOptions,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
