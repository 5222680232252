import React from 'react';

import './speedSign.scss';

interface SpeedSignProps {
    speed?: string | number;
    isMetric?: boolean;
}

/**
 * Renders the content of an asset popup.
 */
const SpeedSign: React.FC<SpeedSignProps> = ({
    speed = 0,
    isMetric = false,
}) => (
    <div className='speed-sign'>
        <div className='inner'>
            <div className='text'>
                {speed}
                <br />
                <span className='subtext'>{isMetric ? 'kph' : 'mph'}</span>
            </div>
        </div>
    </div>
)

export default SpeedSign;