import React from 'react';
import { Tabs } from 'antd';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import LiveVideosTab from './tabs/LiveVideosTab';
import CachedVideoTab from './tabs/CachedVideoTab';
import VideoSearchTab from './tabs/VideoSearchTab';
import CachedVideoFilter from './forms/CachedVideoFilter';
import LiveVideoFilter from './forms/LiveVideoFilter';
import VideoSearchFilter from './forms/VideoSearchFilter';

import './video.scss';

const { TabPane } = Tabs;

export default function () {
    const {
        match, assetId, user, isFetchingCachedVideo, isFetchingLiveVideo, showSelectionScreen,
    } = this.props;
    const {
        isVisibleSearchSidebar, selectedDate, channelList, selectedDvrIsOnline,
        isFetchingSearchedVideo, videoSearchData, timelineSegments, geofenceTimelineSegments,
        isVisibleVideoTiles, videoSearchChannelLinks, isFetchingVideoStreams,
        selectedChannels, videoSearchDateFrom, videoSearchDateTo, eventTypes,
        selectedDivisionId, selectedAssetImei, videoSearchSliderTextValue,
        isFetchingCacheQueueStatus, videoSearchRequestedStartSeconds,
    } = this.state;
    const { params } = match;
    let filterSideBar = (
        <VideoSearchFilter
            videoSearchHasData={videoSearchData?.info && videoSearchData?.locations}
            searchVideos={this.performVideoSearch}
            selectedDivisionId={selectedDivisionId}
            onChangeSelectedDivisionId={this.onChangeSelectedDivisionId}
            selectedAssetImei={selectedAssetImei}
            onChangeSelectedAssetImei={this.onChangeSelectedAssetImei}
            selectedDate={selectedDate}
            onChangeSelectedDate={this.onChangeSelectedDate}
            streamVideos={this.getVideoStreams}
            sliderTextValue={videoSearchSliderTextValue}
            onChangeSliderInput={this.setVideoSearchSliderTextValue}
            cacheVideos={this.queueCachedVideo}
            channelList={channelList}
            selectedDvrIsOnline={selectedDvrIsOnline}
        />
    );
    let selectedTab = params?.selectedTab;
    if (user.permissions.historic_video_downloading !== true && user.permissions.live_video === true) {
        selectedTab = 'live-videos';
    }
    if (selectedTab === 'live-videos') {
        filterSideBar = (<LiveVideoFilter onSubmit={this.liveVideoFilterSubmit} />);
    }
    if (selectedTab === 'cached-videos') {
        filterSideBar = (<CachedVideoFilter onSubmit={this.handleRequestedFilter} />);
    }
    let destroyFilter = false;
    if (!isVisibleSearchSidebar) {
        destroyFilter = true;
        filterSideBar = null;
    }

    const isLoading = isFetchingSearchedVideo || isFetchingCachedVideo || isFetchingLiveVideo || isFetchingVideoStreams || isFetchingCacheQueueStatus;
    return (
        <Layout
            data-test="pages-video"
            className="video-page"
            title={<div>Video</div>}
            headerTitle={<div>Video Page</div>}
            menuPlacement="right"
            showFooter={false}
            loading={isLoading}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                {selectedTab === 'live-videos' && !showSelectionScreen ? (
                    <>
                        <br />
                        <br />
                    </>
                ) : (
                    <FilterSidebar
                        registerCloseSidebar={(f) => { this.closeSidebar = f; }}
                        displayFilter={isVisibleSearchSidebar}
                        onToggleFilterSidebar={this.toggleVideoSearchSidebar}
                    >
                        {filterSideBar}
                    </FilterSidebar>
                )}
                <div 
                    className="content"
                    style={{ opacity: isLoading ? 0 : 1 }}
                >
                    <div className="content-inner">
                        <section className="section">
                            <Tabs
                                activeKey={selectedTab}
                                onTabClick={this.handleTabClick}
                                defaultActiveKey={selectedTab || 'cached-videos'}
                                animated={false}
                                className="tabs-restyled"
                            >
                                {user.permissions.historic_video_downloading === true && (
                                    <TabPane 
                                        tab="video search" 
                                        key="video-search"
                                    >
                                        <div className="section__body">
                                            <VideoSearchTab
                                                data={videoSearchData}
                                                selectedDate={selectedDate}
                                                timelineSegments={timelineSegments}
                                                geofenceTimelineSegments={geofenceTimelineSegments}
                                                selectedDvrIsOnline={selectedDvrIsOnline}
                                                selectedChannels={selectedChannels}
                                                toggleSelectedChannel={this.toggleSelectedChannel}
                                                onChangeSliderValue={this.onChangeSliderValue}
                                                isVisibleSearchSidebar={isVisibleSearchSidebar}
                                                isVisibleVideoTiles={isVisibleVideoTiles}
                                                channelLinks={videoSearchChannelLinks}
                                                eventTypes={eventTypes}
                                                parentIsLoading={isLoading}
                                                sliderTextValue={videoSearchSliderTextValue}
                                                videoSearchRequestedStartSeconds={videoSearchRequestedStartSeconds}
                                            />
                                        </div>
                                    </TabPane>
                                )}
                                {user.permissions.historic_video_downloading === true && (
                                    <TabPane 
                                        tab="downloads" 
                                        key="cached-videos"
                                    >
                                        <div className="section__body">
                                            {selectedTab === 'cached-videos' && (
                                                <CachedVideoTab 
                                                    checkDvrStatus={this.checkDvrStatus.bind(this)} 
                                                    setShouldShowSidebar={this.setShouldShowSidebar} 
                                                    registerCallback={this.registerOnRequestFilterCallback} 
                                                    assetId={assetId} 
                                                />
                                            )}
                                        </div>
                                    </TabPane>
                                )}
                                {user.permissions.live_video === true && (
                                    <TabPane 
                                        tab="live video" 
                                        key="live-videos"
                                    >
                                        <div className="section__body">
                                            <LiveVideosTab 
                                                checkDvrStatus={this.checkDvrStatus.bind(this)} 
                                                params={params} 
                                                setShouldShowSidebar={this.setShouldShowSidebar} 
                                            />
                                        </div>
                                    </TabPane>
                                )}
                            </Tabs>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
