/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { isUndefined, isNull } from 'underscore';
import { connect } from 'react-redux';
import DOM from './trueOdometerReport';
import * as eventsActions from '../../../../core/events/eventsActions';
import * as reportActions from '../../../../core/report/reportActions';
import * as assetActions from '../../../../core/asset/assetActions';
import dayjs from 'dayjs';

const { DB_DATE_FORMAT } = require('../../../../core/constants').default;

class TrueOdometerReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            filterSidebar: true,
            export: false,
            reportHasBeenFetched: false,
        };

        this.view = DOM;
    }

    componentDidMount() {
        const { actions, dataEventToReportParams } = this.props;
        actions.resetReportsRequest();

        let params = {
            from_date: dayjs().format(DB_DATE_FORMAT),
            to_date: dayjs().format(DB_DATE_FORMAT),
        };

        if (dataEventToReportParams !== null) {
            params = {
                ...params,
                ...dataEventToReportParams,
            };
            if (dataEventToReportParams.asset_id) params.asset_id = [dataEventToReportParams.asset_id];
            if (dataEventToReportParams.division_id) params.division_id = [dataEventToReportParams.division_id];

            actions.setDataEventToReportParams(null);

            this.setState({
                filters: params,
                filterSidebar: false,
            });
        }

        actions.getTrueOdometerReportRequest(params);
        this.onEventsTabFilterChange(params);
    }

    onEventsTabFilterChange = (values) => {
        const { actions } = this.props;

        const filters = values;
        if (filters.date_to) {
            filters.to_date = dayjs.isDayjs(values.date_to) ? values.date_to.format(DB_DATE_FORMAT) : values.date_to;
        }
        if (filters.date_from) {
            filters.from_date = dayjs.isDayjs(values.date_from) ? values.date_from.format(DB_DATE_FORMAT) : values.date_from;
        }
        if (!isNull(filters.asset_id) && !isUndefined(filters.asset_id)) filters.asset_id = filters.asset_id;
        if (!isNull(filters.division_id) && !isUndefined(filters.division_id)) filters.division_id = filters.division_id;

        this.setState({ filters });
        actions.getTrueOdometerReportRequest(filters);

        this.setState({ reportHasBeenFetched: true });
    };

    render() {
        return this.view({
            update: (newVals) => (
                this.props.actions.UpdateEventNotifications(newVals)
            ),
        });
    }
}

TrueOdometerReport.propTypes = {
    actions: PropTypes.object.isRequired,
    trueOdometerReport: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        trueOdometerReport: state.report.trueOdometerReport,
        events: state.events.companyEvents,
        user: state.user,
        export: state.export,
        filterSidebar: state.filterSidebar,
        dataEventToReportParams: state.global.dataEventToReportParams,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TrueOdometerReport);
