/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Row,
    Col,
    Tabs,
    Alert,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { isEmpty } from 'underscore';
import AccountPermissions from './tabs/AccountPermissions';
import AssetPermissions from './tabs/AssetPermissions';
import ReportPermissions from './tabs/ReportPermissions';
import DriverPermissions from './tabs/DriverPermissions';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';
import LeftSide from './partials/LeftSide';
import Avatar from '../../components/elements/Avatar';

const { TabPane } = Tabs;

export default function () {
    const {
        user, departments, divisions, userId, isFetching,
    } = this.props;
    const {
        currentTabKey,
    } = this.state;

    const filterContent = null;
    let assetPermissionsTabComponent = null;
    let accountPermissionsTabComponent = null;
    let reportPermissionsTabComponent = null;
    let driverPermissionsTabComponent = null;

    if (currentTabKey === '1') {
        accountPermissionsTabComponent = (
            <AccountPermissions userId={userId} />
        );
    } else if (currentTabKey === '2') {
        assetPermissionsTabComponent = (
            <AssetPermissions userId={userId} />
        );
    } else if (currentTabKey === '3') {
        driverPermissionsTabComponent = (
            <DriverPermissions userId={userId} />
        );
    } else if (currentTabKey === '4') {
        reportPermissionsTabComponent = (
            <ReportPermissions userId={userId} />
        );
    }

    const driverTabLabel = (user && user.userDetail && user.userDetail.name) || '[not-set]';

    return (
        <Layout
            data-test="pages-users-view"
            className="drivers-view"
            title={<div>UserView</div>}
            headerTitle={<div>UserView Page</div>}
            showFooter={false}
            loading={isFetching}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                {filterContent && (
                    <FilterSidebar>
                        {filterContent}
                    </FilterSidebar>
                )}
                <div 
                    className="content"
                    style={{ opacity: isFetching ? 0 : 1 }}
                >
                    <div className="content-inner">
                        <section className="section">
                            <div style={{ width: '100%', height: '32px'}} />
                            <div className="section__body">
                                <div className="top-section">

                                    <div
                                        className="section__action-bar justify-center"
                                        style={{ marginBottom: '16px' }}
                                    >
                                        <span className="edit-user-title">
                                            {user && user.userDetail ? user.userDetail.name : ''}
                                        </span>
                                    </div>
                                    <div className="actions__buttons">
                                        <Button
                                            type="primary"
                                            className="page-header-button"
                                            style={{ top: '-32px', right: '-32px' }}
                                            onClick={this.updateProfile}
                                        >
                                            <span>Save</span>
                                        </Button>
                                    </div>
                                </div>
                                <Row
                                    gutter={40}
                                    type="flex"
                                    justify="space-between"
                                    className="data-row"
                                >
                                    <Col xl={6}>
                                        {!isEmpty(user.userDetail) ? (
                                            <LeftSide
                                                currentUserRole={user.role}
                                                profile={user.userDetail}
                                                departments={departments}
                                                divisions={divisions}
                                                registerGetDetailsHandler={(f) => { this.getAccountDetails = f; }}
                                            />
                                        ) : null}
                                    </Col>
                                    <Col xl={11}>
                                        <div className="actions">
                                            <Row
                                                type="flex"
                                                gutter={40}
                                                justify="space-between"
                                            >
                                                <Col>
                                                    <br />
                                                    <Avatar
                                                        pictureUrl={user.userDetail.profilePictureFull}
                                                        onChange={(data) => this.updateDriverPicture(data)}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Tabs
                                    onTabClick={(key) => this.setState({ currentTabKey: key })}
                                    defaultActiveKey="1"
                                    animated={false}
                                    className="tabs-restyled2"
                                >
                                    <TabPane
                                        tab="Account Permissions"
                                        key="1"
                                    >
                                        <div className="section__body">
                                            <div style={{ height: 20 }} />
                                            {user.userDetail.role === 'super-admin' || user.userDetail.role === 'admin' || user.userDetail.role === 'manager' ? (
                                                <Alert message="Info" description="Admins, Super-Admins and Managers have all account permissions" type="info" />
                                            ) : accountPermissionsTabComponent}
                                        </div>
                                    </TabPane>
                                    <TabPane
                                        tab="Asset Permissions"
                                        key="2"
                                    >
                                        <div className="section__body">
                                            <div style={{ height: 20 }} />
                                            {user.userDetail.role === 'super-admin' || user.userDetail.role === 'admin' ? (
                                                <Alert message="Info" description="Admins and Super-Admins have all division permissions" type="info" />
                                            ) : assetPermissionsTabComponent}
                                        </div>
                                    </TabPane>
                                    <TabPane
                                        tab="Driver Permissions"
                                        key="3"
                                    >
                                        <div className="section__body">
                                            <div style={{ height: 20 }} />
                                            {user.userDetail.role === 'super-admin' || user.userDetail.role === 'admin' ? (
                                                <Alert message="Info" description="Admins, Super-Admins and Managers have all driver permissions" type="info" />
                                            ) : driverPermissionsTabComponent}
                                        </div>
                                    </TabPane>
                                    <TabPane
                                        tab="Report Permissions"
                                        key="4"
                                    >
                                        <div className="section__body">
                                            <div style={{ height: 20 }} />
                                            {user.userDetail.role === 'super-admin' || user.userDetail.role === 'admin' ? (
                                                <Alert message="Info" description="Admins and Super-Admins have all report permissions" type="info" />
                                            ) : reportPermissionsTabComponent}
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
